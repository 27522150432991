export default class Professional {
  constructor(userId, professionalId, cityId, countryId, overview, title, videoIntroduction, availability, languages, educations,
  	serviceCharge, workHistories, portfolioProjects, skills, testimonials, certifications, otherExperiences, jobPreferences, preferences,
    recommendations) {
  	this.userId = userId;
  	this.professionalId = professionalId;
    this.cityId = cityId;
    this.countryId = countryId;
    this.overview = overview;
    this.title = title;
    this.videoIntroduction = videoIntroduction;
    this.availability = availability;
    this.languages = languages;
    this.educations = educations;
    this.serviceCharge = serviceCharge;
    this.workHistories = workHistories;
    this.portfolioProjects = portfolioProjects;
    this.skills = skills;
    this.testimonials = testimonials;
    this.certifications = certifications;
    this.otherExperiences = otherExperiences;
    this.jobPreferences = jobPreferences;
    this.preferences = preferences;
    this.recommendations = recommendations;
  }
}
