import axios from 'axios';
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class ComplianceService{
	findAll(){
		return axios.get(GENERIC_SERVICE_URL + "compliance");
	}

	findById(id){
		return axios.get(GENERIC_SERVICE_URL + "compliance/" + id);
	}

	save(compliance){
		return axios.post(GENERIC_SERVICE_URL + "compliance", {
			id: compliance.id,
			name: compliance.name,
			countryId: compliance.countryId,
			category: compliance.category,
			description: compliance.description,
			occurrence: compliance.occurrence,
			startDate: compliance.startDate,
			endDate: compliance.endDate,
			status: compliance.status,
		});
	}
}

export default new ComplianceService;