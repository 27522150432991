export default class QualificationType{
	constructir(id, countryId, dieselPrice, petrolPrice, currency, active, current, enabled, expired, status, description){
		this.id = id;
		this.countryId = countryId;
		this.dieselPrice = dieselPrice;
		this.petrolPrice = petrolPrice;
		this.currency = currency;
		this.active = active;
		this.current = current;
		this.enabled = enabled;
		this.expired = expired;
		this.status = status;
		this.description = description;
	}
}