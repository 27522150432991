<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<router-link :to="{ name: 'configurations' }" replace><button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button></router-link>

		<h4 class="text-center">All Company Types</h4>
		
		<router-link :to="{ name: 'companyTypes/new' }" replace><button class="btn btn-success pull-right">New Company Type <span class="glyphicon glyphicon-plus"></span></button></router-link><br/><br/>

		
		<table class="table table-responsive table-bordered">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Company Type Name</th>
				<th>Description</th>
				<th>Status</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="companyType in companyTypes" :key="companyType.id">
					<td>{{ companyType.id }}</td>
					<td>{{ companyType.companyTypeName }}</td>
					<td>{{ companyType.companyTypeDescription }}</td>
					<td>
						<span class="label label-success" v-if="companyType.companyType_status == 1">Active</span>
						<span class="label label-default" v-if="companyType.companyType_status == 0">Inactive</span>
					</td>
					<td>
						<router-link :to="{ name: 'companyTypes/edit', params: { companyTypeId: companyType.id }}" replace><button class="btn btn-primary">Edit</button></router-link>&nbsp;
						<router-link :to="{ name: 'companyTypes/view', params: { companyTypeId: companyType.id }}" replace><button class="btn btn-primary">View</button></router-link>&nbsp;
						<button class="btn btn-danger" data-toggle="modal" data-target="#delete-role">Delete</button>
					</td>
				</tr>
			</tbody>
		</table>

		<!-- delete companyType modal -->
		<div class="modal fade in" id="delete-role" role="modal" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<div class="modal-title">Delete companyType Type</div>
					</div>
					<div class="modal-body">
						<h4 class="text-center">Are you sure you want to delete this companyType?</h4>
					</div>
					<div class="modal-footer">
						<button class="btn btn-default" data-dismiss="modal">Cancel</button>&nbsp;<button class="btn btn-danger" data-dismiss="modal">Delete anyway</button>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
	import CompanyTypeService from "@/services/CompanyTypeService";
	import loading from "vue-full-loading";

	export default {
		name: 'companyTypeList',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing...',
				companyTypes: []
			}
		},
		created(){
			this.getAllCompanyTypes();
		},
		methods: {
			getAllCompanyTypes(){
				this.show = true;
				CompanyTypeService.getAllCompanyTypes().then(response => {
					this.show = false;
					this.companyTypes = response.data.data;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>