<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<h4>Bank Details</h4><br>
		<div class="list-group">
			<div class="list-group-item">
				<p>{{ bank.bank_name}}</p>
				<p>{{ bank.bank_description}}</p>
				<p>
					<span class="label label-success" v-if="bank.bank_status == 1">Active</span>
					<span class="label label-default" v-if="bank.bank_status == 0">Inactive</span>
				</p>
				<p>
					<router-link :to="{ name: 'banks' }" replace><button class="btn btn-default" data-dismiss="modal">Back</button></router-link>
				</p>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Bank from "@/models/Bank";
	import BankService from "@/services/BankService";
	import loading from "vue-full-loading";

	export default {
		name: 'ViewBank',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing...',
				bank: new Bank("", "", ""),
			}
		},
		computed: {
			getBankId(){
				return this.$route.params.bankId;
			}
		},
		created(){
			this.getBankById(this.getBankId);
		},
		methods: {
			getBankById: function(id){
				this.show = true;
				BankService.getBankById(id).then(response => {
					this.show = false;
					this.bank = response.data.data;
					console.log('response: ', response.data.data);
				}).catch(error => {
					this.show = false;
					console.log('error: ', error);
					this.$router.push({ name: 'banks'});
				});
			}
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>