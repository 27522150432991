<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <div class="col-md-10">
        <h4>Product department details</h4>
      </div>
      <div class="col-md-2">
        <router-link :to="{ name: 'product-department-list'}">
          <button class="btn btn-warning btn-block">Back</button>
        </router-link>
      </div>
    </div>
    <br>

    <div class="list-group" v-if="productDepartment !== null">
      <div class="list-group-item">
        <p>Department name: {{ productDepartment.name }}</p>
        <p>Description: {{ productDepartment.description }}</p>
        <p>Status: <span class="label label-primary">{{ productDepartment.status }}</span></p>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import ProductDepartmentService from "@/services/ProductDepartmentService";
  import loading from "vue-full-loading";

  export default {
    name: "ViewProductDepartment",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing request",
        productDepartment: null
      };
    },

    computed: {
      getId() {
        return this.$route.params.id;
      }
    },

    mounted() {
      this.findById(this.getId);
    },

    methods: {
      findById: function(id) {
        this.show = true;
        ProductDepartmentService.findById(id).then(response => {
          this.show = false;
          this.productDepartment = response.data.body;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>
