import { GRANT_AND_FUNDING_SERVICE_URL } from "@/config/GrantAndFundingServiceConfig.js";
import axios from "axios";

class GrantAndFundingOpportunityService {
  findAll() {
    return axios.get(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities");
  }

  findById(id) {
    return axios.get(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities/" + id);
  }

   save(grantAndFundingOpportunity) {
    return axios.post(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities", {
      grantAndFundingOpportunityCategoryId: grantAndFundingOpportunity.grantAndFundingOpportunityCategoryId,
      grantAndFundingOpportunityId: grantAndFundingOpportunity.grantAndFundingOpportunityId,
      title: grantAndFundingOpportunity.title,
      currency: grantAndFundingOpportunity.currency,
      estimatedBudget: grantAndFundingOpportunity.estimatedBudget,
      expiryDate: grantAndFundingOpportunity.expiryDate,
      description: grantAndFundingOpportunity.description,
      status: grantAndFundingOpportunity.status,
    });
  }

  saveGrantAndFundingOpportunityAttachment(formData){
    return axios.post(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities/saveGrantAndFundingOpportunityAttachment", formData);
  }

  savePreference(userId, data){
    return axios.post(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities/savePreference/" + userId, data);
  }

  fetchLatestGrantAndFundingOpportunities() {
    return axios.get(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities/fetchLatestGrantAndFundingOpportunities");
  }

  fetchUpcomingDeadlinesGrantAndFundingOpportunities() {
    return axios.get(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunities/fetchUpcomingDeadlinesGrantAndFundingOpportunities");
  }
}

export default new GrantAndFundingOpportunityService;
