<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Carousel Promotion List</h4>
					</div>
					<router-link :to="{ name: 'new-carousel-promo' }">
						<div class="col-md-2">
							<button class="btn btn-primary btn-block">New Carousel Promo <span class="glyphicon glyphicon-plus"></span></button>
						</div>
					</router-link>
				</div>
				<hr>
				<!-- <form class="form">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group">
								<label>Section Type</label>
								<select class="form-control">
									<option value="">Select option</option>
									<option value="FULL_TIME_JOBS">Full time jobs</option>
									<option value="TENDER_JOBS">Contract jobs</option>
									<option value="VOLUNTEERING_JOBS">Volunteering jobs</option>
									<option value="SKILLED_EXPERTS">Skilled Experts</option>
									<option value="GRANT_AND_FUNDING">Grant & Funding</option>
									<option value="PRODUCTS">Product</option>
								</select>
							</div>
						</div>

						<div class="col-md-2">
							<label>Promo Runs From</label>
							<input type="date" class="form-control">
						</div>
						<div class="col-md-2">
							<label>Promo Runs To</label>
							<input type="date" class="form-control">
						</div>

						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-primary btn-block">Filter</button>
							</div>
						</div>
					</div>
				</form> -->
				<br>

				<table class="table table-bordered table-striped table-responsive">
					<thead class="bg-primary">
						<th>ID</th>
						<th>Headline</th>
						<th>Image</th>
						<th>Status</th>
						<th>Action</th>
					</thead>
					<tbody>
						<tr v-for="carouselPromotion in carouselPromotions" :key="carouselPromotion.id">
							<td>{{ carouselPromotion.id }}</td>
							<td>{{ carouselPromotion.headline }}</td>
							<td>{{ carouselPromotion.status }}</td>
							<td>
								<img :src="PDF_API_URL + 'pdf/getCarouselPromo/' + carouselPromotion.id" class="img img-responsive img-rounded" height="50" width="90">
							</td>
							<td>
								<router-link :to="{ name: 'view-carousel-promotion', params: { referenceNumber: carouselPromotion.referenceNumber }}">
									<button class="btn btn-primary btn-sm">View</button>
								</router-link>&nbsp;
								<span v-if="carouselPromotion.status === 'PENDING' && getRoles.includes('ADMIN')">
									<button class="btn btn-success btn-sm" @click="approve(carouselPromotion.id)">Approve</button>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import CarouselPromoService from "@/services/CarouselPromoService.js";
	import loading from "vue-full-loading";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

	export default{
		name: "CarouselPromoList",
		components: {
			loading
		},

		data: function () {
			return {
				carouselPromotions: [],
				label: 'Processing request',
				show: false,
				PDF_API_URL: PDF_API_URL,
			}
		},

		mounted: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				CarouselPromoService.findAll().then(response => {
					this.carouselPromotions = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			approve: function(id){
				CarouselPromoService.approve(id).then(() => {
					this.findAll();
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {
			getUser() {
				return this.$store.state.auth.user.user;
			},

		    getRoles() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		      	}
		      	return roles;
		    }
		}
	};
</script>