var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Please Select the Plan You Wish to Subscribe to Below")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel-heading"},[_vm._m(0),_vm._m(1),_c('h3',[_vm._v("$USD20")]),_c('router-link',{attrs:{"to":{ name: 'compliancePaymentPlanConfirm', params: { plan: 'BASIC'}}}},[_c('button',{staticClass:"btn btn-default"},[_vm._v("Choose Plan")])])],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel-heading"},[_vm._m(2),_vm._m(3),_c('h3',[_vm._v("$USD25")]),_c('router-link',{attrs:{"to":{ name: 'compliancePaymentPlanConfirm', params: { plan: 'ESSENTIALS'}}}},[_c('button',{staticClass:"btn btn-warning"},[_vm._v("Choose Plan")])])],1)]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel-heading"},[_vm._m(4),_vm._m(5),_c('h3',[_vm._v("$USD40")]),_c('router-link',{attrs:{"to":{ name: 'compliancePaymentPlanConfirm', params: { plan: 'PREMIUM'}}}},[_c('button',{staticClass:"btn btn-success"},[_vm._v("Choose Plan")])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("BASIC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Benefit 1")]),_c('li',[_vm._v("Benefit 2")]),_c('li',[_vm._v("Benefit 3")]),_c('br'),_c('br'),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("ESSENTIALS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Benefit 1")]),_c('li',[_vm._v("Benefit 2")]),_c('li',[_vm._v("Benefit 3")]),_c('li',[_vm._v("Benefit 4")]),_c('li',[_vm._v("Benefit 5")]),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('b',[_vm._v("PREMIUM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Benefit 1")]),_c('li',[_vm._v("Benefit 2")]),_c('li',[_vm._v("Benefit 3")]),_c('li',[_vm._v("Benefit 4")]),_c('li',[_vm._v("Benefit 5")]),_c('li',[_vm._v("Benefit 6")]),_c('li',[_vm._v("Benefit 7")])])
}]

export { render, staticRenderFns }