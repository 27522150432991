<template>
	<div>
		<router-link :to="{ name: 'jobConfigurationList' }" replace>
			<button class="btn btn-primary">Back</button>
		</router-link><br/><br/>

		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>New Request For Quotation</h4>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Job Title</label>
				<select class="form-control" v-model="requestForQuotation.jobTitleId">
					<option value="" disabled>Select option</option>
					<option v-for="jobTitle in jobTitles" :key="jobTitle.id" :value="jobTitle.id">{{ jobTitle.name }}</option>
				</select>
			</div>
			<div class="form-group">
				<label>Type</label>
				<select class="form-control" v-model="requestForQuotation.type">
					<option value="" disabled>Select option</option>
					<option value="FULL_TIME">FULL_TIME</option>
					<option value="PART_TIME">PART_TIME</option>
					<option value="VOLUNTEERING">VOLUNTEERING</option>
					<option value="TENDER">TENDER</option>
				</select>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Hourly Rate</label>
						<input type="text" name="hourlyRate" v-model="requestForQuotation.hourlyRate" placeholder="Enter Hourly Rate" class="form-control">
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>Currency</label>
						<select class="form-control" v-model="requestForQuotation.currency">
							<option value="" disabled>Select option</option>
							<option value="USD">USD</option>
							<option value="ZWL">ZWL</option>
							<option value="RAND">RAND</option>
							<option value="PULA">PULA</option>
							<option value="KWACHA">KWACHA</option>
							<option value="SHILLING">SHILLING</option>
						</select>
					</div>
				</div>
			</div>

			<div class="form-group">
				<label>Expiry Date</label>
				<input type="date" name="expiryDate" v-model="requestForQuotation.expiryDate" class="form-control">
			</div>
			
			<div class="form-group">
				<label>Availability</label>
				<select class="form-control" v-model="requestForQuotation.availability">
					<option value="" disabled>Select option</option>
					<option value="MORE_THAN_30_HOURS_PER_WEEK">MORE_THAN_30_HOURS_PER_WEEK</option>
					<option value="LESS_THAN_30_HOURS_PER_WEEK">LESS_THAN_30_HOURS_PER_WEEK</option>
					<option value="AS_NEEDED">AS_NEEDED</option>
					<option value="NONE">NONE</option>
				</select>
			</div>

			<div class="form-group">
				<label>Expertise Level</label>
				<select class="form-control" v-model="requestForQuotation.expertiseLevel">
					<option value="" disabled>Select option</option>
					<option value="JUNIOR">JUNIOR</option>
					<option value="INTERMEDIATE">INTERMEDIATE</option>
					<option value="MIXTURE_OF_SKILLS">MIXTURE_OF_SKILLS</option>
					<option value="HIGHLY_SKILLED">HIGHLY_SKILLED</option>
					<option value="EXPERT">EXPERT</option>
				</select>
			</div>

			<div class="form-group">
				<label>Payment Terms</label>
				<select class="form-control" v-model="requestForQuotation.paymentTerms">
					<option value="" disabled>Select option</option>
					<option value="PAY_ON_DELIVERY">PAY_ON_DELIVERY</option>
					<option value="PAID_PER_HOUR">PAID_PER_HOUR</option>
					<option value="ONCE_OFF">ONCE_OFF</option>
					<option value="ONGOING_PER_DELIVERABLE">ONGOING_PER_DELIVERABLE</option>
				</select>
			</div>

			<div class="form-group">
				<label>Reach</label>
				<select class="form-control" v-model="requestForQuotation.reach">
					<option value="" disabled>Select option</option>
					<option value="LOCAL">LOCAL</option>
					<option value="INTERNATIONAL">INTERNATIONAL</option>
				</select>
			</div>

			<div class="form-group">
				<label>Project Type</label>
				<select class="form-control" v-model="requestForQuotation.projectType">
					<option value="" disabled>Select option</option>
					<option value="SHORT_TERM">SHORT_TERM</option>
					<option value="LONG_TERM">LONG_TERM</option>
					<option value="AS_NEEDED">AS_NEEDED</option>
				</select>
			</div>

			<div class="form-group">
				<label>Project length in months</label>
				<input type="text" name="projectLengthInMonths" v-model="requestForQuotation.projectLengthInMonths" placeholder="Enter project length in months" class="form-control">
			</div>
			
			<div class="form-group">
				<label>Commitment hours per week</label>
				<input type="text" name="commitmentHoursPerWeek" v-model="requestForQuotation.commitmentHoursPerWeek" placeholder="Enter commitment hours per week" class="form-control">
			</div>

			<div class="form-group">
				<label>Submission Requirements And Instructions</label>
				<input type="text" name="submissionRequirementsAndInstructions" v-model="requestForQuotation.submissionRequirementsAndInstructions" placeholder="Enter submissionRequirementsAndInstructions" class="form-control">
			</div>

			<div class="form-group">
				<label>Terms And Conditions</label>
				<input type="text" name="termsAndConditions" v-model="requestForQuotation.termsAndConditions" placeholder="Enter termsAndConditions" class="form-control">
			</div>

			<div class="form-group">
				<label>Evaluation Method</label>
				<select class="form-control" v-model="requestForQuotation.evaluationMethod">
					<option value="" disabled>Select option</option>
					<option value="SHORT_TERM">SHORT_TERM</option>
					<option value="LONG_TERM">LONG_TERM</option>
					<option value="AS_NEEDED">AS_NEEDED</option>
				</select>
			</div>

			<div class="row">
				<h4>Prefered Location Restriction</h4>
				<div class="col-md-3">
					<div class="form-group">
						<label>Country</label>
						<select class="form-control" v-model="location.countryId" v-on:change="findByCountryId">
							<option value="" disabled>Select option</option>
							<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Province</label>
						<select class="form-control" v-model="location.provinceId" v-on:change="findByProvinceId">
							<option value="" disabled>Select option</option>
							<option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>City</label>
						<select class="form-control" v-model="location.cityId" @change="findByCityId">
							<option value="" disabled>Select option</option>
							<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
						</select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Area</label>
						<select class="form-control" v-model="selectedAreas" multiple>
							<option value="" disabled>Select option</option>
							<option v-for="area in areas" :key="area.id" :value="area.id">{{ area.name }}</option>
						</select>
					</div>
				</div>
			</div>
			<br>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import RequestForQuotationService from "@/services/RequestForQuotationService";
	import CountryService from "@/services/CountryService";
	import ProvinceService from "@/services/ProvinceService";
	import CityService from "@/services/CityService";
	import AreaService from "@/services/AreaService";
	import RequestForQuotation from "@/models/RequestForQuotation";
	import Location from "@/models/Location";

	export default{
		name: "NewRequestForQuotation",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				requestForQuotation: new RequestForQuotation,
				label: "Processing...",
				show: false,
				formData: new FormData,
				zipCodes: [],
				countries: [],
				provinces: [],
				cities: [],
				areas: [],
				location: new Location,
				selectedAreas: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			}
		},

		methods: {
			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCountryId: function(){
				ProvinceService.findByCountryId(this.location.countryId).then(response => {
					this.provinces = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByProvinceId: function(){
				CityService.findByProvinceId(this.location.provinceId).then(response => {
					this.cities = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCityId: function(){
				AreaService.findByCityId(this.location.cityId).then(response => {
					this.areas = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.show = true;
				let selectedAreas = [];
				for(var i = 0; i < this.selectedAreas.length; i++){
					selectedAreas.push({
						areaId: this.selectedAreas[i]
					});
				}
				this.requestForQuotation.areaRestriction = selectedAreas;
				this.requestForQuotation.userId = this.getUser.userId;
				RequestForQuotationService.save(this.requestForQuotation).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({
							name: "newRequestForQuotationAttachment", params: { requestForQuotationId: response.data.body.id }});
					}
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>