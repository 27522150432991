import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CarouselPromoService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "carouselPromos");
  }

  findByReferenceNumber(referenceNumber) {
    return axios.get(GENERIC_SERVICE_URL + "carouselPromos" + "/findByReferenceNumber/" + referenceNumber);
  }

  save(formData) {
    return axios.post(GENERIC_SERVICE_URL + "carouselPromos", formData);
  }

  approve(id) {
    return axios.post(GENERIC_SERVICE_URL + "carouselPromos/approve/" + id);
  }
}

export default new CarouselPromoService;
