<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>My assigned orders</h4>
			    <br>
			   	<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary">
				        <th>Order Number</th>
				        <th>Order Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Order Status</th>
				        <th>Delivery Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="carrierOrder in carrierOrders" :key="carrierOrder.id">
				        	<td>{{ carrierOrder.order.orderNumber }}</td>
				        	<td>$$$</td>
				          	<td>
				          		{{ carrierOrder.order.deliveryAddress.location !== null ? carrierOrder.order.deliveryAddress.location.city.name + ' ' + carrierOrder.order.deliveryAddress.location.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ carrierOrder.order.deliveryAddress.location !== null ? carrierOrder.order.deliveryAddress.location.city.name + ' ' + carrierOrder.order.deliveryAddress.location.province.name: '' }}
				          	</td>
				          	<td>
				          		<!-- <span v-if="carrierOrder.booking.assignedTruck !== null">
				          			<router-link :to="{ name: 'trucks/view', params: { truckNumber: carrierOrder.booking.assignedTruck.truckNumber }}">
				          				{{ carrierOrder.booking.assignedTruck !== null ? carrierOrder.booking.assignedTruck.registrationNumber: '' }}
				          			</router-link>
				          		</span> -->
				          	</td>
				          	<td>{{ carrierOrder.order.status }}</td>
				          	<!-- <td>
				          		<span :class="carrierOrder.order.collected ? 'glyphicon glyphicon-thumbs-up': 'glyphicon glyphicon-thumbs-down'">
				          		</span>
				          	</td> -->
				          	<!-- <td>
				          		<span v-show="loadBooking.load.carrierConfirmedDelivery" class="label label-success">Yes</span>
				          		<span v-show="!loadBooking.load.carrierConfirmedDelivery" class="label label-success">In transit</span>
				          	</td> -->
				          	<td>
				          		<!-- <span>
				          			<router-link :to="{ name: 'bookings/view', params: { bookingNumber: loadBooking.bookingNumber }}">
				          				<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
				          			</router-link>&nbsp;
				          		</span> -->
				          		<span>
				          			<router-link :to="{ name: 'view-product-order', params: { orderNumber: carrierOrder.order.orderNumber }}">
				          				<button class="btn btn-primary btn-md"> View details <span class="glyphicon glyphicon-info-sign"></span></button>
				          			</router-link>
				          		</span>&nbsp;
				          		<span v-if="!carrierOrder.order.accepted">
				          			<router-link :to="{ name: 'accept-or-decline-order-offer', params: { orderNumber: carrierOrder.order.orderNumber }}">
				          				<button class="btn btn-primary btn-md"><span class="glyphicon glyphicon-thumbs-up"></span> Accept or decline offer <span class="glyphicon glyphicon-thumbs-down"></span></button>
				          			</router-link>
				          		</span>&nbsp;
				          		<!-- <span v-show="loadBooking.load.released && !loadBooking.load.collected">
				          			<button class="btn btn-success" @click="collected(loadBooking.load)">Confirm Collection</button>
				          		</span>
				          		<span v-show="loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.carrierConfirmedDelivery">
				          			<button class="btn btn-success" @click="carrierConfirmedDelivery(loadBooking.load)">Confirm delivery</button>
				          		</span> -->
				          		<span>
				          			<button class="btn btn-danger btn-md">Assign Vehicle</button>
				          		</span>
				          	</td>
				          </tr>
				      </tbody>
				</table>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import CarrierOrderService from "@/services/CarrierOrderService.js";

	export default{
		name: 'Bookings',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    carrierOrders: []
			}
		},

		mounted(){
			this.findByBookingCarrierAssociatedUserUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			// findByCarrierAssociatedUserUsername: function() {
			// 	this.show = true;
		 //        BookingService.findByCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		 //        	this.bookings = response.data.body;
		 //        	this.show = false;
		 //        }).catch(error => {
		 //        	console.log(error);
		 //        	this.show = false;
		 //        });
		 //    },

		    findByBookingCarrierAssociatedUserUsername: function() {
				this.show = true;
		        CarrierOrderService.findAll(this.getUser.username).then(response => {
		        	this.carrierOrders = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		 //    collected: function(load){
			// 	this.show = true;
			// 	LoadService.collected(load).then(response => {
			// 		if (response.data.statusCode === 201){
			// 			this.findByBookingCarrierAssociatedUserUsername();
			// 		}
			// 	this.show = false;
			// 	}).catch(error => {
			// 		console.log(error);
			// 		this.show = false;
			// 	});
			// },

			// carrierConfirmedDelivery: function(load){
			// 	this.show = true;
			// 	LoadService.carrierConfirmedDelivery(load).then(response => {
			// 		if (response.data.statusCode === 201){
			// 			this.findByBookingCarrierAssociatedUserUsername();
			// 		}
			// 	this.show = false;
			// 	}).catch(error => {
			// 		console.log(error);
			// 		this.show = false;
			// 	});
			// }
		}
	};
</script>