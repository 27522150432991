<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <h4>Edit Role</h4>
    <br />
    <form class="form" @submit.prevent="updateBank">
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          v-model="role.name"
          placeholder="Enter role name"
          class="form-control"
        />
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-messages">
            <span v-if="error.field === 'bank_name'">{{ error.message }}</span>
          </p>
        </div>
      </div>
      <div class="form-group">
        <label>Description</label>
        <input
          type="text"
          v-model="role.description"
          placeholder="Enter role description"
          class="form-control"
        />
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-messages">
            <span v-if="error.field === 'bank_description'">{{
              error.message
            }}</span>
          </p>
        </div>
      </div>
      <div class="form-group">
        <label>Status</label>
        <select v-model="role.status" class="form-control">
          <option value="">Select status</option>
          <option value="1">Active</option>
          <option value="0">Inactive</option>
        </select>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-messages">
            <span v-if="error.field === 'bank_status'">{{
              error.message
            }}</span>
          </p>
        </div>
      </div>
      <div class="form-group">
        <label>&nbsp;</label>
        <router-link :to="{ name: 'roles' }" replace
          ><button class="btn btn-default" data-dismiss="modal">
            Cancel
          </button></router-link
        >
        &nbsp; <button class="btn btn-success">Save & Submit</button>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
import Role from "@/models/Role";
import RoleService from "@/services/RoleService";
import loading from "vue-full-loading";

export default {
  name: "CreateProductCategory",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Processing...",
      role: new Role(),
      errors: []
    };
  },
  computed: {
    getRoleId() {
      return this.$route.params.roleId;
    }
  },
  created() {
    this.findById(this.getRoleId);
  },
  methods: {
    findById: function(id) {
      RoleService.findById(id)
        .then(response => {
          this.role = response.data.data;
        })
        .catch(error => {
          this.$router.push({ name: "roles" });
          console.log(error);
        });
    },
    updateBank: function() {
      this.show = true;
      this.role.id = this.getRoleId;
      RoleService.updateById(this.role, this.getRoleId)
        .then(response => {
          console.log(response.data.errors);
          if (response.data.statusCode == 422) {
            this.show = false;
            this.errors = response.data.errors;
          }
          if (response.data.statusCode == 200) {
            this.show = false;
            this.$router.push({ name: "roles" });
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error);
          this.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
