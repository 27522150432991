<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Add New Job Preference.</h4><br>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Job Preferences</label>
				<p>Keeping your skills up to date helps you get the jobs you want.</p>
				<select class="form-control" id="selectedJobPreferences" name="selectedJobPreferences" v-model="selectedJobPreferences" multiple>
					<option value="" disabled>Select option</option>
					<option value="FULL_TIME">FULL_TIME</option>
					<option value="CONTRACT">CONTRACT</option>
					<option value="VOLUNTEERING">VOLUNTEERING</option>
					<option value="TENDER">TENDER</option>
				</select>
			</div>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import SkillService from "@/services/SkillService";
	import User from "@/models/User";

	export default{
		name: "newWorkHistory",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				user: new User,
				label: "Processing...",
				show: false,
				selectedJobPreferences: [],
				skills: [],
			}
		},

		created: function(){
			this.findAllSkills();
		},

		methods: {
			findAllSkills: function(){
				SkillService.findAll().then(response => {
					this.skills = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			save: function(){
				this.show = true;
				let mySelectedJobPreferences = [];
				for(var i = 0; i < this.selectedJobPreferences.length; i++){
					mySelectedJobPreferences.push({ jobType: this.selectedJobPreferences[i] });
				}
				this.user.jobPreferences = mySelectedJobPreferences;
				ProfessionalService.save(this.user).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "jobPreferenceProfile" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>