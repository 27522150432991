import { render, staticRenderFns } from "./TenderConfigurationList.vue?vue&type=template&id=51680740&scoped=true&"
import script from "./TenderConfigurationList.vue?vue&type=script&lang=js&"
export * from "./TenderConfigurationList.vue?vue&type=script&lang=js&"
import style0 from "./TenderConfigurationList.vue?vue&type=style&index=0&id=51680740&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51680740",
  null
  
)

export default component.exports