export default class Product{
	constructor(id, categoryId, departmentId, name, quantity, unitPrice, photos, percentageDiscount, inStock, 
		availableLocationsOfProduct, deliveryFulfilledBy, availableQuantity, currency, 
		eligibleForNextDayDeliveryOrCollection, eligibleForCashOnDelivery, freeDeliveryAvailable, 
		hassleFreeExchangesAndReturnsFor30Days, warranty, buyNowPayLater, reviews, companyId, sellerId, status,
		description, productPhotos){
		this.id = id;
		this.categoryId = categoryId;
		this.departmentId = departmentId;
		this.name = name;
		this.quantity = quantity;
		this.unitPrice = unitPrice;
		this.photos = photos;
		this.percentageDiscount = percentageDiscount;
		this.inStock = inStock;
		this.availableLocationsOfProduct = availableLocationsOfProduct;
		this.deliveryFulfilledBy = deliveryFulfilledBy;
		this.availableQuantity = availableQuantity;
		this.currency = currency;
		this.eligibleForNextDayDeliveryOrCollection = eligibleForNextDayDeliveryOrCollection;
		this.eligibleForCashOnDelivery = eligibleForCashOnDelivery;
		this.freeDeliveryAvailable = freeDeliveryAvailable;
		this.hassleFreeExchangesAndReturnsFor30Days = hassleFreeExchangesAndReturnsFor30Days;
		this.warranty = warranty;
		this.buyNowPayLater = buyNowPayLater;
		this.reviews = reviews;
		this.companyId = companyId;
		this.sellerId = sellerId;
		this.status = status;
		this.description = description;
		this.productPhotos = productPhotos;
	}
}