<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-8">
						<h4>Products list</h4>
					</div>
					<div class="col-md-2">
						<button class="btn btn-primary btn-block" @click="refresh()">Refresh</button>
					</div>
					<div class="col-md-2" v-if="sellerProductUploadLimitReached !== true">
						<router-link :to="{ name: 'new-product'}">
							<button class="btn btn-primary btn-block pull-right">New <span class="glyphicon glyphicon-plus"></span></button>
						</router-link>
					</div>
					<div class="col-md-2" v-if="sellerProductUploadLimitReached">
						<router-link :to="{ name: 'new-product'}">
							<button class="btn btn-success btn-block">New Paid Product <span class="glyphicon glyphicon-plus"></span></button>
						</router-link>
					</div>
				</div>
				<br><br>

				<table class="table table-responsive table-bordered table-hover">
					<thead class="bg-primary">
						<th>ID</th>
						<th>Name</th>
						<th>Category</th>
						<th>Price</th>
						<th>Premium</th>
						<th>Actions</th>
					</thead>
					<tbody>
						<tr v-for="product in products" :key="product.id">
							<td>{{ product.id }}</td>
							<td>{{ product.name }}</td>
							<td>{{ product.category !== null ? product.category.name: '' }}</td>
							<td>{{ product.currency }}{{ product.price }}</td>
							<td><span class="label label-success" v-if="product.premium">Premium</span></td>
							<td>
								<router-link :to="{ name: 'productsPreview/view', params: { productId: product.id }}">
									<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
								</router-link>&nbsp;
								<router-link :to="{ name: 'new-premium-product', params: { productId: product.id }}" v-if="!product.premium">
									<button class="btn btn-success">Make Premium <span class="glyphicon glyphicon-edit"></span></button>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>			
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProductService from "@/services/ProductService";
	import SellerService from "@/services/SellerService";

	export default{
		name: "ProductListPreview",
		components: {
			loading
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				products: [],
				cartProducts: [],
				addedToCart: false,
				seller: null,
				sellerProductUploadLimitReached: null
			}
		},

		mounted(){
			this.findAll();
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts") || '[]');
			localStorage.removeItem("cartProducts");
			console.log("cartProducts: ", this.cartProducts);
			this.findByAssociatedAccountUsername();
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProductService.findAll().then(response => {
					this.products = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			addToCart: function(index, product){
				console.log(index);
				console.log(product);
				if(this.products[index].addedToCart){
					this.products[index].addedToCart = false;
					console.log("remove from cartProducts");
					this.cartProducts.splice(index, 1);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
					console.log(this.cartProducts);
				}else{
					this.products[index].addedToCart = true;
					console.log("add to cartProducts");
					this.cartProducts.push(product);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
					console.log("cartProducts: ", this.cartProducts);
				}
			},

			refresh(){
				this.findAll();
			},

			findByAssociatedAccountUsername: function(){
				this.show = true;
				SellerService.findByAssociatedAccountUsername(this.getUser.username).then(response => {
					this.seller = response.data.body;
					this.checkSellerProductUploadLimit();
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			checkSellerProductUploadLimit: function(){
				this.show = true;
				ProductService.checkSellerProductUploadLimit(this.seller.id).then(response => {
					this.sellerProductUploadLimitReached = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},
		},

		computed: {
			getUser(){
				return this.$store.state.auth.user.user;
			}
		}
	};
</script>