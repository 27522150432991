<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<h4>MyProductListDetails</h4>
		<p><router-link :to="{ name: 'sellers/products', params: { sellerId: getSellerId, productId: getProductId }}"><button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button></router-link></p>

		<div class="row">
			<div class="col-md-9">
				<div class="panel">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-3">
								<h4>{{ product.percentageOff }}% off</h4>
								<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded" width="280" height="200">
							</div>
							<div class="col-md-6">
								<h4>{{ product.name }}</h4>
								<h4>{{ product.name }}</h4>
								<p>{{ product.description }}</p>
								<p>4.3, 104 Reviews</p>
								<hr>
								<h4>In stock <span class="badge">{{ product.inStock ? "Yes": "No" }}</span>, JHBCPT, When do I get it?</h4>
								<p>Sold by <span><a href="#">{{ product.soldBy }}</a></span>. Fulfilled by <span><a href="#">{{ product.deliveryFulfilledBy }}</a></span></p>
								<hr>
								<ul>
									<li>Eligible for next-day delivery or collection. <span class="badge">{{ product.eligibleForNextDayDeliveryOrCollection ? "Yes": "No" }}</span></li>
									<li>Eligible for Cash on Delivery. <span class="badge">{{ product.eligibleForCashOnDelivery ? "Yes": "No" }}</span></li>
									<li>Free Delivery Available. <span class="badge">{{ product.freeDeliveryAvailable ? "Yes": "No" }}</span></li>
									<li>Hassle-Free Exchanges & Returns for 30 Days. <span class="badge">{{ product.hassleFreeExchangesAndReturnsFor30Days ? "Yes": "No" }}</span></li>
									<li>12-Month Limited Warranty. <span class="badge">{{ product.twelveMonthLimitedWarranty ? "Yes": "No" }}</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="panel">
					<div class="panel-body">
						<h4>Description</h4>
						<p>These goods have been reconditioned by replacing some components and reconditioned, as the case may be and 12 months carry-in warranty on this item will be honoured or fulfilled by us. However, any warranty claims will NOT BE covered when is due to malicious damage, software and data, lighting damage and power surge. Returns will be handled according to the relevant Third-Party Supplier or in accordance with our Returns Policy.</p>
					</div>
				</div>

				<h4>You Might Also Like</h4>
				<div class="row">
					<div class="col-md-3" v-for="product in products" :key="product.id">
						<div class="panel">
							<div class="panel-body">
								<p>{{ product.percentageOff }}% off <span class="pull-right">Like/Dislike</span></p>
								<img src="@/assets/images/professional-1024x768.jpeg" height="100" width="150" class="img img-rounded"><br><br>
								<img src="@/assets/images/professional-1024x768.jpeg" height="20" width="20" class="img img-rounded">
								<p>{{ product.name }}</p>
								<h4>{{ product.description }}</h4>
								<p>Current price: {{ product.currency }}{{ product.unitPrice }}. Was <del>{{ product.currency }}{{ product.unitPrice }}</del></p>
								<p><span class="badge">JHB</span> <span class="badge">CPT</span></p>
								<p>
									<button class="btn btn-primary">Add to cart <span class="glyphicon glyphicon-plus"></span></button>&nbsp;
									<router-link :to="{ name: 'myProductListDetails/view', params: { productId: product.id }}">
										<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="panel">
					<div class="panel-body">
						<h4>{{ product.currency }}{{ product.unitPrice }}</h4>
						<p><span class="badge">{{ product.freeDeliveryAvailable ? "FREE DELIVERY": "FREE DELIVERY NOT AVAILABLE" }}</span></p>
						<p><del>{{ product.currency }}{{ product.unitPrice }}</del></p>
						<p>Ebucks logo eB19,250 Discovery Miles 19,250</p>
						<hr>
						<p>{{ product.buyNowPayLater ? "Buy Now, Pay Later": "Buy Now, Pay Later Not Available" }}</p>
						<p>Pay 4 interest-free instalments of R 481 using Payflex. Learn More</p>
						<p><button class="btn btn-primary">Add to cart</button></p>
						<p><button class="btn btn-primary">Add to wish list</button></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import loading from "vue-full-loading";
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

	export default{
		name: "MyProductListDetails",
		components: {
			loading
		},

		data: function(){
			return {
				products: [],
				product: null,
				show: false,
				label: "Processing request",
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL
			}
		},

		created(){
			this.findById();
			this.findAll();
		},

		computed: {
			getProductId: function(){
				return this.$route.params.productId;
			},

			getSellerId: function(){
				return this.$route.params.sellerId;
			},
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProductService.findAll().then(response => {
					this.show = false;
					this.products = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			findById: function(){
				this.show = true;
				ProductService.findById(this.getProductId).then(response => {
					this.show = false;
					this.product = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
	.processing-row {
	  display: flex;
	  justify-content: center;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
	.error-message {
	  color: red;
	}
</style>
