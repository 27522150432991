<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>View Job Details</h4>
					</div>
					<router-link :to="{ name: 'my-contract-jobs-configuration'}">
						<div class="col-md-2">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>
				<br>

				<ul class="list-group" v-if="job !== null">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">ID: {{ job.id }}</li>
					<li class="list-group-item">Job Number: {{ job.jobNumber }}</li>
					<li class="list-group-item">Job Title: {{ job.title }}</li>
					<li class="list-group-item">Type: {{ job.type }}</li>
					<span v-if="job.type === 'FULL_TIME'">
						<li class="list-group-item">Fixed Rate: {{ job.currency }}{{ job.fixedRate }} per hour</li>
					</span>
					<span v-if="job.type === 'CONTRACT'">
						<li class="list-group-item">Hourly Rate: {{ job.currency }} {{ job.hourlyRate }} per hour</li>
					</span>
					<li class="list-group-item">Expiry Date: {{ job.expiryDate }}</li>
					<li class="list-group-item">Skills Level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Availability: {{ job.availability }}</li>
					<li class="list-group-item">Estimated Budget: {{ job.estimatedBudget }}</li>
					<li class="list-group-item">Expertise Level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Payment Terms: {{ job.paymentTerms }}</li>
					<li class="list-group-item">Project Type: {{ job.projectType }}</li>
					<li class="list-group-item">Commitment hours per week: {{ job.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Project length: {{ job.projectLengthInMonths }}</li>
					<li class="list-group-item">Description: {{ job.description }}</li>
					<li class="list-group-item">
						<a :href="PDF_API_URL + 'pdf/getJobAttachment/' + job.id" target="_blank">View Job Attachment</a>
					</li>
					<li class="list-group-item" v-if="job.location !== null">
						Job location
						<ul class="list-group">
							<li class="list-group-item">Country: {{ job.location.country !== null ? job.location.country.name: 'NA' }}</li>
							<li class="list-group-item">Province: {{ job.location.province !== null ? job.location.province.name: 'NA' }}</li>
							<li class="list-group-item">City: {{ job.location.city !== null ? job.location.city.name: 'NA' }}</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

	export default{
		name: "ViewJob",
		component: {

		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		data: function(){
			return {
				job: null,
				PDF_API_URL: PDF_API_URL
			}
		},

		created: function(){
			this.findById();
		},

		methods: {
			findById: function(){
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>