<template>
	<div>
		<router-link :to="{ name: 'compliance-management' }" replace>
			<button class="btn btn-primary">Back</button>
		</router-link>
		<br><br>

		<h4>Compliance Impact Report</h4>
		<hr>

		<div class="row">
			<div class="col-md-10"></div>
			<div class="col-md-2">
				<select class="form-control">
					<option>Choose option</option>
					<option>Last 15 days</option>
					<option>Last 30 days</option>
					<option>Last month</option>
				</select>
			</div>
		</div>

        <div class="row">
	      <div class="col-md-4">
	        <zingchart :data="proposalLineChart" :height="300" :width="600"/>
	      </div>
	      <div class="col-md-4">
	        <zingchart :data="proposalBarChart" :height="300" :width="600"/>
	      </div>
	      <div class="col-md-4">
	        <zingchart :data="proposalPieChart" :height="300" :width="600"/>
	      </div>
	    </div>

	    <div>
	    	<div class="col-md-8"></div>
	    	<div class="col-md-4">
	          <h4><u>KEY</u></h4>
	          <div class="btn-group text-center">
	            <button type="button" class="btn btn-success">One Time</button>
	        	<button type="button" class="btn btn-warning">On Going</button>
	        	<button type="button" class="btn btn-primary">Event Based</button>
	        	<button type="button" class="btn btn-danger">Recurring</button>
	          </div>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	export default{
		data: function(){
			return {
				proposalLineChart: {
          type: 'line',
          series: [{
            values: [13,23,14,14,19,17,18,29,19,19]
          }],

          title: {
            fontColor: "#8e99a9",
            text: 'Compliances Frequency',
            align: "left",
            offsetX: 10,
            fontFamily: "Open Sans",
            fontSize: 25
          },
        },

        proposalBarChart: {
          type: 'bar',
          series: [{
            values: [13,23,14,14,19,17,18,29,19,19]
          }],
          title: {
            fontColor: "#8e99a9",
            text: 'Monthly Compliances',
            align: "left",
            offsetX: 10,
            fontFamily: "Open Sans",
            fontSize: 25
          },
        },
				proposalPieChart: {
	          type: 'pie',
	            // plot: {
	            //   borderColor: "#2B313B",
	            //   borderWidth: 5,
	            //   // slice: 90,
	            //   valueBox: {
	            //     placement: 'out',
	            //     text: '%t\n%npv%',
	            //     fontFamily: "Open Sans"
	            //   },
	            //   tooltip: {
	            //     fontSize: '18',
	            //     fontFamily: "Open Sans",
	            //     padding: "5 10",
	            //     text: "%npv%"
	            //   },
	            //   animation: {
	            //     effect: 2,
	            //     method: 5,
	            //     speed: 900,
	            //     sequence: 1,
	            //     delay: 300
	            //   }
	            // },
	            // source: {
	            //   text: 'gs.statcounter.com',
	            //   fontColor: "#8e99a9",
	            //   fontFamily: "Open Sans"
	            // },
	            title: {
	              fontColor: "#8e99a9",
	              text: 'Breakdown by category',
	              align: "left",
	              offsetX: 10,
	              fontFamily: "Open Sans",
	              fontSize: 25
	            },
	            // subtitle: {
	            //   offsetX: 10,
	            //   offsetY: 10,
	            //   fontColor: "#8e99a9",
	            //   fontFamily: "Open Sans",
	            //   fontSize: "16",
	            //   text: 'May 2016',
	            //   align: "left"
	            // },
	            // plotarea: {
	            //   margin: "20 0 0 0"
	            // },
	            series: [{
	                values: [20],
	                text: "One time",
	                backgroundColor: '#50ADF5',
	              },
	              {
	                values: [40],
	                text: "On Going",
	                backgroundColor: '#FF7965',
	                // detached: true
	              },
	              {
	                values: [20],
	                text: 'Event Based',
	                backgroundColor: '#FFCB45',
	                // detached: true
	              },

	              {
	                text: 'Recurring',
	                values: [20],
	                backgroundColor: '#6FB07F'
	              }
	            ]
	          },
			}
		},

		computed: {
			getComplianceManagementByName: function(){
				return this.$route.params.name;
			}
		}
	};
</script>