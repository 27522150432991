import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class TenderCategoryService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "tenderCategories");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "tenderCategories/" + id);
  }

  save(tenderCategory) {
    return axios.post(GENERIC_SERVICE_URL + "tenderCategories", {
      cityId: tenderCategory.cityId,
      name: tenderCategory.name,
      description: tenderCategory.description,
      status: tenderCategory.status
    });
  }
}

export default new TenderCategoryService;
