import { GRANT_AND_FUNDING_SERVICE_URL } from "@/config/GrantAndFundingServiceConfig.js";
import axios from "axios";

class GrantAndFundingOpportunityCategoryService {
  findAll() {
    return axios.get(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunityCategories");
  }

  findById(id) {
    return axios.get(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunityCategories/" + id);
  }

   save(grantAndFundingOpportunityCategory) {
    return axios.post(GRANT_AND_FUNDING_SERVICE_URL + "grantAndFundingOpportunityCategories", {
      grantAndFundingOpportunityCategoryId: grantAndFundingOpportunityCategory.grantAndFundingOpportunityCategoryId,
      name: grantAndFundingOpportunityCategory.name,
      description: grantAndFundingOpportunityCategory.description,
      status: grantAndFundingOpportunityCategory.status,
    });
  }
}

export default new GrantAndFundingOpportunityCategoryService;
