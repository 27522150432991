import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class BusinessCategoryService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "businessCategories");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "businessCategories/" + id);
  }

  findByBusinessSectorId(businessSectorId) {
    return axios.get(
      GENERIC_SERVICE_URL +
        "businessCategories/findByBusinessSectorId/" +
        businessSectorId
    );
  }

  save(entity) {
    return axios.post(GENERIC_SERVICE_URL + "businessCategories", {
      businessSectorId: entity.businessSectorId,
      name: entity.name,
      description: entity.description,
      status: entity.status
    });
  }

  updateById(entity, entityId) {
    return axios.put(
      GENERIC_SERVICE_URL + "businessCategories" + "/" + entityId,
      {
        businessCategoryId: entityId,
        businessSectorId: entity.businessSectorId,
        name: entity.name,
        description: entity.description,
        status: entity.status
      }
    );
  }
}

export default new BusinessCategoryService();
