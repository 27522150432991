<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>View Product Order</h4>
					</div>
					<router-link :to="{ name: 'product-orders' }">
						<div class="col-md-1">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>
				<br>
				<div v-if="order !== null">
					<ul class="list-group">
						<li class="list-group-item">ID: {{ order.id }}</li>
						<li class="list-group-item">Order Number: {{ order.orderNumber }}</li>
						<li class="list-group-item">awarded CarrierId: {{ order.awardedCarrierId }}</li>
						<li class="list-group-item">assignedVehicleId: {{ order.assignedVehicleId }}</li>
						<li class="list-group-item">Fulfilled By: {{ order.fulfilledById }}</li>
						<li class="list-group-item">Cart products: <br><br>
							<div class="list-group">
								<table class="table table-bordered table-striped">
								<thead class="bg-primary">
									<th>ID</th>
									<th>Name</th>
									<th>Price</th>
									<th>Seller</th>
									<th>Actions</th>
								</thead>
								<tbody>
									<tr v-for="cartProduct in order.cartProducts" :key="cartProduct.id">
										<td>ID: {{ cartProduct.id }}</td>
										<td>Name: {{ cartProduct.name }}</td>
										<td>Price: {{ cartProduct.currency }}{{ cartProduct.price }}</td>
										<td class="text-danger">Seller: {{ cartProduct.seller.name }}</td>
										<td>
											<router-link :to="{ name: 'view-product', params: { productId: cartProduct.id }}">View product</router-link>
										</td>
									</tr>
								</tbody>
							</table>
							</div>
						</li>
						<li class="list-group-item">Collection Addresses: <br><br>
							<div class="list-group">
								<table class="table table-striped table-bordered">
									<thead class="bg-primary">
										<th>ID</th>
										<th>City</th>
										<th>Physical Address</th>
										<th>Actions</th>
									</thead>
									<tbody>
										<tr v-for="cartProduct in order.cartProducts" :key="cartProduct.id">
											<td>{{ cartProduct.id }}</td>
											<td>{{ cartProduct.seller.company.physicalAddress.city !== null ? cartProduct.seller.company.physicalAddress.city.name: 'Not available' }}</td>
											<td>{{ cartProduct.seller.company.physicalAddress.physicalAddress }}</td>
											<td><a href="#">View on Google map</a></td>
										</tr>
									</tbody>
								</table>
							</div>
						</li>
						<li class="list-group-item">Delivery Address: <br><br>
							<div class="list-group">
								<li class="list-group-item">City {{ order.deliveryAddress.city !== null ? order.deliveryAddress: '' }}</li>
								<li class="list-group-item">Address {{ order.deliveryAddress }}</li>
							</div>
						</li>
						<li class="list-group-item">Customer: {{ order.customerId }}</li>
						<li class="list-group-item">Order Date: {{ order.orderDate }}</li>
						<li class="list-group-item">Order Lines: {{ order.orderLines }}</li>
						<li class="list-group-item">Invoice: {{ order.invoice }}</li>
						<li class="list-group-item">Released: {{ order.released }}</li>
						<li class="list-group-item">Released Date: {{ order.releasedDate }}</li>
						<li class="list-group-item">Released Time: {{ order.releasedTime }}</li>
						<li class="list-group-item">Collected: {{ order.collected }}</li>
						<li class="list-group-item">Collection At: {{ order.collectionAt }}</li>
						<li class="list-group-item">Delivered At: {{ order.deliveredAt }}</li>
						<li class="list-group-item">Confirmed Delivery: {{ order.orderNumber }}</li>
						<li class="list-group-item">Confirmed DeliveryAt: {{ order.confirmedDeliveryAt }}</li>
						<li class="list-group-item">Delivery Comments: {{ order.deliveryComments }}</li>
						<li class="list-group-item">Delivery Charge: {{ order.currency }}{{ order.deliveryCharge }}</li>
						<li class="list-group-item text-danger">Net earning: {{ order.currency }}{{ (order.deliveryCharge) - (order.deliveryCharge * 0.05) }}</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import OrderService from "@/services/OrderService.js";

	export default{
		name: 'Bookings',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    order: []
			}
		},

		mounted(){
			this.findByOrderNumber();
		},

		computed: {
			getOrderNumber(){
				return this.$route.params.orderNumber;
			}
		},

		methods: {
		    findByOrderNumber: function() {
				this.show = true;
		        OrderService.findByOrderNumber(this.getOrderNumber).then(response => {
		        	this.order = response.data.body;
		        	console.log('order', this.order);
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    }
		}
	};
</script>