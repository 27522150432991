import axios from "axios";
import { SKILLS_SERVICE_URL } from "@/config/SkillsServiceConfig";

class RequestForQuotationService{
	findAll(){
		return axios.get(SKILLS_SERVICE_URL + "requestForQuotations");
	}

	findById(id){
		return axios.get(SKILLS_SERVICE_URL + "requestForQuotations/" + id);
	}

	save(requestForQuotation){
		return axios.post(SKILLS_SERVICE_URL + "requestForQuotations", {
			jobId: requestForQuotation.jobId,
			jobTitleId: requestForQuotation.jobTitleId,
			type: requestForQuotation.type,
			hourlyRate: requestForQuotation.hourlyRate,
			currency: requestForQuotation.currency,
			expiryDate: requestForQuotation.expiryDate,
			attachment: requestForQuotation.attachment,
			availability: requestForQuotation.availability,
			expertiseLevel: requestForQuotation.expertiseLevel,
			paymentTerms: requestForQuotation.paymentTerms,
			reach: requestForQuotation.reach,
			projectType: requestForQuotation.projectType,
			projectLengthInMonths: requestForQuotation.projectLengthInMonths,
			commitmentHoursPerWeek: requestForQuotation.commitmentHoursPerWeek,
			userId: requestForQuotation.userId,
			evaluationMethod: requestForQuotation.evaluationMethod,
			termsAndConditions: requestForQuotation.termsAndConditions,
			submissionRequirementsAndInstructions: requestForQuotation.submissionRequirementsAndInstructions,
			areaRestriction: requestForQuotation.areaRestriction
		});
	}

	saveRequestForQuotationAttachment(formData){
		return axios.post(SKILLS_SERVICE_URL + "requestForQuotations/saveRequestForQuotationAttachment", formData);
	}
}

export default new RequestForQuotationService;