<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<div class="row">
					<div class="col-md-10">
						<p>New Contract Job</p>
					</div>
					<router-link :to="{ name: 'my-contract-jobs-configuration'}">
						<div class="col-md-2">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>
				<br>

				<form class="form" @submit.prevent="save">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Job category</label>
								<select class="form-control" v-model="job.jobCategoryId">
									<option value="" disabled>Select option</option>
									<option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">{{ jobCategory.name }}</option>
								</select>
							</div>	
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Title</label>
								<input type="text" name="" class="form-control" v-model="job.title" placeholder="Enter job title" maxlength="50" minlength="2" required>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Expiry date</label>
								<input type="date" name="expiryDate" v-model="job.expiryDate" class="form-control">
							</div>
						</div>
					</div>
					
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Hourly rate</label>
								<input type="text" name="hourlyRate" v-model="job.hourlyRate" placeholder="Enter Hourly Rate" class="form-control">
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Currency</label>
								<select class="form-control" v-model="job.currency">
									<option value="" disabled>Select option</option>
									<option value="USD">USD</option>
									<option value="ZWL">ZWL</option>
									<option value="RAND">RAND</option>
									<option value="PULA">PULA</option>
									<option value="KWACHA">KWACHA</option>
									<option value="SHILLING">SHILLING</option>
								</select>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Availability required</label>
								<select class="form-control" v-model="job.availability">
									<option value="" disabled>Select option</option>
									<option value="MORE_THAN_30_HOURS_PER_WEEK">MORE_THAN_30_HOURS_PER_WEEK</option>
									<option value="LESS_THAN_30_HOURS_PER_WEEK">LESS_THAN_30_HOURS_PER_WEEK</option>
									<option value="AS_NEEDED">AS_NEEDED</option>
									<option value="NONE">NONE</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Expertise level</label>
								<select class="form-control" v-model="job.expertiseLevel">
									<option value="" disabled>Select option</option>
									<option value="JUNIOR">JUNIOR</option>
									<option value="INTERMEDIATE">INTERMEDIATE</option>
									<option value="MIXTURE_OF_SKILLS">MIXTURE_OF_SKILLS</option>
									<option value="HIGHLY_SKILLED">HIGHLY_SKILLED</option>
									<option value="EXPERT">EXPERT</option>
								</select>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group" v-show="job.type !== 'FULL_TIME'">
								<label>Payment terms</label>
								<select class="form-control" v-model="job.paymentTerms">
									<option value="" disabled>Select option</option>
									<option value="PAY_ON_DELIVERY">PAY_ON_DELIVERY</option>
									<option value="PAID_PER_HOUR">PAID_PER_HOUR</option>
									<option value="ONCE_OFF">ONCE_OFF</option>
									<option value="ONGOING_PER_DELIVERABLE">ONGOING_PER_DELIVERABLE</option>
								</select>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group" v-show="job.type !== 'FULL_TIME'">
								<label>Project type</label>
								<select class="form-control" v-model="job.projectType">
									<option value="" disabled>Select option</option>
									<option value="SHORT_TERM">SHORT_TERM</option>
									<option value="LONG_TERM">LONG_TERM</option>
									<option value="AS_NEEDED">AS_NEEDED</option>
								</select>
							</div>		
						</div>
					</div>

					<div class="row">
						<div class="col-md-3">
							<div class="form-group" v-show="job.type !== 'FULL_TIME'">
								<label>Project length in months</label>
								<input type="text" name="projectLengthInMonths" v-model="job.projectLengthInMonths" placeholder="Enter project length in months" class="form-control">
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Commitment hours per week</label>
								<input type="text" name="commitmentHoursPerWeek" v-model="job.commitmentHoursPerWeek" placeholder="Hours of commitment required per week" class="form-control">
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobCategoryService from "@/services/JobCategoryService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";
	import CountryService from "@/services/CountryService";
	import ProvinceService from "@/services/ProvinceService";
	import CityService from "@/services/CityService";
	import AreaService from "@/services/AreaService";
	import Location from "@/models/Location";

	export default{
		name: "CreateJob",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				jobCategories: [],
				job: new Job,
				label: "Processing...",
				show: false,
				formData: new FormData,
				location: new Location,
				selectedAreas: [],
				areas: [],
				cities: [],
				countries: [],
				provinces: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
			this.findAllJobCategories();
		},

		methods: {
			findAllJobCategories: function(){
				JobCategoryService.findAll(this.job).then(response => {
					this.jobCategories = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCountryId: function(){
				ProvinceService.findByCountryId(this.location.countryId).then(response => {
					this.provinces = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByProvinceId: function(){
				CityService.findByProvinceId(this.location.provinceId).then(response => {
					this.cities = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCityId: function(){
				AreaService.findByCityId(this.location.cityId).then(response => {
					this.areas = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.formData.append("jobTitleId", this.job.jobTitleId);
				this.formData.append("type", this.job.type);
				this.formData.append("hourlyRate", this.job.hourlyRate);
				this.formData.append("currency", this.job.currency);
				this.formData.append("expiryDate", this.job.expiryDate);
				this.formData.append("availability", this.job.availability);
				this.formData.append("expertiseLevel", this.job.expertiseLevel);
				this.formData.append("paymentTerms", this.job.paymentTerms);
				this.formData.append("reach", this.job.reach);
				this.formData.append("projectType", this.job.projectType);
				this.formData.append("projectLengthInMonths", this.job.projectLengthInMonths);
				this.formData.append("commitmentHoursPerWeek", this.job.commitmentHoursPerWeek);
				localStorage.setItem("type", JSON.stringify(this.job.type));
				this.show = true;
				let selectedAreas = [];
				for(var i = 0; i < this.selectedAreas.length; i++){
					selectedAreas.push({
						areaId: this.selectedAreas[i]
					});
				}
				this.job.areaRestriction = selectedAreas;

				this.job.postedByUsername = this.getUser.username;
				this.job.type = 'CONTRACT';
				this.job.status = 'PENDING';
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "contract-job-location-restriction", params: { jobId: response.data.body.id }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user.user;
			}
		}
	};
</script>