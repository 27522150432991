<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<router-link :to="{ name: 'jobConfigurationList'}">
					<button class="btn btn-primary">Back</button>
				</router-link><br>

				<h4>Request For Quotation Configuration View</h4>
				<ul class="list-group">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">ID: {{ requestForQuotation.id }}</li>
					<li class="list-group-item">Job Number: {{ requestForQuotation.jobNumber }}</li>
					<li class="list-group-item">Job Title: {{ requestForQuotation.jobTitle.name }}</li>
					<li class="list-group-item">Type: {{ requestForQuotation.type }}</li>
					<li class="list-group-item">Hourly Rate: {{ requestForQuotation.currency }} {{ requestForQuotation.hourlyRate }} per hour</li>
					<li class="list-group-item">Expiry Date: {{ requestForQuotation.expiryDate }}</li>
					<li class="list-group-item">Attachment: {{ requestForQuotation.attachment }}</li>
					<li class="list-group-item">Skills Level: {{ requestForQuotation.skillsLevel }}</li>
					<li class="list-group-item">Availability: {{ requestForQuotation.availability }}</li>
					<li class="list-group-item">Estimated Budget: {{ requestForQuotation.estimatedBudget }}</li>
					<li class="list-group-item">Expertise Level: {{ requestForQuotation.expertiseLevel }}</li>
					<li class="list-group-item">Payment Terms: {{ requestForQuotation.paymentTerms }}</li>
					<li class="list-group-item">Reach: {{ requestForQuotation.reach }}</li>
					<li class="list-group-item">Project Type: {{ requestForQuotation.projectType }}</li>
					<li class="list-group-item">Commitment hours per week: {{ requestForQuotation.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Project length: {{ requestForQuotation.projectLengthInMonths }}</li>
					<li class="list-group-item">areaRestriction: {{ requestForQuotation.areaRestriction }}</li>
					<li class="list-group-item">contactPerson: {{ requestForQuotation.contactPerson }}</li>
					<li class="list-group-item">evaluationMethod: {{ requestForQuotation.evaluationMethod }}</li>
					<li class="list-group-item">termsAndConditions: {{ requestForQuotation.termsAndConditions }}</li>
					<li class="list-group-item">submissionRequirementsAndInstructions: {{ requestForQuotation.submissionRequirementsAndInstructions }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import RequestForQuotationService from "@/services/RequestForQuotationService";

	export default{
		name: "RequestForQuotationConfigurationView",
		component: {

		},

		computed: {
			getRequestForQuotationId: function(){
				return this.$route.params.requestForQuotationId;
			}
		},

		data: function(){
			return {
				requestForQuotation: null
			}
		},

		created: function(){
			this.findById();
		},

		methods: {
			findById: function(){
				RequestForQuotationService.findById(this.getRequestForQuotationId).then(response => {
					this.requestForQuotation = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>