<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<router-link :to="{ name: 'tenderConfigurationList'}">
					<button class="btn btn-primary">Back</button>
				</router-link><br><br>

				<ul class="list-group">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">ID: {{ tender.id }}</li>
					<li class="list-group-item">Country: {{ tender.country.name }}</li>
					<li class="list-group-item">Title: {{ tender.title }}</li>
					<li class="list-group-item">Tender Number: {{ tender.tenderNumber }}</li>
					<li class="list-group-item">Company: {{ tender.company.name }}</li>
					<li class="list-group-item">Reach: {{ tender.reach }}</li>
					<li class="list-group-item">Type: {{ tender.type }}</li>
					<li class="list-group-item">Section: {{ tender.section }}</li>
					<li class="list-group-item">Tender Document: {{ tender.tenderDocument }}</li>
					<li class="list-group-item">Closing Date: {{ tender.closingDate }}</li>
					<li class="list-group-item">Estimated Cost: {{ tender.estimatedCostCurrency }}{{ tender.estimatedCost }}</li>
					<li class="list-group-item">Tender Fees: 
						<div class="list-group" v-for="tenderFee in tender.tenderFees" :key="tenderFee.id">
							<li class="list-group-item">Fee Type: {{ tenderFee.feeType }}</li>
							<li class="list-group-item">Amount: {{ tenderFee.currency }}{{ tenderFee.amount }}</li>
						</div>
					</li>
					<hr>

					<h4>Skilled professional details</h4>
					<li class="list-group" v-if="tender.professionalCountries.length > 0">
						<p>Professional countries</p>
						<ul class="list-group">
							<li class="list-group-item" v-for="professionalCountry in tender.professionalCountries" :key="professionalCountry.id">{{ professionalCountry.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">
						Overview: {{ tender.overview }}
					</li>
					<li class="list-group-item">
						Subject: {{ tender.subject }}
					</li>
					<li class="list-group-item">
						Experience Level: {{ tender.experienceLevel }}
					</li>
					<li class="list-group" v-if="tender.degrees.length > 0">
						<p>Tender degrees</p>
						<ul class="list-group">
							<li class="list-group-item" v-for="degree in tender.degrees" :key="degree.id">{{ degree.name }}</li>
						</ul>
					</li>
					<li class="list-group" v-if="tender.certifications.length > 0">
						<p>Certifications</p>
						<ul class="list-group">
							<li class="list-group-item" v-for="certification in tender.certifications" :key="certification.id">{{ certification.name }}</li>
						</ul>
					</li>
					<li class="list-group" v-if="tender.skills.length > 0">
						<p>Certifications</p>
						<ul class="list-group">
							<li class="list-group-item" v-for="skill in tender.skills" :key="skill.id">{{ skill.name }}</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TenderService from "@/services/TenderService";

	export default{
		name: "TenderList",
		component: { },

		data: function(){
			return {
				tender: null
			}
		},

		created: function(){
			this.findById();
		},

		computed: {
			getTenderId: function(){
				return this.$route.params.tenderId;
			}
		},

		methods: {
			findById: function(){
				TenderService.findById(this.getTenderId).then(response => {
					this.tender = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>