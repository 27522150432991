<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>New Friend Invite</h4>
		      	<hr>

				<form class="form" @submit.prevent="save">
					<p>Enter your friend email and they will be notified to check SkilledBios site.</p>
					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<label>Email</label>
								<input type="text" name="title" v-model="friendInvite.email" placeholder="Enter your friend email address and we will send the invitaton to them" class="form-control" required>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-success pull-right" type="submit">Send Invite</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import FriendInviteService from "@/services/FriendInviteService";
	import FriendInvite from "@/models/FriendInvite";

	export default{
		name: "FriendInvite",
		components: {
			loading
		},

		data: function(){
			return {
				friendInvite: new FriendInvite,
				label: "Processing request",
				show: false,
			}
		},

		methods: {
			save: function(){
				this.show = true;
				this.friendInvite.username = this.$store.state.auth.user.user.username;
				FriendInviteService.save(this.friendInvite).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: "dashboard" });
					}
					this.show = false;
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>