<template>
	<div>
		<router-link :to="{ name: 'compliance-management' }" replace>
			<button class="btn btn-primary">Back</button>
		</router-link>

		<h4>Compliance Evidence for {{ getComplianceManagementByName }}</h4>
		<table class="table table-responsive table-hover table-bordered">
			<thead>
				<tr>
					<th class="bg-primary">ID</th>
					<td>1</td>
				</tr>
				<tr>
					<th class="bg-primary">Complied On</th>
					<td>13 May 2023</td>
				</tr>
				<tr>
					<th class="bg-primary">Attachment</th>
					<td><a href="#">View</a></td>
				</tr>
			</thead>
		</table>
		<hr>

	    <form class="form" @submit.prevent="save">
	    	<div class="form-group">
	    		<label>&nbsp;</label>
	    		<input type="file" name="" class="form-control">
	    	</div>

	      <div class="form-group">
	        <label>Description</label>
	        <textarea class="form-control" rows="5" placeholder="Enter Evidence Description"></textarea>
	      </div>

	      <div class="form-group">
	        <label>Status</label>
	        <select class="form-control">
	          <option value="">Select status</option>
	          <option value="ACTIVE">Active</option>
	          <option value="INACTIVE">Inactive</option>
	          <option value="DELETED">Deleted</option>
	        </select>
	      </div>

	      <div class="row">
	      	<div class="col-md-2">
	      		<div class="form-group">
	      			<label>&nbsp;</label>
	      			<router-link :to="{ name: 'complianceManagement' }" replace>
	      				<button class="btn btn-default btn-block" data-dismiss="modal">Cancel</button>
	      			</router-link>
	      		</div>		
	      	</div>
	      	<div class="col-md-8"></div>
	      	<div class="col-md-2">
	      		<div class="form-group">
	      			<label>&nbsp;</label>
	      			<button class="btn btn-success btn-block">Attach Evidence</button>
		      </div>
	      	</div>
	      </div>
	    </form>
	</div>
</template>

<script type="text/javascript">
	export default{
		name: 'ComplianceManagementDetail',
		data: function(){
			return {

			}
		},

		computed: {
			getComplianceManagementByName: function(){
				return this.$route.params.name;
			}
		}
	};
</script>