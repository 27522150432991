<template>
  <div>
    <router-link :to="{ name: 'users' }" replace
      ><button class="btn btn-primary">
        <span class="glyphicon glyphicon-step-backward"></span> Back
      </button></router-link
    >

    <h4>User Details</h4>
    <div class="list-group">
      <p>
        <router-link
          :to="{
            name: 'users/profile/editProfile',
            params: { userId: this.getUser.user.id }
          }"
        >
          <button class="btn btn-warning pull-right">Edit Profile</button>
        </router-link>
      </p>

      <div class="list-group-item">User ID: {{ user.id }}</div>
      <div class="list-group-item">
        First Name:
        {{ user.firstName === null ? "No available" : user.lastName }}
      </div>
      <div class="list-group-item">
        Last Name: {{ user.lastName === null ? "No available" : user.lastName }}
      </div>
      <div class="list-group-item">Email: {{ user.email }}</div>
      <div class="list-group-item">Salutation: {{ user.salutation }}</div>
      <div class="list-group-item">Gender: {{ user.gender }}</div>
      <div class="list-group-item">
        Occupation:
        {{ user.occupation === null ? "No available" : user.occupation }}
      </div>
      <div class="list-group-item">
        <ul class="list-group">
          <!-- <li class="list-group-item">{{ user.roles }}</li> -->
        </ul>
      </div>
    </div>
    <br />

    <div class="col-md-12">
      <router-link :to="{ name: 'users' }">
        <button class="btn btn-primary">Back</button>
      </router-link>
    </div>
  </div>
</template>

<script type="text/javascript">
import UserService from "@/services/UserService.js";
import User from "@/models/User.js";

export default {
  name: "ViewUser",
  data: function() {
    return {
      user: new User()
    };
  },

  computed: {
    getUser() {
      return JSON.parse(localStorage.getItem("user"));
    },

    getUserId: function() {
      return this.$route.params.userId;
    }
  },

  created: function() {
    this.findById();
  },

  methods: {
    findById: function() {
      UserService.findById(this.getUserId)
        .then(response => {
          this.user = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
