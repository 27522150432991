<template>
	<div>
		<h4>Compliance List</h4>
		<router-link :to="{ name: 'new-compliance' }">
			<button class="btn btn-success pull-right">New Compliance <span class="glyphicon glyphicon-plus"></span></button>
		</router-link>
		<br><br>
		
		<v-client-table :columns="columns" :data="compliances" :options="options">
			<td slot="id" slot-scope="{ row }">{{ row.id }}</td>
			<td slot="name" slot-scope="{ row }">{{ row.name }}</td>
			<td slot="country" slot-scope="{ row }">{{ row.country !== null ? row.country.name: '' }}</td>
			<td slot="type" slot-scope="{ row }">{{ row.type !== null ? row.type.name: '' }}</td>
			<td slot="status" slot-scope="{ row }">
				<span class="label label-success" v-if="row.status === 'ACTIVE'">{{ row.status }}</span>
				<span class="label label-primary" v-else>{{ row.status }}</span>
			</td>
			<td slot="actions" slot-scope="{ row }">
				<router-link :to="{ name: 'view-compliance', params: { complianceId : row.id }}">
					<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
				</router-link>
			</td>
		</v-client-table>
	</div>
</template>

<script type="text/javascript">
	import ComplianceService from "@/services/ComplianceService";

	export default{
		name: 'ComplianceList',
		data: function(){
			return {
				compliances: [],
				columns: [ 'id', 'name', 'country', 'category', "type", 'status', 'actions' ],
				options: {
		          headings: {
		            id: h => this.a11yCols(h, 'ID'),
		            name: h => this.a11yCols(h, 'Name'),
		            country: h => this.a11yCols(h, 'Country'),
		            category: h => this.a11yCols(h, 'Category'),
		            type: h => this.a11yCols(h, 'Type'),
		            status: h => this.a11yCols(h, 'Status'),
		            actions: h => this.a11yCols(h, 'Actions'),
		          },
		          sortable: [ 'id', 'name', 'country', 'category', 'type', 'status' ],
		          filterable: [ 'id', 'name', 'country', 'category', 'type', 'status' ],
		        }
			}
		},

		created(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				ComplianceService.findAll().then(response => {
					this.compliances = response.data.body
				}).catch(error => {
					console.log(error);
				});
			},

			a11yCols: function(h, header) {
		        return h("a", {
		        	domProps: {
		        		href: "javascript:function a() { return false }",
		            	innerHTML: header
		            }
		        });
		    },
		},
		
		computed: {}
	};
</script>