import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

class PasswordResetService {
  sendResetToken(username) {
    return axios.post(GENERIC_SERVICE_URL + "passwordReset/sendResetToken/" + username);
  }

  changePassword(token, user) {
    return axios.post(GENERIC_SERVICE_URL + "passwordReset/changePassword/" + token, {
      password: user.password,
      confirmPassword: user.confirmPassword,
    });
  }
}
export default new PasswordResetService;
