import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class SkillsService{
	findAll(){
		return axios.get(GENERIC_SERVICE_URL + "skills");
	}

	findById(id){
		return axios.get(GENERIC_SERVICE_URL + "skills/" + id);
	}

	save(skill){
		return axios.post(GENERIC_SERVICE_URL + "skills", {
			skillId: skill.skillId,
			description: skill.description,
			status: skill.status,
			name: skill.name
		});
	}
}

export default new SkillsService;