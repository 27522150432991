import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class QualificationTypeService {
  save(qualificationType) {
    return axios.post(GENERIC_SERVICE_URL + "qualificationTypes", {
      id: qualificationType.id,
      name: qualificationType.name,
      description: qualificationType.description,
      status: qualificationType.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "qualificationTypes");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "qualificationTypes/" + id);
  }
}

export default new QualificationTypeService;
