<template>
	<div>
		<h4 class="text-center">Checkout</h4>
		<hr>

		<!-- <router-link :to="{ name: 'complianceManagement/subscription' }">
			<button class="btn btn-primary">Back</button>
		</router-link> -->

		<div class="row">
			<div class="col-md-3"></div>
			<div class="col-md-6">
				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>&nbsp;</label>
						<p><img src="@/assets/images/visa.png" height="100" width="150"></p>
					</div>

					<div class="form-group">
						<label>&nbsp;</label>
						<h4>Please select your payment method</h4>
					</div>
					<div class="form-group">
						<label>Card Number</label>
						<input type="text" name="cardNumber" id="cardNumber" placeholder="Enter Card Number" class="form-control">
					</div>

					<div class="form-group">
						<label>Card Holder Name</label>
						<input type="text" name="cardNumber" id="cardNumber" placeholder="Enter Card Holder Name" class="form-control">
					</div>

					<div class="row">
						<div class="col-md-4">
							<div class="form-group">
								<br>
								<label>Card Expiry Date</label>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>&nbsp;</label>
								<select class="form-control">
									<option value="" disabled>Select year</option>
									<option value="01">01</option>
									<option value="02">02</option>
									<option value="03">03</option>
									<option value="04">04</option>
									<option value="05">05</option>
									<option value="06">06</option>
									<option value="07">07</option>
									<option value="08">08</option>
									<option value="09">09</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
								</select>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label>&nbsp;</label>
								<select class="form-control">
									<option value="" disabled>Select year</option>
									<option value="2023">2023</option>
									<option value="2024">2024</option>
									<option value="2025">2025</option>
									<option value="2026">2026</option>
									<option value="2027">2027</option>
								</select>
							</div>
						</div>
					</div>

					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success btn-block pull-right" type="submit">PAY NOW</button>
					</div>
				</form>
			</div>
			<div class="col-md-3"></div>
		</div>
	</div>
</template>


<script type="text/javascript">
	// after sunscription they see this screen

	// complianceManagement
	export default{
		name: "ComplianceManagementPlanCheckout",

		methods: {
			save: function(){
				this.$router.push({ name: 'complianceManagement' });
			}
		}
	}
</script>