import axios from "axios";
import { AUTH_API_URL } from "@/config/AuthServiceConfig.js";
import authHeader from "@/services/AuthHeaderService.js";

class AuthService {
  register(user) {
    const authToken = JSON.parse(localStorage.getItem("user"));
    return axios.post(
      AUTH_API_URL + "users",
      {
        personId: authToken === null ? user.personId: authToken.userId,
        personType: "USER", // for now all are users
        username: user.username,
        email: user.email,
        password: user.password,
        confirmPassword: user.confirmPassword,
        accountNonExpired: user.accountNonExpired,
        accountNonLocked: user.accountNonLocked,
        credentialsNonExpired: user.credentialsNonExpired,
        enabled: user.enabled,
        roles: user.roles,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        nickname: user.nickname,
        dateOfBirth: user.dateOfBirth,
        phoneNumbers: user.phoneNumbers,
        faxNumber: user.faxNumber,
        skypeAddress: user.skypeAddress,
        gender: user.gender,
        salutation: user.salutation,
        maritalStatus: user.maritalStatus,
        nationalIdNumber: user.nationalIdNumber,
        passportNumber: user.passportNumber,
        driversLicenceNumber: user.driversLicenceNumber,
        nationality: user.nationality,
        placeOfBirth: user.placeOfBirth,
        countryOfBirth: user.countryOfBirth,
        homeAddress: user.homeAddress,
        postalAddress: user.postalAddress,
        ethnicGroup: user.ethnicGroup,
        religion: user.religion,
        digitalSignature: user.digitalSignature,
        photo: user.photo,

        overview: user.overview,
        title: user.title,
        videoIntroduction: user.videoIntroduction,
        availability: user.availability,
        languages: user.languages,
        educations: user.educations,
        serviceCharge: user.serviceCharge,
        workHistories: user.workHistories,
        portfolioProjects: user.portfolioProjects,
        skills: user.skills,
        testimonials: user.testimonials,
        certifications: user.certifications,
        otherExperiences: user.otherExperiences,
        jobPreferences: user.jobPreferences
      },

      { headers: authHeader() }
    );
  }

  findById(userId){
    return axios.post(AUTH_API_URL + "users/findById/" + userId, { headers: authHeader() });
  }

  login(user) {
    const formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("scopes", "webclient");
    formData.append("username", user.username);
    formData.append("password", user.password);

    return axios.post(AUTH_API_URL + "oauth/token", formData, {
      auth: {
        username: "skilledbios", // change to business name
        password: "skilledbios" // change to business name :: need to make this configuration property in Dockerfile, or in key cloak on Docker CLuster Swarm/Kubernetes
      }
    }); // dont use the response here, otherwise store action will get nothing in Promise since it would have resolved the response
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }
}

export default new AuthService();
