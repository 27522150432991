<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Add employment</h4>
      	<form class="form" @submit.prevent="save">
      		<div class="form-group">
				<label>Company</label>
				<input type="text" name="Company" id="Company" placeholder="Ex: SkilledBioz" class="form-control">
			</div>

      		<div class="row">
      			<h4>Location</h4>
      			<div class="col-md-6">
      				<label>City</label>
      				<select class="form-control">
      					<option value="">Select option</option>
      				</select>
      			</div>
      			<div class="col-md-6">
      				<label>Country</label>
      				<select class="form-control">
      					<option value="">Select option</option>
      				</select>
      			</div>
      		</div>

      		<div class="form-group">
				<label>Title</label>
				<input type="text" name="Company" id="Company" placeholder="Ex: Senior Software Engineer" class="form-control">
			</div>

			<div class="row">
      			<h4>Period</h4>
      			<div class="col-md-6">
      				<select class="form-control">
      					<option value="">From, month</option>
      				</select>
      			</div>
      			<div class="col-md-6">
      				<select class="form-control">
      					<option value="">From, year</option>
      				</select>
      			</div>
      		</div>

      		<div class="form-group">
      			<label>Description (Optional)</label>
      			<textarea class="form-control" rows="5" placeholder="Description (Optional)"></textarea>
      		</div>

      		<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success" type="submit">Save</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobService from "@/services/JobService";
	import CountryService from "@/services/CountryService";
	import Job from "@/models/Job";

	export default{
		name: "NewEmploymentHistory",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				job: new Job,
				label: "Processing request...",
				show: false,
				countries: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
		},

		methods: {
			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.data;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.data;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				this.$router.push({ name: "newOtherExperiences" });
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newOtherExperiences" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>