<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <router-link :to="{ name: 'zipCodes' }">
          <button class="btn btn-primary">
            <span class="fa fa-step-backward"></span> Back
          </button> </router-link
        ><br /><br />

        <ul class="list-group">
          <li class="list-group-item">ID : {{ zipCode.id }}</li>
          <li class="list-group-item">Code : +{{ zipCode.code }}</li>
          <li class="list-group-item">
            Description : {{ zipCode.description }}
          </li>
          <li class="list-group-item">
            Status
            <span v-if="zipCode.status === 'ACTIVE'">
              <span class="badge badge-success">Active</span>
            </span>
            <span v-if="zipCode.status === 'INACTIVE'">
              <span class="badge badge-secondary">Inactive</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import ZipCodeService from "@/services/ZipCodeService";
import ZipCode from "@/models/ZipCode";

export default {
  name: "ViewZipCode",
  data: function() {
    return {
      zipCode: new ZipCode()
    };
  },

  computed: {
    getZipCodeId() {
      return this.$route.params.zipCodeId;
    }
  },

  created: function() {
    this.findById();
  },

  methods: {
    findById: function() {
      ZipCodeService.findById(this.getZipCodeId)
        .then(response => {
          this.zipCode = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
