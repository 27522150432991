<template>
  <div class="panel">
    <div class="panel-body">
      <h3 class="text-center">Skilled Biographies.<i>Connecting you to the right business.</i>
      </h3>
      <hr />

      <footer>
        <div class="row">
          <div class="col-sm-3">
            <h4>Quick Links</h4>
            <ul class="list-unstyled">
              <li>
                <router-link to="jobs"
                  >Full time and Contract jobs or volunteering opportunities</router-link
                >
              </li>
              <li>
                <router-link to="tenders">Tenders</router-link>
              </li>
              <li>
                <router-link to="complianceManagement">Compliance Management </router-link>
              </li>
              <li>
                <router-link to="products">Sales leads</router-link>
              </li>
              <li>
                <router-link to="callForProposals">Call for proposals</router-link>
              </li>
            </ul>
          </div>

          <div class="col-sm-3">
            <h4>Help and Support</h4>
            <ul class="list-unstyled">
              <li>Call: <a href="tel:2631234567890">+2631234567890</a></li>
              <li>Mail to:<a href="mailto:support@skilledbioz.com">support@skilledbios.com</a>
              </li>
            </ul>
          </div>

          <div class="col-sm-3">
            <h4>Our Company</h4>
            <ul class="list-unstyled">
              <li><router-link to="#">Our Culture</router-link></li>
              <li><router-link to="#">Our Blog</router-link></li>
              <li><router-link to="#">Careers</router-link></li>
              <li><router-link to="#">White Papers</router-link></li>
            </ul>
          </div>

          <div class="col-sm-3">
            <h4>Follow Us On</h4>
            <ul class="list-unstyled">
              <li><a href="https://www.linkedin.com/company/skilledbios" target="_blank">LinkedIn</a></li>
              <li><a href="https://www.twitter.com/skilledbios" target="_blank">Twitter</a></li>
              <li><a href="https://www.facebook.com/skilledbios" target="_blank">Facebook</a></li>
              <li><a href="https://www.tnstagram.com/skilledbios" target="_blank">Instagram</a></li>
            </ul>
          </div>
        </div>
      </footer>

      <div class="panel blueBackground">
        <footer>
          <div class="row">
            <div class="col-sm-4">
              <p style="color: #FFFFFF;">© {{ date }} SkilledBios Pvt Ltd. All rights reserved.</p>
            </div>
            <div class="col-sm-2">
              <router-link to="about" style="color: #FFF;">About Us</router-link>
            </div>
            <div class="col-sm-2">
              <router-link to="termsAndConditions" style="color: #FFF;"><img src="@/assets/images/termsOfService.png" width="20" height="20"> Terms and Conditions</router-link>
            </div>
            <div class="col-sm-2">
              <router-link to="privacyPolicy" style="color: #FFF;"><img src="@/assets/images/privacyPolicy.png" width="20" height="20"> Privacy Policy</router-link>
            </div>
            <div class="col-sm-2">
              <router-link to="privacyPolicy" style="color: #FFF;"><img src="@/assets/images/securityPolicy.png" width="20" height="20"> Information Security</router-link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default{
    data: function(){
      return {
        date: new Date().getFullYear(),
      }
    }
  }
</script>
