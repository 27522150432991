<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>What are you interested in ? We will help you customize your preferences.</h4><br>

      	<div v-show="person.jobCategories !== null">
      		<h4><i>You have already selected the following tender preferences, you can add or remove any time.</i></h4>
      		<ul class="list-group" v-for="(tenderCategory, index) in person.tenderPreferences" :key="tenderCategory.id">
      			<li class="list-group-item">{{ index + 1 }}. {{ tenderCategory.name }} <button class="btn btn-danger pull-right btn-sm">Delete</button></li>
      		</ul>
      	</div>
      	<hr>

      	<h4>Choose from a variety of tender categories and get notified when a tender that matches your preference is posted.</h4>
      	<form class="form" @submit.prevent="save">
			<div class="form-group" v-for="tenderCategory in tenderCategories" v-bind:key="tenderCategory.name">
				<label>&nbsp;</label>
				<input type="checkbox" name="selectedPreferences" id="selectedPreferences" v-model="selectedPreferences" :value="tenderCategory.id"> {{ tenderCategory.name }}
			</div>

      		<div class="form-group" v-show="selectedPreferences.length >= 1">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit preference</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import TenderCategoryService from "@/services/TenderCategoryService";
	import TenderService from "@/services/TenderService";
	import UserService from "@/services/UserService";
	import User from "@/models/User";

	export default{
		name: "NewGrantAndFundingOpportunityCategoryPreference",
		components: {
			loading
		},

		data: function(){
			return {
				selectedPreferences: [],
				user: new User,
				label: "Processing request...",
				show: false,
				tenderCategories: [],
				person: null
			}
		},

		created: function(){
			this.findAll();
			this.findByUserId();
		},

		computed: {
			getPersonId: function(){
				return this.$store.state.auth.user.userId;
			}
		},

		methods: {
			findAll() {
				this.show = true;
		       	TenderCategoryService.findAll().then(response => {
		          this.show = false;
		          this.tenderCategories = response.data.body;
		        }).catch(error => {
		          this.show = false;
		          console.log(error);
		        });
		    },

		    findByUserId() {
				this.show = true;
		        UserService.findByUserId(this.getPersonId).then(response => {
		          this.show = false;
		          this.person = response.data.body;
		        }).catch(error => {
		          this.show = false;
		          console.log(error);
		        });
		    },

			save: function(){
				this.show = true;
				let preferences = [];
				for(var i = 0; i < this.selectedPreferences.length; i++){
					preferences.push({
						tenderCategoryId: this.selectedPreferences[i]
					});
				}
				TenderService.savePreference(this.getPersonId, preferences).then(response => {
					if(response.data.statusCode === 201){
						this.$router.push({ name: "dashboard" });
					}
					this.show = false;
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>