<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11"></div>
					<div class="col-md-1">
						<router-link :to="{ name: 'funding-opportunity-search'}">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link><br>
					</div>
				</div>

				<h4>Grant and funding opportunity details</h4>
				<ul class="list-group" v-if="grantAndFundingOpportunity !== null">
					<li class="list-group-item"><img src="@/assets/images/grants.png" height="150" width="250"></li>
					<li class="list-group-item">Grant And Funding Opportunity Number: {{ grantAndFundingOpportunity.grantAndFundingOpportunityNumber }}</li>
					<li class="list-group-item">Title: {{ grantAndFundingOpportunity.title }}</li>
					<li class="list-group-item">Type: {{ grantAndFundingOpportunity.type }}</li>
					<li class="list-group-item">Estimated Budget: {{ grantAndFundingOpportunity.currency }} {{ grantAndFundingOpportunity.estimatedBudget }}</li>
					<li class="list-group-item">Expiry Date: {{ grantAndFundingOpportunity.expiryDate }}</li>
					<li class="list-group-item">
						<a :href="PDF_API_URL + 'pdf/getGrantAndFundingOpportunity/' + grantAndFundingOpportunity.id" target="_blank">View Document</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import GrantAndFundingOpportunityService from "@/services/GrantAndFundingOpportunityService";
	import loading from "vue-full-loading";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

	export default{
		name: "GrantAndFundingOpportunityView",
		component: {
			loading
		},

		computed: {
			getGrantAndFundingOpportunityId: function(){
				return this.$route.params.grantAndFundingOpportunityId;
			}
		},

		data: function(){
			return {
				grantAndFundingOpportunity: null,
				show: false,
				label: "Processing request...",
				PDF_API_URL: PDF_API_URL,
			}
		},

		created: function(){
			this.findById();
		},

		methods: {
			findById: function(){
				GrantAndFundingOpportunityService.findById(this.getGrantAndFundingOpportunityId).then(response => {
					this.grantAndFundingOpportunity = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	};
</script>