<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>Recently delivered</h4>
	    <div class="panel">
	    	<div class="panel-body">
	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary" style="background: #000000; color: #ffffff;">
				        <th>Load Number</th>
				        <!-- <th>Booking Number</th> -->
				        <th>Load Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Assigned Truck</th>
				        <th>Load Status</th>
				        <th>Delivery Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="order in getFilteredLoadBookings" :key="order.id">
				        	<td>
				        		<router-link :to="{ name: 'view-order', params: { orderNumber: order.orderNumber }}">
				        			{{ order.orderNumber }}
				        		</router-link>
				        	</td>
				        	<!-- <td>{{ loadBooking.booking.bookingNumber }}</td> -->
				        	<td>{{ order.currency }}{{ order.price }}</td>
				          	<td>
				          		<!-- {{ loadBooking.load.pickupAddress !== null ? loadBooking.load.pickupAddress.city.name + ' ' + loadBooking.load.pickupAddress.province.name: '' }} -->
				          	</td>
				          	<td>
				          		<!-- {{ loadBooking.load.dropOffAddress !== null ? loadBooking.load.dropOffAddress.city.name + ' ' + loadBooking.load.dropOffAddress.province.name: '' }} -->
				          	</td>
				          	<td>
				          		<!-- <router-link :to="{ name: 'trucks/view', params: { truckNumber: loadBooking.booking.assignedTruck.truckNumber }}">
				          			{{ loadBooking.booking.assignedTruck !== null ? loadBooking.booking.assignedTruck.registrationNumber: '' }} -->
				          		<!-- </router-link> -->
				          	</td>
				          	<td>{{ order.status }}</td>
				          	<td>
				          		<span :class="order.delivered && order.confirmedDelivery ? 'glyphicon glyphicon-thumbs-up': 'glyphicon glyphicon-thumbs-down'">
				          		</span>
				          	</td>
				          	<td></td>
				          </tr>
				    </tbody>
				</table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";

	export default{
		name: 'RecentlyDeliveredLoads',
		components: {
			loading,
		},

		props: {
			orders: {
				type: Array,
				default: function (){
					return []
				}
			}
		},

		data: function(){
			return {
				label: "Processing request",
				show: false
			}
		},

		computed: {
			getFilteredLoadBookings: function() {
				return this.orders.filter(order => {
					return order.invoice !== null && order.released && order.collected && order.delivered && order.confirmedDelivery && order.status === 'DELIVERY_CONFIRMED';
				});
		    }
		},
	};
</script>