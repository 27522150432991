import axios from "axios";
import { PURCHASING_SERVICE_URL } from "@/config/PurchasingServiceConfig";

class RequisitionService {
  findAll() {
    return axios.get(PURCHASING_SERVICE_URL + "requisitions");
  }

  save(requisition) {
    return axios.post(PURCHASING_SERVICE_URL + "requisitions", {
      requestingDepartmentId: requisition.requestingDepartmentId,
      requestedDate: requisition.requestedDate,
      products: requisition.products,
      purchaseReason: requisition.purchaseReason,
      recommendedSupplierId: requisition.recommendedSupplierId,
      secondaryRecommendedSupplierId:
        requisition.secondaryRecommendedSupplierId,
      budgetedExpenditure: requisition.budgetedExpenditure,
      proposedBudget: requisition.proposedBudget,
      fundsAvailable: requisition.fundsAvailable
    });
  }

  findById(requisitionId) {
    return axios.get(
      PURCHASING_SERVICE_URL + "requisitions" + "/" + requisitionId
    );
  }

  approve(requisitionId, approverUsername) {
    return axios.post(PURCHASING_SERVICE_URL + "requisitions/approve", {
      requisitionId: requisitionId,
      approverUsername: approverUsername
    });
  }
}

export default new RequisitionService();
