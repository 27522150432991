<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>Customer Order List</h4>
					</div>
				</div>
				<br><br>
				<table class="table table-responsive table-bordered table-hover">
					<thead class="bg-primary">
						<th>ID</th>
						<th>Name</th>
						<th>Order Date</th>
						<th>Price</th>
						<th>Status</th>
						<th>Actions</th>
					</thead>
					<tbody>
						<tr v-for="order in orders" :key="order.id">
							<td>{{ order.id }}</td>
							<td>{{ order.orderNumber }}</td>
							<td>{{ order.orderDate }}</td>
							<td>{{ order.currency }}{{ order.price }}</td>
							<td><span class="label label-primary">{{ order.status }}</span></td>
							<td>
								<router-link :to="{ name: 'view-order', params: { orderNumber: order.orderNumber }, query: { source: 'customer-order-list'}}">
									<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>			
	</div>
</template>

<script type="text/javascript">
	import OrderService from "@/services/OrderService";

	export default{
		name: "CustomerOrderList",
		data: function(){
			return {
				orders: [],
			}
		},

		mounted: function(){
			this.findByCustomerUsername();
		},

		methods: {
			findByCustomerUsername: function(){
				this.show = true;
				OrderService.findByCustomerUsername(this.$store.state.auth.user.user.username).then(response => {
					this.show = false;
					this.orders = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>