export default class BasicDeliveryCharge{
	constructir(id, amount, currency, active, current, enabled, expired, status, description){
		this.id = id;
		this.amount = amount;
		this.currency = currency;
		this.active = active;
		this.current = current;
		this.enabled = enabled;
		this.expired = expired;
		this.status = status;
		this.description = description;
	}
}