<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<h4>Delivery service subscription</h4>
				<br>
				<div class="row">
					<div class="col-md-4">
						<h4 class="text-danger">Basic Plan - $15</h4>
						<hr>
						<ul>
							<li>Delivery service profile</li>
							<li>Orders</li>
						</ul>
						<br><br><br>
						<router-link :to="{ name: 'subscription-payment-response' }">
							<button class="btn btn-primary">Subscribe</button>
						</router-link>
					</div>
					<div class="col-md-4">
						<h4 class="text-danger">Advanced Plan - $30</h4>
						<hr>
						<ul>
							<li>Basic Plan features +</li>
							<li>Orders</li>
							<li>Best delivery route recommendation</li>
						</ul>
						<br><br>
						<router-link :to="{ name: 'subscription-payment-response' }">
							<button class="btn btn-primary">Subscribe</button>
						</router-link>
					</div>
					<div class="col-md-4">
						<h4 class="text-danger">Premium Plan - $50</h4>
						<hr>
						<ul>
							<li>Advanced Plan features +</li>
							<li>Orders</li>
							<li>Order scheduling</li>
							<li>Profitability analytics</li>
						</ul>
						<br>
						<router-link :to="{ name: 'subscription-payment-response' }">
							<button class="btn btn-primary">Subscribe</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
  import loading from "vue-full-loading";

  export default {
    name: "NewDeliveryServiceSubscription",
    components: {
      loading
    },

    data: function() {
      return {
        GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
        label: "Processing request",
        show: false
      };
    },

    computed: {
    	//subscriptionPrice: null
    	// subscriptionPrice(){
     //    if (this.company.carrierType === 'OWN_OPERATOR') {
     //      return 
     //    }
     //  }
    },

    mounted(){

    },

    methods: {
    	save: function() {

    	}
    }
  };
</script>