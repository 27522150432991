<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <router-link :to="{ name: 'configurations' }" replace>
      <button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button>
    </router-link>

    <router-link :to="{ name: 'productCategories/new' }" replace>
      <button class="btn btn-success pull-right"> New Product Category<span class="glyphicon glyphicon-plus"></span></button>
    </router-link><br/><br/>

    <h4 class="text-center">All Product Categories</h4>
    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="productCategory in productCategories" :key="productCategory.id">
          <td>{{ productCategory.id }}</td>
          <td>{{ productCategory.name }}</td>
          <td>{{ productCategory.description }}</td>
          <td>
            <span class="label label-success" v-if="productCategory.status == 'ACTIVE'"
              >Active</span
            >
            <span class="label label-default" v-if="productCategory.status == 'INACTIVE'"
              >Inactive</span
            >
            <span class="label label-danger" v-if="productCategory.status == 'DELETED'"
              >Deleted</span
            >
          </td>
          <td>
            <router-link
              :to="{ name: 'complianceCategories/edit', params: { productCategoryId: productCategory.id } }"
              ><button class="btn btn-primary">Edit</button></router-link
            >&nbsp;
            <router-link
              :to="{ name: 'complianceCategories/view', params: { productCategoryId: productCategory.id } }"
              ><button class="btn btn-primary">View</button></router-link
            >&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import ProductCategoryService from "@/services/ProductCategoryService";
  import loading from "vue-full-loading";

  export default {
    name: "ComplianceCategoryList",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing...",
        productCategories: []
      };
    },

    created() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        ProductCategoryService.findAll().then(response => {
            this.show = false;
            this.productCategories = response.data.body;
          }).catch(error => {
            this.show = false;
            console.log(error);
          });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
