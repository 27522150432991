<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <h4>Business Postal Address</h4>
    <p class="error-message text-center" v-if="message != null">{{ message }}</p>
    <form class="form" @submit.prevent="save">
      <div class="form-group">
        <label>City</label>
        <select class="form-control" v-model="postalAddress.cityId" required>
          <option value="">Select City</option>
          <option v-for="city in cities" v-bind:key="city.id" v-bind:value="city.id">{{ city.name }}</option>
        </select>
      </div>                 

      <div class="form-group">
        <label>Physical Address</label>
        <textarea rows="5" class="form-control" placeholder="Enter Physical Postal Address" v-model="postalAddress.physicalAddress" required></textarea>
      </div>

      <div class="row">
        <div class="col-md-2">
          <router-link :to="{ name: 'new-business-profile/address', params: { uuid: this.getUuid }}">
            <!-- <button class="btn btn-default btn-block">Back</button> -->
          </router-link>
        </div>
        <div class="col-md-8"></div>
        <div class="col-md-2">
          <button class="btn btn-success pull-right btn-block" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import CityService from "@/services/CityService.js";
  import Company from "@/models/Company.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    name: "PostalAddressComponent",
    data: function() {
      return {
        show: false,
        label: "Processing request...",
        cities: [],
        message: null,
        errors: null,
        postalAddress: {
          zipCodeId: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          areaId: null,
          postalAddress: null
        },
        company: new Company,
      };
    },

    computed: {
      getUuid() {
        return this.$route.params.uuid;
      }
    },

    created() {
      console.log(JSON.parse(localStorage.getItem('username')));
      this.findAllCities();
    },

    methods: {
      findAllCities: function() {
        CityService.findAll().then(response => {
          this.cities = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.company.uuid = this.getUuid;
        this.company.postalAddress = this.postalAddress;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode === 422) {
            this.message = response.data.message;
          }
          if (response.data.statusCode === 400) {
            this.message = response.data.message;
          }
          if (response.data.statusCode === 500) {
            this.message = response.data.message;
          }
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "new-business-profile/business-documents", params: { uuid: this.getUuid }});
          }
          this.show = false;
        })
        .catch(error => {
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    }
  };
</script>
