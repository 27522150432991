<template>
	<div>
		<p>New compliances configuraton</p>
		<hr>

		<!-- <form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>&nbsp;</label>
				<div class="input-group">
					<input type="text" name="search" id="search" class="form-control" placeholder="Search...">
					<div class="input-group-addon">
						<span class="glyphicon glyphicon-search"></span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label><h4>Select the compliances that your organization is required by law or reulation.</h4></label><br><br>
				<div v-for="compliance in compliances" :key="compliance.id">
					<input type="checkbox" name="compliances" v-model="checkedCompliances" :value="compliance"> {{ compliance.name }} ~ <i>{{ compliance.description }}</i> <br><br>
				</div>
			</div>
			<hr>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Comply now</button>
			</div>
		</form> -->

		<div class="row">
			<div class="col-md-2">
				<router-link :to="{ name: 'compliance-management' }">
					<button class="btn btn-default">Back</button>
				</router-link>
			</div>
			<div class="col-md-8"></div>
			<div class="col-md-2">
				<button class="btn btn-primary pull-right" @click="addComplianceField">New compliance line</button>		
			</div>
		</div>
		<br><br>
		
		<form class="form" @submit.prevent="saveFields">
			<div class="row" v-for="(complianceField, index) in complianceFields" :key="complianceField.id">
				<div class="col-md-3">
					<div class="form-group">
						<label>Compliance</label>
						<select class="form-control" v-model="complianceField.complianceId" required>
							<option value="">Select option</option>
							<option v-for="compliance in compliances" :key="compliance.id" :value="compliance.id">
								{{ compliance.name }} ~ {{ compliance.description }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Inception Date</label>
						<input type="date" name="dueDate" v-model="complianceField.inceptionDate" class="form-control">
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Due Date</label>
						<input type="date" name="dueDate" v-model="complianceField.dueDate" class="form-control">
					</div>
				</div>
				<div class="col-md-3">
					<br>
					<button class="btn btn-danger btn-md" type="button" @click="complianceFields.splice(index, 1)">Delete</button>
				</div>
			</div>
			<div class="row" v-show="complianceFields.length > 0">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success btn-block pull-right" type="submit">Submit</button>
					</div>
				</div>
			</div>
		</form>
		<br><br><br>

		<!-- <div class="form-group">
			<label>&nbsp;</label>
			<button class="btn btn-primary pull-right" @click="submit">Submit Selected Compliances</button>
		</div>
		<v-client-table :columns="columns" :data="compliances" :options="options">
			<td slot="id" slot-scope="{ row }">{{ row.id }}</td>
			<td slot="name" slot-scope="{ row }">{{ row.name }}</td>
			<td slot="country" slot-scope="{ row }">{{ row.country !== null ? row.country.name: '' }}</td>
			<td slot="type" slot-scope="{ row }">{{ row.type !== null ? row.type.name: '' }}</td>
			<td slot="status" slot-scope="{ row }">
				<span class="label label-success" v-if="row.status === 'ACTIVE'">{{ row.status }}</span>
				<span class="label label-primary" v-else>{{ row.status }}</span>
			</td>
			<td slot="actions" slot-scope="{ row }">
				<router-link :to="{ name: 'view-compliance', params: { complianceId : row.id }}">
					<button class="btn btn-warning">Select <span class="glyphicon glyphicon-thumbs-up"></span></button>
				</router-link>
				<input type="checkbox" name="compliances" v-model="selectedCompliances" :value="row.id">
			</td>
			<td slot="dueDate" slot-scope="{ row }">
				<input type="date" v-model="row.dueDate" class="form-control">
			</td>
		</v-client-table> -->
	</div>
</template>

<script type="text/javascript">
	import ComplianceService from "@/services/ComplianceService";
	import CompanyService from "@/services/CompanyService";
	import ComplianceLineService from "@/services/ComplianceLineService";

	export default{
		name: "NewComplianceManagement",
		data: function(){
			return{
				compliances: [],
			    checkedCompliances: [],
			    selectedCompliances: [],
			    columns: [ 'id', 'name', 'country', 'category', "type", 'status', 'actions', 'dueDate'],
				options: {
		          headings: {
		            id: h => this.a11yCols(h, 'ID'),
		            name: h => this.a11yCols(h, 'Name'),
		            country: h => this.a11yCols(h, 'Country'),
		            category: h => this.a11yCols(h, 'Category'),
		            type: h => this.a11yCols(h, 'Type'),
		            status: h => this.a11yCols(h, 'Status'),
		            actions: h => this.a11yCols(h, 'Actions'),
		            dueDate: h => this.a11yCols(h, 'Due Date')
		          },
		          sortable: [ 'id', 'name', 'country', 'category', 'type', 'status', 'dueDate' ],
		          filterable: [ 'id', 'name', 'country', 'category', 'type', 'status', 'dueDate' ],
		        },
		        complianceFields: [],
		        companyId: null
			}
		},

		created(){
			this.findByAssociatedUserUsername();
			this.findAll();
			console.log(this.companyId);
			console.log(this.getUser);
		},

		methods: {
			addComplianceField(){
				this.complianceFields.push({
					companyId: this.companyId,
					complianceId: null,
					inceptionDate: null,
					dueDate: null,
					remainingDays: null,
					overdue: null,
				});
			},

			findAll: function(){
				ComplianceService.findAll().then(res => {
					this.compliances = res.data.body;
				}).catch(err => {
					console.log(err);
				});
			},
			save: function(){
				localStorage.setItem("checkedCompliances", JSON.stringify(this.checkedCompliances));
				if (this.checkedCompliances !== null) {
					this.$router.push({ name: 'complianceManagement/preview' });
				}
			},
			a11yCols: function(h, header) {
		        return h("a", {
		        	domProps: {
		        		href: "javascript:function a() { return false }",
		            	innerHTML: header
		            }
		        });
		    },

		    submit(){
		    	console.log(this.selectedCompliances);
		    },

		    findByAssociatedUserUsername: function(){
				CompanyService.findByAssociatedUserUsername(this.getUser.user.username).then(response => {
					this.companyId = response.data.body.id;
				}).catch(error => {
					console.log(error);
				});
			},

		    saveFields(){
		    	console.log(this.complianceFields);	
		    	this.$store.commit('setComplianceFields', JSON.stringify(this.complianceFields));
		    	this.$router.push({ name: 'compliance-management-preview' });

		  //   	this.show = true;
				// let complianceList = [];
				// for(var i = 0; i < this.compliances.length; i++){
				// 	complianceList.push({ complianceId: this.compliances[i].id });
				// }
				// this.company.compliances = complianceList;
				ComplianceLineService.saveCompliance(this.complianceFields).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						console.log('saved');
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
		    }
		},

		computed: {
			getCheckedCompliances: function(){
				return this.checkedCompliances;
			},

			getUser: function(){
				return this.$store.state.auth.user;
			}
		},

		watch: {
			getCheckedCompliance: function(){
				return this.checkedCompliances;
			}
		}
	};
</script>