<template>
	<div>
		<loading :show="show" :label="label">
		    <div class="processing-row" slot="loading-container">
		      <div class="text-center">
		        <div slot="loading-spinner">
		          <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
		        </div>
		        <p class="text-center" slot="loading-text">{{ label }}</p>
		      </div>
		    </div>
		</loading>

		<CarouselPromo/>

		<div class="row">
			<div class="col-md-3">
				<h4>Filter/Search</h4>
				<div class="panel">
					<div class="panel-body">
						<form class="form">
							<div class="form-group">
								<label>Category</label>
								<select class="form-control">
									<option v-for="jobCategory in jobCategories" :key="jobCategory.id">{{ jobCategory.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>Title</label>
								<select class="form-control">
									<option value="">Choose option</option>
									<option v-for="jobTitle in jobTitles" :key="jobTitle.id">{{ jobTitle.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>Rate</label>
								<input type="text" name="" placeholder="Enter rate" class="form-control">
							</div>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success">Search...</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<h4>Browse for professionals and get work done superb!</h4>
				<div class="panel" v-for="professional in professionals" :key="professional.id">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-3">
								<img src="@/assets/images/professional-1024x768.jpeg" height="100" width="150" class="img img-rounded">
							</div>
							<div class="col-md-9">
								<!-- <p v-if="professional.city !== null && professional.country !== null"> {{ professional.city !== null ? professional.city.name: 'Not available' }}, {{ professional.country !== null ? professional.country.name: 'Not available' }} – local time: {{ new Date() }}</p> -->
							</div>
						</div><hr>
						<div class="row">
							<div class="col-md-3">
								<h4>Hours per week</h4>
								<p>{{ professional.availability.replace('_', ' ') }}</p>
								<!-- <h4 v-if="professional.languages !== null">Languages 
									<span class="badge" v-for="language in professional.languages" :key="language.id">{{ language !== null ? language.name: "Not available" }}</span>
								</h4> -->
								<h4>Education</h4>
								<!-- <span v-if="professional.educations !== null">
									<div v-for="education in professional.educations" :key="education.id">
										<p>
										<span class="badge">{{ education.school !== null ? education.school.name: "Not available" }}</span> -  
										<span class="badge">{{ education.degree !== null ? education.degree.name: "Not available" }}</span>
										</p>
									</div>
								</span> -->
							</div>
							<div class="col-md-9">
								<div class="row">
									<div class="col-md-6">
										<h4>{{ professional.title }}</h4>
									</div>
									<div class="col-md-6">
										<h4>{{ professional.serviceCharge === null ? "NA": professional.serviceCharge.currency }} {{ professional.serviceCharge === null ? "NA": professional.serviceCharge.hourlyRate }}/hr
										</h4>
									</div>
								</div><hr>
								<p>{{ professional.overview }}</p>
								<hr>
								<h4>Skills</h4>
								<span class="badge" v-for="skill in professional.skills" :key="skill.id">{{ skill !== null ? skill.name: "NA" }}</span>
							</div>
						</div>
						<p>
							<router-link :to="{ name: 'view-professional', params: { professionalId: professional.id }}">
								<button class="btn btn-primary pull-right">View <span class="glyphicon glyphicon-info-sign"></span></button>
							</router-link>&nbsp;
						</p>
					</div>
				</div>
				<ul class="pagination">
					<li><a href="#">Prev</a></li>
					<li><a href="#">1</a></li>
					<li><a href="#">2</a></li>
					<li><a href="#">Next</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import ProfessionalService from "@/services/ProfessionalService";
	import loading from "vue-full-loading";
	import JobCategoryService from "@/services/JobCategoryService";
	import JobTitleService from "@/services/JobTitleService";
	import CarouselPromo from "@/views/carousel/CarouselPromo";

	export default{
		name: "ProfessionalList",
		components: {
			loading,
			CarouselPromo
		},

		data: function(){
			return {
				professionals: [],
				show: false,
				label: "Processing request",
				jobCategories: [],
				jobTitles: [],
			}
		},

		mounted: function(){
			this.findAll();
			this.findAllJobCategories();
			this.findAllJobTitles();
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProfessionalService.findAll().then(response => {
					this.professionals = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobCategories: function(){
				this.show = true;
				JobCategoryService.findAll().then(response => {
					this.jobCategories = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobTitles: function(){
				this.show = true;
				JobTitleService.findAll().then(response => {
					this.jobTitles = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},
		}
	};
</script>

<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>