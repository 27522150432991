<template>
  <div id="app">
    <app-nav-bar />
    <div class="container-fluid">
      <!-- this is where content is rendered -->
      <router-view />
    </div>
  </div>
</template>

<script type="text/javascript">
import AppNavBar from "@/components/AppNavBar.vue";

export default {
  components: {
    AppNavBar
  }
};
</script>

<style>
@import "./assets/styles/global_style.css";
/*
  #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}*/
</style>
