import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class PromotionService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "promotions");
  }

  findByReferenceNumber(referenceNumber) {
    return axios.get(GENERIC_SERVICE_URL + "promotions" + "/findByReferenceNumber/" + referenceNumber);
  }

  save(formData) {
    return axios.post(GENERIC_SERVICE_URL + "promotions", formData);
  }

  approve(id) {
    return axios.post(GENERIC_SERVICE_URL + "promotions/approve/" + id);
  }
}

export default new PromotionService;
