export default class Job{
	constructor(id, title, categoryId, type, hourlyRate, fixedRate, currency, expiryDate, attachment, 
		availability, estimatedBudget, expertiseLevel, paymentTerms, reach, projectType, projectLengthInMonths, 
		commitmentHoursPerWeek, applications, internalOrExternal, externalLink, locationRestriction, postedByUsername,
		workType, location, remuneration, totalHoursOfService, referenceNumber, experienceYears, requiredSkills,
		engagementDuration, engagementDurationMeasurement, engagementObjective, scopeOfWorks, reportingRequirements,
		contractualObligations, feesAndExpenses, description, status){
		this.id = id;
		this.title = title;
		this.categoryId = categoryId;
		this.type = type;
		this.hourlyRate = hourlyRate;
		this.fixedRate = fixedRate;
		this.currency = currency;
		this.expiryDate = expiryDate;
		this.attachment = attachment;
		this.availability = availability;
		this.expertiseLevel = expertiseLevel;
		this.paymentTerms = paymentTerms;
		this.reach = reach;
		this.projectType = projectType;
		this.projectLengthInMonths = projectLengthInMonths;
		this.commitmentHoursPerWeek = commitmentHoursPerWeek;
		this.applications = applications;
		this.internalOrExternal = internalOrExternal;
		this.externalLink = externalLink;
		this.locationRestriction = locationRestriction;
		this.postedByUsername = postedByUsername;
		this.workType = workType;
		this.location = location;
		this.remuneration = remuneration;
		this.totalHoursOfService = totalHoursOfService;
		this.referenceNumber = referenceNumber;
		this.experienceYears = experienceYears;
		this.requiredSkills = requiredSkills;
		this.engagementDuration = engagementDuration;
		this.engagementDurationMeasurement = engagementDurationMeasurement;
		this.engagementObjective = engagementObjective;
		this.scopeOfWorks = scopeOfWorks;
		this.reportingRequirements = reportingRequirements;
		this.contractualObligations = contractualObligations;
		this.feesAndExpenses = feesAndExpenses;
		this.description = description;
		this.status = status;
	}
}
