export default class Order{
	constructor(id, orderNumber, awardedCarrierId, assignedVehicleId, fulfilledById, deliveryAddressId, collectionAddresses,
		customerUsername, orderDate, orderLines, invoice, released, releasedDate, releasedTime, collected, collectionAt, deliveredAt,
		confirmedDelivery, confirmedDeliveryAt, deliveryComments, deliveryCharge, currency, status){
		this.id = id;
		this.orderNumber = orderNumber;
		this.awardedCarrierId = awardedCarrierId;
		this.assignedVehicleId = assignedVehicleId;
		this.fulfilledById = fulfilledById;
		this.deliveryAddressId = deliveryAddressId;
		this.collectionAddresses = collectionAddresses;
		this.customerUsername = customerUsername;
		this.orderDate = orderDate;
		this.orderLines = orderLines;
		this.invoice = invoice;

		// shipment details
		this.released = released;
		this.releasedDate = releasedDate;
		this.releasedTime = releasedTime;
		this.collected = collected;
		this.collectionAt = collectionAt;
		this.deliveredAt = deliveredAt;
		this.confirmedDelivery = confirmedDelivery;
		this.confirmedDeliveryAt = confirmedDeliveryAt;
		this.deliveryComments = deliveryComments;
		this.deliveryCharge = deliveryCharge;
		this.currency = currency;
		this.status = status;
	}
}