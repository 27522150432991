<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>Carrier assigned</h4>
	    <div class="panel">
	    	<div class="panel-body">
	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary" style="background: #000000; color: #ffffff;">
				        <th>Load #</th>
				        <th>Booking #</th>
				        <th>Load Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Assigned Truck</th>
				        <th>Load Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="order in getFilteredLoadBookings" :key="order.id">
				        	<td>
				        		<router-link :to="{ name: 'view-load', params: { orderNumber: order.orderNumber }}">
				        			{{ order.orderNumber }}
				        		</router-link>
				        	</td>
				        	<td>{{ order.orderNumber }}</td>
				        	<td>{{ order.currency }}{{ order.price }}</td>
				        	<td>ded</td>
				        	<td>ded</td>
				          	<!-- <td>
				          		{{ order.pickupAddress !== null ? order.pickupAddress.city.name + ' ' + order.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ order.dropOffAddress !== null ? order.dropOffAddress.city.name + ' ' + order.dropOffAddress.province.name: '' }}
				          	</td> -->
				          	<td>
				          		<!-- <router-link :to="{ name: 'trucks/view', params: { truckNumber: order.assignedTruck.truckNumber }}">
				          			{{ order.assignedTruck !== null ? order.assignedTruck.registrationNumber: '' }}
				          		</router-link> -->
				          	</td>
				          	<td>{{ order.status }}</td>
				          	<td>
				          		<span v-show="order.invoice !== null && !order.released && order.status === 'ACCEPTED'">
				          			<div class="row">
				          				<div class="col-md-3">
				          					<div class="form-group">
				          						<label>Collection Date</label>
				          						<input type="date" name="releasedDate" v-model="order.releasedDate">
				          					</div>
				          				</div>&nbsp;&nbsp;&nbsp;
				          				<div class="col-md-3">
				          					<label>Time</label>
				          					<vue-timepicker v-model="order.releasedTime" format="HH:mm" required></vue-timepicker>
				          				</div>
				          				<div class="col-md-1"></div>
				          				<div class="col-md-2">
				          					<label>&nbsp;</label><br>
				          					<button class="btn btn-success btn-sm" @click="release(order)">Authorize</button>
				          				</div>
				          			</div>
				          		</span>
				          	</td>
				          </tr>
				    </tbody>
				</table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import OrderService from "@/services/OrderService.js";
	import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

	export default{
		name: 'CarrierAssigned',
		components: {
			loading,
			VueTimepicker
		},

		props: {
			orders: {
				type: Array,
				default: function(){
					return []
				}
			}
		},

		data: function(){
			return {
				label: "Processing request",
				show: false
			}
		},

		computed: {
		    getFilteredLoadBookings(){
		    	return this.orders.filter(order => {
		    		return order.status === 'ACCEPTED' && !order.released
		    	});
		    }
		},

		methods: {
			release: function(order){
				this.show = true;
				if (order.releasedTime != null) {
					var releasedHour = order.releasedTime.HH;
					if (releasedHour === '') {
						releasedHour = "00";
					}
					var releasedMin = order.releasedTime.mm;
					if (releasedMin === '') {
						releasedMin = '00';
					}
					order.releasedTime = releasedHour + ':' + releasedMin;
				}
				OrderService.release(order).then(response => {
					if (response.data.statusCode === 201){
						window.location.reload();
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>