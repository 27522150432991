export default class Promotion {
  constructor(id, sectionType, headline, image, runsFrom, runsTo, description, status) {
    this.id = id;
    this.sectionType = sectionType;
    this.headline = headline;
    this.image = image;
    this.runsFrom = runsFrom;
    this.runsTo = runsTo;
    this.description = description;
    this.status = status;
  }
}
