<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<CarouselPromo/>
      	
		<div class="row">
			<div class="col-md-3">
				<h4>Filter/Search</h4>
				<div class="panel">
					<div class="panel-body">
						<form class="form">
							<div class="form-group">
								<label>Country</label>
								<select class="form-control">
									<option value="">All countries</option>
									<option v-for="country in countries" :key="country.id">{{ country.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>Category</label>
								<select class="form-control">
									<option value="">All categories</option>
									<option v-for="grantAndFundingOpportunityCategory in grantAndFundingOpportunityCategories" :key="grantAndFundingOpportunityCategory.id">{{ grantAndFundingOpportunityCategory.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block">Filter</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-md-9">
				<div class="panel">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-6">
								<span class="glyphicon glyphicon-alert"></span>
								<h4><router-link to="funding-opportunity-search" @click.native="latest">View Latest Funding opportunities</router-link></h4>
								<p>View latest grants, fellowships, and training opportunities.</p>
							</div>
							<div class="col-md-6">
								<span class="glyphicon glyphicon-alert"></span>
								<h4><router-link to="funding-opportunity-search" @click.native="deadlines">Upcoming deadlines</router-link></h4>
								<p>View upcoming deadlines for grants, fellowships, and training opportunities.</p>
							</div>
						</div>
					</div>
				</div>
				<div class="panel" v-for="grantAndFundingOpportunity in grantAndFundingOpportunities" :key="grantAndFundingOpportunity.id">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-3">
								<img src="@/assets/images/grants.png" height="150" width="250">
							</div>
							<div class="col-md-3">
								<p>{{ grantAndFundingOpportunity.title }}</p>
								<p>{{ grantAndFundingOpportunity.currency }} {{ grantAndFundingOpportunity.estimatedBudget }} per hour</p>
								<p>{{ grantAndFundingOpportunity.type }}</p>
								<p><b>Post Date: {{ grantAndFundingOpportunity.createdAt }}</b></p>
								<p><b>Deadline Date: {{ grantAndFundingOpportunity.expiryDate }}</b></p>
								<p>
									<router-link :to="{ name: 'view-funding-opportunity', params: { grantAndFundingOpportunityId: grantAndFundingOpportunity.id }}">
										<button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
				<ul class="pagination">
					<li><a href="#">Prev</a></li>
					<li><a href="#">1</a></li>
					<li><a href="#">2</a></li>
					<li><a href="#">Next</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import loading from "vue-full-loading";
	import GrantAndFundingOpportunityService from "@/services/GrantAndFundingOpportunityService";
	import GrantAndFundingOpportunityCategoryService from "@/services/GrantAndFundingOpportunityCategoryService";
	import CountryService from "@/services/CountryService";
	import CarouselPromo from "@/views/carousel/CarouselPromo";

	export default{
		name: "GrantAndFundingOpportunityList",
		components: {
			loading,
			CarouselPromo
		},

		data: function(){
			return {
				grantAndFundingOpportunities: [],
				grantAndFundingOpportunityCategories: [],
				countries: [],
				label: "Processing request",
				show: false,
			}
		},

		mounted: function(){
			this.findAll();
			this.findAllTenderCategories();
			this.findAllCountries();
		},

		methods: {
			findAll: function(){
				this.show = true;
				GrantAndFundingOpportunityService.findAll().then(response => {
					this.grantAndFundingOpportunities = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			},

			fetchLatestGrantAndFundingOpportunities: function(){
				this.show = true;
				GrantAndFundingOpportunityService.findAll().then(response => {
					this.show = false;
					this.grantAndFundingOpportunities = response.data.body;
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			},

			fetchUpcomingDeadlinesGrantAndFundingOpportunities: function(){
				this.show = true;
				GrantAndFundingOpportunityService.findAll().then(response => {
					this.show = false;
					this.grantAndFundingOpportunities = response.data.body;
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			},

			findAllTenderCategories: function(){
				this.show = true;
				GrantAndFundingOpportunityCategoryService.findAll().then(response => {
					this.grantAndFundingOpportunityCategories = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllCountries: function(){
				this.show = true;
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			latest(){
				this.fetchLatestGrantAndFundingOpportunities();
			},

			deadlines(){
				this.fetchUpcomingDeadlinesGrantAndFundingOpportunities();
			}
		},
	};
</script>