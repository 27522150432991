<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <button class="btn btn-primary pull-right" @click="enableEdit">Edit <span class="glyphicon glyphicon-edit"></span></button>
    <br><br>

    <form class="form" @submit.prevent="update">
        <div class="form-group">
          <label>Business Name</label>
          <input type="text" name="name" id="name" class="form-control" v-model="company.name" :placeholder="company.name" :disabled="isDisabled">
        </div>

        <div class="form-group">
          <label>Business Trading Name</label>
          <input type="text" name="tradingName" id="tradingName" class="form-control" v-model="company.tradingName" :placeholder="company.tradingName !== null ? company.tradingName: 'Not available'" :disabled="isDisabled">
        </div>

        <div class="form-group">
          <label>Business Partner/Registration Number</label>
          <input type="text" name="businessPartnerNumber" id="businessPartnerNumber" class="form-control" v-model="company.businessPartnerNumber" :placeholder="company.businessPartnerNumber !== null ? company.businessPartnerNumber: 'Not available'" :disabled="isDisabled">
        </div>

        <span v-show="isDisabled === false">
          <button class="btn btn-success btn-sm pull-right" type="button" @click="addPhoneNumberField">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
        </span>

        <label>Phone Numbers</label><br><br>
        <div v-if="company.phoneNumbers.length > 0">
          <div v-for="(phoneNumberField, phoneNumberIndex) in company.phoneNumbers" :key="phoneNumberField.id">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" v-show="isDisabled">
                  <label>Country Code</label>
                  <input type="text" name="zipCodeId" id="zipCodeId" class="form-control" :placeholder="phoneNumberField.zipCode !== null ? phoneNumberField.zipCode.code: 'Not available'" :disabled="isDisabled">
                </div>
                <div class="form-group" v-show="!isDisabled">
                  <label for="phoneNumbers">Country Code</label>
                  <select class="form-control" v-model="phoneNumberField.zipCodeId" :disabled="isDisabled">
                    <option value="" disabled>Select option</option>
                    <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="phoneNumbers">Business Phone Number</label>
                  <input type="number" name="phoneNumber" id="phoneNumber" class="form-control" v-model="phoneNumberField.phoneNumber" maxlength="9" oninput="javascript: if (this.value.length > this.maxLength) { return this.value = this.value.slice(0, this.maxLength); }" placeholder="Enter Phone Number Without Zero Prefix e.g. 77123456789..." :disabled="isDisabled" required>
                </div>              
              </div>
              <div class="col-md-2">
                <div class="form-group" v-show="company.phoneNumbers.length > 1">
                  <label>&nbsp;</label><br>
                  <button class="btn btn-danger btn-sm pull-left" type="button" @click="company.phoneNumbers.splice(phoneNumberIndex, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                </div>
              </div>
            </div>          
          </div>
        </div>

        <div class="form-group">
          <label>Business Email</label>
          <input type="text" name="email" id="email" class="form-control" v-model="company.email" :placeholder="company.email" :disabled="isDisabled">
        </div>

        <div class="form-group">
          <label>Created On</label>
          <input type="text" name="createdAt" id="createdAt" class="form-control" v-model="company.faxNumber" :placeholder="company.createdAt" disabled>
        </div>

        <div class="form-group">
          <label>Last Updated On</label>
          <input type="text" name="updatedAt" id="updatedAt" class="form-control" v-model="company.updatedAt" :placeholder="company.updatedAt" disabled>
        </div>

        <div class="form-group">
          <label>Business Description e.g We are a business that deasl in...</label>
          <textarea name="description" id="description" class="form-control" v-model="company.description" :placeholder="company.description" rows="5" :disabled="isDisabled"></textarea>
        </div>

        <div class="form-group">
          <label>Status</label>
          <select class="form-control" :disabled="isDisabled">
            <option value="" disabled>Select option</option>
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        </div>

        <div class="row" v-show="isDisabled === false">
          <div class="col-md-10"></div>
          <div class="col-md-2">
            <div class="form-group">
              <label>&nbsp;</label>
              <button class="btn btn-warning btn-block pull-right" type="submit">Update</button>
            </div>
          </div>
        </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import loading from "vue-full-loading";
  import BusinessSectorService from "@/services/BusinessSectorService.js";
  import ZipCodeService from "@/services/ZipCodeService.js";

  export default {
    name: "BasicDetailsComponent",
    components: {
      loading
    },

    props: {
      company: {
        type: Object,
        default: null
      }
    },

    data: function() {
      return {
        isDisabled: true,
        label: "Processing request...",
        show: false,
        businessSectors: [],
        zipCodes: [],
      }
    },

    created(){
      this.getAllBusinessSectors();
      this.getZipCodes();
    },

    methods: {
      enableEdit: function(){
        if (this.isDisabled) {
          this.isDisabled = false;
        }
      },

      update: function(){
        this.show = true;
        this.company.companyId = this.getCompanyId;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode == 201) {
            this.show = false;
            this.isDisabled = true;
            this.$router.push({ name: 'companies' });
          }}).catch(error => {
            console.log(error);
        });
      },

      getAllBusinessSectors: function() {
        return BusinessSectorService.findAll()
          .then(response => {
            this.businessSectors = response.data.body;
          })
          .catch(error => {
            console.log(error);
          });
      },

      getZipCodes: function() {
        return ZipCodeService.findAll()
          .then(response => {
            this.zipCodes = response.data.body;
          })
          .catch(error => {
            console.log(error);
          });
      },

      addPhoneNumberField: function(){
        this.company.phoneNumbers.push({
          id: null,
          zipCodeId: null,
          phoneNumber: null
        });
      },
    },

    computed: {
      getCompanyId: function(){
        return this.$route.params.companyId;
      },

      years: function() {
        const year = new Date().getFullYear();
        const years = Array.from(
          { length: year - 1900 },
          (value, index) => 1901 + index
        );
        return years;
      },
    }
  };
</script>
