<template>
    <div>
        <loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

        <div class="panel">
            <div class="panel-body">
              <p>Please ensure that you put pictures that outline the product visibly to get more chances of getting buy for your next purchase order!</p>
                <div class="alert alert-success" v-if="message !== null">
                    <h4 class="text-danger">{{ message }}</h4>
                </div>
                <div>
                    <h4>Choose image (can upload multiple files or select one by one)</h4><br>
                    <input type="file" multiple class="form-control" accept="image/png, image/jpeg, image/jpg" @change="handleFileUpload" ref="photosRef" required>
                </div><br>
                <div v-if="images">
                    <div class="row">
                        <div class="col-md-2" v-for="(image, index) in product.photos" :key="index">
                        	image: {{ image.fileName }}
                            <img :src="image.previewUrl" class="img img-responsive img-rounded img-preview"/>
                            <button class="btn btn-danger btn-sm" @click="removeImage(index)">Remove image</button>
                            <br><br>
                        </div>
                    </div>
                </div>
                <br><br><br>
                <span v-if="images.length > 6">
                    <h4>Cannot submit images. Please remove some images. Max limit is 5</h4>
                </span>
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-2">
                    <span v-if="images.length > 0">
                      <span v-if="images.length <= 6">
                        <button class="btn btn-primary btn-block" @click="save">Submit</button>
                      </span>
                    </span>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
  import axios from "axios";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
  import loading from "vue-full-loading";
  import ProductService from "@/services/ProductService";

  export default{
  	name: 'EditProductPhotos',
    components: {
      loading
    },

    data(){
      return {
        images: [],
        formData: new FormData,
        show: false,
        label: 'Processing request',
        message: null,
        maxFileSize: 5,
        product: null
    }
  },

  computed: {
    getProductNumber(){
        return this.$route.params.productNumber;
    },

    getProductId(){
        return this.$route.params.productId;
    }
  },

  mounted(){
  	this.findById();
  },

  methods: {
  	findById: function(){
  		this.show = true;
  		ProductService.findById(this.getProductId).then(response => {
  			this.show = false;
  			this.product = response.data.body;
  		}).catch(error => {
  			this.show = false;
  			console.log(error);
  		});
  	},

    onFileChange(e) {
    
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      //this.handleFileUpload(files);
    },

    createImage(files) {
      // var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        // reader.onload = function(event) {
        //   const imageUrl = event.target.result;
        //    vm.images.push(imageUrl);
        // }
        // reader.readAsDataURL(files[index]);

        reader.onload = (e) => {
            this.product.photos.push({
                file: files[index],
                previewUrl: e.target.result
              });
            };
        reader.readAsDataURL(files[index]);
      }
    },

    handleFileUpload(event) {
        if (this.images.length < 6) {
          const files = event.target.files;
          for (let i = 0; i < files.length; i++) {
            if (files[i].size / (1024 * 1024) > this.maxFileSize) {
              alert('File ' + files[i].name + ' exceeds size of ' + this.maxFileSize + 'MB');
              this.$refs.photosRef.value = null;
              return;
            }
            else{
              const reader = new FileReader();
              reader.onload = (e) => {
                this.images.push({ // also check if file not already uploaded and deny
                  file: files[i],
                  previewUrl: e.target.result
                });
              };
              reader.readAsDataURL(files[i]);
              console.log('test');
              //this.$refs.photosRef.value = null;
            }
          }
        }else{
            alert('Max number of images reached.');
        }
        
    },

    removeImage(index) {
      this.images.splice(index, 1)
    },

    save(){
        this.show = true;
        for (var i = 0; i < this.images.length; i++) {
            this.formData.append('photos', this.images[i].file);
        }
        axios.post(GENERIC_SERVICE_URL + "products/uploadPhotos/" + this.getProductNumber, this.formData).then(response => {
          console.log(response.data);
          if (response.status === 201) {
            console.log('yay');
            this.message = response.data.body;
            this.formData = new FormData;
            this.$refs.photosRef.value = null;
            this.images = [];
            this.$router.push({ name: 'productsPreview' });
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response.status);
          this.show = false;
          if (error.response.status === 500) {
            // this.message = "Snap! error occured. Please try again or contact support if problem persists";
            this.message = error.response.data.message;
          }
          this.show = false;
        });
    }
  }
};
</script>

<style scoped>
  .img-preview {
    width: 100%;
    height: 200px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    object-fit: cover;
  }
</style>