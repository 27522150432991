import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class SubscriptionService {
  
  findByActiveSubscription(username) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptions/findByActiveSubscription/" + username);
  }

  subscriptionExpired(username) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptions/subscriptionExpired/" + username);
  }
}

export default new SubscriptionService;
