<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add portfolio project</h4><br>
				<form class="form" @submit.prevent="save">
					<button class="btn btn-success btn-sm pull-right" @click="addPortfolioProjectField" type="button">Add <span class="glyphicon glyphicon-plus"></span></button>

					<div v-for="(portfolioProjectField, index) in portfolioProjectFields" :key="portfolioProjectField.id">
						<h4>Portfolio project Number {{ index + 1 }}</h4>

						<div class="form-group">
							<label>Project Title</label><br><br>
							<textarea name="title" id="title" placeholder="Enter a brief, but descriptive title max 50 characters" class="form-control" v-model="portfolioProjectField.title" rows="5" minlength="2" maxlength="50"></textarea>
						</div>

						<div class="form-group">
							<label>Project Description</label><br><br>
							<textarea name="title" id="title" placeholder="Enter a project description max 250 characters" class="form-control" v-model="portfolioProjectField.title" rows="5" minlength="2" maxlength="250"></textarea>
						</div>

						<!-- <div class="form-group">
							<label>Related Upwork Job (optional)</label>
							<p>Once you've completed contracts on Upwork, you'll be able to link your work.</p>
						</div> -->

						<div class="form-group">
							<label>Completion Date (optional).</label>
							<input type="date" name="completionDate" id="completionDate" class="form-control" v-model="portfolioProjectField.completionDate">
						</div>

						<!-- consider that images are uploaded in the professional section -->
						<div class="form-group">
							<label>Photo(s).</label>
							<input type="file" name="completionDate" id="completionDate" class="form-control">
						</div>

						<!-- <div class="row">
							<h4>Select template</h4><br>
							<p>Choose how you want this project displayed to clients. You can always switch templates, even after you publish.</p>
							<div class="row text-center">
								<div class="col-md-4">
									<img src="@/assets/logo.png">
									<h4>Gallery</h4>
									<p>Display images or videos, one at a time</p>
								</div>
								<div class="col-md-4">
									<img src="@/assets/logo.png">
									<h4>Case Study</h4>
									<p>Highlight the project problem and your solution</p>
								</div>
								<div class="col-md-4">
									<img src="@/assets/logo.png">
									<h4>Classic</h4>
									<p>Allow clients to scroll through your work</p>
								</div>
								<p>Showcase the work you did on this portfolio project with a description underneath a carousel of images and videos.</p>
							</div>
						</div> -->
						<div class="form-group" v-if="portfolioProjectFields.length > 0">
							<label>&nbsp;</label>
							<button class="btn btn-danger btn-sm pull-right" type="button" @click="portfolioProjectFields.splice(index, 1)">Remove</button>
						</div>
					</div><br><br>
					<br>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group" v-if="portfolioProjectFields.length > 0">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
					<div class="row" v-if="portfolioProjectFields.length === 0">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<router-link :to="{ name: 'newEducation'}">
									<button class="btn btn-primary btn-block" type="submit">Next</button>
								</router-link>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewPortfolio",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing request",
				show: false,
				portfolioProjectFields: []
			}
		},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			addPortfolioProjectField: function(){
				this.portfolioProjectFields.push({
					title: null,
					completionDate: null,
					photos: null
				});
			},

			save: function(){
				this.show = true;
				this.professional.portfolioProjects = this.portfolioProjectFields;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newSkills", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>