import { API_URL } from "@/config/api.js";
import axios from "axios";
import authHeader from "@/services/authHeader";

class IndustryTypeService {
  getAllIndustryTypes() {
    return axios.get(API_URL + "industryTypes");
  }
  createIndustryType(industryType) {
    return axios.post(API_URL + "industryTypes", {
      industryTypeName: industryType.industryTypeName,
      industryTypeDescription: industryType.industryTypeDescription,
      industryTypeStatus: industryType.industryTypeStatus
    });
  }

  getIndustryTypeById(id) {
    return axios.get(API_URL + "industryTypes/" + id);
  }

  updateIndustryType(industryType, id) {
    return axios.put(
      API_URL + "industryTypes/" + id,
      {
        id: industryType.id,
        industryTypeName: industryType.industryTypeName,
        industryTypeDescription: industryType.industryTypeDescription,
        industryTypeStatus: industryType.industryTypeStatus
      },
      { headers: authHeader() }
    );
  }
}

export default new IndustryTypeService();
