<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="row">
      		<div class="col-md-10"></div>
      		<div class="col-md-2">
      			<router-link :to="{ name: 'job-category-list' }">
					<button class="btn btn-default pull-right">Cancel</button>
				</router-link>
      		</div>
      	</div>
		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Name</label>
				<input type="text" name="name" id="name" placeholder="Enter job category name" class="form-control" v-model="jobCategory.name">
			</div>

			<div class="form-group">
				<label>Description</label>
				<textarea class="form-control" rows="5" placeholder="Enter job category description" name="description" id="description" v-model="jobCategory.description"></textarea>
			</div>

			<div class="form-group">
				<label>Status</label>
				<select class="form-control" name="status" id="status" v-model="jobCategory.status">
					<option value="">Select option</option>
					<option value="ACTIVE">ACTIVE</option>
					<option value="INACTIVE">INACTIVE</option>
				</select>
			</div>

			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success btn-block" type="submit">Submit</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import JobCategoryService from "@/services/JobCategoryService";
	import JobCategory from "@/models/JobCategory";
	import loading from "vue-full-loading";

	export default{
		name: "NewJobTitle",
		components: {
			loading
		},

		data: function(){
			return {
				jobCategory: new JobCategory,
				label: 'Processing request...',
				show: false
			}
		},

		created(){

		},

		methods: {
			save: function(){
				this.show = true;
				JobCategoryService.save(this.jobCategory).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name : 'job-category-list' });
					}
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>