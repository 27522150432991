<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>


  </div>
</template>

<script type="text/javascript">
import Role from "@/models/Role";
import RoleService from "@/services/RoleService";
import loading from "vue-full-loading";

export default {
  name: "NewRole",
  components: {
    loading
  },

  data: function() {
    return {
      show: false,
      label: "Processing...",
      role: new Role(),
      errors: [],
      message: null,
      editing: false
    };
  },

  mounted(){
    if("roleId" in this.$route.params){
      this.editing = true;
      this.findById()
    }
  },

  methods: {
    saveRole: function() {
      this.role.id = this.$route.params.roleId;
      this.show = true;
      RoleService.save(this.role)
        .then(response => {
          console.log(response);
          if (response.data.statusCode == 422) {
            this.show = false;
            this.errors = response.data.errors;
          }
          if (response.data.statusCode == 400) {
            this.show = false;
            this.errors = response.data.errors;
            this.message = response.data.message;
          }
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "roles" });
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error);
          this.errors = error.response.data.errors;
          this.message = error.response.data.message;
        });
    },

    findById: function(){
      RoleService.findById(this.$route.params.roleId).then(response => {
        this.role = response.data.body;
      }).catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
