<template>
  <div>
    <ul class="nav" v-for="menu in getUserMenu" :key="menu.name">
      <li>
        <router-link :to="{ name: menu.link }" replace>
          <div class="row">
            <div class="col-md-3">
              <img :src="'/menuIcons/' + menu.icon + imageExtension" v-bind:alt="menu.name" height="30" width="30" class="img img-rounded img-responsive"/>
            </div>
            <div class="col-md-9">
              {{ dashboardLinkNameHidden ? "" : menu.name }}
            </div>
          </div>
          <div v-show="sideBarMenuToggle">
            <img :src="'/menuIcons/' + menu.icon + imageExtension" v-bind:alt="menu.name" height="30" width="30" class="img img-rounded img-responsive"/>
            {{ dashboardLinkNameHidden ? "" : menu.name }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
  import SellerService from "@/services/SellerService.js";

  export default{
    props: {
      roles: {
        type: Array,
        default: function(){
          return []
        }
      },

      dashboardLinkNameHidden: { // hide link name when we toggle sidebar
        type: Boolean,
        default: false
      },

      sideBarMenuToggle: {
        type: Boolean,
        default: false
      },

      preferences: {
        type: Array,
        default: function(){
          return []
        }
      }
    },

    data: function(){
      return{
        menuArray: [],
        imageExtension: '.png',
        seller: null
      }
    },

    mounted(){
      this.findById();
    },

    methods: {
      findById: function(){
        SellerService.findByAssociatedAccountUsername(this.getUser.username).then(response => {
          this.seller = response.data.body;
          console.log(this.seller);
        }).catch(error => {
          console.log(error);
        });
      }
    },

    computed: {
      getUser: function(){
        return this.$store.state.auth.user.user;
      },

      getUserMenu: function(){
        let defaultMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "My Profile",
            link: "userProfile",
            icon: "user"
          }
        ];

        let superAdminMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard",
          },

          {
            name: "Settings",
            link: "configurations",
            icon: "configuration"
          },

          // {
          //   name: "All Jobs",
          //   link: "jobConfigurationList",
          //   icon: "fullTimeJob"
          // },

          {
            name: "Full time Jobs",
            link: "full-time-jobs",
            icon: "fullTimeJob"
          },

          {
            name: "Tender Jobs",
            link: "tender-jobs-configuration",
            icon: "fullTimeJob"
          },

          {
            name: "Volunteering Jobs",
            link: "volunteering-jobs-configuration-list",
            icon: "fullTimeJob"
          },

          // {
          //   name: "Tenders",
          //   link: "tenderConfigurationList",
          //   icon: "configuration"
          // },   

          {
            name: "Delivery Services",
            link: "delivery-services",
            icon: "box-truck"
          },

          {
            name: "Payments",
            link: "payment-list",
            icon: "user"
          },
          
          // {
          //   name: "Products",
          //   link: "products/configuration",
          //   icon: "product"
          // },

          // {
          //   name: "Full Time Jobs",
          //   link: "jobs/fullTimeJobList",
          //   icon: "fullTimeJob"
          // },

          // {
          //   name: "Contract Jobs",
          //   link: "jobs/contractJobList",
          //   icon: "contractJob"
          // },

          // {
          //   name: "Volunteering Jobs",
          //   link: "jobs/volunteeringJobList",
          //   icon: "fullTimeJob"
          // },

          // {
          //   name: "Grants & Funds",
          //   link: "grandAndfundingOpportunityConfigurationList",
          //   icon: "configuration"
          // },

          {
            name: "Promotions",
            link: "promotion-list",
            icon: "tender"
          },

          {
            name: "Carousel Promo",
            link: "carousel-promo-list",
            icon: "tender"
          },
        ];

        let userMenu = [
         {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Profiles",
            link: "profiles",
            icon: "user"
          },
          
          // {
          //   name: "Preferences",
          //   link: "preferences",
          //   icon: "preference"
          // },

          // {
          //   name: "Contract jobs",
          //   link: "contract-jobs",
          //   icon: "preference"
          // },

          {
            name: "Hire a Pro",
            // link: "contract-jobs-configuration",
            link: 'my-contract-jobs-configuration',
            icon: "fullTimeJob"
          },

          // {
          //   name: "My Subscriptions",
          //   link: "subscriptions",
          //   icon: "subscription-model"
          // },

          {
            name: "Compliances",
            link: "compliance-management",
            icon: "RFQ"
          },

          // {
          //   name: "My Quotations",
          //   link: "jobQuotations",
          //   icon: "RFQ"
          // },

          {
            name: "Seller Profile",
            link: "sellerProfile",
            icon: "seller"
          },

          {
            name: "Products",
            link: "productsPreview",
            icon: "product"
          },

          {
            name: "Payments",
            link: "payment-list",
            icon: "user"
          },

          // {
          //   name: "Message Feeds",
          //   link: "messageFeeds",
          //   icon: "news-feed"
          // }
          {
            name: 'Orders',
            link: 'customer-order-list',
            icon: 'seller'
          },

          {
            name: "Shipments",
            link: "buyer-shipments",
            icon: "tracking"
          },

          {
            name: "Promotions",
            link: "promotion-list",
            icon: "tender"
          },

          {
            name: "Carousel Promo",
            link: "carousel-promo-list",
            icon: "tender"
          },
        ];

        let carrrierMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Profiles",
            link: "profiles",
            icon: "user"
          },
          
          {
            name: 'Orders',
            //link: 'product-orders',
            link: 'carrier-order-list',
            icon: 'seller'
          },

          {
            name: "Payments",
            link: "payment-list",
            icon: "user"
          },

          {
            name: 'Shipments',
            link: 'carrier-shipments',
            icon: 'tracking'
          },

          {
            name: 'Vehicles',
            link: 'truck-list',
            icon: 'tracking'
          },

          {
            name: "Promotions",
            link: "promotion-list",
            icon: "tender"
          },

          {
            name: "Carousel Promo",
            link: "carousel-promo-list",
            icon: "tender"
          },
        ];

        let sellerMenu = [
         {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Profiles",
            link: "profiles",
            icon: "user"
          },
          
          // {
          //   name: "Preferences",
          //   link: "preferences",
          //   icon: "preference"
          // },

          // {
          //   name: "Contract jobs",
          //   link: "contract-jobs",
          //   icon: "preference"
          // },

          {
            name: "Hire a Pro",
            //link: "contract-jobs-configuration",
            link: 'my-contract-jobs-configuration',
            icon: "fullTimeJob"
          },

          // {
          //   name: "My Subscriptions",
          //   link: "subscriptions",
          //   icon: "subscription-model"
          // },

          {
            name: "Compliances",
            link: "compliance-management",
            icon: "RFQ"
          },

          // {
          //   name: "My Quotations",
          //   link: "jobQuotations",
          //   icon: "RFQ"
          // },

          {
            name: "Seller Profile",
            link: "sellerProfile",
            icon: "seller"
          },

          {
            name: "Products",
            link: "productsPreview",
            icon: "product"
          },

          {
            name: 'Orders',
            link: 'seller-order-list',
            icon: 'seller'
          },

          // {
          //   name: "Hire a Pro",
          //   link: "hire-a-pro",
          //   icon: "user"
          // },

          // {
          //   name: "Message Feeds",
          //   link: "messageFeeds",
          //   icon: "news-feed"
          // }

          {
            name: "Payments",
            link: "payment-list",
            icon: "user"
          },

          {
            name: "Shipments",
            link: "seller-shipments",
            icon: "tracking"
          },

          {
            name: "Promotions",
            link: "promotion-list",
            icon: "tender"
          },

          {
            name: "Carousel Promo",
            link: "carousel-promo-list",
            icon: "tender"
          },
        ];
        
        if(this.roles.includes("SUPER_ADMIN") || this.roles.includes("ADMIN")){
          return superAdminMenu;
        }

        if (this.roles.includes("USER") && this.seller !== null) {
          console.log('Yay seller');
          return sellerMenu;
        }

        if (this.roles.includes('USER')) {
          console.log('Yay');
          return userMenu;
        }
        
        if (this.roles.includes('CARRIER')) {
          return carrrierMenu;
        }
        else{
          return defaultMenu;
        }
      }
    }
  };
</script>