<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>Shopping Cart</h4>
					</div>
					<router-link :to="{ name: 'product-search' }">
						<div class="col-md-1">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>

				<div v-if="cartProducts !== null">
					<div class="row" v-show="cartProducts.length > 0">
						<div class="col-md-9">
							<div class="panel" v-for="(product, index) in cartProducts" :key="product.id">
								<div class="panel-body">
									<div class="row">
										<div class="col-md-3">
											<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[index].id" class="img img-responsive img-rounded product-image">
										</div>
										<div class="col-md-4">
											<h4>{{ product.name }}</h4>
											<p>{{ product.description }}</p>
											<p>{{ product.availability }}</p>
										</div>
										<div class="col-md-5">
											<h4>{{ product.currency }}{{ product.totalProductCost === 0 ? product.totalProductCost: product.price }}</h4>
											<p>Quantity {{ product.quantity }}</p>
											<div class="form-group">
												<select class="form-control" @change="calculateTotalProductCostTest2(index, quantity)" v-model="quantity">
													<option value="">Select option</option>
													<option value="1" selected>1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4">4</option>
													<option value="5">5</option>
													<option value="6">6</option>
													<option value="7">7</option>
													<option value="8">8</option>
													<option value="9">9</option>
													<option value="10">10</option>
												</select>
											</div>
											<p><button class="btn btn-danger" type="button" @click="removeCartProduct(index)">Remove <span class="glyphicon glyphicon-trash"></span></button></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="panel">
								<div class="panel-body">
									<h4>Cart Summary</h4>
									<hr>
									<h4>TOTAL: ({{ cartProducts === null ? "0 items in cart": cartProducts.length + " items" }}) USD{{calculateTotalProductsCost }}</h4>
									<h4>
										<!-- <router-link :to="{ name: 'checkout' }">
											<button class="btn btn-success"><b>Proceed to checkout</b></button>
										</router-link> -->
										<router-link :to="{ name: 'checkout-option' }">
											<button class="btn btn-success"><b>Proceed to checkout</b></button>
										</router-link>
									</h4>
									<hr>
									<ul class="list-unstyled text-danger">
										<li>Secure checkout</li>
										<li>Many ways to pay</li>
										<li>Fast, reliable delivery</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-show="cartProducts === null || cartProducts.length < 1">
					<img src="@/assets/images/EmptyShoppingCart.webp" height="150" width="250">
					<h4>Your shopping cart is empty</h4><br>
					<h4><router-link :to="{ name: 'products' }"><button class="btn btn-success">Continue Shopping <span class="glyphicon glyphicon-shopping-cart"></span></button></router-link></h4>
				</div>
			</div>
		</div>			
	</div>
</template>

<script type="text/javascript">
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

	export default{
		name: "ProductCart",
		components: {

		},
		data: function(){
			return {
				cartProducts: [],
				quantity: 1,
				totalProductsCost: 0,
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
				transformedCartProducts: []
			}
		},

		mounted(){
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts" || '[]'));
			// for(let i = 0; i < this.cartProducts.length; i++){
			// 	this.transformedCartProducts.push({
			// 		id: this.cartProducts[i].id,
			// 		price: this.cartProducts[i].price,
			// 		cartQuantity: 1,
			// 	});
			// }
		},

		methods: {
			calculateTotalProductCost: function(index){
				this.cartProducts[index].totalProductCost = this.cartProducts[index].quantity * this.cartProducts[index].price;
				localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
			},

			removeCartProduct: function(index){
				this.cartProducts.splice(index, 1);
				localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
			},

			calculateTotalProductCostTest2: function(index, quantity){
				for(let i = 0; i < this.cartProducts.length; i++){
					this.cartProducts[index].price = parseFloat(quantity) * parseFloat(this.cartProducts[index].price);
					this.cartProducts[index].quantity = quantity; //) * parseFloat(this.cartProducts[index].price);
				}
				console.log('cartProducts: ', this.cartProducts);
			},
		},

		computed: {
			calculateTotalProductCostTest: function(index){
				let test = this.cartProducts[1];
				console.log('test: ', test);

				var vm = this;
				return function(){
					console.log('index: ', index);
					console.log('yay: ', vm.cartProducts[1]);
					//return null; // vm.cartProducts[index].quantity * vm.cartProducts[index].price;
					return vm.transformedCartProducts[1].price;
				}
				//localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
			},

			calculateTotalProductsCost: function(){
				let total = 0;
				for(var i = 0; i < this.cartProducts.length; i++){
					total += this.cartProducts[i].price;
				}
				return total;
			}
		}
	};
</script>

<style type="text/css" scoped>
  .product-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
</style>