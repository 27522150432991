<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<h4>Payment List</h4>
				<hr>
				<form class="form">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group">
								<label>Payment Type</label>
								<select class="form-control" v-model="paymentType">
									<option value="" disabled>Select payment type</option>
									<option value="ALL">All payments</option>
									<option value="ONLINE_PAYMENT">Online payments</option>
									<option value="BANK">Bank payments</option>
									<option value="CASH">Cash payments</option>
								</select>
							</div>
						</div>

						<div class="col-md-2">
							<div class="form-group">
								<label>Currency</label>
								<select class="form-control">
									<option value="" disabled>Select currency</option>
									<option value="ZWL">ZWL</option>
									<option value="USD">USD</option>
								</select>
							</div>
						</div>

						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-primary btn-block">Filter</button>
							</div>
						</div>
					</div>
				</form>
				<br>

				<table class="table table-bordered table-striped table-responsive">
					<thead class="bg-primary">
						<th>ID</th>
						<th>Reference Number</th>
						<th>Currency</th>
						<th>Amount Paid</th>
						<th>Status</th>
						<th>Action</th>
					</thead>
					<tbody>
						<tr v-for="payment in payments" :key="payment.id">
							<td>{{ payment.id }}</td>
							<td>{{ payment.referenceNumber }}</td>
							<td>{{ payment.currency }}</td>
							<td>{{ payment.amount }}</td>
							<td>{{ payment.status }}</td>
							<td>
								<router-link :to="{ name: 'view-payment', params: { referenceNumber: payment.referenceNumber }}">
									<button class="btn btn-primary btn-sm">View</button>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService.js";
	import loading from "vue-full-loading";

	export default{
		name: "PaymentList",
		components: {
			loading
		},

		data: function () {
			return {
				paymentType: null,
				payments: [],
				filterPayments: [],
				label: 'Processing request',
				show: false
			}
		},

		mounted: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				PaymentService.findAll().then(response => {
					this.payments = response.data.body;
					console.log('payments: ', this.payments);
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>