import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";
import authHeader from "@/services/authHeader";
const authToken = JSON.parse(localStorage.getItem("user"));

class CarrierOrderService {
  save(company) {
  	return axios.post(GENERIC_SERVICE_URL + "carrierOrders" + "/", {
      uuid: company.uuid,
      associatedUserId: authToken === null ? company.associatedUserId: authToken.userId,
      companyId: company.companyId,
      companyType: company.companyType,
      name: company.name,
      shortName: company.shortName,
      businessPartnerNumber: company.businessPartnerNumber,
      phoneNumbers: company.phoneNumbers,
      email: company.email,
      faxNumber: company.faxNumber,
      websiteUrl: company.websiteUrl,
      businessSectorId: company.businessSectorId,
      businessCategories: company.businessCategories,
      organizationType: company.organizationType,
      establishedYear: company.establishedYear,
      numberOfEmployees: company.numberOfEmployees,
      physicalAddress: company.physicalAddress,
      postalAddress: company.postalAddress,
      contactPeople: company.contactPeople,
      socialMediaAccounts: company.socialMediaAccounts,
      branches: company.branches,
      directors: company.directors,
      shareholders: company.shareholders,
      attachments: company.attachments,
      otherCreditFacilities: company.otherCreditFacilities,
      compliances: company.compliances,
      carrierType: company.carrierType,
      associatedUserUsername: company.associatedUserUsername
    });
  }

  saveCompliance(company) {
  	return axios.post(GENERIC_SERVICE_URL + "carrierOrders" + "/saveCompliance", {
  		companyId: company.companyId,
  		compliances: company.compliances
  	});
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "carrierOrders");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "carrierOrders/" + id);
  }

  findByAssociatedUserId(userId) {
    return axios.get(GENERIC_SERVICE_URL + "carrierOrders/findByAssociatedUserId/" + userId);
  }
  
  updateCompanyById(company, id) {
    return axios.put(GENERIC_SERVICE_URL + "carrierOrders/" + id,{
        companyId: company.companyId,
        companyTypeId: company.companyTypeId,
        companyName: company.companyName,
        companyDescription: company.companyDescription,
        possibleWorkDays: company.possibleWorkDays,
        companyStatus: company.companyStatus
      },
      { headers: authHeader() }
    );
  }

  saveAttachment(formData){
    return axios.post(GENERIC_SERVICE_URL + "carrierOrders/saveAttachment", formData);
  }

  findByUuid(uuid) {
    return axios.get(GENERIC_SERVICE_URL + "carrierOrders/findByUuid/" + uuid);
  }

  findByAssociatedUserUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "carrierOrders/findByAssociatedUserUsername/" + username);
  }
}
export default new CarrierOrderService;
