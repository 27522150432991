<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div v-if="seller === null">
					<div class="row">
						<div class="col-md-10">
							<h4>Seller profile not available</h4>
						</div>
						<div class="col-md-2">
							<router-link :to="{ name: 'sellers/new' }">
								<button class="btn btn-success pull-right">New Store Front Profile <span class="glyphicon glyphicon-plus"></span></button>
							</router-link>
						</div>
					</div>
				</div>

				<div v-else>
					<h4>Your Seller or StoreFront Profile Details</h4>
					<br>
					<ul class="list-group">
						<li class="list-group-item">ID: {{ seller.id }}</li>
						<li class="list-group-item">Name: {{ seller.name }}</li>
						<li class="list-group-item">Seller Type: {{ seller.sellerType }}</li>
						<li class="list-group-item">Seller Code: {{ seller.sellerCode }}</li>
						<li class="list-group-item">Company Name: 
							<span v-if="seller.company !== null">
								<router-link :to="{ name: 'company-profile'}">{{ seller.company.name }}</router-link>
							</span>
						</li>
						<li class="list-group-item">Contact Person: {{ seller.associatedAccount.firstName }} {{ seller.associatedAccount.lastName }}</li>
						
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import SellerService from "@/services/SellerService.js";

	export default{
		name: "CompanyProfile",
		data: function(){
			return {
				seller: null
			}
		},

		created(){
			this.findById();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user.user;
			},

			checkIfCompanyProfileAvailable: function(){
				return this.seller !== null;
			}
		},

		methods: {
			findById: function(){
				SellerService.findByAssociatedAccountUsername(this.getUser.username).then(response => {
					this.seller = response.data.body;
					console.log(this.seller);
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>