
import CompanyService from "@/services/CompanyService";
import { SET_COMPANIES } from "@/store/mutations.type";
import { FETCH_COMPANIES } from "@/store/actions.type";

const state = {
  companies: []
};

const mutations = {
  [SET_COMPANIES](state, companies) {
    state.companies = companies;
  }
};

const actions = {
  [FETCH_COMPANIES]({ commit }, companies) { // companies is not necessary
    CompanyService.getAllCompanies()
      .then(response => {
        commit(SET_COMPANIES, response.data.data);
        return Promise.resolve(companies);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
};

const getters = {};

export const companies = {
  state,
  mutations,
  actions,
  getters
};
