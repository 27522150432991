<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>{{ editing ? "Edit": "New" }} Preference Category</h4>
          </div>
          <router-link :to="{ name: 'preference-category-list' }">
            <div class="col-md-2">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
        <br>

         <p v-if="message !== null" class="error-message text-center">{{ message }}</p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label> Name</label>
            <select v-model="preference.name" class="form-control" required>
              <option value="">Select option</option>
              <option value="SALES_LEADS">Sales Leads</option>
              <option value="TENDERS">Tenders</option>
              <option value="FULL_TIME_JOBS">Full Time Jobs</option>
              <option value="VOLUNTEERING_JOBS">Volunteering Jobs</option>
              <option value="SKILLED_EXPERTS">Skilled Experts</option>
              <option value="GRANTS_AND_FUNDING_OPPORTUNITIES">Grants and Funding Opportunities</option>
              <option value="COMPLIANCE_MANAGEMENT">Compliance Management</option>
            </select>
          </div>
          
          <div class="form-group">
            <label>Description</label>
            <input type="text" v-model="preference.description" placeholder="Enter preference description" class="form-control"/>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select v-model="preference.status" class="form-control">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <label>&nbsp;</label>
              <button class="btn btn-success btn-block">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import Preference from "@/models/Preference";
  import PreferenceService from "@/services/PreferenceService";
  import loading from "vue-full-loading";

  export default {
    name: "NewRole",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        preference: new Preference,
        errors: [],
        message: null,
        editing: false
      }
    },

    methods: {
      save: function() {
        this.show = true;
        PreferenceService.save(this.preference).then(response => {
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "preference-category-list" });
          }
        }).catch(error => {
          this.show = false;
          this.message = error.response.data.message;
        });
      }
    }
  };
</script>
