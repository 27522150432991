<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Add other experiences</h4>
      	<p>Add any other experiences that help you stand out</p>
      	<form class="form" @submit.prevent="save">
      		<button class="btn btn-success btn-sm pull-right" @click="addOtherExperienceField" type="button">Add <span class="glyphicon glyphicon-plus"></span></button>

			<div v-for="(otherExperienceField, index) in otherExperienceFields" :key="otherExperienceField.id">
				<h4>Other experience Number {{ index + 1 }}</h4>

				<div class="form-group">
					<label>Subject</label>
					<input type="text" name="Company" id="Company" placeholder="Ex: SkilledBioz" class="form-control" v-model="otherExperienceField.subject">
				</div>

	      		<div class="form-group">
	      			<label>Description</label>
	      			<textarea class="form-control" rows="5" placeholder="Description (Optional)"></textarea>
	      		</div>

	      		<div class="form-group" v-if="otherExperienceFields.length > 0">
					<label>&nbsp;</label>
					<button class="btn btn-danger btn-sm pull-right" type="button" @click="otherExperienceFields.splice(index, 1)">Remove</button>
				</div>
			</div><br><br>
			<hr>

			<div class="form-group" v-if="otherExperienceFields.length > 0">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>

			<div class="form-group" v-else>
				<label>&nbsp;</label>
				<router-link :to="{ name: 'newEducation'}">
					<button class="btn btn-success pull-right" type="submit">Next</button>
				</router-link>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewEmploymentHistory",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing request...",
				show: false,
				otherExperienceFields: []
			}
		},

		created: function(){},

		methods: {
			addOtherExperienceField: function(){
				this.otherExperienceFields.push({
					firstName: null,
					lastName: null,
					clientLinkedInProfile: null,
					clientTitle: null,
					projectType: null,
					messageToClient: null,
				});
			},

			save: function(){
				this.show = true;
				this.professional.otherExperiences = this.otherExperienceFields;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "professionalProfilePreview" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>