import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class ComplianceLineService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "complianceLines");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "complianceLines/" + id);
  }

  saveCompliance(compliance) {
    return axios.post(GENERIC_SERVICE_URL + "complianceLines" + "/saveCompliance", {
        companyId: compliance.companyId,
        complianceId: compliance.compliances,
        inceptionDate: compliance.inceptionDate,
        dueDate: compliance.dueDate,
        remainingDays: compliance.remainingDays,
        overdue: compliance.overdue,
        evidences: compliance.evidences
      }
    );
  }
}
export default new ComplianceLineService;
