export default class User {
  constructor(personId, personType, username, email, password, confirmPassword, accountNonExpired, accountNonLocked, credentialsNonExpired,
    enabled, roles, firstName, middleName, lastName, nickname, dateOfBirth, phoneNumbers, homePhoneNumber, workPhoneNumber, faxNumber,
    skypeAddress, gender, salutation, maritalStatus, nationalIdNumber, passportNumber, driversLicenceNumber, nationality,
    placeOfBirth, countryOfBirth, homeAddress, postalAddress, ethnicGroup, religion, digitalSignature, photo, occupation,
    overview, title, videoIntroduction, availability, languages, educations, serviceCharge, workHistories, portfolioProjects,
    skills, testimonials, certifications, otherExperiences, jobPreferences, preferences) {
    this.personType = personType;
    this.personId = personId;
    this.username = username;
    this.email = email;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.accountNonExpired = accountNonExpired;
    this.accountNonLocked = accountNonLocked;
    this.credentialsNonExpired = credentialsNonExpired;
    this.enabled = enabled;
    this.roles = roles;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.nickname = nickname;
    this.dateOfBirth = dateOfBirth;
    this.phoneNumbers = phoneNumbers;
    this.homePhoneNumber = homePhoneNumber;
    this.workPhoneNumber = workPhoneNumber;
    this.faxNumber = faxNumber;
    this.skypeAddress = skypeAddress;
    this.gender = gender;
    this.salutation = salutation;
    this.maritalStatus = maritalStatus;
    this.nationalIdNumber = nationalIdNumber;
    this.passportNumber = passportNumber;
    this.driversLicenceNumber = driversLicenceNumber;
    this.nationality = nationality;
    this.placeOfBirth = placeOfBirth;
    this.countryOfBirth = countryOfBirth;
    this.homeAddress = homeAddress;
    this.postalAddress = postalAddress;
    this.ethnicGroup = ethnicGroup;
    this.religion = religion;
    this.digitalSignature = digitalSignature;
    this.photo = photo;
    this.occupation = occupation;

    this.overview = overview;
    this.title = title;
    this.videoIntroduction = videoIntroduction;
    this.availability = availability;
    this.languages = languages;
    this.educations = educations;
    this.serviceCharge = serviceCharge;
    this.workHistories = workHistories;
    this.portfolioProjects = portfolioProjects;
    this.skills = skills;
    this.testimonials = testimonials;
    this.certifications = certifications;
    this.otherExperiences = otherExperiences;
    this.jobPreferences = jobPreferences;
    this.preferences = preferences;
  }
}
