<!-- @OneToOne
    private User customer; // is this necessary since is in delivery address??
    private String orderNumber;
    @OneToOne
    private Company awardedCarrier;
    @OneToOne
    private Vehicle assignedVehicle;
    @OneToOne
    private Company fulfilledBy;
    @OneToOne
    private ProductDeliveryAddress deliveryAddress;
    @OneToMany
    private List<Location> collectionAddresses;
    private LocalDateTime orderDate;
    @OneToMany
    private List<OrderLine> orderLines; // cart products
    @ManyToMany
    private List<Product> cartProducts;
    @OneToOne
    private Invoice invoice;

    // how do we manage order bookings including those that reject? We reassign another carrier offer?
    private boolean accepted;

    // notify the product buyer when product has been collected

    // delivery details
    private boolean released;
    private LocalDate releasedDate;
    private LocalTime releasedTime;

    private boolean collected;
    private LocalDateTime collectionAt;

    private boolean delivered;
    private LocalDateTime deliveredAt;

    private boolean confirmedDelivery;
    private LocalDateTime confirmedDeliveryAt;

    private String deliveryComments;

    private BigDecimal deliveryCharge;
    @Enumerated(EnumType.STRING)
    private CurrencyEnum currency; -->
<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>Order details</h4>
					</div>
					<router-link :to="{ name: this.getSource }">
						<div class="col-md-1">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>
				<br><br>
				<div v-if="order !== null">
					<ul class="list-group">
						<li class="list-group-item">ID: {{ order.id }}</li>
						<li class="list-group-item">Order Number: {{ order.orderNumber }}</li>
						<li class="list-group-item">OrderNumber: {{ order.orderNumber }}</li>
						<li class="list-group-item">Collection Addresses: <br><br>
							<ul class="list-group" v-for="(collectionAddress, index) in order.collectionAddresses" :key="collectionAddress.id">
								<li class="list-group-item">
									Drop off address {{ index + 1 }}: {{ collectionAddress.city.name + ' ' + collectionAddress.physicalAddress }}
								</li>
							</ul>
						</li>
						<li class="list-group-item">
							Delivery Address: {{ order.deliveryAddress !== null ? order.deliveryAddress.location.city.name + ' ' + order.deliveryAddress.location.physicalAddress: 'N/A' }}
						</li>
					</ul>
				</div>
			</div>
		</div>			
	</div>
</template>

<script type="text/javascript">
	import OrderService from "@/services/OrderService";

	export default{
		name: "ViewOrder",
		data: function(){
			return {
				order: null,
			}
		},

		mounted: function(){
			this.findByOrderNumber();
		},

		methods: {
			findByOrderNumber: function(){
				this.show = true;
				OrderService.findByOrderNumber(this.getOrderNumber).then(response => {
					this.show = false;
					this.order = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		},

		computed: {
			getOrderNumber(){
				return this.$route.params.orderNumber;
			},

			getSource(){
				return this.$route.query.source;
			}
		}
	};
</script>