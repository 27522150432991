<template>
	<div>
		<div class="row">
			<div class="col-md-10"></div>
			<div class="col-md-2">
				<router-link :to="{ name: 'job-category-list' }">
					<button class="btn btn-default pull-right">Close</button>
				</router-link>
			</div>
		</div><br>

		<div class="list-group" v-if="jobCategory !== null">
			<li class="list-group-item">ID: {{ jobCategory.id }}</li>
			<li class="list-group-item">Name: {{ jobCategory.name }}</li>
			<li class="list-group-item">Description: {{ jobCategory.description }}</li>
			<li class="list-group-item">Status: {{ jobCategory.status }}</li>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobCategoryService from "@/services/JobCategoryService";

	export default{
		name: 'ViewJobCategory',
		data: function(){
			return {
				jobCategory: null
			}
		},

		created(){
			this.findById();
		},

		methods: {
			findById: function(){
				JobCategoryService.findById(this.getJobCategoryId).then(response => {
					this.jobCategory = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {
			getJobCategoryId: function(){
				return this.$route.params.jobCategoryId;
			}
		}
	}
</script>