<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <h4>Compliance Category Details</h4>
    <br/>
    <div class="list-group">
      <div class="list-group-item">
        <p>{{ complianceCategory.name }}</p>
        <p>{{ complianceCategory.description }}</p>
        <p>
          <span class="label label-success" v-if="complianceCategory.status == 'ACTIVE'"
            >Active</span
          >
          <span class="label label-default" v-if="complianceCategory.status == 'INACTIVE'"
            >Inactive</span
          >
          <span class="label label-danger" v-if="complianceCategory.status == 'DELETED'"
            >Deleted</span
          >
        </p>
        <p>
          <router-link :to="{ name: 'complianceCategories' }" replace
            ><button class="btn btn-default" data-dismiss="modal">
              Back
            </button></router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import ComplianceCategory from "@/models/ComplianceCategory";
  import ProductCategoryService from "@/services/ProductCategoryService";
  import loading from "vue-full-loading";

  export default {
    name: "ViewComplianceCategory",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing...",
        complianceCategory: new ComplianceCategory()
      };
    },
    computed: {
      getRoleId() {
        return this.$route.params.roleId;
      }
    },
    created() {
      this.findById(this.getRoleId);
    },
    methods: {
      findById: function(id) {
        this.show = true;
        ProductCategoryService.findById(id).then(response => {
            this.show = false;
            this.complianceCategory = response.data.body;
          }).catch(error => {
            this.show = false;
            console.log("error: ", error);
            this.$router.push({ name: "complianceCategories" });
          });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
