<template>
	<div>
		<loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>
    
		<div class="panel" v-if="truck !== null">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-4">
            <h4>Truck details</h4>
          </div>
          <div class="col-md-2">
            <span v-if="truck.trailer !== null">
              <router-link :to="{ name: 'view-trailer', params: { trailerNumber: truck.trailer.trailerNumber }, query: { source: 'VIEW_TRUCK', truckNumber: truck.truckNumber }}">
                <button class="btn btn-primary col-4">View trailer details</button>
              </router-link>
            </span>
          </div>
          <div class="col-md-2">
            <span v-if="truck.driver !== null">
              <router-link :to="{ name: 'view-driver', params: { id: truck.driver.id }, query: { source: 'VIEW_DRIVER', truckNumber: truck.truckNumber }}">
                <button class="btn btn-primary col-4">View allocated driver</button>
              </router-link>
            </span>
          </div>
          <div class="col-md-2">
            <span v-if="truck.type === 'STANDARD'">
              <router-link :to="{ name: 'trailer-list', query: { truckNumber: truck.truckNumber }}">
                <button class="btn btn-primary" v-if="truck.trailer === null">Allocate trailer</button>
              </router-link>
            </span>
          </div>
          <div class="col-md-2">
            <router-link :to="{ name: 'truck-list'}">
              <button class="btn btn-primary btn-block">Back</button>
            </router-link>
          </div>
        </div>
        <hr>

        <h4>Truck photos</h4>
        <div class="row">
          <div class="col-md-2" v-for="photo in truck.photos" :key="photo.id">
            <span v-if="ENV === 'development' || ENV === 'test'">
              <img :src="GENERIC_SERVICE_URL + 'photos/getTruckPhotos/' + photo.id" class="img img-responsive img-rounded img-view">
            </span>
            <span v-if="ENV === 'production'">
              <img :src="AWS_S3_BUCKET + '/' + photo.fileName" class="img img-responsive img-rounded img-view">
            </span>
          </div>
        </div>
        <br>

        <ul class="list-group">
          <li class="list-group-item">Registration number: {{ truck.registrationNumber }}</li>
          <li class="list-group-item">Truck number: {{ truck.truckNumber }}</li>
          <li class="list-group-item">Truck type: {{ truck.type }}</li>
          <li class="list-group-item">Make: {{ truck.make }}</li>
          <li class="list-group-item">Model: {{ truck.model }}</li>
          <li class="list-group-item">Year: {{ truck.year }}</li>
          <li class="list-group-item">VIN: {{ truck.vehicleIdentificationNumber }}</li>
          <li class="list-group-item">Ownership: {{ truck.ownershipType }}</li>
          <li class="list-group-item">In service date: {{ truck.inServiceDate }}</li>
          <li class="list-group-item" v-if="truck.type !== 'STANDARD'">Trailer Type: {{ truck.trailerType }}</li>
          <li class="list-group-item">Load mode: {{ truck.loadMode }}</li>
          <li class="list-group-item">Recurring type: {{ truck.recurringType }}</li>
          <li class="list-group-item"><span class="label label-primary">Regular Pickup Address</span> <br><br>
            <ul class="list-group" v-if="truck.regularPickUpAddress !== null">
              <li class="list-group-item">City: {{ truck.regularPickUpAddress.city !== null ? truck.regularPickUpAddress.city.name: '' }}</li>
              <li class="list-group-item">State/Province: {{ truck.regularPickUpAddress.province !== null ? truck.regularPickUpAddress.province.name: '' }}</li>
            </ul>
          </li>
          <li class="list-group-item"><span class="label label-primary">Regular Dropoff Address</span> <br><br>
            <ul class="list-group" v-if="truck.regularDropOffAddress !== null">
              <li class="list-group-item">City: {{ truck.regularDropOffAddress.city !== null ? truck.regularDropOffAddress.city.name: '' }}</li>
              <li class="list-group-item">State/Province: {{ truck.regularDropOffAddress.province !== null ? truck.regularDropOffAddress.province.name: '' }}</li>
            </ul>
          </li>
          <li class="list-group-item">Length: {{ truck.length }} {{ truck.lengthMeasurement }}</li>
          <li class="list-group-item">Max Load Size: {{ truck.maxLoadSize }}{{ truck.maxLoadSizeMeasurement }}</li>
          <li class="list-group-item">Gross Vehicle Mass(GVM): {{ truck.grossVehicleMass }}{{ truck.grossVehicleMassMeasurement }}</li>
          <li class="list-group-item">Net Vehicle Mass (NVM): {{ truck.netVehicleMass }}{{ truck.netVehicleMassMeasurement }}</li>
          <li class="list-group-item">Max Height Clearance: {{ truck.maxHeightClearance }}{{ truck.maxHeightClearanceMeasurement }}</li>
          <li class="list-group-item">Desired Rate: {{ truck.desiredRateCurrency }}{{ truck.desiredRate }} per {{ truck.desiredRateMeasurement }}</li>
          <li class="list-group-item">Notes: {{ truck.description }}</li>
        </ul>
      </div>
    </div>
	</div>
</template>

<script type="text/javascript">
  import TruckService from "@/services/TruckService";
  import loading from "vue-full-loading";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

  export default {
    name: "ViewTruck",
    components: {
      loading,
    },

    data: function() {
      return {
        trucks: [],
        show: false,
        label: "Processing request",
        truck: null,
        GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
        AWS_S3_BUCKET: process.env.VUE_APP_AWS_TRUCK_S3_BUCKET,
        ENV: process.env.NODE_ENV
      };
    },

    created(){
      this.findByTruckNumber();
    },

    computed: {
      getTruckNumber(){
        return this.$route.params.truckNumber;
      }
    },

    methods: {
      findByTruckNumber() {
        this.show = true;
        TruckService.findByTruckNumber(this.getTruckNumber).then(response => {
          this.truck = response.data.body;
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      },

      refresh: function(){
        this.findByTruckNumber();
      }
    }
  };
</script>

<style type="text/css">
  .img-view {
    width: 100%;
    height: 200px;
    object-fit: cover;
}
</style>