<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<div class="row">
	    			<div class="col-md-10">
	    				<h4>New subscription plan</h4>
	    			</div>
	    			<router-link :to="{ name: 'subscription-plans'}">
	    				<div class="col-md-2">
	    					<button class="btn btn-primary btn-block">Back</button>
	    				</div>
	    			</router-link>
	    		</div>
	    		<br>

	    		<p v-if="message !== null" class="error-message text-center">{{ message }}</p>
				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>Name</label>
						<select class="form-control" v-model="subscriptionPlan.name" required>
							<option value="" disabled>Select option</option>
							<option value="COMPLIANCE_MANAGEMENT">Premium Compliance Management</option>
							<option value="TENDER">Tender Pro</option>
							<option value="CONTRACT_JOB">Contract Job Leads</option>
							<option value="GRANT_AND_FUNDING">NGO Funding and Grants Leads</option>
							<option value="STOREFRONT">Storefront Seller Slots</option>
							<option value="DELIVERY_SERVICE_REGISTRATION_OWN_OPERATOR">Transporters for Delivery Service (Own operator) - Reg fee (once off)</option>
							<option value="DELIVERY_SERVICE_REGISTRATION_FLEET_OWNER">Transporters for Delivery Service (Fleet Operator) - Reg fee (once off)</option>
							<option value="FEATURED_PROFILE_PROMOTION">Featured Profile Promotion</option>
							<option value="FEATURED_PRODUCT_PROMOTION">Featured Product Promotion</option>
							<option value="FEATURED_ADVERTISEMENT_PROMOTION">Featured Advertisements Promotion</option>
							<option value="PRODUCT_UPLOAD">Product Upload</option>
						</select>
					</div>
					<div class="form-group">
						<label>Price</label>
						<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="subscriptionPlan.price" placeholder="Enter price"/>
					</div>

					<div class="form-group">
						<label>Currency</label>
						<select class="form-control" name="currency" v-model="subscriptionPlan.currency">
							<option value="">Select currency</option>
							<option value="ZWL">ZWL</option>
							<option value="USD">USD</option>
						</select>
					</div>

					<div class="form-group">
						<label>Validity Period (Days)</label>
						<input type="number" name="validityPeriodInDays" class="form-control" v-model="subscriptionPlan.validityPeriodInDays" placeholder="Enter number of days">
					</div>

					<div class="form-group">
						<label>Subscription Type</label>
						<select class="form-control" v-model="subscriptionPlan.subscriptionType" required>
							<option value="" disabled>Select option</option>
							<option value="BASIC">Basic</option>
							<option value="ADVANCED">Advanced</option>
							<option value="PREMIUM">Premium</option>
						</select>
					</div>

					<div class="form-group">
						<label>Description</label>
						<input type="text" name="description" class="form-control" placeholder="Enter description"/>
					</div>

					<div class="form-group">
						<label>Status</label>
						<select class="form-control" v-model="subscriptionPlan.status">
							<option value="" disabled>Select option</option>
							<option value="ACTIVE">Active</option>
							<option value="INACTIVE">In active</option>
						</select>
					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-primary btn-block">Submit</button>
							</div>
						</div>
					</div>
				</form>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import SubscriptionPlanService from "@/services/SubscriptionPlanService";
	import SubscriptionPlan from "@/models/SubscriptionPlan";

	export default{
		name: 'NewSubscriptionPlanFeature',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				subscriptionPlan: new SubscriptionPlan,
				message: null
			}
		},

		created(){
			
		},

		methods: {
			save: function(){
				this.show = true;
				SubscriptionPlanService.save(this.subscriptionPlan).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'subscription-plans' });
					}
					this.show = false;
				}).catch(error => {
					this.show = false;
					this.message = error.response.data.message;
				});
			}
		}
	};
</script>