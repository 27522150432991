<template>
  <div>
   <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
      	<h4>Company Details</h4>
      	<div class="row">
	        <div class="col-md-4">
	        	<img src="@/assets/images/grants.png" height="100" width="150">
	        </div>
	        <div class="col-md-6">
	        	<p>{{ company.name }}</p>
	        	<p><b>Member since: {{ company.createdAt }}</b></p>
	            <p>
	            	<span class="badge">{{ company.followers.length }}</span> Followers
	              <router-link :to="{ name: 'view-company-search-detail', params: { companyId: company.id }}">
	                <button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
	              </router-link>&nbsp;
	              <router-link :to="{ name: 'follow-company', params: { companyId: company.id }}">
	                <button class="btn btn-danger">Follow</button>
	              </router-link>&nbsp;
	            </p>
	        </div>
	        <router-link :to="{ name: 'company-search' }">
	        	<div class="col-md-2">
	        		<button class="btn btn-primary btn-block">Back</button>
	        	</div>
	        </router-link>
	    </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService";
  import loading from "vue-full-loading";

  export default {
    name: "ViewCompanySearchDetail",
    components: {
      loading
    },

    data: function() {
      return {
        company: null,
        show: false,
        label: "Processing request",
      };
    },

    computed: {
    	getCompanyId() {
    		return this.$route.params.companyId;
      	},
    },

    mounted() {
      this.findById();
    },

    methods: {
      findById: function() {
        this.show = true;
        CompanyService.findById(this.getCompanyId).then(response => {
        	this.company = response.data.body;
          	this.show = false;
        }).catch(error => {
        	console.log(error);
          	this.show = false;
        });
      }
    }
  };
</script>