<template>
	<div>
		<loading :show="show" :label="label">
		    <div class="processing-row" slot="loading-container">
		      <div class="text-center">
		        <div slot="loading-spinner">
		          <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
		        </div>
		        <p class="text-center" slot="loading-text">{{ label }}</p>
		      </div>
		    </div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<router-link :to="{ name: 'tenders'}">
					<button class="btn btn-primary">Back</button>
				</router-link><br><br>

				<ul class="list-group" v-if="tender !== null">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">Country: {{ tender.country !== null ? tender.country.name: "Not available" }}</li>
					<li class="list-group-item">Title: {{ tender.title }}</li>
					<li class="list-group-item">Tender Number: {{ tender.tenderNumber }}</li>
					<li class="list-group-item">Company: {{ tender.company === null ? "Not available": tender.company }}</li>
					<li class="list-group-item">Reach: {{ tender.reach }}</li>
					<li class="list-group-item">Type: {{ tender.type }}</li>
					<li class="list-group-item">Section: {{ tender.section }}</li>
					<li class="list-group-item">Tender Document: {{ tender.tenderDocument }}</li>
					<li class="list-group-item">Closing Date: {{ tender.closingDate }}</li>
					<li class="list-group-item">Estimated Cost: {{ tender.estimatedCostCurrency }}{{ tender.estimatedCost }}</li>
					<li class="list-group-item">Tender Fees: 
						<div class="list-group" v-for="tenderFee in tender.tenderFees" :key="tenderFee.id">
							<li class="list-group-item">Fee Type: {{ tenderFee.feeType }}</li>
							<li class="list-group-item">Amount: {{ tenderFee.currency }}{{ tenderFee.amount }}</li>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TenderService from "@/services/TenderService";
	import loading from "vue-full-loading";

	export default{
		name: "TenderList",
		components: {
			loading
		},

		data: function(){
			return {
				tender: null,
				show: false,
				label: "Processing request..."
			}
		},

		mounted: function(){
			this.findById();
		},

		computed: {
			getTenderId: function(){
				return this.$route.params.tenderId;
			}
		},

		methods: {
			findById: function(){
				this.show = true;
				TenderService.findById(this.getTenderId).then(response => {
					this.tender = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
	.error-message {
	  color: red;
	}
	.processing-row {
	  display: flex;
	  justify-content: center;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
</style>