import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class JobTitleService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "jobTitles");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "jobTitles/" + id);
  }

  save(jobTitle) {
    return axios.post(GENERIC_SERVICE_URL + "jobTitles", {
      jobTitleId: jobTitle.jobTitleId,
      name: jobTitle.name,
      description: jobTitle.description,
      status: jobTitle.status
    });
  }
}

export default new JobTitleService;
