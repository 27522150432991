<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <router-link :to="{ name: 'areas/new' }">
          <button class="btn btn-success pull-right">
            New <span class="fa fa-plus"></span>
          </button> </router-link
        ><br /><br />

        <h4>Area List</h4>

        <table class="table table-bordered table-responsive-md">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Status</th>
            <td>Actions</td>
          </thead>
          <tbody>
            <tr v-for="area in areas" :key="area.id">
              <td>{{ area.id }}</td>
              <td>{{ area.name }}</td>
              <td>
                <span v-if="area.status === 'ACTIVE'">
                  <span class="badge badge-success">Active</span>
                </span>
                <span v-if="area.status === 'INACTIVE'">
                  <span class="badge badge-secondary">Inactive</span>
                </span>
              </td>
              <td>
                <router-link
                  :to="{ name: 'areas/view', params: { areaId: area.id } }"
                >
                  <button class="btn btn-primary">
                    View <span class="fa fa-info"></span>
                  </button> </router-link
                >&nbsp;
                <router-link
                  :to="{ name: 'areas/edit', params: { areaId: area.id } }"
                >
                  <button class="btn btn-warning">
                    Edit <span class="fa fa-pencil"></span>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import AreaService from "@/services/AreaService.js";

export default {
  name: "ProvinceList",
  data: function() {
    return {
      areas: []
    };
  },

  created: function() {
    this.findAll();
  },

  methods: {
    findAll: function() {
      AreaService.findAll()
        .then(response => {
          this.areas = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
