export default class RequestForQuotation{
	constructor(jobId, jobTitleId, type, hourlyRate, currency, expiryDate, attachment, availability, estimatedBudget,
		expertiseLevel, paymentTerms, reach, projectType, projectLengthInMonths, commitmentHoursPerWeek, userId,
        evaluationMethod, termsAndConditions, submissionRequirementsAndInstructions, areaRestriction){
		this.jobId = jobId;
		this.jobTitleId = jobTitleId;
		this.type = type;
		this.hourlyRate = hourlyRate;
		this.currency = currency;
		this.expiryDate = expiryDate;
		this.attachment = attachment;
		this.availability = availability;
		this.expertiseLevel = expertiseLevel;
		this.paymentTerms = paymentTerms;
		this.reach = reach;
		this.projectType = projectType;
		this.projectLengthInMonths = projectLengthInMonths;
		this.commitmentHoursPerWeek = commitmentHoursPerWeek;
        this.userId = userId;
        this.evaluationMethod = evaluationMethod;
        this.termsAndConditions = termsAndConditions;
        this.submissionRequirementsAndInstructions = submissionRequirementsAndInstructions;
        this.areaRestriction = areaRestriction;
	}
}