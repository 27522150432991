<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <div class="panel-header">
          <h4><b>Skilled Bios Dashboard</b></h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div :class="sideBarMenuToggle ? dashboardCollapsedLeftPanelWidth: dashboardLeftPanelWidth">
        <div class="panel">
          <div class="panel-body">
            &nbsp;&nbsp;&nbsp;<img :src="!sideBarMenuToggle ? require('@/assets/hamburgerMenu.png'): require('@/assets/hamburgerMenuClose.png')" height="40" width="40" @click="toogleSideBarMenu"/>
            <br>
            <hr/>
            <h4>
              <NavigationMenu
                :sideBarMenuToggle="sideBarMenuToggle"
                :dashboardLinkNameHidden="isDashboardLinkNameHidden"
                :roles="getUserRoles"/>
                <br/>
            </h4>
          </div>
        </div>
      </div>

      <div :class="sideBarMenuToggle ? dashboardCollapsedRightPanelWidth: dashboardRightPanelWidth">
        <!-- <div class="panel"> -->
          <!-- <div class="panel-body"> -->
            <router-view></router-view>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import NavigationMenu from "@/components/dashboardNavigations/NavigationMenu.vue";

export default {
  name: "DashboardNavigation",
  components: {
    NavigationMenu
  },

  data: function() {
    return {
      userRoles: [],
      sideBarMenuToggle: false,
      sideBarTextCollapseText: "<< Collapse sidebar",
      dashboardLeftPanelWidth: "col-md-2",
      dashboardRightPanelWidth: "col-md-10",
      dashboardCollapsedLeftPanelWidth: "col-md-1",
      dashboardCollapsedRightPanelWidth: "col-md-11"
    };
  },

  created() {
    this.sideBarMenuToggle = JSON.parse(
      localStorage.getItem("sideBarMenuToggle" || false)
    );
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    getUserRoles: function() {
      let user = this.$store.state.auth.user;
      let roles = [];
      for (var i = 0; i < user.user.authorities.length; i++) {
        roles.push(user.user.authorities[i].authority);
      }
      return roles;
    },

    isDashboardLinkNameHidden: function() {
      return this.sideBarMenuToggle;
    },

    getSideBarMenuToggleStatus: function() {
      return JSON.parse(localStorage.getItem("sideBarMenuToggle" || false));
    }
  },

  methods: {
    toogleSideBarMenu: function() {
      if (this.sideBarMenuToggle) {
        this.sideBarMenuToggle = false;
        this.setSideBarMenuToggleStatus(false);
      } else {
        this.sideBarMenuToggle = true;
        this.setSideBarMenuToggleStatus(true);
      }
    },

    setSideBarMenuToggleStatus: function(status) {
      localStorage.setItem("sideBarMenuToggle", JSON.stringify(status));
    }
  },

  mounted() {}
};
</script>

<style type="text/css" scoped>
.nav .router-link-exact-active {
  /*or use the router-link-active when the target route is matched*/
  color: #42b983;
}
</style>
