export default class Tender{
	constructor(tenderId, countryId, title, companyId, reach, type, section, tenderDocument, closingDate,
		status, estimatedCost, estimatedCostCurrency, tenderFees, professionalCountries, overview, subject, 
		experienceLevel, degrees, certifications, skills, tenderCategoryId){
		this.tenderId = tenderId;
		this.countryId = countryId;
		this.title = title;
		this.companyId = companyId;
		this.reach = reach;
		this.type = type;
		this.section = section;
		this.tenderDocument = tenderDocument;
		this.closingDate = closingDate;
		this.status = status;
		this.estimatedCost = estimatedCost;
		this.estimatedCostCurrency = estimatedCostCurrency;
		this.tenderFees = tenderFees;
		this.professionalCountries = professionalCountries;
		this.overview = overview;
		this.subject = subject;
		this.experienceLevel = experienceLevel;
		this.degrees = degrees;
		this.certifications = certifications;
		this.skills = skills;
		this.tenderCategoryId = tenderCategoryId;
	}
}