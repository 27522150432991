<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Add Certification</h4><br>
      	<p>Manually add</p> or<br>
      	<p>Import from Credly</p>
      	<form class="form" @submit.prevent="save">
      		<div class="form-group" v-for="certification in certifications" :key="certification.id">
				<label>&nbsp;</label>
				<input type="checkbox" name="selectedCertifications" id="selectedCertifications" v-model="selectedCertifications" :value="certification.id"> {{ certification.name }}
			</div>

      		<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success" type="submit">Save</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import CertificationService from "@/services/CertificationService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewCertifications",
		components: {
			loading
		},

		data: function(){
			return {
				selectedCertifications: [],
				professional: new Professional,
				label: "Processing request...",
				show: false,
				certifications: []
			}
		},

		created: function(){
			this.findAllCertifications();
		},

		methods: {
			findAllCertifications: function(){
				CertificationService.findAll().then(response => {
					this.certifications = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},
			
			save: function(){
				this.show = true;
				let certifications = [];
				for(var i = 0; i < this.selectedCertifications.length; i++){
					certifications.push({
						certificationId: this.selectedCertifications[i]
					});
				}
				this.professional.certifications = certifications;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newOtherExperiences" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>