<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="panel">
			<div class="panel-body">
				<p>Professional Profile Overview</p>
				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>Your title</label>
						<textarea id="title" name="title" v-model="professional.title" class="form-control" rows="5" placeholder="Enter a single sentence description of your professional skills/experience (e.g. Expert Web Designer with Ajax experience)"></textarea>
					</div>
					<div class="form-group">
						<label>Overview</label>
						<p>Use this space to show clients you have the skills and experience they're looking for.</p>
						<ul>
							<li>Describe your strengths and skills</li>
							<li>Highlight projects, accomplishments and education</li>
							<li>Keep it short and make sure it's error-free</li>
						</ul>
						<textarea v-model="professional.overview" id="overview" name="overview" class="form-control" rows="5" placeholder="Java Certified Professional with experience in Spring and Spring Boot including the Spring Mvc projects, proficient in Oracle Databases. Full stack Agile Software Developer with a serious development passion for Java, Android development (Web, Mobile & Enterprise software). Seriously obsessed with Spring framework for any serious project of any size or complexity. Open minded and business oriented. Open for business and closed for mediocrity."></textarea>
					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewProfessional",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing...",
				show: false,
				countries: [],
				cities: [],
				cityId: null,
				countryId: null
			}
		},

		methods: {
			save: function(){
				this.show = true;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					console.log(response);
					if(response.data.statusCode === 201){
						this.$router.push({ name: "new-professional-location-restriction", params: { professionalId: response.data.body.id }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>