import axios from "axios";
import { TENDER_SERVICE_URL } from "@/config/TenderServiceConfig";
const authToken = JSON.parse(localStorage.getItem("user"));
class TenderService{
	findAll(){
		return axios.get(TENDER_SERVICE_URL + "tenders");
	}

	findById(id){
		return axios.get(TENDER_SERVICE_URL + "tenders/" + id);
	}

	save(tender){
		return axios.post(TENDER_SERVICE_URL + "tenders", {
			tenderId: tender.tenderId,
			countryId: tender.countryId,
			title: tender.title,
			companyId: tender.companyId,
			reach: tender.reach,
			type: tender.type,
			section: tender.section,
			tenderDocument: tender.tenderDocument,
			closingDate: tender.closingDate,
			status: tender.status,
			estimatedCost: tender.estimatedCost,
			estimatedCostCurrency: tender.estimatedCostCurrency,
			tenderFees: tender.tenderFees,
			userId: authToken === null ? tender.userId: authToken.userId,
			professionalCountries: tender.professionalCountries,
			overview: tender.overview,
			subject: tender.subject,
			experienceLevel: tender.experienceLevel,
			degrees: tender.degrees,
			certifications: tender.certifications,
			skills: tender.skills,
			tenderCategoryId: tender.tenderCategoryId
		});
	}

	approve(tender){
		return axios.post(TENDER_SERVICE_URL + "tenders/approve", {
			tenderId: tender.tenderId,
			status: tender.status,
		});
	}

	saveRFP(formData){
		return axios.post(TENDER_SERVICE_URL + "tenders/saveRFP", formData);
	}

	savePreference(userId, data){
		return axios.post(TENDER_SERVICE_URL + "tenders/savePreference/" + userId, data);
	}
}

export default new TenderService;