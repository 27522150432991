export default class SubscriptionPlan{
	constructor(id, subscriptionPlanNumber, name, identifier, price, currency, subscriptionType, description, status, validityPeriodInDays){
		this.id = id;
		this.subscriptionPlanNumber = subscriptionPlanNumber;
		this.name = name;
		this.identifier = identifier;
		this.price = price;
		this.currency = currency;
		this.subscriptionType = subscriptionType;
		this.description = description;
		this.status = status;
		this.validityPeriodInDays = validityPeriodInDays;
	}
} 