export default class Company {
  constructor(
    uuid, associatedUserId, companyId, companyType, name, shortName, businessPartnerNumber, landlinePhoneNumber,
    cellPhoneNumber, alternativePhoneNumber, email, fax, websiteUrl, businessSectorId, businessCategories,
    organizationType, companyStamp, establishedYear, numberOfEmployees, physicalAddress, postalAddress, 
    primaryContactPerson, secondaryContactPerson, socialMediaAccounts, branches, directors, shareholders, 
    attachments, otherCreditFacilities, compliances, carrierType, associatedUserUsername){
    this.uuid = uuid;
    this.associatedUserId = associatedUserId;
    this.companyId = companyId;
    this.companyType = companyType;
    this.name = name;
    this.shortName = shortName;
    this.businessPartnerNumber = businessPartnerNumber;
    this.landlinePhoneNumber = landlinePhoneNumber;
    this.cellPhoneNumber = cellPhoneNumber;
    this.alternativePhoneNumber = alternativePhoneNumber;
    this.email = email;
    this.fax = fax;
    this.websiteUrl = websiteUrl;
    this.businessSectorId = businessSectorId;
    this.businessCategories = businessCategories;
    this.organizationType = organizationType;
    this.companyStamp = companyStamp;
    this.establishedYear = establishedYear;
    this.numberOfEmployees = numberOfEmployees;
    this.physicalAddress = physicalAddress;
    this.postalAddress = postalAddress;
    this.primaryContactPerson = primaryContactPerson;
    this.secondaryContactPerson = secondaryContactPerson;
    this.socialMediaAccounts = socialMediaAccounts;
    this.branches = branches;
    this.directors = directors;
    this.shareholders = shareholders;
    this.attachments = attachments;
    this.otherCreditFacilities = otherCreditFacilities;
    this.compliances = compliances;
    this.carrierType = carrierType;
    this.associatedUserUsername = associatedUserUsername;
  }
}
