import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class SubscriptionPlanService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "subscriptionPlans");
  }

  findByIdentifier(identifier) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptionPlans/findByIdentifier/" + identifier);
  }
  
  save(subscriptionPlan) {
    return axios.post(GENERIC_SERVICE_URL + "subscriptionPlans", {
    	id: subscriptionPlan.id,
    	subscriptionPlanNumber: subscriptionPlan.subscriptionPlanNumber,
    	name: subscriptionPlan.name,
    	identifier: subscriptionPlan.identifier,
    	price: subscriptionPlan.price,
    	currency: subscriptionPlan.currency,
    	subscriptionType: subscriptionPlan.subscriptionType,
    	subscriptionPlanFeatures: subscriptionPlan.subscriptionPlanFeatures,
    	description: subscriptionPlan.description,
    	status: subscriptionPlan.status,
      validityPeriodInDays: subscriptionPlan.validityPeriodInDays
    });
  }
}

export default new SubscriptionPlanService;
