<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Browse products and buy at knockdown prices!</h4>
		<div class="row">
			<div class="col-md-2">
				<div class="panel">
					<div class="panel-body">
						<p>Refine by Category</p>
						<select class="form-control">
							<option value="">All categories</option>
						</select>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<div class="row">
					<div class="panel">
						<div class="panel-body">
							<div class="col-md-2">
								<p>{{ products.length }} results</p>
							</div>
							<div class="col-md-8">
								<p>Sort by:</p>
								<select class="form-control">
									<option value="">Relevance</option>
									<option value="">Price: High to Low</option>
									<option value="">Price: Low to High</option>
									<option value="">Top rated</option>
									<option value="">Newest arrivals</option>
								</select>
							</div>
							<div class="col-md-2">
								<router-link :to="{ name: 'products/cart'}">
									<button class="btn btn-primary pull-right">View cart</button><br><br>
								</router-link>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3" v-for="(product, index) in products" :key="product.id">
						<div class="panel">
							<div class="panel-body">
								<p>{{ product.percentageOff }}% off <span class="pull-right">Like/Dislike</span></p>
								<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded" width="280" height="200">
								<br><br>
								<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded" width="40" height="40">
								<p>{{ product.name }}</p>
								<h4>{{ product.description }}</h4>
								<p>Current price: {{ product.currency }}{{ product.unitPrice }}. Was <del>{{ product.currency }}{{ product.unitPrice }}</del></p>
								<p><span class="badge">JHB</span> <span class="badge">CPT</span></p>
								<p>
									<button :class="product.addedToCart ? 'btn btn-success': 'btn btn-default' " @click="addToCart(index, product)" type="button">{{ product.addedToCart ? "Added to cart": "Add to cart" }} <span class="glyphicon glyphicon-plus"></span>
									</button>&nbsp;
									<router-link :to="{ name: 'sellers/products/view', params: { productId: product.id }}">
										<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import loading from "vue-full-loading";
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

	export default{
		name: "MyProductList",
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: "Processing request...",
				products: [],
				cartProducts: [],
				addedToCart: false,
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL
			}
		},

		created(){
			this.findAll();
			this.findAll();
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts") || '[]');
			localStorage.removeItem("cartProducts");
			console.log("cartProducts: ", this.cartProducts);
		},

		computed: {
			getSellerId: function(){
				return this.$route.params.sellerId;
			}
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProductService.findBySellerId(this.getSellerId).then(response => {
					this.show = false;
					this.products = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			addToCart: function(index, product){
				console.log(index);
				console.log(product);
				if(this.products[index].addedToCart){
					this.products[index].addedToCart = false;
					console.log("remove from cartProducts");
					this.cartProducts.splice(index, 1);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
					console.log(this.cartProducts);
				}else{
					this.products[index].addedToCart = true;
					console.log("add to cartProducts");
					this.cartProducts.push(product);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
					console.log("cartProducts: ", this.cartProducts);
				}
			}
		}
	};
</script>

<style type="text/css" scoped>
	.processing-row {
	  display: flex;
	  justify-content: center;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
	.error-message {
	  color: red;
	}
</style>