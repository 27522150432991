import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CityService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "cities");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "cities/" + id);
  }

  findByProvinceId(provinceId) {
    return axios.get(GENERIC_SERVICE_URL + "cities/findByProvinceId/" + provinceId);
  }

  save(city) {
    return axios.post(GENERIC_SERVICE_URL + "cities", {
      provinceId: city.provinceId,
      name: city.name,
      description: city.description,
      status: city.status
    });
  }
}

export default new CityService();
