<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img
	              src="@/assets/images/station-loading.gif"
	              height="100"
	              width="100"
	            />
	          </div>
	          <p class="text-center" slot="loading-text">Processing...</p>
	        </div>
	      </div>
	    </loading>

		<h4>Tender List</h4><br>

		<router-link :to="{ path: '/tenders/new'}">
			<button class="btn btn-success pull-right">New tender <span class="glyphicon glyphicon-plus"></span></button>
		</router-link><br><br>

		<table class="table table-bordered table-responsive">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Tender Number</th>
				<th>Expiry Date</th>
				<th>Status</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="tender in tenders" :key="tender.id">
					<td>{{ tender.id }}</td>
					<td>{{ tender.tenderNumber }}</td>
					<td>{{ tender.closingDate }}</td>
					<td>
						<span v-if="getRoles.includes('USER')">
							<span class="label label-primary">{{ tender.status }}</span>
						</span>
						<span v-if="tender.status === 'OPEN'" class="label label-primary">{{ tender.status }}</span>
					</td>
					<td>
						<span v-if="getRoles.includes('ADMIN') || getRoles.includes('SUPER_ADMIN')">
							<span v-if="tender.status === 'PENDING'">
								<button class="btn btn-success btn-sm" @click="approve(tender.id)">Approve <span class="glyphicon glyphicon-edit"></span></button>
							</span>
						</span>&nbsp;
						<router-link :to="{ name: 'viewTenderConfigurationList/view', params: { tenderId: tender.id }}">
							<button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script type="text/javascript">
	import TenderService from "@/services/TenderService";
	import Tender from "@/models/Tender";
	import loading from "vue-full-loading";

	export default{
		name: "TenderConfigurationList",
		components: {
			loading
		},

		data: function(){
			return {
				tenders: [],
				tender: new Tender,
				show: false,
				label: "Processing...",
				errors: null,
			}
		},

		created: function(){
			this.findAll();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user.user;
			},

			getRoles: function(){
				let roles = [];
				for(var i = 0; i < this.getUser.authorities.length; i++){
					roles.push(this.getUser.authorities[i].authority);
					console.log(this.getUser.authorities[i].authority);
				}
				return roles;
			}
		},

		methods: {
			findAll: function(){
				this.show = true;
				TenderService.findAll().then(response => {
					this.tenders = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			approve: function(tenderId){
				this.show = true;
				console.log(tenderId);
				this.tender.status = "OPEN";
				this.tender.tenderId = tenderId;
				console.log(this.tender);
				TenderService.approve(this.tender).then(response => {
					this.show = false;
					this.tenders = response.data.body;
					console.log(response.data.statusCode);
					if(response.data.statusCode === 201){
						this.show = false;
						this.findAll();
					}
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			},
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.error-message {
  color: red;
}
</style>