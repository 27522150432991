<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Professional Profile Preview</h4>
					</div>
					<div class="col-md-2">
						<button class="btn btn-success btn-block" @click="publish">Publish</button><br><br>
					</div>
				</div>

				<ul class="list-group">
					<li class="list-group-item">Person Type: {{ professional.user !== null ? professional.user.personType: 'NA' }}</li>
					<li class="list-group-item">First Name: {{ professional.user !== null ? professional.user.firstName: 'NA' }}</li>
					<li class="list-group-item">Middle Name: {{ professional.user !== null ? professional.user.middleName: 'NA' }}</li>
					<li class="list-group-item">Nickname: {{ professional.user !== null ? professional.user.nickname: 'NA' }}</li>
					<li class="list-group-item">Last Name: {{ professional.user !== null ? professional.user.lastName: 'NA' }}</li>
					<li class="list-group-item">Email: {{ professional.user !== null ? professional.user.email: 'NA' }}</li>
					<li class="list-group-item">Date Of Birth: {{ professional.user !== null ? professional.user.dateOfBirth: 'NA' }}</li>
					<li class="list-group-item">Phone Numbers: 
						<ul class="list-group">
							<li class="list-group-item" v-for="phoneNumber in professional.user.phoneNumbers" :key="phoneNumber.phoneNumber">{{ phoneNumber.phoneNumber }}</li>
						</ul>
					</li>
					<li class="list-group-item">Fax Number: {{ professional.faxNumber }}</li>
					<li class="list-group-item">Skype Address: {{ professional.skypeAddress }}</li>
					<li class="list-group-item">Gender: {{ professional.gender }}</li>
					<li class="list-group-item">Salutation: {{ professional.salutation }}</li>
					<li class="list-group-item">National ID Number: {{ professional.nationalIdNumber }}</li>
					<li class="list-group-item">Passport Number: {{ professional.passportNumber }}</li>
					<li class="list-group-item">Drivers Licence Number: {{ professional.driversLicenceNumber }}</li>
					<li class="list-group-item">Nationality: {{ professional.nationality }}</li>
					<li class="list-group-item">Home Address: {{ professional.homeAddress }}</li>
					<li class="list-group-item">Postal Address: {{ professional.postalAddress }}</li>
					<li class="list-group-item">Marital Status: {{ professional.maritalStatus }}</li>
					<li class="list-group-item">Place Of Birth: {{ professional.placeOfBirth }}</li>
					<li class="list-group-item">Country Of Birth: {{ professional.countryOfBirth }}</li>
					<li class="list-group-item">Ethnic Group: {{ professional.ethnicGroup }}</li>
					<li class="list-group-item">Religion: {{ professional.religion }}</li>
					<li class="list-group-item">Photo: {{ professional.photo }}</li>
					<li class="list-group-item">Digital Signature: {{ professional.digitalSignature }}</li>

					<li class="list-group-item"><h4><b>Professional Skills Profile Details</b></h4></li>
					<li class="list-group-item">Title: {{ professional.title }}</li>
					<li class="list-group-item">Overview: {{ professional.overview }}</li>
					<li class="list-group-item">Video Introduction: {{ professional.videoIntroduction }}</li>
					<li class="list-group-item">Availability: {{ professional.availability }}</li>
					<li class="list-group-item">Languages: 
						<ul class="list-group">
							<li class="list-group-item" v-for="language in professional.languages" :key="language.id">{{ language.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Educations: 
						<ul class="list-group">
							<li class="list-group-item" v-for="education in professional.educations" :key="education.id">{{ education.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Service Charge: {{ professional.serviceCharge }}</li>
					<li class="list-group-item">Work Histories: 
						<ul class="list-group">
							<li class="list-group-item" v-for="workHistory in professional.workHistories" :key="workHistory.id">{{ workHistory.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Portfolio Projects: 
						<ul class="list-group">
							<li class="list-group-item" v-for="portfolioProject in professional.portfolioProjects" :key="portfolioProject.id">{{ portfolioProject.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Skills: 
						<ul class="list-group">
							<li class="list-group-item" v-for="skill in professional.skills" :key="skill.id">{{ skill.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Projects: 
						<ul class="list-group">
							<li class="list-group-item" v-for="project in professional.projects" :key="project.id">{{ project.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Testimonials: 
						<ul class="list-group">
							<li class="list-group-item" v-for="testimonial in professional.testimonials" :key="testimonial.id">{{ testimonial.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Certifications: 
						<ul class="list-group">
							<li class="list-group-item" v-for="certification in professional.certifications" :key="certification.id">{{ certification.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Other Experiences: 
						<ul class="list-group">
							<li class="list-group-item" v-for="otherExperience in professional.otherExperiences" :key="otherExperience.id">{{ otherExperience.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Job Preferences: 
						<ul class="list-group">
							<li class="list-group-item" v-for="jobPreference in professional.jobPreferences" :key="jobPreference.id">{{ jobPreference.name }}</li>
						</ul>
					</li>
					<li class="list-group-item">Preferences: 
						<ul class="list-group">
							<li class="list-group-item" v-for="preference in professional.preferences" :key="preference.id">{{ preference.name }}</li>
						</ul>
					</li>
				</ul>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "ProfessionalProfilePreview",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing request",
				show: false
			}
		},

		mounted(){
			this.findUserById();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			},

			getProfessionalId(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			findUserById: function(){
				ProfessionalService.findById(this.getProfessionalId).then(response => {
					this.professional = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			publish: function(){
				this.show = true;
				console.log(this.getProfessionalId);
				ProfessionalService.publish(this.getProfessionalId).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'professionals-search' });
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>