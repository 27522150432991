<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>
    
    <h4>All Companies</h4>
    <router-link :to="{ name: 'registerUser' }" replace>
      <button class="btn btn-success pull-right">New Company <span class="glyphicon glyphicon-plus"></span></button>
    </router-link>
    <br><br>

    <form class="form">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <label>&nbsp;</label>
            <input
              type="text"
              name="search"
              class="form-control"
              placeholder="Search here..."
            />
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>&nbsp;</label><br />
            <button class="btn btn-success btn-block" type="button">Filter <span class="glyphicon glyphicon-filter"></span></button>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>&nbsp;</label>
            <button class="btn btn-primary btn-block" type="button" @click="refresh">Refresh <span class="glyphicon glyphicon-refresh"></span></button>
          </div>
        </div>
      </div>
    </form>

    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Name</th>
        <th>Type</th>
        <th>Verified</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="company in companies" :key="company.id">
          <td>{{ company.id }}</td>
          <td>{{ company.companyType }}</td>
          <td>
            <span v-show="company.verified" class="label label-success">Verified</span>
            <span v-show="!company.verified" class="label label-default">Not Verified</span>
          </td>
          <td>{{ company.status }}</td>
          <td>{{ company.name }}</td>
          <td>
            <router-link :to="{ name: 'companies/view', params: { uuid: company.uuid }}">
              <button class="btn btn-primary col-4">
                View <span class="glyphicon glyphicon-info-sign"></span></button>
            </router-link>&nbsp;
          <!--   <span v-show="!company.verified">
              <router-link :to="{ name: 'companies/verify', params: { uuid: company.uuid }}">
                <button class="btn btn-warning col-4">Verify <span class="glyphicon glyphicon-thumbs-up"></span></button>
             </router-link>
            </span> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService";
  import loading from "vue-full-loading";

  export default {
    name: "CompanyList",
    components: {
      loading
    },

    data: function() {
      return {
        companies: [],
        show: false,
        label: 'Processing request'
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        CompanyService.findAll().then(response => {
          this.companies = response.data.body.filter(company => {
              return company.companyType !== 'FINANCIAL_INSTITUTION';
            });
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      },

      refresh: function(){
        this.findAll();
      }
    }
  };
</script>