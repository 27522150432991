<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<router-link :to="{ name: 'payment-list' }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>

				<ul class="list-group" v-if="payment !== null">
					<li class="list-group-item">Payment ID: {{ payment.id }}</li>
					<li class="list-group-item">Amount : {{ payment.amount }}</li>
					<li class="list-group-item">Currency : {{ payment.currency }}</li>
					<li class="list-group-item">Reference Number : {{ payment.referenceNumber }}</li>
					<li class="list-group-item">Amount Paid : {{ payment.amount }} </li>
					<li class="list-group-item">Payment Status : {{ payment.status }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService.js";
	export default{
		name: "ViewPayment",
		data: function(){
			return {
				payment: null,
			}
		},

		computed: {
			getReferenceNumber(){
				return this.$route.params.referenceNumber;
			}
		},

		created: function(){
			this.findByReferenceNumber();
		},

		methods: {
			findByReferenceNumber: function () {
				PaymentService.findByReferenceNumber(this.getReferenceNumber).then(response => {
					this.payment = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>