<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>


	<h4>Browse jobs and get bucks!</h4>
	<div v-if="jobs.length > 0">
		<div class="row" v-for="job in jobs" :key="job.id">
			<div class="col-md-3">
				<img src="@/assets/images/contractJobs.png" height="150" width="250">
			</div>
			<div class="col-md-3">
				<p>Job Title: {{ job.jobTitle !== null ? job.jobTitle.name: "Not available" }}</p>
				<p>Job Number: {{ job.jobNumber }}</p>
				<span v-if="job.type === 'FULL_TIME'">
					<p>{{ job.currency }} {{ job.fixedRate }} per month</p>
				</span>
				<span v-else>
					<p>{{ job.currency }} {{ job.hourlyRate }} per hour</p>
				</span>
				<p><span class="badge badge-primary">{{ job.type }}</span></p>
				<p>Quotations <span class="badge badge-primary">{{ job.applications.length }}</span></p>
				<p>
					<router-link :to="{ name: 'jobs/view', params: { jobId: job.id }}">
						<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
					</router-link>&nbsp;
					
					<router-link :to="{ name: 'jobs/quickApply', params: { jobId: job.id }}">
						<button class="btn btn-warning">Submit Quotation <span class="glyphicon glyphicon-edit"></span></button>
					</router-link>
				</p>
			</div>
		</div>

		<ul class="pagination">
			<li><a href="#">Prev</a></li>
			<li class="active"><a href="#">1</a></li>
			<li><a href="#">2</a></li>
			<li><a href="#">Next</a></li>
		</ul>
	</div>
	<div v-else>
		<div class="panel">
			<div class="panel-body">
				<h4>No jobs available at the moment. Check back again soon.</h4>
			</div>
		</div>
	</div>
			
		
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import JobTitleService from "@/services/JobTitleService";
	import loading from "vue-full-loading";

	export default{
		name: "ContractJobs",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitleId: null,
				jobTitles: [],
				jobs: [],
				show: false,
				label: "Processing request"
			}
		},

		mounted: function(){
			this.findAllJobTitles();
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				JobService.findAll().then(response => {
					this.jobs = response.data.body.filter(job => {
						return job.type === 'CONTRACT';
					});
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobTitles: function(){
				this.show = true;
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					this.show = false;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},
		}
	}
</script>