<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-12">
            <h4>Where do you want the products to be delivered at?</h4>
            <br>
            <ProductPickupAddressComponent/>
          </div>

         <!--  <div class="col-md-6">
            <DestinationComponent/>
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-10">
            <p><i>You can drag around the red marker to point to exact position you want on the map!</i></p>
            <p>And also zoom in and out to choose exact address from the map!</p>
          </div>
          <div class="col-md-2">
            <button class='btn btn-primary pull-right' @click='getDistance'>Compute delivery address details then submit</button>
          </div>
        </div>
        <table class="table table-hover table-bordered">
          <tbody>
           <!--  <tr>
              <th class="bg-success">Estimated Distance</th>
              <td>{{ distance.text }}</td>
            </tr>

            <tr>
              <th class="bg-success">Route Summary</th>
              <td>via {{ summary }}</td>
            </tr> -->

            <!-- <tr>
              <th class="bg-success">Estimated Delivery Time</th>
              <td>{{ duration.text }}</td>
            </tr> -->

          <!--   <tr>
              <th class="bg-success">Source address</th>
              <td>{{ start_address }}</td>
            </tr>

            <tr>
              <th class="bg-success">Destination address</th>
              <td>{{ end_address }}</td>
            </tr> -->

            <!-- <tr>
              <th class="bg-success">Copyrights</th>
              <td>{{ copyrights }}</td>
            </tr> -->
          </tbody>
        </table>
        <br><hr>
        <div class="row">
          <div class="col-md-10"></div>
          <!-- <div class="col-md-2">
            <form @submit.prevent="save">
              <span v-show="pickupAddressLatitude !== null && pickupAddressLongitude">
                <button class='btn btn-primary btn-block' type="submit">Submit</button>
              </span>
            </form>
          </div> -->
          <div class="col-md-2" v-if="this.physicalAddress !== null">
            <div v-if="this.physicalAddress.latitude && this.physicalAddress.longitude">
              <form @submit.prevent="save">
                <span v-show="this.physicalAddress !== 0">
                  <button class='btn btn-primary btn-block' type="submit">Submit</button>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
  import ProductPickupAddressComponent from "@/views/locations/ProductPickupAddressComponent.vue"
  // import DestinationComponent from "@/views/locations/DestinationComponent.vue"
  import * as VueGoogleMaps from 'vue2-google-maps';
  import loading from "vue-full-loading";
  import ProductDeliveryAddressService from "@/services/ProductDeliveryAddressService";
  import ProductDeliveryAddress from "@/models/ProductDeliveryAddress";

export default {
  name: "GoogleMap",
  components: {
    loading,
    ProductPickupAddressComponent,
    // DestinationComponent,
  },

  data() {
    return {
      show: false,
      label: 'Processing request',
      distance: '',
      summary: '',
      copyrights: '',
      duration: '',
      start_address: '',
      end_address: '',
      physicalAddress: {
        country: null,
        province: null,
        city: null,
        physicalAddress: null,
        sameAsPostalAddress: false,
        latitude: null,
        longitude: null
      },
      destinationAddress: {
        country: null,
        province: null,
        city: null,
        physicalAddress: null,
        latitude: null,
        longitude: null
      },
      productDeliveryAddress: new ProductDeliveryAddress,
      message: null
    };
  },
 
  created() {
    console.log('yay')
    console.log('user', this.getUser);
  },

  methods: {
    // proprietray source code!!!
    getDistance() {
      this.$gmapApiPromiseLazy().then(() => {
        // var directionsService = new this.google.maps.DirectionsService();
        // var start = new this.google.maps.LatLng(parseFloat(this.pickupAddressLatitude), parseFloat(this.pickupAddressLongitude));
        // var end = new this.google.maps.LatLng(parseFloat(this.destinationAddressLatitude), parseFloat(this.destinationAddressLongitude));
        // var bounds = new this.google.maps.LatLngBounds();
        // bounds.extend(start);
        // bounds.extend(end);
        // var request = {
        //     origin: start,
        //     destination: end,
        //     travelMode: this.google.maps.TravelMode.DRIVING
        // };
        // var googleInstance = this.google; // local var, (google instance cannot reach vue js this object!!!)
        // var self = this; // reference back to the "real" object as opposed to the event.
        // directionsService.route(request, function (response, status) {
        //     if (status == googleInstance.maps.DirectionsStatus.OK) {
        //         self.distance = response.routes[0].legs[0].distance;
        //         self.summary = response.routes[0].summary;
        //         self.copyrights = response.routes[0].copyrights;
        //         self.duration = response.routes[0].legs[0].duration;
        //         self.$store.commit('setEstimatedDrivingTime', response.routes[0].legs[0].duration.value);
        //         self.$store.commit('setEstimatedDistance', response.routes[0].legs[0].distance.value);
        //         self.start_address = response.routes[0].legs[0].start_address;
        //         self.end_address = response.routes[0].legs[0].end_address;
        //     } else {
        //         alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
        //     }
        // });
        var self = this;
        this.getPickupAddress(self.pickupAddressLatitude, self.pickupAddressLongitude, self);
        //this.getDestinationAddress(self.destinationAddressLatitude, self.destinationAddressLongitude, self);
      }).catch(error => { // I added the error handling
        console.log(error);
      });
    },

    getPickupAddress: function(lat, lng, self){
       var latlng = { lat: lat, lng: lng };
       var city, state, country;
        var geocoder = new this.google.maps.Geocoder(); // reverse geocoding
        geocoder.geocode({ 'location': latlng }, function(response, status) {
          if (status === 'OK') {
            if (response[0]) {
              for(var i = 0; i < response[0].address_components.length; i++){
                for(var j = 0; j < response[0].address_components[i].types.length; j++){
                    if (response[0].address_components[i].types[j] === 'locality'){
                        city = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'administrative_area_level_1'){
                        state = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'country'){
                        country = response[0].address_components[i].long_name;
                    }
                }
              }
              self.physicalAddress.country = country;
              self.physicalAddress.province = state;
              self.physicalAddress.city = city;
              self.physicalAddress.physicalAddress = response[0].formatted_address;
              self.physicalAddress.latitude = self.pickupAddressLatitude;
              self.physicalAddress.longitude = self.pickupAddressLongitude;
              console.log('physicalAddress: {}', self.physicalAddress);
              self.$store.commit('setPickupAddress', self.physicalAddress);
              console.log('Yay physicalAddress: ', self.$store.state.physicalAddress);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });
      },

      // repeated this method! Need refactor
      getDestinationAddress: function(lat, lng, self){
       var latlng = { lat: lat, lng: lng };
       var city, state, country;
        var geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ 'location': latlng }, function(response, status) {
          if (status === 'OK') {
            if (response[0]) {
              for(var i = 0; i < response[0].address_components.length; i++){
                for(var j = 0; j < response[0].address_components[i].types.length; j++){
                    if (response[0].address_components[i].types[j] === 'locality'){
                        city = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'administrative_area_level_1'){
                        state = response[0].address_components[i].long_name;
                    }
                    if (response[0].address_components[i].types[j] === 'country'){
                        country = response[0].address_components[i].long_name;
                    }
                }
              }
              self.destinationAddress.country = country;
              self.destinationAddress.province = state;
              self.destinationAddress.city = city;
              self.destinationAddress.physicalAddress = response[0].formatted_address;
              self.destinationAddress.latitude = self.destinationAddressLatitude;
              self.destinationAddress.longitude = self.destinationAddressLongitude;
              self.$store.commit('setDestinationAddress', self.destinationAddress);
              console.log('Yay destinationAddress: ', self.$store.state.destinationAddress);
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });
    },

    save: function(){
      this.show = true;
      this.productDeliveryAddress.location = this.$store.state.physicalAddress;
      this.productDeliveryAddress.username = this.getUser.username;
      
      console.log(this.productDeliveryAddress.location);
      this.productDeliveryAddress.destinationAddress = this.$store.state.destinationAddress;
      this.productDeliveryAddress.estimatedDistance = this.$store.state.estimatedDistance;
      this.productDeliveryAddress.estimatedDistanceMeasurement = "METERS";
      this.productDeliveryAddress.estimatedDrivingTime = this.$store.state.estimatedDrivingTime;
      this.productDeliveryAddress.estimatedDrivingTimeMeasurement = "SEC";
      ProductDeliveryAddressService.save(this.productDeliveryAddress).then(response => {
        if (response.data.statusCode === 201) {
          this.$router.push({ name: 'search-delivery-service', params: { deliveryAddressId: response.data.body.id }});
        }
        this.show = false;
      }).catch(error => {
        console.log(error);
        this.show = false;
      });
    },
  },

  computed: {
    getUUid: function(){
      return this.$route.params.uuid;
    },

    google: VueGoogleMaps.gmapApi,
    pickupAddressLatitude: function(){
      return this.$store.state.pickupAddressLatitude;
    },

    pickupAddressLongitude: function(){
      return this.$store.state.pickupAddressLongitude;
    },

    destinationAddressLatitude: function(){
      return this.$store.state.destinationAddressLatitude;
    },

    destinationAddressLongitude: function(){
      return this.$store.state.destinationAddressLongitude;
    },

    getUser: function() {
      return this.$store.state.auth.user.user;
    }
  }
};
</script>