<template>
	<div>
		<div class="panel">
				<div class="panel-body">

					<loading :show="show" :label="label">
						<div class="processing-row" slot="loading-container">
						  <div class="text-center">
						    <div slot="loading-spinner">
						      <img
						        src="@/assets/images/station-loading.gif"
						        height="100"
						        width="100"
						      />
						    </div>
						    <p class="text-center" slot="loading-text">{{ label }}</p>
						  </div>
						</div>
					</loading>

					<router-link :to="{ name: 'payments' }">
						<button class="btn btn-primary">All payments</button>
					</router-link>

					<!-- this form resembles what we have as integration at the bank on Lithium Platform :: but payments must have an invoice
						number that is being paid against-->
					<h4 class="text-center error-message" v-if="errorMessage !== 0">{{ errorMessage }}</h4>
					<form class="form-horizontal" @submit.prevent="save">
						<h4 class="text-danger">Payer Details</h4>

						<div class="form-group">
							<div v-if="errors.length !== 0">
								<span v-for="error in errors" :key="error.id">
									<span class="error-message">{{ error.transactions }}</span>
								</span>
							</div>
						</div>

						<div class="form-group">
							<label>Customer Reference</label>
							<input type="text" v-model="payer.customerReference" class="form-control" placeholder="Enter customerReference">
						</div>

						<div class="form-group">
							<label>First Name</label>
							<input type="text" v-model="payer.firstName" class="form-control" placeholder="Enter First Name">
						</div>

						<div class="form-group">
							<label>Last Name</label>
							<input type="text" v-model="payer.lastName" class="form-control" placeholder="Enter Last Name">
						</div>

						<div class="form-group">
							<label>Email</label>
							<input type="text" v-model="payer.email" class="form-control" placeholder="Enter Email">
						</div>

						<div class="form-group">
							<label>Phone Number</label>
							<input type="text" v-model="payer.phoneNumber" class="form-control" placeholder="Enter Phone Number">
						</div>

						<h4>Transaction Details</h4>
						<!-- NB Make an computed property to check if invoice exists before proceeding, else disable the add transaction button -->
						<div class="form-group">
							<label>Invoice Number</label>
							<input type="text" v-model="payment.studentInvoiceNumber" class="form-control" placeholder="Enter Invoice Number" v-on:keyup="payment.studentInvoiceNumber = $event.target.value">
							<div v-if="errors.length !== 0">
								<span v-for="error in errors" :key="error.id">
									<span class="error-message">{{ error.studentInvoiceNumber }}</span>
								</span>
							</div>
						</div>

						<div class="form-group">
							<!-- just show user this amount as a computed property from the transactions -->
							<label>Total Amount</label>
							<input type="text" v-model="transactionsTotal" class="form-control" disabled v-on:keyup="payment.invoiceNumber = $event.target.value">
						</div>

						<h4 class="text-danger"><b><i>Enter Transaction(s) Below</i></b></h4>
						<!-- multiple transactions might have been done at different banks or dates or even platforms?? 
							we might not need to record all these transactions, unless its cioming from the mobile payments / online payments-->
						<button class="btn btn-success btn-sm pull-right" type="button" @click="addTransactionField"><span class="glyphicon glyphicon-plus"></span></button><br>

						<div v-for="(transactionField, index) in transactionFields" :key="index">
							<h4 class="text-primary"><b>Transaction Number {{ index + 1 }}</b></h4>
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<label>Amount</label>
										<input type="text" v-model="transactionField.amount" class="form-control" placeholder="Enter amount" required>
									</div>
								</div>
								<div class="col-md-1"></div>

								<div class="col-md-3">
									<div class="form-group">
										<label>Currency</label>
										<select class="form-control" v-model="transactionField.currency" required>
											<option value="" disabled>Select currency</option>
											<option value="ZWL">ZWL</option>
											<option value="USD">USD</option>
										</select>
									</div>
								</div>
								<div class="col-md-1"></div>

								<div class="col-md-3">
									<div class="form-group">
										<label>School Term</label>
										<select class="form-control" v-model="transactionField.schoolTerm" required>
											<option value="" disabled>Select schoolTerm</option>
											<option value="FIRST_TERM">First Term</option>
											<option value="SECOND_TERM">Second Term</option>
											<option value="THIRD_TERM">Third Term</option>
										</select>
									</div>
								</div>
								<div class="col-md-1"></div>
							</div>

							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<label>Narrative</label>
										<select class="form-control" v-model="transactionField.narrative" required>
											<option value="" disabled>Select narrative</option>
											<option value="SCHOOL_FEES">School Fees</option>
										</select>
									</div>
								</div>
								<div class="col-md-1"></div>

								<div class="col-md-3">
									<div class="form-group">
										<!-- is this necessary since is mananed by the amount paid against the invoice -->
										<label>Payment Type</label>
										<select class="form-control" v-model="transactionField.paymentType" required>
											<option value="" disabled>Select paymentType</option>
											<option value="BANK">BANK</option>
											<option value="CASH">CASH</option>
										</select>
									</div>
								</div>
								<div class="col-md-1"></div>

								<div class="col-md-3">
									<div class="form-group">
										<label>Reference Number</label>
										<input type="text" v-model="transactionField.referenceNumber" class="form-control" placeholder="Enter Reference Number" required>
									</div>
								</div>
								<div class="col-md-1">
									<button class="btn btn-danger btn-sm pull-right" type="button" @click="transactionFields.splice(index, 1)"><span class="glyphicon glyphicon-minus"></span></button>
								</div>
							</div>

							
						</div>

						<div class="form-group">
							<label>&nbsp;</label>
							<div v-if="errors.length !== 0">
								<span v-for="error in errors" :key="error.id">
									<span class="error-message">{{ error.transactions }}</span>
								</span>
							</div>
						</div>

						<div class="form-group" v-if="this.transactionFields.length !== 0">
							<label>&nbsp;</label>
							<button class="btn btn-success" type="submit">Submit and Save Payment</button>
						</div>
					</form>
				</div>	
		</div>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService.js";
	import Payment from "@/models/Payment.js";
	import Payer from "@/models/Payer.js";
	import loading from "vue-full-loading";

	export default{
		components: {
			loading
		},

		data: function () {
			return {
				payment: new Payment(),
				payer: new Payer(),
				transactionFields: [{
		            amount: null,
		            currency: null,
		            schoolTerm: null,
		            narrative: null,
		            transactionStatus: null,
		            paymentType: null,
		            referenceNumber: null
		        }],
		        show: false,
		        label: "Processing payment. Please wait.",
		        errorMessage: null,
		        errors: [],
			}
		},

		computed: {
			transactionsTotal: function(event){
				console.log(event);
				return 1 + 1;
			}
		},

		methods: {
			addTransactionField: function(){
				this.transactionFields.push({
					amount: null,
		            currency: null,
		            schoolTerm: null,
		            narrative: null,
		            transactionStatus: null,
		            paymentType: null,
		            referenceNumber: null,
				});
			},

			save: function (){
				this.payment.payer = this.payer;
				this.payment.transactions = this.transactionFields;
				this.show = true;
				PaymentService.save(this.payment).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "payments" });
					}
					if(response.data.statusCode === 400){
						this.errorMessage = response.data.message;
					}
					if(response.data.statusCode === 500){
						this.errorMessage = response.data.message;
					}
					if(response.data.statusCode === 422){
						this.errors = response.data.errors;
						this.errorMessage = response.data.message;
					}
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>

<style type="text/css">
	.error-message{
		color: red;
	}
</style>