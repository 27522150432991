var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('p',[_vm._v("We offer delivery service for our e-commerce platform, in a simple, affordable & convinient way for the buyer, seller and delivery service company.")]),_c('hr'),_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("1. "),_c('router-link',{attrs:{"to":{ name: 'product-search' }}},[_vm._v("Go to our e-commerce platform")])],1),_c('p',[_vm._v("2. Browse & buy products")]),_c('p',[_vm._v("3. Choose to collect in person or add delivery service")]),_c('p',[_vm._v("4. Check out")]),_c('p',[_vm._v("5 Wait for your parcel to be delivered to your doorstep")]),_vm._m(1)])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("1. "),_c('router-link',{attrs:{"to":{ name: 'register' }}},[_vm._v("Create your storefront")])],1),_c('p',[_vm._v("2. Create products portfolio")]),_c('p',[_vm._v("3. Buyers can find out your store")]),_vm._m(2)]),_vm._m(3)]),_c('hr'),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-md-6"},[_c('p',[_vm._v("1. "),_c('router-link',{attrs:{"to":{ name: 'register-delivery-service' }}},[_vm._v("Go to our delivery service registration")])],1),_c('p',[_vm._v("2. Create delivery service company profile")]),_c('p',[_vm._v("3. Pay registration fee")]),_c('p',[_vm._v("4. Wait for orders to hit your inbox")]),_c('p',[_vm._v("5. Fullfill orders on time to enjoy more orders")]),_vm._m(5)])])])]),_c('FooterComponent')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"text-danger"},[_vm._v("For the Buyer")]),_c('img',{staticClass:"img img-responsive img-bordered img-rounded",attrs:{"src":require("@/assets/images/buyer.png"),"width":"300","height":"300"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Enjoy the convinience: "),_c('img',{attrs:{"src":require("@/assets/images/approved.png"),"height":"50","width":"50"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Enjoy the convinience: "),_c('img',{attrs:{"src":require("@/assets/images/approved.png"),"height":"50","width":"50"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"text-danger"},[_vm._v("For the Seller")]),_c('img',{staticClass:"img img-responsive img-bordered img-rounded",attrs:{"src":require("@/assets/images/seller.jpeg"),"width":"500","height":"500"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('h4',{staticClass:"text-danger"},[_vm._v("For delivery service company")]),_c('img',{staticClass:"img img-responsive img-bordered img-rounded",attrs:{"src":require("@/assets/images/delivery-service-home.jpeg"),"width":"500","height":"500"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Enjoy the convinience: "),_c('img',{attrs:{"src":require("@/assets/images/approved.png"),"height":"50","width":"50"}})])
}]

export { render, staticRenderFns }