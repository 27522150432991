<template>
	<div>
		<div class="jumbotron text-center hero-bkg-animated">
	      <!-- <a href="#" class="btn btn-default blueButton" data-toggle="modal" data-target="#contactUsModal">
	      	<span class="glyphicon glyphicon-envelope"></span> Talk to us
	      </a> -->

	      &nbsp;<a :href="'tel:' + companyContactNumber" class="btn btn-success greenButton">
	      	<span class="glyphicon glyphicon-phone"></span> Call Us</a>

	      <h2 style="color: white;">
	        <a href="#" class="btn">
	          <img src="@/assets/images/contractJobs.png" class="img img-responsive img-rounded" width="250" height="80"/>
	        </a>
	        <marquee>
	          <b><h2>Welcome to your home of Skilled Biographies</h2></b>
	        </marquee>
	      </h2>
	      <p style="color: white;">A skills based platform that enables professional services delivery by connecting service providers and those in need of services in both formal and informal settings</p>
	    </div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-8">
						<h3>Find the right Full time and Contract jobs or volunteering opportunities</h3>
						<p>Receive alerts of full time and contract jobs or volunteering opportunities that match your preferences, within and beyond borders. Receive an alert each time a job opportunity that matches your preference is posted.</p>
						<h3><router-link :to="{ name: 'jobs' }"><button class="btn btn-primary">Search Jobs</button></router-link></h3>
					</div>
					<div class="col-md-4">
						<img src="@/assets/images/jobOpportunity.png" height="250" width="350" class="img img-responsive img-rounded">
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-4">
						<img src="@/assets/images/findTender.png" height="350" width="500" class="img img-responsive img-rounded">
					</div>
					<div class="col-md-8">
						<h3>Find a tender that suits your skills</h3>
						<p>Get notified of a tender matching your company skills and experience at a specified frequency.</p>
						<p>Get connected to worldwide tenders that match your company’s expertise and service areas, together with auto suggested profiles of experts who you can engage to make your team composition for that important proposal.</p>
						<p>Proposal writers and assistance for each tender will be at your disposal.</p>
						<h3><router-link :to="{ name: 'tenders' }"><button class="btn btn-primary">Search Tenders</button></router-link></h3>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-8">
						<h3>Use the Compliance Management Tool to alert submission of all statutory remittances and regulatory reports on time</h3>
						<p>Create a checklist of your Company’s Legal & Regulatory requirements and start to receive notifications of due dates, alongside the effects of non-compliance</p>
						<h3><router-link :to="{ name: 'complianceManagement' }"><button class="btn btn-primary">Learn more...</button></router-link></h3>
					</div>
					<div class="col-md-4">
						<img src="@/assets/images/complianceManagement.png" height="350" width="500" class="img img-responsive img-rounded">
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-4">
						<img src="@/assets/images/skilledWorker.png" height="350" width="500" class="img img-responsive img-rounded">
					</div>
					<div class="col-md-8">
						<h3>Get any job done by competent skilled experts at least cost</h3>
						<p>Post any job you need done and the Skilled Bioz advanced algorithm will match your job requirements with relevant professional journeymen within your locality or beyond borders, who in turn send you quotations that you can make a decision based on.</p>
						<h3><router-link :to="{ name: 'professionals' }"><button class="btn btn-primary">Learn more...</button></router-link></h3>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-8">
						<h3>Receive sales leads by getting matched to buyers</h3>
						<p>Purchase any products of choice and quality at reduced prices from the Products knockdown sales platform</p>
						<h3><router-link :to="{ name: 'products' }"><button class="btn btn-primary">Learn more...</button></router-link></h3>
					</div>
					<div class="col-md-4">
						<img src="@/assets/images/B2B-sales-leads.png" height="350" width="500" class="img img-responsive img-rounded">
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-4">
						<!-- <img src="@/assets/images/call_for_proposals.png" height="350" width="500"> -->
						<img src="@/assets/images/callForProposal.png" height="350" width="500" class="img img-responsive img-rounded">
					</div>
					<div class="col-md-8">
						<h3>Latest Call for proposals and other funding opportunities</h3>
						<p>Receive an alert of a Call for proposal, grants and other funding opportunities matching your individual or organizational areas of focus in thematic interventions.</p>
						<p>Assistance with proposal writing or review will be at your disposal.</p>
						<h3><router-link :to="{ name: 'fundingOpportunities' }"><button class="btn btn-primary">Learn more...</button></router-link></h3>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-8">
						<h2>Skilled Bios</h2>
						<p>Opportunities for all.</p>
					</div>
					<div class="col-md-4">
						<img src="@/assets/images/opportunity.png" height="350" width="500" class="img img-responsive img-rounded">
					</div>
				</div>
			</div>
		</div>

		<FooterComponent/>
	</div>
</template>

<script type="text/javascript">
	import FooterComponent from "@/components/FooterComponent";

	export default{
		name: "Landingpage",
		components: {
			FooterComponent
		},

		data: function(){
			return {
				companyContactNumber: "+2631234567890"
			}
		}
	};
</script>

<style type="text/css" scoped>
.hero-bkg-animated {
  background: gray
    url("~@/assets/images/skilledWorker.png")
    repeat 0 0;
  text-align: center;
  box-sizing: border-box;
  -webkit-animation: slide 20s linear infinite;
}

.hero-bkg-animated h1 {
  font-family: sans-serif;
}

@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -400px 0;
  }
}
</style>