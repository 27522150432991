import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class OccupationService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "occupations");
  }

  findById(entityId) {
    return axios.get(GENERIC_SERVICE_URL + "occupations" + "/" + entityId);
  }

  save(entity) {
    return axios.post(GENERIC_SERVICE_URL + "occupations", {
      name: entity.name,
      description: entity.description,
      status: entity.status
    });
  }

  updateById(entity, entityId) {
    return axios.put(GENERIC_SERVICE_URL + "occupations" + "/" + entityId, {
      name: entity.name,
      description: entity.description,
      status: entity.status
    });
  }
}

export default new OccupationService();
