<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>Registered Companies</h4>
        <br>

        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Type</th>
            <th>Verified</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="company in companies" :key="company.id">
              <td>{{ company.id }}</td>
              <td>{{ company.type }}</td>
              <td>
                <span v-show="company.verified" class="label label-success">Verified</span>
                <span v-show="!company.verified" class="label label-default">Not Verified</span>
              </td>
              <td>{{ company.status }}</td>
              <td>{{ company.name }}</td>
              <td>
                <router-link :to="{ name: 'view-delivery-service', params: { uuid: company.uuid }}">
                  <button class="btn btn-primary col-4">
                    View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>&nbsp;
                <span v-show="!company.verified">
                  <router-link :to="{ name: 'companies/verify', params: { id: company.id }}">
                    <button class="btn btn-warning btn-sm">Verify <span class="glyphicon glyphicon-thumbs-up"></span></button>
                 </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

     <!--    <ul class="pagination">
          <li><a href="#">Prev</a></li>
          <li class="active"><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">Next</a></li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService";
  import loading from "vue-full-loading";

  export default {
    name: "CompanyList",
    components: {
      loading
    },

    data: function() {
      return {
        companies: [],
        show: false,
        label: 'Processing request'
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        CompanyService.findAll().then(response => {
          this.companies = response.data.body.filter(company => {
              return company.type === 'CARRIER';
            });
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      },

      refresh: function(){
        this.findAll();
      }
    }
  };
</script>