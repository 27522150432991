import axios from "axios";
import { SKILLS_SERVICE_URL } from "@/config/SkillsServiceConfig";

class OfferLetterService{
	findAll(){
		return axios.get(SKILLS_SERVICE_URL + "offerLetters");
	}

	findById(id){
		return axios.get(SKILLS_SERVICE_URL + "offerLetters/" + id);
	}
	
	sendOfferLetter(jobId, username){
		return axios.post(SKILLS_SERVICE_URL + "offerLetters/sendOfferLetter/" + jobId + "/" + username);
	}
}

export default new OfferLetterService;