<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
	    
	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>Congrats, you have been awarded <router-link :to="{ name: 'view-product-order', params: { orderNumber: getOrderNumber  }}"><span class="text-danger">a new products order</span></router-link>  booking. Accept or decline the offer below.</h4>
			    <hr>
				<div class="row">
					<div class="col-md-6">
						<p>If you decline the offer, your booking for this order will be automatically closed.</p>
						<form class="form" @submit.prevent="declineOrder">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-danger">Decline Order <span class="glyphicon glyphicon-thumbs-down"></span></button>
							</div>
						</form>
					</div>
					<div class="col-md-6">
						<p>If you accept the offer, we'll reserve your delivery order charge and release your funds within 72 hrs.</p>
						<form class="form" @submit.prevent="acceptOrder">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-success">Accept Order <span class="glyphicon glyphicon-thumbs-up"></span></button>
							</div>
						</form>
					</div>
					<div class="col-md-2"></div>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import OrderService from "@/services/OrderService.js";
	import CompanyService from "@/services/CompanyService.js";

	export default{
		name: 'AcceptOrDeclineOrderOffer',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				company: null
			}
		},

		computed: {
			getOrderNumber: function(){
				return this.$route.params.orderNumber;
			},

			getUser(){
				return this.$store.state.auth.user.user;
			}
		},

		mounted(){
			this.findByAssociatedUserUsername();
		},

		methods: {
			acceptOrder: function() {
				this.show = true;
		        OrderService.acceptOrder(this.getOrderNumber, this.company.id).then(response => {
		        	if(response.data.statusCode === 201){
		        		this.$router.push({ name: "carrier-order-list" });
		        	}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    declineOrder: function() {
				this.show = true;
		        OrderService.declineOrder(this.getOrderNumber).then(response => {
		        	if(response.data.statusCode === 201){
		        		this.$router.push({ name: "carrier-order-list" });
		        	}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    findByAssociatedUserUsername: function(){
		    	CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
		    		this.company = response.data.body;
		    	}).catch(error => {
		    		console.log(error);
		    	});
		    }
		}
	};
</script>