<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<div class="row">
		      		<div class="col-md-10"><h4>New Volunteering Job</h4></div>
		      		<router-link :to="{ name: 'volunteering-jobs-configuration-list' }">
		      			<div class="col-md-2">
				      		<button class="btn btn-primary btn-block">Back</button>
		      			</div>
		      		</router-link>
		      	</div>
		      	<br>

				<form class="form" @submit.prevent="save">
					<fieldset>
						<legend>Job Details</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Job category</label>
									<select class="form-control" v-model="job.categoryId">
										<option value="" disabled>Select option</option>
										<option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">{{ jobCategory.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Job title</label>
									<input type="text" name="" class="form-control" placeholder="Enter job title" v-model="job.title">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expiry date</label>
									<input type="date" name="expiryDate" v-model="job.expiryDate" class="form-control">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Internal or external</label>
									<select class="form-control" v-model="job.internalOrExternal">
										<option value="" disabled>Select option</option>
										<option value="INTERNAL">INTERNAL</option>
										<option value="EXTERNAL">EXTERNAL</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-if="job.internalOrExternal === 'INTERNAL'">
								<div class="form-group">
									<label>Remuneration/Allowance</label>
									<input type="text" name="fixedRate" v-model="job.remuneration" placeholder="Enter remuneration $$$" class="form-control">
								</div>
							</div>
							<div class="col-md-3" v-if="job.internalOrExternal === 'INTERNAL'">
								<div class="form-group">
									<label>Currency</label>
									<select class="form-control" v-model="job.currency">
										<option value="" disabled>Select option</option>
										<option value="USD">USD</option>
										<option value="ZWL">ZWL</option>
										<option value="RAND">RAND</option>
										<option value="PULA">PULA</option>
										<option value="KWACHA">KWACHA</option>
										<option value="SHILLING">SHILLING</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-9">
								<div class="form-group" v-if="job.internalOrExternal === 'EXTERNAL'">
									<label>External link</label>
									<input type="text" name="externalLink" v-model="job.externalLink" placeholder="Enter external link" class="form-control">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Availability</label>
									<select class="form-control" v-model="job.availability">
										<option value="" disabled>Select option</option>
										<option value="MORE_THAN_30_HOURS_PER_WEEK">MORE_THAN_30_HOURS_PER_WEEK</option>
										<option value="LESS_THAN_30_HOURS_PER_WEEK">LESS_THAN_30_HOURS_PER_WEEK</option>
										<option value="AS_NEEDED">AS_NEEDED</option>
										<option value="NONE">NONE</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expertise level</label>
									<select class="form-control" v-model="job.expertiseLevel">
										<option value="" disabled>Select option</option>
										<option value="JUNIOR">JUNIOR</option>
										<option value="INTERMEDIATE">INTERMEDIATE</option>
										<option value="MIXTURE_OF_SKILLS">MIXTURE_OF_SKILLS</option>
										<option value="HIGHLY_SKILLED">HIGHLY_SKILLED</option>
										<option value="EXPERT">EXPERT</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Work type</label>
									<select class="form-control" v-model="job.workType" required>
										<option value="" disabled>Select option</option>
										<option value="ONSITE">On site</option>
										<option value="REMOTE">Remote</option>
										<option value="HYBRID">Hybrid</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Total hours of service</label>
									<input type="text" name="" class="form-control" v-model="job.totalHoursOfService" placeholder="Enter total hours of service">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Hours of commitment per week</label>
									<input type="text" name="commitmentHoursPerWeek" v-model="job.commitmentHoursPerWeek" placeholder="Enter commitment hours per week" class="form-control">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group" v-show="job.type !== 'FULL_TIME'">
									<label>Project type</label>
									<select class="form-control" v-model="job.projectType">
										<option value="" disabled>Select option</option>
										<option value="SHORT_TERM">SHORT_TERM</option>
										<option value="LONG_TERM">LONG_TERM</option>
										<option value="AS_NEEDED">AS_NEEDED</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-9">
								<div class="form-group">
									<label>Additional offers / other benefits</label>
									<textarea rows="5" class="form-control" placeholder="Enter any additional offers or other benefits"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<label>Job description</label>
								<textarea class="form-control" v-model="job.description" placeholder="Enter job description (optional) - max 3000 characters" rows="30" maxlength="3000"></textarea>
							</div>
						</div>
					</fieldset>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobCategoryService from "@/services/JobCategoryService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";

	export default{
		name: "NewVolunteeringJob",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				jobCategories: [],
				job: new Job,
				label: "Processing request",
				show: false,
			}
		},

		created: function(){
			this.findAllJobCategories();
		},

		methods: {
			findAllJobCategories: function(){
				JobCategoryService.findAll(this.job).then(response => {
					this.jobCategories = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				this.job.type = 'VOLUNTEERING';
				this.job.postedByUsername = this.getUser.user.username;
				this.job.location = this.location;
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "volunteering-job-location", params: { jobId: response.data.body.id, type: response.data.body.type }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			}
		}
	};
</script>