<template>
	<div>
		<h4>Job Configuration List</h4>

		<router-link :to="{ name: 'newGrandAndFundingOpportunity' }" replace><button class="btn btn-success pull-right">New Grant And Funding Opportunity <span class="glyphicon glyphicon-plus"></span></button></router-link><br/><br/>

		<table class="table table-responsive table-bordered">
			<tr class="bg-primary">
				<th>ID</th>
				<th>Title</th>
				<th>EstimatedBudget</th>
				<th>Status</th>
				<th>Actions</th>
			</tr>
			<tbody>
				<tr v-for="grantAndFundingOpportunity in grantAndFundingOpportunities" :key="grantAndFundingOpportunity.id">
					<td>{{ grantAndFundingOpportunity.id }}</td>
					<td>{{ grantAndFundingOpportunity.title }}</td>
					<td>{{ grantAndFundingOpportunity.currency }} {{ grantAndFundingOpportunity.estimatedBudget }} per hour</td>
					<td>
						<span class="label label-success" v-if="grantAndFundingOpportunity.status === 'ACTIVE'">{{ grantAndFundingOpportunity.status }}</span>
						<span class="label label-primary" v-if="grantAndFundingOpportunity.status === 'INACTIVE'">{{ grantAndFundingOpportunity.status }}</span>
					</td>
					<td>
						<router-link :to="{ name: 'jobConfigurationView', params: { jobId: grantAndFundingOpportunity.id }}">
							<button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script type="text/javascript">
	import GrantAndFundingOpportunityService from "@/services/GrantAndFundingOpportunityService";

	export default{
		name: "GrantAndFundingOpportunityList",
		component: {

		},

		data: function(){
			return {
				grantAndFundingOpportunities: []
			}
		},

		created: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				GrantAndFundingOpportunityService.findAll().then(response => {
					this.grantAndFundingOpportunities = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>