<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <router-link :to="{ name: 'configurations' }" replace>
      <button class="btn btn-primary">Back</button>
    </router-link>

    <router-link :to="{ name: 'new-preference-category' }" replace>
      <button class="btn btn-success pull-right"> New Preference Category<span class="glyphicon glyphicon-plus"></span></button>
    </router-link><br/><br/>

    <h4 class="text-center">All Product Categories</h4>
    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="preference in preferences" :key="preference.id">
          <td>{{ preference.id }}</td>
          <td>{{ preference.name.replace('_', ' ') }}</td>
          <td>{{ preference.description }}</td>
          <td>
            <span class="label label-success" v-if="preference.status == 'ACTIVE'"
              >Active</span
            >
            <span class="label label-default" v-if="preference.status == 'INACTIVE'"
              >Inactive</span
            >
            <span class="label label-danger" v-if="preference.status == 'DELETED'"
              >Deleted</span
            >
          </td>
          <td>
            <router-link
              :to="{ name: 'complianceCategories/edit', params: { preferenceCategoryId: preference.id } }"
              ><button class="btn btn-primary">Edit</button></router-link
            >&nbsp;
            <router-link
              :to="{ name: 'complianceCategories/view', params: { preferenceCategoryId: preference.id } }"
              ><button class="btn btn-primary">View</button></router-link
            >&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import PreferenceService from "@/services/PreferenceService";
  import loading from "vue-full-loading";

  export default {
    name: "PreferenceCategoryList",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        preferences: []
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        PreferenceService.findAll().then(response => {
            this.show = false;
            this.preferences = response.data.body;
          }).catch(error => {
            this.show = false;
            console.log(error);
          });
      }
    }
  };
</script>
