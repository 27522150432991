<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<p>We offer delivery service for our e-commerce platform, in a simple, affordable & convinient way for the buyer, seller and delivery service company.</p>
				<hr>
				<div class="row">
					<div class="col-md-6">
						<h4 class="text-danger">For the Buyer</h4>
						<img src="@/assets/images/buyer.png" class="img img-responsive img-bordered img-rounded" width="300" height="300">
					</div>
					<div class="col-md-6">
						<p>1. <router-link :to="{ name: 'product-search' }">Go to our e-commerce platform</router-link></p>
						<p>2. Browse & buy products</p>
						<p>3. Choose to collect in person or add delivery service</p>
						<p>4. Check out</p>
						<p>5 Wait for your parcel to be delivered to your doorstep</p>
						<p>Enjoy the convinience: <img src="@/assets/images/approved.png" height="50" width="50"></p>
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col-md-6">
						<p>1. <router-link :to="{ name: 'register' }">Create your storefront</router-link></p>
						<p>2. Create products portfolio</p>
						<p>3. Buyers can find out your store</p>
						<p>Enjoy the convinience: <img src="@/assets/images/approved.png" height="50" width="50"></p>
					</div>
					<div class="col-md-6">
						<h4 class="text-danger">For the Seller</h4>
						<img src="@/assets/images/seller.jpeg" class="img img-responsive img-bordered img-rounded" width="500" height="500">
					</div>
				</div>
				<hr>
				<div class="row">
					<div class="col-md-6">
						<h4 class="text-danger">For delivery service company</h4>
						<img src="@/assets/images/delivery-service-home.jpeg" class="img img-responsive img-bordered img-rounded" width="500" height="500">
					</div>
					<div class="col-md-6">
						<p>1. <router-link :to="{ name: 'register-delivery-service' }">Go to our delivery service registration</router-link></p>
						<p>2. Create delivery service company profile</p>
						<p>3. Pay registration fee</p>
						<p>4. Wait for orders to hit your inbox</p>
						<p>5. Fullfill orders on time to enjoy more orders</p>
						<p>Enjoy the convinience: <img src="@/assets/images/approved.png" height="50" width="50"></p>
					</div>
				</div>
			</div>
		</div>
		<FooterComponent/>
	</div>
</template>

<script type="text/javascript">
	import FooterComponent from "@/components/FooterComponent";

	export default{
		components: {
			FooterComponent
		}
	};
</script>