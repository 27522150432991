import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class FriendInviteService {
  save(friendInvite) {
    return axios.post(GENERIC_SERVICE_URL + "friendInvite", {
      username: friendInvite.username,
      name: friendInvite.name,
      email: friendInvite.email,
      phoneNumber: friendInvite.phoneNumber
    });
  }
}

export default new FriendInviteService;
