<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<h4><b>Quick Apply (Send Quotation)</b></h4><br>
		
		<div v-if="job.internalOrExternal !== 'EXTERNAL'">
			<form class="form" @submit.prevent="save">
				<div class="form-group">
					<label>Validity In Days</label>
					<input type="number" name="validityInDays" id="validityInDays" class="form-control" placeholder="Enter Validity In Days" v-model="validityInDays" required>
				</div>

				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label>Currency</label>
							<select class="form-control" v-model="currency" required>
								<option value="">Select option</option>
								<option value="USD">USD</option>
								<option value="ZWL">ZWL</option>
								<option value="ZAR">ZAR</option>
							</select>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label>Your Offer</label>
							<input type="text" name="offer" id="offer" class="form-control" placeholder="Enter your offer" v-model="offer" required>
						</div>
					</div>
				</div>

				<div class="form-group">
					<label>Hours Needed To Complete</label>
					<input type="text" name="hoursNeededToComplete" id="hoursNeededToComplete" class="form-control" placeholder="Enter hours Needed To complete" v-model="hoursNeededToComplete" required>
				</div>

				<div class="form-group">
					<label>Application Message</label>
					<textarea name="" id="" placeholder="Enter additional information" rows="5" class="form-control" v-model="additionalInformation" required></textarea>
				</div>

				<div class="form-group">
					<label>&nbsp;</label>
					<button class="btn btn-success pull-right">Submit application</button>
				</div>
			</form>
		</div>
		<div v-else>
			<h4>This job is an external job and you can apply it outside SkilledBioz.</h4>
			<p>Link to apply or email address: <a :href="job.externalLink">{{ job.externalLink }}</a></p>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService.js";
	import Job from "@/models/Job.js";
	import JobQuotation from "@/models/JobQuotation.js";
	import loading from "vue-full-loading";

	export default{
		name: "QuickApply",
		components: {
			loading
		},

		data: function(){
			return {
				job: new Job,
				applications: [],
				jobQuotation: new JobQuotation,
				show: false,
				label: "Processing request...",
				validityInDays: null,
				offer: null,
				currency: null,
				hoursNeededToComplete: null,
				additionalInformation: null
			}
		},

		created: function(){
			this.findById();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			},

			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		methods: {
			findById: function(){
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				let applicationPostDto = {
					userId: this.getUser.userId,
					jobId: this.job.id,
					validityInDays: this.validityInDays,
					offer: this.offer,
					currency: this.currency,
					hoursNeededToComplete: this.hoursNeededToComplete,
					additionalInformation: this.additionalInformation
				};
				console.log(applicationPostDto);
				JobService.quickApply(applicationPostDto).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({
							name: "jobs"
						});
					}
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>