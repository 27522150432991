<template>
  <div>
    <nav class="navbar navbar-default" role="navigation">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>    
      </div>
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/logo.png" class="img img-responsives img-rounded" width="85" height="60">
      </router-link>
      <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav navbar-left">
          <li id="mainList">
            <router-link :to="{ name: 'login' }" v-if="loggedIn"><h4 class="text-danger">Dashboard</h4></router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'my-contract-jobs-configuration' }" v-if="loggedIn"><h4 class="text-danger">Hire a Pro</h4></router-link>
          </li>
        </ul>
        <ul class="nav navbar-nav navbar-center">
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li id="mainList">
            <router-link :to="{ name: 'login' }" v-if="!loggedIn" style="color: #000000;" data-toggle="collapse" data-target=".in">Login</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'full-time-job-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Full Time Jobs</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'volunteering-job-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Volunteering Jobs</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'tender-job-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Tender Jobs</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'professionals-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Skilled Experts</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'funding-opportunity-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Grants & Funding</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'product-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Products</router-link>
          </li>
          <li id="mainList">
            <router-link :to="{ name: 'delivery-service-home' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Delivery Services</router-link>
          </li>
          <li id="mainList" v-if="!loggedIn">
            <router-link :to="{ name: 'company-search' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Companies</router-link>
          </li>
          <li id="mainList" v-if="!loggedIn">
            <router-link :to="{ name: 'complianceManagementFeatures' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Compliance</router-link>
          </li>
          <li id="mainList" v-if="!loggedIn">
            <router-link :to="{ name: 'pricing' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Pricing</router-link>
          </li>
          <li id="mainList" v-if="loggedIn">
            <router-link :to="{ name: 'friendInvite' }" style="color: #000000;" data-toggle="collapse" data-target=".in">Invite Friend</router-link>
          </li>
          <li class="dropdown" v-if="loggedIn">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" style="color: blue;"><h4>{{ currentUser.username }} <span class="caret"></span></h4>
            </a>
            <ul class="dropdown-menu" role="menu">
              <li>
                <router-link :to="{ name: 'userProfile'}" style="color: #000000;"><img src="@/assets/images/user.png" height="20" width="20"> Account</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'company-profile'}" style="color: #000000;"><img src="@/assets/images/user.png" height="20" width="20"> Company Profile</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'my-subscription'}" style="color: #000000;"><img src="@/assets/images/subscription.png" height="20" width="20"> Subscription</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'my-subscription'}" style="color: #000000;"><img src="@/assets/images/privacyPolicy.png" height="20" width="20"> Help Center</router-link>
              </li>
              <li><a href="#" v-on:click="logOut" style="color: red"> <img src="@/assets/images/logout.png" height="20" width="20"> Sign Out</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script type="text/javascript">
  export default {
    data: function(){
      return {
        company: null,
        companyName: null
      }
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      currentUser() {
        return this.$store.state.auth.user.user;
      },

      getUser: function() {
          return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    mounted() {
      // if (!this.loggedIn) {
      //   this.$router.push("/login");
      // }
    },

    methods: {
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      }
    },
  };
</script>

<style type="text/css" scoped>
.navbar{
  background: #ffffff;
}
.navbar-brand {
    padding-top: 0; /* makes logo fit in navbar*/

}

.btn-circle {
  width: 85px;
  height: 35px;
  text-align: center;
  padding: 6px 0;
  line-height: 1.42;
  border-radius: 15px;
}

  @media(max-width: 768px){
    .navbar-brand img{
      width: 100px;
      max-height: 60px;
      /*padding-bottom: 20px;*/
      padding-bottom: 20px;
    }
  }
</style>
