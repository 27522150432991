<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>
      	<br>
      	<div class="panel">
      		<div class="panel-body">
      			<div class="row">
					<div class="col-md-10">
						<h4>Full Time Job List</h4>
					</div>	
					<router-link :to="{ name: 'new-full-time-job' }">
						<div class="col-md-2">
							<button class="btn btn-success btn btn-block">New Full Time Job <span class="glyphicon glyphicon-plus"></span></button>
						</div>
					</router-link>
				</div>
				<br>

				<table class="table table-responsive table-bordered">
					<tr class="bg-primary">
						<th>ID</th>
						<th>Title</th>
						<th>Remuneration/Salary</th>
						<th>Type</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
					<tbody>
						<tr v-for="job in jobs" :key="job.id">
							<td>{{ job.id }}</td>
							<td>{{ job.title }}</td>
							<td>{{ job.remunerationCurrency }} {{ job.remuneration }} per hour</td>
							<td>{{ job.type.replace('_', ' ') }}</td>
							<td><span class="label label-primary">{{ job.status }}</span></td>
							<td>
								<router-link :to="{ name: 'view-full-time-jobs-configuration', params: { jobId: job.id }}">
									<button class="btn btn-primary btn-sm">View <span class="glyphicon glyphicon-info-sign"></span></button>
								</router-link>&nbsp;
								<span v-if="job.status === 'PENDING'">
									<button class="btn btn-warning" type="button" @click="approve(job.id)">Approve</button>
								</span>&nbsp;
								<span v-if="job.status === 'APPROVED'">
									<router-link :to="{ name: 'full-time-job-applications', params: { jobId: job.id }}"><span class="badge">{{ job.applications.length }}</span> Applications</router-link>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
      		</div>
      	</div>
	</div>
</template>
<script type="text/javascript">
	import JobService from "@/services/JobService";
	import loading from "vue-full-loading";

	export default{
		name: "FullTimeJobConfiguration",
		components: {
			loading
		},

		data: function(){
			return {
				jobs: [],
				label: "Processing request",
				show: false,
			}
		},

		mounted(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				JobService.findAll().then(response => {
					this.jobs = response.data.body.filter(job => {
						return job.type === 'FULL_TIME'
					});
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			approve: function(id){
				this.show = true;
				JobService.approve(id).then(response => {
					if (response.data.statusCode === 201) {
						this.findAll();
					}
					this.show = false;
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>