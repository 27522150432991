<template>
	<div>
		<loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

		<div class="panel">
			<div class="panel-body">
				<h4>Delivery Service Lookup</h4>
				<hr>
				<div class="list-group">
					Products in the cart:
					<li class="list-group-item" v-for="cartProduct in cartProducts" :key="cartProduct.id">{{ cartProduct.name }}, {{ cartProduct.currency }}{{ cartProduct.price }}, <span class="text-danger">sold by {{ cartProduct.seller.name }}</span></li>
				</div>
				<button class="btn btn-danger" type="button" @click="calculateDeliveryCharge">Get delivery charge</button>
				<br><br>

				<table class="table table-hover table-bordered">
		          <tbody>
		          	<tr>
		              <th class="bg-success">Collection address</th>
		              <td>{{ collectionAddress !== null ? collectionAddress.physicalAddress: '' }}</td>
		            </tr>
		            <tr>
		              <th class="bg-success">Destination address</th>
		              <td>
		              	<span v-if="productDeliveryAddress !== null">
		              		{{ productDeliveryAddress !== null ? productDeliveryAddress.location.physicalAddress: '' }}		
		              	</span>
		              </td>
		            </tr>
		           <!--  <tr>
		              <th class="bg-success">Estimated distance from collection</th>
		              <td>{{ distanceFromDepotToFirstCollectionAddress / 1000 }} km</td>
		            </tr> -->
		            <!-- <tr>
		              <th class="bg-success">Route summary</th>
		              <td>via {{ summary }}</td>
		            </tr> -->
		            <!-- <tr>
		              <th class="bg-success">Estimated driving time after pickup</th>
		              <td>{{ duration.text }}</td>
		            </tr>	 -->	            
		            <tr>
		              <th class="bg-success">Copyrights</th>
		              <td>{{ copyrights }}</td>
		            </tr>
		          </tbody>
		        </table>
		        <!-- closestDeliveryServiceToPickupAddress: {{ closestDeliveryServiceToPickupAddress }} -->

		        <!-- dont show this to customer -->
		        <!-- <h4 v-if="closestDeliveryServiceToPickupAddress !== null">Closest delivery service to the collection address of the first product in the cart is <span class="text-danger">{{ closestDeliveryServiceToPickupAddress.deliveryServiceName }}, {{ closestDeliveryServiceToPickupAddress.distance.text }} away from collection address</span></h4> -->
			</div>
		</div>

		<!-- this.distanceFromDepotToFirstCollectionAddress: {{ this.distanceFromDepotToFirstCollectionAddress }} <br>  -->
		<!-- this.totalPickupDistance: {{ this.totalPickupDistance }} <br> -->
		<!-- this.distanceFromLastCollectionToDeliveryAddress: {{ this.distanceFromLastCollectionToDeliveryAddress }} -->

		<div class="panel" v-if="distanceFromLastCollectionToDeliveryAddress > 0">
			<div class="panel-body">
				<!-- <h4>Total Distance From Collection To Delivery: {{ totalDistanceFromCollectionToDelivery / 1000 }} km</h4> -->
				<h4>Delivery charge is ${{ parseFloat(deliveryCharge) }}</h4>
				<button class="btn btn-danger" @click="bookOrder" v-if="closestDeliveryServiceToPickupAddress !== null">Add to checkout</button>
			</div>
		</div>

		<!-- <div class="panel">
			<div class="panel-body">
				<button class="btn btn-primary" @click="getClosestDeliveryServiceToPickupAddress">Closest Delivery Service To Pickup Address</button>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<button class="btn btn-primary" @click="calculateDistanceFromDepotToFirstCollectionAddress">Distance from Depot to collection address</button>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<button class="btn btn-primary" @click="calculateDistanceFromCollectionToAllPickupAddresses">Distance from collection to pickup addresses</button>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<button class="btn btn-primary" @click="calculateDistanceFromLastCollectionToDeliveryAddress">Distance from last collection to delivery address</button>
			</div>
		</div>
		<div class="list-group" v-if="activeFuelPrice !== null && activeBasicDeliveryCharge !== null">
			<li class="list-group-item">Diesel price: {{ activeFuelPrice.currency }}{{ activeFuelPrice.dieselPrice }}</li>
			<li class="list-group-item">Petrol price: {{ activeFuelPrice.currency }}{{ activeFuelPrice.petrolPrice }}</li>
			<li class="list-group-item">VUE_APP_AVERAGE_KM_PER_LITER: {{ VUE_APP_AVERAGE_KM_PER_LITER }} km</li>
			<li class="list-group-item">Active Basic Delivery Charge: {{ activeBasicDeliveryCharge.currency }}{{ activeBasicDeliveryCharge.amount }}</li>
		</div> -->
	</div>
</template>

<script type="text/javascript">
	import CompanyService from "@/services/CompanyService";
	import OrderService from "@/services/OrderService";
	import loading from "vue-full-loading";
	import ProductDeliveryAddressService from "@/services/ProductDeliveryAddressService";
	import * as VueGoogleMaps from 'vue2-google-maps';
	import Order from "@/models/Order";
	import FuelPriceService from "@/services/FuelPriceService";
	import BasicDeliveryChargeService from "@/services/BasicDeliveryChargeService";

	export default {
		name: "CalculateDeliveryCharge",
		components: {
			loading
		},
		data: function(){
			return {
				companies: [],
				show: false,
				label: 'Processing request',
				productDeliveryAddress: null,
				deliveryServices: [],
				distance: '',
		      summary: '',
		      copyrights: '@google maps',
		      duration: '',
		      start_address: '',
		      end_address: '',
		      closestDeliveryServiceToPickupAddress: null,
		      deliveryServiceAddresses: [],
		      deliveryAddress: null,
		      collectionAddress: null,
		      cartProducts: [],
		      productOwnerCompany: null,

		      distanceFromDepotToFirstCollectionAddress: 0,
		      totalPickupDistance: 0, // of pickup addresses
		      distanceFromLastCollectionToDeliveryAddress: 0,
		      totalDistance: 0,
		      pickupAddresses: [],
		      order: new Order,
		      currency: 'USD',
		      activeFuelPrice: null,
		      VUE_APP_AVERAGE_KM_PER_LITER: process.env.VUE_APP_AVERAGE_KM_PER_LITER,
		      activeBasicDeliveryCharge: null,
			}
		},

		mounted: function(){
			this.findById();
			this.findActiveFuelPrice();
			this.findActiveBasicDeliveryCharge();
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts") || '[]');
			console.log('cartProducts: ', this.cartProducts);

			// get the first product in the product list
			let firstProductInCart = null;
			if (this.cartProducts !== null && this.cartProducts !== undefined && this.cartProducts.length > 0) {
				firstProductInCart = this.cartProducts[0];
			}
			this.collectionAddress = {
				lat: firstProductInCart.seller.company.physicalAddress.latitude,
				lng: firstProductInCart.seller.company.physicalAddress.longitude,
				physicalAddress: firstProductInCart.seller.company.physicalAddress.physicalAddress
			}

			let sellers = [];
			for(let i = 0; i < this.cartProducts.length; i++){
				sellers.push(this.cartProducts[i].seller);  // make sure is unique seller
			}

			for(let i = 0; i < sellers.length; i++){
				this.pickupAddresses.push({
					lat: sellers[i].company.physicalAddress.latitude,
					lng: sellers[i].company.physicalAddress.longitude,
					physicalAddress: sellers[i].company.physicalAddress.physicalAddress
				});
			}
		},

		computed: {
			google: VueGoogleMaps.gmapApi,

			getDeliveryAddressId(){
				return this.$route.params.deliveryAddressId;
			},

			totalDistanceFromCollectionToDelivery(){
				// now delivery charge should be the total distance from the first collection of the first product to the last product in cart, then add collection distance from their location their collection address
				let totalDistance = 0;
				if (this.distanceFromDepotToFirstCollectionAddress !== null && this.totalPickupDistance !== null && this.distanceFromLastCollectionToDeliveryAddress) {
					totalDistance = this.distanceFromDepotToFirstCollectionAddress + this.totalPickupDistance + this.distanceFromLastCollectionToDeliveryAddress;
				}
				return totalDistance;
			},

			deliveryCharge(){
				if (this.activeFuelPrice !== null) {
					// console.log('activeFuelPrice: ', this.activeFuelPrice);

					let petrolPrice = this.activeFuelPrice.petrolPrice;
					let dieselPrice = this.activeFuelPrice.dieselPrice;

					// console.log('petrolPrice: ', petrolPrice);
					// console.log('dieselPrice: ', dieselPrice);

					let averageFuelPricePerLiter = ((petrolPrice + dieselPrice) / 2);

					let VUE_APP_AVERAGE_KM_PER_LITER = 6.0; // should be configurable in admin side!

					let chargePerKm = averageFuelPricePerLiter;

					// convert active basic delivery charge into currency on products
					if (this.activeBasicDeliveryCharge !== null) {
						// add basic of $1.50 + delivery charge calculated

						// 1. Get global charge in USD
						// 2. calculate end delivery charge in localized currency converted rate => $$$
						return this.activeBasicDeliveryCharge.amount + ((this.totalDistanceFromCollectionToDelivery / 1000) / VUE_APP_AVERAGE_KM_PER_LITER) * chargePerKm;
					}else{
						return 0;
					}
				}else{
					return 0;
				}
			},

			getNetCurrency(){
				// convert the currency on products if USD apply exchange rates
				return "USD";
			},

			getUser(){
				return this.$store.state.auth.user.user;
			}
		},

		methods: {
			findActiveFuelPrice(){
				this.show = true;
				FuelPriceService.findActiveFuelPrice(1).then(response => {
					this.activeFuelPrice = response.data.body;
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findActiveBasicDeliveryCharge(){
				this.show = true;
				BasicDeliveryChargeService.findActiveBasicDeliveryCharge().then(response => {
					this.activeBasicDeliveryCharge = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			bookOrder(){
				this.show = true;
				// console.log('Test: ', this.closestDeliveryServiceToPickupAddress);
				this.order.awardedCarrierId = this.closestDeliveryServiceToPickupAddress.deliveryServiceId;
				this.order.deliveryAddressId = this.productDeliveryAddress.id;
				let collectionAddressList = []; // should get it from the multiple products and create a distict list
				collectionAddressList.push(this.collectionAddress);
				this.order.collectionAddresses = collectionAddressList;
				// why not the product owners and then we have enough detail for all this
				let cartProductList = [];
				for(let i = 0; i < this.cartProducts.length; i++){
					cartProductList.push({
						productNumber: this.cartProducts[i].productNumber
					});
				}
				this.order.cartProducts = cartProductList;
				this.order.customerUsername = this.getUser.username;
				this.order.deliveryCharge = this.deliveryCharge;
				this.order.currency = this.getNetCurrency; // aways in USD? or currency of products?
				console.log('order', this.order);

				localStorage.setItem('deliveryCharge', JSON.stringify(this.deliveryCharge));
				localStorage.setItem('deliveryChargeCurrency', JSON.stringify(this.getNetCurrency));
				
				OrderService.save(this.order).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'checkout' });
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

		    findByAssociatedUserUsername: function(username){
				this.show = true;
				CompanyService.findByAssociatedUserUsername(username).then(response => {
					this.productOwnerCompany = response.data.body;
					this.collectionAddress = {
						lat: this.productOwnerCompany.physicalAddress.latitude,
						lng: this.productOwnerCompany.physicalAddress.longitude,
						physicalAddress: this.productOwnerCompany.physicalAddress.physicalAddress
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

		    findById: function(){
				this.show = true;
				ProductDeliveryAddressService.findById(this.getDeliveryAddressId).then(response => {
					this.productDeliveryAddress = response.data.body;
					this.deliveryAddress = {
		    			lat: this.productDeliveryAddress.location.latitude,
		    			lng: this.productDeliveryAddress.location.longitude
		    		};
					if (this.productDeliveryAddress !== null) {
						this.findDeliveryServices(this.productDeliveryAddress.location.city.name);
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findDeliveryServices: function(cityName){
				this.show = true;
				CompanyService.findDeliveryServices(cityName).then(response => {
					this.deliveryServices = response.data.body;
					for (let i = 0; i < this.deliveryServices.length; i++) {
						if (this.deliveryServices[i].physicalAddress !== null) {
							this.deliveryServiceAddresses.push({
								deliveryServiceId: this.deliveryServices[i].id,
								deliveryServiceName: this.deliveryServices[i].name,
			    				lat: this.deliveryServices[i].physicalAddress.latitude !== null ? this.deliveryServices[i].physicalAddress.latitude: 0,
			    				lng: this.deliveryServices[i].physicalAddress.longitude !== null ? this.deliveryServices[i].physicalAddress.longitude: 0
							});
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			getClosestDeliveryServiceToPickupAddress(){
		    	this.show = true;
		    	this.$gmapApiPromiseLazy().then(() => {
		    		var self = this;
		    		let foundDeliveryServices = [];
		    		for(let i = 0; i < self.deliveryServiceAddresses.length; i++){
		    			var directionsService = new this.google.maps.DirectionsService();
			    		var start = new this.google.maps.LatLng(parseFloat(self.deliveryServiceAddresses[i].lat), parseFloat(self.deliveryServiceAddresses[i].lng));
			    		var end = new this.google.maps.LatLng(parseFloat(this.collectionAddress.lat), parseFloat(this.collectionAddress.lng));
			    		var bounds = new this.google.maps.LatLngBounds();
			    		bounds.extend(start);
			    		bounds.extend(end);

			    		var request = {
			    			origin: start,
			    			destination: end,
			    			travelMode: this.google.maps.TravelMode.DRIVING
			    		};
			    		var google = this.google;
			    		directionsService.route(request, function (response, status) {
			    			if (status === google.maps.DirectionsStatus.OK) {
			    				foundDeliveryServices.push({
			    					deliveryServiceId: self.deliveryServiceAddresses[i].deliveryServiceId,
			    					deliveryServiceName: self.deliveryServiceAddresses[i].deliveryServiceName,
			    					distance: response.routes[0].legs[0].distance,
			    					lat: self.deliveryServiceAddresses[i].lat,
			    					lng: self.deliveryServiceAddresses[i].lng,
			    				});

			    				self.closestDeliveryServiceToPickupAddress = foundDeliveryServices.reduce((object, result) => (object.distance.value < result.distance.value) ? object: result);
			    				self.show = false;
			    			}else{
			    				self.show = false;
			    			}
			    		});
		    		}
		    		// console.log('Yay method completed 1');
		    		//this.calculateDistanceFromDepotToFirstCollectionAddress();
		    	}).catch(error => {
		    		console.log(error);
		    		this.show = false;
		    	});
		    },

			calculateDistanceFromDepotToFirstCollectionAddress(){
		  		this.show = true;
		  		this.$gmapApiPromiseLazy().then(() => {
		  			// let collectionAddress = {
		  			// 	lat: -17.782,
		  			// 	lng: 31.0271
		  			// };

		  			// let depotAddress = {
		    	// 		lat: -17.7891,
		    	// 		lng: 30.9993
		    	// 	};
		  			var self = this;
		  			// console.log('Yay: ', self.closestDeliveryServiceToPickupAddress);
		  			var directionsService = new this.google.maps.DirectionsService();
		  			var start = new this.google.maps.LatLng(parseFloat(self.closestDeliveryServiceToPickupAddress.lat), parseFloat(self.closestDeliveryServiceToPickupAddress.lng));
		  			var end = new this.google.maps.LatLng(parseFloat(self.collectionAddress.lat), parseFloat(self.collectionAddress.lng));

		  			// console.log('Yebo: ', self.closestDeliveryServiceToPickupAddress);
		  			var bounds = new this.google.maps.LatLngBounds();
	    			bounds.extend(start);
	    			bounds.extend(end);

		  			var request = {
		  				origin: start,
		  				destination: end,
		  				travelMode: this.google.maps.TravelMode.DRIVING
		  			};
		  			var google = this.google;
		  			directionsService.route(request, function(response, status){
		  				if (status === google.maps.DirectionsStatus.OK) {
		  					self.distanceFromDepotToFirstCollectionAddress = response.routes[0].legs[0].distance.value;
		  					// console.log('distance from depot To First Collection Address: ', self.distanceFromDepotToFirstCollectionAddress);
		  				}
		  			});
		  			// console.log('Yay method completed 2');
		  			// this.calculateDistanceFromCollectionToAllPickupAddresses();
		  		}).catch(error => {
		  			console.log(error);
		  		});
		  		this.show = false;
			},

			calculateDistanceFromCollectionToAllPickupAddresses(){
				// let pickupAddresses = [
	   //  			{
	   //  				// Mabelreign
	   //  				lat: -17.787,
	   //  				lng: 31.0049
	   //  			},

	   //  			{
	   //  				// Avondale
	   //  				lat: -17.7969,
	   //  				lng: 31.0335
	   //  			},

	   //  			{
	   //  				// Avondale West
	   //  				lat: -17.782,
	   //  				lng: 31.0271
	   //  			},
	   //  		];
	   			// console.log('pickupAddresses: ', this.pickupAddresses);
	    		this.$gmapApiPromiseLazy().then(() => {
	    			var self = this;
	    			var directionsService = new this.google.maps.DirectionsService();
	    			for(let i = 0; i < self.pickupAddresses.length - 1; i++){
		    			var start = new this.google.maps.LatLng(parseFloat(self.pickupAddresses[i].lat), parseFloat(self.pickupAddresses[i].lng));
		    			var end = new this.google.maps.LatLng(parseFloat(self.pickupAddresses[i + 1].lat), parseFloat(self.pickupAddresses[i + 1].lng));

		    			var request = {
		    				origin: start,
		    				destination: end,
		    				travelMode: this.google.maps.TravelMode.DRIVING
		    			};

		    			var bounds = new this.google.maps.LatLngBounds();
		    			bounds.extend(start);
		    			bounds.extend(end);

		    			var google = this.google;
		    			var accumulatorList = [];
		    			directionsService.route(request, function(response, status){
		    				if (status === google.maps.DirectionsStatus.OK) {
		    					accumulatorList.push({
		    						text: response.routes[0].legs[0].distance.text,
		    						value: response.routes[0].legs[0].distance.value
		    					});
		    					// console.log(accumulatorList);
		    					// self.totalPickupDistance = accumulatorList.reduce((a, b) => a.value + b.value);
		    					// console.log('totalPickupDistance: ', self.totalPickupDistance);

		    					// const sum = accumulatorList.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
		    					// console.log('sum: ', sum);

		    					self.totalPickupDistance = accumulatorList.map(item => item.value).reduce((prev, curr) => prev + curr, 0);
		    					// console.log('totalPickupDistance: ', self.totalPickupDistance);
		    				}
		    			});
	    			}
	    			// console.log('Yay method completed 3');
	    			// this.calculateDistanceFromLastCollectionToDeliveryAddress();
	    		}).catch(error => {
	    			console.log(error);
	    		});
			},

			calculateDistanceFromLastCollectionToDeliveryAddress(){
				// let pickupAddresses = [
	   //  			{
	   //  				// Mabelreign
	   //  				lat: -17.787,
	   //  				lng: 31.0049
	   //  			},

	   //  			{
	   //  				// Avondale
	   //  				lat: -17.7969,
	   //  				lng: 31.0335
	   //  			},

	   //  			{
	   //  				// Avondale West
	   //  				lat: -17.782,
	   //  				lng: 31.0271
	   //  			},
	   //  		];
	    		this.$gmapApiPromiseLazy().then(() => {
	    			var self = this;
	    			var directionsService = new this.google.maps.DirectionsService();
	    			var start = new this.google.maps.LatLng(parseFloat(self.pickupAddresses[self.pickupAddresses.length - 1].lat), parseFloat(self.pickupAddresses[self.pickupAddresses.length - 1].lng));
	    			var end = new this.google.maps.LatLng(parseFloat(self.deliveryAddress.lat), parseFloat(self.deliveryAddress.lng));

	    			var request = {
	    				origin: start,
	    				destination: end,
	    				travelMode: this.google.maps.TravelMode.DRIVING
	    			};

	    			var bounds = new this.google.maps.LatLngBounds();
	    			bounds.extend(start);
	    			bounds.extend(end);

	    			var google = this.google;
	    			directionsService.route(request, function(response, status){
	    				if (status === google.maps.DirectionsStatus.OK) {
	    					self.distanceFromLastCollectionToDeliveryAddress = response.routes[0].legs[0].distance.value;
	    					// console.log('distanceFromLastCollectionToDeliveryAddress: ', self.distanceFromLastCollectionToDeliveryAddress);
	    				}
	    			});
	    			// console.log('Yay method completed 4');
	    		}).catch(error => {
	    			console.log(error);
	    		});
			},

			calculateDeliveryCharge(){
				this.show = true;
				this.getClosestDeliveryServiceToPickupAddress();
				this.calculateDistanceFromDepotToFirstCollectionAddress();
				this.calculateDistanceFromCollectionToAllPickupAddresses();
				this.calculateDistanceFromLastCollectionToDeliveryAddress();
				this.show = false;
			},

			calculateDeliveryCharge2(){
				this.show = true;
		    	this.$gmapApiPromiseLazy().then(() => {
		    		// getClosestDeliveryServiceToPickupAddress
		    		var self = this;
		    		var directionsService = new this.google.maps.DirectionsService();
		    		let foundDeliveryServices = [];
		    		for(let i = 0; i < self.deliveryServiceAddresses.length; i++){
			    		var start1 = new this.google.maps.LatLng(parseFloat(self.deliveryServiceAddresses[i].lat), parseFloat(self.deliveryServiceAddresses[i].lng));
			    		var end1 = new this.google.maps.LatLng(parseFloat(this.collectionAddress.lat), parseFloat(this.collectionAddress.lng));
			    		var bounds1 = new this.google.maps.LatLngBounds();
			    		bounds1.extend(start1);
			    		bounds1.extend(end1);

			    		var request1 = {
			    			origin: start1,
			    			destination: end1,
			    			travelMode: this.google.maps.TravelMode.DRIVING
			    		};
			    		let google = this.google;
			    		directionsService.route(request1, function (response, status) {
			    			if (status === google.maps.DirectionsStatus.OK) {
			    				foundDeliveryServices.push({
			    					deliveryServiceName: self.deliveryServiceAddresses[i].deliveryServiceName,
			    					distance: response.routes[0].legs[0].distance,
			    					lat: self.deliveryServiceAddresses[i].lat,
			    					lng: self.deliveryServiceAddresses[i].lng,
			    				});

			    				self.closestDeliveryServiceToPickupAddress = foundDeliveryServices.reduce((object, result) => (object.distance.value < result.distance.value) ? object: result);
			    				self.show = false;
			    			}else{
			    				self.show = false;
			    			}
			    		});
		    		}

		    		// calculateDistanceFromDepotToFirstCollectionAddress
		  			// console.log('Yay: ', self.closestDeliveryServiceToPickupAddress);
		  			var start2 = new this.google.maps.LatLng(parseFloat(self.closestDeliveryServiceToPickupAddress.lat), parseFloat(self.closestDeliveryServiceToPickupAddress.lng));
		  			var end2 = new this.google.maps.LatLng(parseFloat(self.collectionAddress.lat), parseFloat(self.collectionAddress.lng));

		  			// console.log('Yebo: ', self.closestDeliveryServiceToPickupAddress);
		  			var bounds2 = new this.google.maps.LatLngBounds();
	    			bounds2.extend(start2);
	    			bounds2.extend(end2);

		  			var request2 = {
		  				origin: start2,
		  				destination: end2,
		  				travelMode: this.google.maps.TravelMode.DRIVING
		  			};
		  			let google = this.google;
		  			directionsService.route(request2, function(response, status){
		  				if (status === google.maps.DirectionsStatus.OK) {
		  					self.distanceFromDepotToFirstCollectionAddress = response.routes[0].legs[0].distance.value;
		  					// console.log('distance from depot To First Collection Address: ', self.distanceFromDepotToFirstCollectionAddress);
		  				}
		  			});

		  			// calculateDistanceFromCollectionToAllPickupAddresses
		  			
	    			for(let i = 0; i < self.pickupAddresses.length - 1; i++){
		    			var start3 = new this.google.maps.LatLng(parseFloat(self.pickupAddresses[i].lat), parseFloat(self.pickupAddresses[i].lng));
		    			var end3 = new this.google.maps.LatLng(parseFloat(self.pickupAddresses[i + 1].lat), parseFloat(self.pickupAddresses[i + 1].lng));

		    			var request3 = {
		    				origin: start3,
		    				destination: end3,
		    				travelMode: this.google.maps.TravelMode.DRIVING
		    			};

		    			var bounds3 = new this.google.maps.LatLngBounds();
		    			bounds3.extend(start3);
		    			bounds3.extend(end3);

		    			let google = this.google;
		    			var accumulatorList = [];
		    			directionsService.route(request3, function(response, status){
		    				if (status === google.maps.DirectionsStatus.OK) {
		    					accumulatorList.push({
		    						text: response.routes[0].legs[0].distance.text,
		    						value: response.routes[0].legs[0].distance.value
		    					});
		    					console.log(accumulatorList);
		    					self.totalPickupDistance = accumulatorList.reduce((a, b) => a.value + b.value);
		    					// console.log('totalPickupDistance: ', self.totalPickupDistance);
		    				}
		    			});
	    			}

	    			// calculateDistanceFromLastCollectionToDeliveryAddress
	    			var start4 = new this.google.maps.LatLng(parseFloat(self.pickupAddresses[self.pickupAddresses.length - 1].lat), parseFloat(self.pickupAddresses[self.pickupAddresses.length - 1].lng));
	    			var end4 = new this.google.maps.LatLng(parseFloat(self.deliveryAddress.lat), parseFloat(self.deliveryAddress.lng));

	    			var request4 = {
	    				origin: start4,
	    				destination: end4,
	    				travelMode: this.google.maps.TravelMode.DRIVING
	    			};

	    			var bounds4 = new this.google.maps.LatLngBounds();
	    			bounds4.extend(start4);
	    			bounds4.extend(end4);

	    			// let google = this.google;
	    			directionsService.route(request4, function(response, status){
	    				if (status === google.maps.DirectionsStatus.OK) {
	    					self.distanceFromLastCollectionToDeliveryAddress = response.routes[0].legs[0].distance.value;
	    					// console.log('distanceFromLastCollectionToDeliveryAddress: ', self.distanceFromLastCollectionToDeliveryAddress);
	    				}
	    			});
		    	}).catch(error => {
		    		console.log(error);
		    		this.show = false;
		    	});
			}
		}
	};
</script>
