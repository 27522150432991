<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <div class="col-md-10"></div>
      <div class="col-md-2">
        <router-link :to="{ name: 'company-profile' }">
          <button class="btn btn-default pull-right">Cancel</button>
        </router-link>
      </div>
    </div>
    
    <p class="error-message" v-if="message !== null">{{ message }}</p>
    <form class="form" @submit.prevent="save">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="organizationType">Business Type</label>
            <select v-model="company.organizationType" class="form-control">
              <option value="" disabled>Select Organization Type</option>
              <option value="PRIVATE_LIMITED">Private Limited Company</option>
              <option value="SOLE_PROPRIETORSHIP">Sole Proprietorship</option>
              <option value="PARTNERSHIP">Partnership</option>
             <option value="PUBLIC_LIMITED">Public Limited Company</option>
             <option value="COOPERATIVES">Cooperatives</option>
           </select>
         </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="name">Company Name</label>
            <input type="text" v-model="company.name" id="name" class="form-control" placeholder="Enter company name" required/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="tradingName">Trading Name</label>
            <input type="text" v-model="company.tradingName" id="tradingName" class="form-control" placeholder="Enter trading name e.g ABC or XYZ etc"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="shortName">Business Partner (Registration) Number</label>
            <input type="text" v-model="company.businessPartnerNumber" id="businessPartnerNumber" class="form-control" placeholder="Enter business partner number"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" v-model="company.email" id="email" class="form-control" placeholder="Enter business email"/>
          </div>
        </div>
        <div class="col-md-3">
           <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="websiteUrl">Website Url</label>
            <input type="text" v-model="company.websiteUrl" id="websiteUrl" class="form-control" placeholder="Enter company website"/>
            <div class="error-message">
              <span v-for="error in errors" v-bind:key="error.websiteUrl">{{
                error.websiteUrl
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Business Sector</label>
            <select v-model="company.businessSectorId" id="businessSector" class="form-control" v-on:change="findByBusinessSectorId">
              <option value="">Select option</option>
              <option v-for="businessSector in businessSectors" v-bind:key="businessSector.id" :value="businessSector.id">{{ businessSector.name }}</option>
            </select>
          </div>
        </div>
      </div>

      <button class="btn btn-success btn-sm pull-right" type="button" @click="addPhoneNumberField">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
      <div v-if="phoneNumberFields.length > 0">
        <div v-for="(phoneNumberField, phoneNumberIndex) in phoneNumberFields" :key="phoneNumberField.id">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="phoneNumbers">Country Code</label>
                <select class="form-control" v-model="phoneNumberField.zipCodeId" required>
                  <option value="" disabled>Select option</option>
                  <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="phoneNumbers">Business Phone Number</label>
                <input type="number" name="phoneNumber" id="phoneNumber" class="form-control" v-model="phoneNumberField.phoneNumber" minlength="6" maxlength="9" placeholder="Enter phone number e.g 77123456789" required>
              </div>              
            </div>
            <div class="col-md-2">
              <div class="form-group" v-show="phoneNumberFields.length > 1">
                <label>&nbsp;</label><br>
                <button class="btn btn-danger btn-sm pull-left" type="button" @click="phoneNumberFields.splice(phoneNumberIndex, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
              </div>
            </div>
          </div>          
        </div>
      </div>

      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <div class="form-group">
            <label>&nbsp;</label>
            <button class="btn btn-success btn-block" type="sumbit">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import BusinessSectorService from "@/services/BusinessSectorService.js";
  import BusinessCategoryService from "@/services/BusinessCategoryService.js";
  import CompanyService from "@/services/CompanyService.js";
  import Company from "@/models/Company.js";
  import loading from "vue-full-loading";
  import ZipCodeService from "@/services/ZipCodeService.js";

  export default {
    name: "NewProposal",
    components: {
      loading
    },
    data: function() {
      return {
        businessSectors: [],
        businessCategoryList: [],
        businessSector: null,
        businessCategory: null,
        company: new Company,
        show: false,
        label: "Processing request",
        errors: null,
        companyId: null,
        businessCategories: [],
        errorMessage: null,
        phoneNumberFields: [{
          zipCodeId: null,
          phoneNumber: null
        }],
        zipCodes: [],
        message: null
      };
    },

    created() {
      this.getAllBusinessSectors();
      this.getZipCodes();
    },

    methods: {
      getZipCodes: function() {
        return ZipCodeService.findAll().then(response => {
          this.zipCodes = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      addPhoneNumberField: function(){
        this.phoneNumberFields.push({
          zipCodeId: null,
          phoneNumber: null
        });
      },

      getAllBusinessSectors: function() {
        return BusinessSectorService.findAll()
          .then(response => {
            this.businessSectors = response.data.body;
          })
          .catch(error => {
            console.log(error);
          });
      },

      findByBusinessSectorId: function() {
        return BusinessCategoryService.findByBusinessSectorId(this.company.businessSectorId).then(response => {
          this.businessCategoryList = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.company.companyType = "COMPANY";
        this.company.phoneNumbers = this.phoneNumberFields;
        this.company.businessCategories = this.businessCategories;
        var businessCategoriesList = [];
        for (var i = 0; i < this.company.businessCategories.length; i++) {
          businessCategoriesList.push({
            businessCategoryId: this.company.businessCategories[i]
          });
        }
        this.company.businessCategories = businessCategoriesList;
        this.company.associatedUserUsername = this.getUser.username;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode == 201) {
            this.companyId = response.data.body.id;
            this.$router.push({ name: "new-company-address", params: { uuid: response.data.body.uuid }});
          }
        this.show = false; 
        }).catch(error => {
          console.log(error.response);
          this.show = false;
          this.message = error.response.data.message;
        });
      }
    },

    computed: {
      years: function() {
        const year = new Date().getFullYear();
        const years = Array.from(
          { length: year - 1900 },
          (value, index) => 1901 + index
        );
        return years;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      }
    }
  };
</script>
