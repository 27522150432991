import { API_URL } from "@/config/api.js";
import axios from "axios";
import authHeader from "@/services/authHeader";

class BankBranchService {
  getAllBankBranches(bankId) {
    return axios.get(API_URL + "branches/findAllByBankId/" + bankId);
  }

  findByIdAndBankId(branchId, bankId) {
    return axios.get(
      API_URL + "branches/findByIdAndBankId" + branchId + "/" + bankId
    );
  }

  createBranch(branch) {
    return axios.post(API_URL + "branches", {
      bankId: branch.bankId,
      branchName: branch.branchName
    });
  }
  updateBranch(branch, bankId) {
    return axios.put(
      API_URL + "updateBranch/" + bankId,
      {
        id: branch.id,
        bankId: branch.bankId,
        branchName: branch.branchName
      },
      { headers: authHeader() }
    );
  }
}

export default new BankBranchService();
