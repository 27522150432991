<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

        <router-link :to="{ name: 'userProfile' }" replace
          ><button class="btn btn-primary">
            <span class="glyphicon glyphicon-step-backward"></span> Back
          </button></router-link
        >

        <form class="form" @submit.prevent="updateUser">
          <p class="error-message text-center">{{ errorMessage }}</p>
          <h4>Edit account profile</h4>

          <div class="form-group">
            <label>First Name</label>
            <input
              type="text"
              v-model="user.firstName"
              class="form-control"
              placeholder="Enter First Name"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <label>Last Name</label>
            <input
              type="text"
              v-model="user.lastName"
              class="form-control"
              placeholder="Enter Last Name"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <label>Username</label>
            <input
              type="text"
              v-model="user.username"
              class="form-control"
              placeholder="Enter Username"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <label>Email</label>
            <input
              class="form-control"
              v-model="user.email"
              placeholder="Enter Email"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <button
              class="btn btn-success pull-right"
              type="button"
              @click="addPhoneNumberFields"
            >
              <span class="fa fa-plus"></span>
            </button>
          </div>
          <br />

          <div
            class="form-group"
            v-for="(phoneNumber, index) in phoneNumbers"
            :key="phoneNumber.phoneNumber"
          >
            <label for="phoneNumber"
              >Phone Number {{ index + 1 }}
              <span class="text-danger">* </span></label
            >
            <input
              type="text"
              v-model="phoneNumber.phoneNumber"
              id="phoneNumber"
              class="form-control border-info"
              placeholder="Enter First Phone Number"
            />
          </div>

          <div class="form-group" v-if="phoneNumbers.length !== 0">
            <button
              class="btn btn-danger pull-right"
              type="button"
              @click="phoneNumbers.splice(index, 1)"
            >
              <span class="fa fa-minus"></span>
            </button>
          </div>
          <br />

          <div class="form-group">
            <label>Change password</label><br />
            <input type="checkbox" v-model="changePassword" />
          </div>

          <div class="form-group" v-show="changePassword">
            <label>New Password</label>
            <input
              class="form-control"
              v-model="user.password"
              placeholder="Enter new Password"
              type="password"
              autocomplete="on"
            />
          </div>

          <div class="form-group" v-show="changePassword">
            <label>Comfirm New Password</label>
            <input
              class="form-control"
              v-model="user.confirmPassword"
              placeholder="Confirm  new password"
              type="password"
              autocomplete="on"
            />
          </div>

          <div class="form-group">
            <label>Gender</label>
            <select v-model="user.genderId" class="form-control">
              <option value="">Select gender</option>
              <option
                v-for="gender in genders"
                v-bind:key="gender.id"
                :value="gender.id"
                >{{ gender.name }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <label>Salutation</label>
            <select v-model="user.salutationId" class="form-control">
              <option value="">Select salutation</option>
              <option
                v-for="salutation in salutations"
                v-bind:key="salutation.id"
                :value="salutation.id"
                >{{ salutation.name }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <label>Occupation</label>
            <input
              class="form-control"
              v-model="user.occupation"
              placeholder="Enter occupation or job title"
              type="text"
              autocomplete="on"
            />
          </div>

          <!-- <div class="form-group">
            <label>Roles</label>
            <select class="form-control" multiple v-model="roles" required>
              <option value="" disabled>Select roles</option>
              <option v-for="role in allRoles" :key="role.id" :value="role.name">{{ role.name }}</option>
            </select>
        </div> -->

          <div class="form-group">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="form-group">
                  <label>Digital Signature</label>
                  <input
                    class="form-control"
                    type="file"
                    v-on:change="selectDigitalSignature"
                  />
                </div>
              </li>
            </ul>
          </div>

          <div class="form-group">
            <label>Account Status</label>
            <select v-model="user.enabled" class="form-control">
              <option value="">Select active</option>
              <option value="true">Active</option>
              <!-- maybe user wants to deactivate it when going on leave and will be reactivated by the admin when they come back -->
              <option value="false">Deactivated</option>
            </select>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-6">
                <router-link :to="{ name: 'users' }">
                  <button
                    color="success"
                    class="btn btn-primary form-control"
                    type="button"
                    block
                  >
                    Back
                  </button>
                </router-link>
              </div>
              <div class="col-md-6">
                <button
                  color="success"
                  class="btn btn-warning form-control"
                  type="submit"
                  block
                >
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import User from "@/models/User.js";
import UserService from "@/services/UserService.js";
import GenderService from "@/services/GenderService.js";
import SalutationService from "@/services/SalutationService.js";
import RoleService from "@/services/RoleService.js";
import loading from "vue-full-loading";

export default {
  name: "Register",
  components: {
    loading
  },

  data: function() {
    return {
      show: false,
      label: "Updating user account. Please wait...",
      user: new User(),
      errorMessage: null,
      roles: [],
      changePassword: false,
      genders: [],
      salutations: [],
      allRoles: [],
      phoneNumbers: [
        {
          phoneNumber: null
        }
      ],
      formData: new FormData()
    };
  },

  computed: {
    getEmail: function() {
      return this.$route.params.userEmail;
    },

    getUser: function() {
      return JSON.parse(localStorage.getItem("user"));
    }
  },

  created: function() {
    this.findAllGenders();
    this.findAllSalutations();
    this.findAllRoles();
    this.findByEmail();
  },

  methods: {
    selectDigitalSignature: function(event) {
      this.formData.append("digitalSignature", event.target.files[0]);
    },

    addPhoneNumberFields: function() {
      this.phoneNumbers.push({
        phoneNumber: null
      });
    },

    findByEmail: function() {
      UserService.findByEmail(this.getUser.user.email)
        .then(response => {
          this.user = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllGenders: function() {
      GenderService.findAll()
        .then(response => {
          this.genders = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllSalutations: function() {
      SalutationService.findAll()
        .then(response => {
          this.salutations = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findAllRoles: function() {
      RoleService.findAll()
        .then(response => {
          this.allRoles = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateUser: function() {
      this.user.phoneNumbers = this.phoneNumbers;

      if (this.user.password !== null && this.user.confirmPassword !== null) {
        this.show = false;
        if (this.user.password !== this.user.confirmPassword) {
          this.errorMessage =
            "Passwords do not match. Please make sure passwords are the same.";
        }
      }

      this.user.roles = this.roles;
      this.show = true;
      UserService.updateUser(this.user)
        .then(response => {
          this.show = false;
          if (response.data.statusCode === 204) {
            this.$router.push("/users/profile");
          }
          if (response.data.statusCode === 422) {
            this.errorMessage = response.data.message;
            console.log("errorMessage: ", this.errorMessage);
          }
          if (response.data.statusCode === 400) {
            this.errorMessage = response.data.message;
            console.log("errorMessage: ", this.errorMessage);
          }
          if (response.data.statusCode === 500) {
            console.log(
              "Problem updating user account. Please contact your administrator for support if Problem persists!"
            );
          }
        })
        .catch(error => {
          this.show = false;
          console.log(error);
        });
    }
  }
};
</script>

<style type="text/css">
.error-message {
  color: red;
}
</style>
