<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

        <router-link :to="{ name: 'countries' }">
          <button class="btn btn-primary">
            <span class="fa fa-step-backward"></span> Back
          </button> </router-link
        ><br /><br />

        <form class="form" @submit.prevent="updateById">
          <div class="form-group">
            <label>Zip Code</label>
            <select class="form-control" v-model="country.zipCodeId" required>
              <option value="">Select Zip Code</option>
              <option
                v-for="zipCode in zipCodes"
                :key="zipCode.id"
                :value="zipCode.id"
                >+{{ zipCode.code }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <label for="code">Name</label>
            <input
              type="text"
              v-model="country.name"
              class="form-control"
              placeholder="Enter Country Name"
            />
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input
              type="text"
              v-model="country.description"
              class="form-control"
              placeholder="Enter Description"
            />
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="country.status">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="form-group">
            <label for="submit">&nbsp;</label>
            <button class="btn btn-warning" type="submit">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import CountryService from "@/services/CountryService";
import ZipCodeService from "@/services/ZipCodeService";
import Country from "@/models/Country";
import loading from "vue-full-loading";

export default {
  name: "ViewZipCode",
  components: {
    loading
  },

  data: function() {
    return {
      show: false,
      label: "Processing...",
      country: new Country(),
      zipCodes: []
    };
  },

  computed: {
    getCountryId() {
      return this.$route.params.countryId;
    }
  },

  created: function() {
    this.findById();
    this.findAllZipCodes();
  },

  methods: {
    findAllZipCodes: function() {
      ZipCodeService.findAll()
        .then(response => {
          this.zipCodes = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findById: function() {
      CountryService.findById(this.getCountryId)
        .then(response => {
          this.country = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },

    updateById: function() {
      this.showLoader();
      CountryService.updateById(this.country, this.getCountryId)
        .then(response => {
          this.cancelLoader();
          if (response.data.statusCode == 422) {
            this.setNotificationData(response);
          }
          if (response.data.statusCode == 204) {
            this.clearNotificationData();
            this.$router.push({ name: "countries" });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // make these functions global :: reuse, via the app instance
    clearNotificationData: function() {
      this.errors = null;
      this.errorMessage = null;
    },

    setNotificationData: function(response) {
      this.errors = response.data.errors;
      this.errorMessage = response.data.message;
    },

    cancelLoader: function() {
      this.show = false;
    },

    showLoader: function() {
      this.show = true;
    }
  }
};
</script>

<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
