export default class Truck{
	constructor(id, carrierUsername, truckNumber, registrationNumber, type, regularPickUpAddress, 
		regularDropOffAddress, recurringType, make, model, year, vehicleIdentificationNumber,
		ownershipType, inServiceDate, photos, trailerNumber, maxHeightClearance, maxHeightClearanceMeasurement,
		grossVehicleMass, grossVehicleMassMeasurement, netVehicleMass, netVehicleMassMeasurement, 
		maxLoadSize, maxLoadSizeMeasurement, description, trailerType, loadMode, desiredRate, currency, desiredRateMeasurement,
		length, lengthMeasurement){
		this.id = id;
		this.carrierUsername = carrierUsername;
		this.truckNumber = truckNumber;
		this.type = type;
		this.registrationNumber = registrationNumber;
		this.regularPickUpAddress = regularPickUpAddress;
		this.regularDropOffAddress = regularDropOffAddress;
		this.recurringType = recurringType;
		this.make = make;
		this.model = model;
		this.year = year;
		this.vehicleIdentificationNumber = vehicleIdentificationNumber;
		this.ownershipType = ownershipType;
		this.inServiceDate = inServiceDate;
		this.photos = photos;
		this.trailerNumber = trailerNumber;
		this.maxHeightClearance = maxHeightClearance;
		this.maxHeightClearanceMeasurement = maxHeightClearanceMeasurement;
		this.grossVehicleMass = grossVehicleMass;
		this.grossVehicleMassMeasurement = grossVehicleMassMeasurement;
		this.netVehicleMass = netVehicleMass;
		this.netVehicleMassMeasurement = netVehicleMassMeasurement;
		this.maxLoadSize = maxLoadSize;
		this.maxLoadSizeMeasurement = maxLoadSizeMeasurement;
		this.description = description;
		this.trailerType = trailerType;
		this.loadMode = loadMode;
		this.desiredRate = desiredRate;
		this.currency = currency;
		this.desiredRateMeasurement = desiredRateMeasurement;
		this.desiredRateMeasurement = desiredRateMeasurement;
		this.length = length;
		this.lengthMeasurement = lengthMeasurement;
	}
}