import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CountryService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "countries");
  }

  findById(id) {
    return axios.get(
      GENERIC_SERVICE_URL + "countries/" + id
    );
  }

  findByZipCodeId(zipCodeId) {
    return axios.get(
      GENERIC_SERVICE_URL + "countries/findByZipCodeId/" + zipCodeId
    );
  }

  save(country) {
    return axios.post(GENERIC_SERVICE_URL + "countries", {
      zipCodeId: country.zipCodeId,
      name: country.name,
      description: country.description,
      status: country.status
    });
  }
}

export default new CountryService();
