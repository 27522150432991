<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Tender Jobs</h4>	
					</div>
					<router-link :to="{ name: 'new-tender-job' }">
						<div class="col-md-2">
							<button class="btn btn-success pull-right">New Tender Job <span class="glyphicon glyphicon-plus"></span></button>
						</div>
					</router-link>
				</div>
				<br>
				<table class="table table-responsive table-bordered">
					<tr class="bg-primary">
						<th>ID</th>
						<th>Title</th>
						<th>Fixed Rate</th>
						<th>Type</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
					<tbody>
						<tr v-for="job in jobs" :key="job.id">
							<td>{{ job.id }}</td>
							<td>{{ job.title }}</td>
							<td>{{ job.currency }} {{ job.fixedRate }} per hour</td>
							<td>{{ job.type }}</td>
							<td>
								<span class="label label-success" v-if="job.status === 'ACTIVE'">{{ job.status }}</span>
								<span class="label label-primary" v-if="job.status === 'INACTIVE'">{{ job.status }}</span>
							</td>
							<td>
								<router-link :to="{ name: 'view-tender-jobs-configuration', params: { jobId: job.id }}">
									<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";

	export default{
		name: "TenderJobsConfiguration",
		component: {

		},

		data: function(){
			return {
				jobs: []
			}
		},

		created: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				JobService.findAll().then(response => {
					this.jobs = response.data.body.filter(job => {
						return job.type === 'TENDER';
					});
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	};
</script>