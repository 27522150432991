<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div v-if="!getRoles.includes('SUPER_ADMIN') || !getRoles.includes('ADMIN')">
      		<h4>Quick apply</h4>
			<hr>
			<div v-if="!userAlreadyAppliedJob">
				<div v-if="job !== null">
					<div v-if="job.internalOrExternal !== 'EXTERNAL'">
						<form class="form" @submit.prevent="submitApplication">
							<p class="text-danger">You are applying for the job of {{ job.title }} posted on {{ job.createdAt }}</p>
							<h4>We'll attach your professional profile with this aplication.</h4>
							<br>
							<p>Keep an eye on your next gig!</p>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success pull-right">Submit Application</button>
							</div>
						</form>
					</div>
					<div v-else>
						<h4>This job is an external job and you can apply it outside SkilledBioz.</h4>
						<p>Link to apply or email address: <a :href="job.externalLink">{{ job.externalLink }}</a></p>
					</div>
				</div>
			</div>
			<div v-else>
				<h4>You have already applied for this job. <router-link :to="{ name: 'full-time-job-search' }">Search for more jobs here</router-link></h4>
			</div>
      	</div>
      	<div v-else>
      		<h4 class="text-danger">You are not authorized to apply for this job! <router-link :to="{ name: 'full-time-job-search' }">Search for more jobs here</router-link></h4>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService.js";
	import loading from "vue-full-loading";

	export default{
		name: "NewFullTimeJobApplication",
		components: {
			loading
		},

		data: function(){
			return {
				job: null,
				show: false,
				label: "Processing request",
				userAlreadyAppliedJob: null
			}
		},

		created: function(){
			this.findById();
			this.checkUserAlreadyAppliedJob();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user.user;
			},

			getJobId: function(){
				return this.$route.params.jobId;
			},

			getRoles: function() {
		        let roles = [];
		        for (var i = 0; i < this.getUser.authorities.length; i++) {
		        	roles.push(this.getUser.authorities[i].authority);
		        }
		        return roles;
		    },
		},

		methods: {
			findById: function(){
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			checkUserAlreadyAppliedJob: function(){
				JobService.checkUserAlreadyAppliedJob(this.getJobId, this.getUser.username).then(response => {
					this.userAlreadyAppliedJob = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			submitApplication: function(){
				this.show = true;
				JobService.submitApplication(this.getJobId, this.getUser.username).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({
							name: "full-time-jobs"
						});
					}
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},
		}
	};
</script>