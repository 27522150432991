import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class SchoolService{
	findAll(){
		return axios.get(GENERIC_SERVICE_URL + "schools");
	}

	findById(id){
		return axios.get(GENERIC_SERVICE_URL + "schools/" + id);
	}

	save(school){
		return axios.post(GENERIC_SERVICE_URL + "schools", {
			schoolId: school.schoolId,
			description: school.description,
			status: school.status,
			name: school.name
		});
	}
}

export default new SchoolService;