<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>
        
    <marquee v-if="carouselPromotions.length > 0">
      <div class="panel">
        <div class="panel-body">
          <span v-for="carouselPromotion in carouselPromotions" :key="carouselPromotion.id">
            <!-- carouselPromotion.link: {{ carouselPromotion.link }} -->
            <!-- <router-link :to="{ name: carouselPromotion.link }"> -->
              <a :href="carouselPromotion.link" target="_blank">
                <div class="col-md-2">
                  <p>{{ carouselPromotion.headline }}</p>
                  <p>{{ carouselPromotion.description }}</p>
                  <p><img :src="PDF_API_URL + 'pdf/getCarouselPromo/' + carouselPromotion.id" width="120" height="60" style="object-fit: cover;"></p>
                </div>
              </a>
              <!-- </router-link> -->
          </span>
          </div>
      </div>
    </marquee>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import CarouselPromoService from "@/services/CarouselPromoService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default{
    name: "CarouselPromo",
    components: {
      loading
    },

    data: function(){
      return {
        show: false,
        label: 'Processing request',
        carouselPromotions: [],
        PDF_API_URL: PDF_API_URL
      }
    },

    mounted: function(){
      this.findAllCarouselPromos();
    },

    methods: {
      findAllCarouselPromos: function(){
        this.show = true;
        CarouselPromoService.findAll().then(response => {
          this.carouselPromotions = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
  .img-preview {
    width: 100%;
    height: 200px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    object-fit: cover;
  }
</style>
