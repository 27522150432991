<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <p class="error-message" v-if="message !== null">{{ message }}</p>
        <div v-show="company">
          <form class="form" @submit.prevent="save">
            <h4>New delivery service business details</h4>
            <hr>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="name">Business Name</label>
                  <input type="text" v-model="company.name" id="name" class="form-control" placeholder="Enter business name" required/>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="tradingName">Trading Name</label>
                  <input type="text" v-model="company.tradingName" id="tradingName" class="form-control" placeholder="Enter trading name e.g ABC or XYZ etc"/>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="shortName">Business Partner/Registration Number</label>
                  <input type="text" v-model="company.businessPartnerNumber" id="businessPartnerNumber" class="form-control" placeholder="Enter business partner or registration number" required/>
                </div>
              </div>
            </div>
            <button class="btn btn-success btn-sm pull-right" type="button" @click="addPhoneNumberField">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
            <div v-if="phoneNumberFields.length > 0">
              <div v-for="(phoneNumberField, phoneNumberIndex) in phoneNumberFields" :key="phoneNumberField.id">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="zipCodeId">Country Code</label>
                      <select class="form-control" v-model="phoneNumberField.zipCodeId" required>
                        <option value="" disabled>Select option</option>
                        <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="number">Business Phone Number</label>
                      <input type="number" name="number" class="form-control" v-model="phoneNumberField.number" minlength="6" maxlength="10" placeholder="Enter phone number" required>
                    </div>              
                  </div>
                  <div class="col-md-2">
                    <div class="form-group" v-show="phoneNumberFields.length > 1">
                      <label>&nbsp;</label><br>
                      <button class="btn btn-danger btn-sm pull-left" type="button" @click="phoneNumberFields.splice(phoneNumberIndex, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                    </div>
                  </div>
                </div>          
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="email">Business Email</label>
                  <input type="email" v-model="company.email" id="email" class="form-control" placeholder="Enter business email" required/>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Carrier Type</label>
                  <select class="form-control" v-model="company.carrierType">
                    <option value="" disabled>Select option</option>
                    <option value="OWN_OPERATOR">Own Operator</option>
                    <option value="FLEET_OWNER">Fleet Owner</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>&nbsp;</label>
                  <button class="btn btn-success btn-block pull-right" type="sumbit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import ZipCodeService from "@/services/ZipCodeService.js";
  import CompanyService from "@/services/CompanyService.js";
  import Company from "@/models/Company.js";
  import loading from "vue-full-loading";

  export default {
    name: "NewBusinessProfile",
    components: {
      loading
    },

    data: function() {
      return {
        company: new Company,
        show: false,
        label: "Processing request",
        errors: null,
        companyId: null,
        zipCodes: [],
        errorMessage: null,
        phoneNumberFields: [{
          zipCodeId: null,
          number: null
        }],
        username: JSON.parse(localStorage.getItem("username")),
        message: null
      };
    },

    mounted() {
      this.getZipCodes();
    },

    computed: {

    },

    methods: {
      addPhoneNumberField: function(){
        this.phoneNumberFields.push({
          zipCodeId: null,
          number: null
        });
      },

      getZipCodes: function() {
        return ZipCodeService.findAll().then(response => {
          this.zipCodes = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.company.phoneNumbers = this.phoneNumberFields;
        this.company.associatedUserUsername = this.username;
        this.company.type = "CARRIER";
        localStorage.setItem('carrierType', JSON.stringify(this.company.carrierType));
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode === 201) {
            this.companyId = response.data.body.id;
            this.$router.push({ name: "delivery-service-address", params: { uuid: response.data.body.uuid }});
          }
          if (response.data.statusCode === 500) {
            this.message = response.data.body.message;
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response);
            if (error.response.status === 500) {
              this.message = error.response.data.message;
            }
            this.show = false;
          });
      },
    }
  };
</script>
