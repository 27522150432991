<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="95" height="65" class="img img-responsive img-rounded"/>        
    </div>

    <div class="panel">
      <div class="panel-body">
          <h4>To get paid, provide bank accounts</h4>
          <br>
          <p v-if="message !== null" class="error-message text-center">{{ message }}</p>
          <form class="form" @submit.prevent="save">

            <span v-show="bankAccountFields.length < 2">
              <button class="btn btn-success btn-sm pull-right" type="button" @click="addBankAccountField">Add <span class="glyphicon glyphicon-plus"></span></button>
            </span>

            <div v-if="bankAccountFields.length > 0">
              <div v-for="(bankAccountField, index) in bankAccountFields" :key="bankAccountField.id">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="phoneNumbers">Financial Institution <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="bankAccountField.financialInstitutionId" required>
                        <option value="" disabled>Select option</option>
                        <option v-for="financialInstitution in financialInstitutions" :key="financialInstitution.id" :value="financialInstitution.id">{{ financialInstitution.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="phoneNumbers">Account Number <span class="text-danger">*</span></label>
                      <input type="number" name="accountNumber" id="accountNumber" class="form-control" v-model="bankAccountField.accountNumber" placeholder="Enter account number" required>
                    </div>              
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label for="phoneNumbers">Account Currency <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="bankAccountField.accountCurrency">
                        <option value="" disabled>Select option</option>
                        <option value="ZWL">ZWL</option>
                        <option value="USD">USD</option>
                      </select>
                    </div>              
                  </div>
                  <div class="col-md-2">
                    <div class="form-group" v-show="bankAccountFields.length > 1">
                      <label>&nbsp;</label><br>
                      <button class="btn btn-danger btn-sm pull-left" type="button" @click="bankAccountFields.splice(index, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                    </div>
                  </div>
                </div>          
              </div>
            </div>

            <div class="row">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>&nbsp;</label>
                  <button class="btn btn-success btn-block pull-right" type="sumbit">Submit</button>
                </div>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import Company from "@/models/Company";

  export default {
    name: "NewBankAccount",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        errors: [],
        message: null,
        editing: false,
        bankAccountFields: [{
          financialInstitutionId: null,
          accountNumber: null,
          accountCurrency: null
        }],
        financialInstitutions: [],
        company: new Company
      };
    },

    mounted(){
      console.log(JSON.parse(localStorage.getItem('username')));
      this.getFinancialInstitutions();
    },

    computed: {
      getUuid(){ return this.$route.params.uuid; }
    },

    methods: {
      addBankAccountField: function(){
        this.bankAccountFields.push({
          financialInstitutionId: null,
          accountNumber: null,
          accountCurrency: null
        });
      },

      getFinancialInstitutions: function() {
        return CompanyService.findAll().then(response => {
          this.financialInstitutions = response.data.body.filter(company => {
            return company.companyType === 'FINANCIAL_INSTITUTION';
          });
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.company.uuid = this.getUuid;
        this.company.bankAccounts = this.bankAccountFields;
        CompanyService.save(this.company).then(response => {
            console.log(response);
            if (response.data.statusCode == 422) {
              this.show = false;
              this.errors = response.data.errors;
            }
            if (response.data.statusCode == 400) {
              this.show = false;
              this.errors = response.data.errors;
              this.message = response.data.message;
            }
            if (response.data.statusCode == 201) {
              this.show = false;
              this.$router.push({ name: "new-business-profile/subscriptions", params: { uuid: response.data.body.uuid }});
            }
          })
          .catch(error => {
            this.show = false;
            console.log(error);
            this.errors = error.response.data.errors;
            this.message = error.response.data.message;
          });
      }
    }
  };
</script>
