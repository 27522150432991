<template>
	<div>
		<loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

		<h4>Compliance Management Preview ~ your company have chosen to be compliant with the following laws and regulations.</h4>
		<hr>

		<h4>
			<router-link :to="{ name: 'complianceManagement/new' }">
				<button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button>
			</router-link>
		</h4>

		<h4>You have selected the following compliances for your company. You will be notified before compliance is due.</h4><br>

		<div v-if="compliances.length > 0">
			<div class="list-group">
				<li class="list-group-item" v-for="(compliance, index) in compliances" :key="compliance.id">
					<button class="btn btn-danger btn-sm pull-right" @click="compliances.splice(index, 1); updateCheckedCompliance">Delete <span class="glyphicon glyphicon-minus"></span>
					</button><br> {{ compliance.name }}
				</li>
			</div>

			<form class="form" @submit.prevent="save">
				<div class="form-group">
					<label>&nbsp;</label>
					<button class="btn btn-success pull-right" type="submit">Submit now</button>
				</div>
			</form>
		</div>

		<div v-else>
			<h4 class="text-danger">No compliance has been selected yet.</h4>
		</div>
	</div>
</template>

<script type="text/javascript">
	import CompanyService from "@/services/CompanyService";
	import Company from "@/models/Company";
	import loading from "vue-full-loading";

	export default{
		name: "ComplianceManagementPreview",
		components: {
			loading
		},

		data: function(){
			return{
				compliances: [],
				company: new Company,
				label: "Processing request...",
				show: false,
				companyId: null
			}
		},

		created(){
			this.compliances = JSON.parse(localStorage.getItem("checkedCompliances"));
			this.findByAssociatedUserId();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			}
		},

		methods: {
			findByAssociatedUserId: function(){
				CompanyService.findByAssociatedUserId(this.getUser.userId).then(response => {
					this.companyId = response.data.body.id;
				}).catch(error => {
					console.log(error);
				});
			},

			save: function(){
				this.show = true;
				this.company.companyId = this.companyId;
				let complianceList = [];
				for(var i = 0; i < this.compliances.length; i++){
					complianceList.push({ complianceId: this.compliances[i].id });
				}
				this.company.compliances = complianceList;
				CompanyService.saveCompliance(this.company).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'complianceManagement' });
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			updateCheckedCompliance: function(){
				localStorage.setItem("checkedCompliances", JSON.stringify(this.compliances));
			}
		},
	};
</script>