<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Browse products and buy at knockdown prices from sellers!</h4>
		<div class="row">
			<div class="col-md-2">
				<div class="panel">
					<div class="panel-body">
						<p>Refine by Category</p>
						<select class="form-control">
							<option value="">All categories</option>
						</select>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<div class="row">
					<div class="panel">
						<div class="panel-body">
							<div class="col-md-2">
								<p>{{ sellers.length }} results</p>
							</div>
							<div class="col-md-8">
								<p>Sort by:</p>
								<select class="form-control">
									<option value="">Relevance</option>
									<option value="">Price: High to Low</option>
									<option value="">Price: Low to High</option>
									<option value="">Top rated</option>
									<option value="">Newest arrivals</option>
								</select>
							</div>
							<div class="col-md-2">
								
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3" v-for="seller in sellers" :key="seller.id">
						<div class="panel">
							<div class="panel-body">
								<p>{{ seller.name }}% off <span class="pull-right">Like/Dislike</span></p>
								<img src="@/assets/images/professional-1024x768.jpeg" height="100" width="150" class="img img-rounded"><br><br>
								<img src="@/assets/images/professional-1024x768.jpeg" height="20" width="20" class="img img-rounded">
								<p>{{ seller.name }}</p>
								<h4>{{ seller.description }}</h4>
								<p><span class="badge">JHB</span> <span class="badge">CPT</span></p>
								<p>
									<router-link :to="{ name: 'sellers/products', params: { sellerId: seller.id }}">
										<button class="btn btn-primary">View Products <span class="glyphicon glyphicon-info-sign"></span></button>
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import SellerService from "@/services/SellerService";
	import loading from "vue-full-loading";

	export default{
		name: "SellerList",
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: "Processing request...",
				sellers: [],
			}
		},

		created(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				SellerService.findAll().then(response => {
					this.show = false;
					this.sellers = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
	.processing-row {
	  display: flex;
	  justify-content: center;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
	.error-message {
	  color: red;
	}
</style>