<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10"></div>
          <div class="col-md-2">
            <router-link :to="{ name: 'sellerProfile' }">
              <button class="btn btn-danger pull-right">Cancel</button>
            </router-link>
          </div>
        </div>

        <p class="error-message" v-if="errorMessage !== null">{{ errorMessage }}</p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label for="sellerType">Choose how you want to sell</label>
            <select v-model="seller.sellerType" id="sellerType" class="form-control" required @change="findByAssociatedUserUsername(seller.sellerType)">
              <option value="" disabled>Select option</option>
              <option value="COMPANY">As a company</option>
              <option value="INDIVIDUAL">As an individual seller</option>
            </select>
          </div>

          <div class="form-group">
            <label for="name">Seller Name</label>
            <input type="text" v-model="seller.name" id="name" class="form-control" placeholder="Enter Seller Name">
          </div>

          <div v-if="seller.sellerType === 'COMPANY'">
            <div v-if="company !== null">
              <p>You are creating the storefront seller profile as {{ company !== null ? company.name: '' }}</p>
            </div>
            <div v-else>
              <p class="text-danger">You haven't created company to associate with your seller profile. 
                <router-link :to="{ name: 'new-seller-company' }">Create new company here</router-link>
              </p>
            </div>
          </div>

          <div class="row" v-if="company !== null">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-success btn-block" type="sumbit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import SellerService from "@/services/SellerService.js";
  import CompanyService from "@/services/CompanyService.js";
  import Seller from "@/models/Seller.js";
  import loading from "vue-full-loading";

  export default {
    name: "NewSeller",
    components: {
      loading
    },

    data: function() {
      return {
        seller: new Seller,
        show: false,
        label: "Processing request",
        errors: null,
        companyId: null,
        errorMessage: null,
        company: null
      };
    },

    created() { },

    methods: {

      save: function() {
        this.show = true;
        this.seller.associatedAccountUsername = this.getUser.username;
        SellerService.save(this.seller).then(response => {
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "sellerProfile" });
          }
        }).catch(error => {
          console.log(error);
        });
      },

      findByAssociatedUserUsername: function(sellerType){
        console.log(sellerType);
        if (sellerType === 'COMPANY') {
          CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
            this.company = response.data.body;
            console.log(this.company);
          }).catch(error => {
            console.log(error);
          });
        }
      }
    },

    computed: {

      getUser: function() {
        return this.$store.state.auth.user.user;
      }
    }
  };
</script>