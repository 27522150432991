<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
    	<div class="panel-body">
    		<div class="row" v-if="product !== null">
    			<div class="col-md-10">Like {{ product.name }}</div>
    			<div class="col-md-2">
    				<router-link :to="{ name: 'view-product', params: { productId: this.product.id }}">
    					<button class="btn btn-default btn-block">Back</button>
		        	</router-link>
		      	</div>
		    </div>
		   	<hr>

		   	<p class="error-message" v-if="message !== null">{{ message }}</p>
		   	<form class="form" @submit.prevent="likeDislikeProduct">
		   		<div class="row">
		   			<div class="col-md-10"></div>
		   			<div class="col-md-2">
		   				<div class="form-group">
		   					<label>&nbsp;</label>
		            		<button class="btn btn-success btn-block" type="sumbit">Submit Like</button>
		          		</div>
		        	</div>
		      	</div>
		      </form>
		  </div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService.js";
	import loading from "vue-full-loading";

	export default {
		name: "LikeProduct",
		components: {
			loading
		},
  
	    data: function() {
	      return {
	        product: null,
	        show: false,
	        label: "Processing request",
	        message: null
	      };
	    },

	    mounted() {
	      this.findByProductNumber();
	    },

	    methods: {
	      findByProductNumber: function() {
	        ProductService.findByProductNumber(this.getProductNumber).then(response => {
	        	this.product = response.data.body;
	        }).catch(error => {
	        	console.log(error);
	        });
	      },

	      likeDislikeProduct: function() {
	        this.show = true;
	        ProductService.likeDislikeProduct(this.getProductNumber, this.getUser.username).then(response => {
	        	console.log(response);
	        	this.show = false;
	        	// if(response.data.statusCode === 201){
	        		this.$router.push({ name: "view-product", params: { productId: this.product.id }});
	        	// }
	        }).catch(error => {
	        	console.log(error.response);
	        	this.show = false;
	          	this.message = error.response.data.message;
	        });
	      }
	    },

	    computed: {
	    	getProductNumber: function() {
	    		return this.$route.params.productNumber;
	    	},

	    	getUser: function() {
	    		return this.$store.state.auth.user.user;
	    	}
	    }
	};
</script>
