<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel" v-if="product !== null">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Payment Checkout</h4>
					</div>
					<router-link :to="{ name: 'new-premium-product', params: { productId: this.getProductId }}">
						<div class="col-md-2">
							<button class="btn btn-danger btn-block">Cancel Premium</button>
						</div>
					</router-link>
				</div>
				<hr><br>

				<p>Products Name: {{ product.name }}</p>
				<p>Products Number: {{ product.productNumber }}</p>
				<hr>
				<p class="text-danger">Total payment $5.00</p>
				<br><br>

				<form class="form" @submit.prevent="payPremiumProduct">
					<div class="form-group">
						<label>Choose payment gateway</label>
						<select class="form-control" required>
							<option value="">Select option</option>
							<option value="DPO">VISA (USD Payment)</option>
						</select>
					</div><br><br>
					<div class="row">
						<div class="col-md-10">
							<br>
							<p>If you choose to pay, you will be redirected to payment gateway to complete the payment process.</p>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Pay now</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import PaymentService from "@/services/PaymentService";
	import Payment from "@/models/Payment";
	import loading from "vue-full-loading";
	
	export default{
		name: "PayPremiumProduct",
		components: {
			loading
		},

		data: function(){
			return {
				product: null,
				payment: new Payment,
				label: 'Processing request',
				show: false
			}
		},

		computed: {
			getProductId: function(){
				return this.$route.params.productId;
			}
		},

		mounted(){
			this.findById();
		},

		methods: {
			findById: function(){
				this.show = true;
				ProductService.findById(this.getProductId).then(response => {
					this.product = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			payPremiumProduct: function(){
				this.show = true;
				this.payment.amount = 5;
				this.payment.payerUsername = this.$store.state.auth.user.user.username;
				PaymentService.save(this.payment).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.makePremiumProduct();
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			makePremiumProduct: function(){
				this.show = true;
				ProductService.makePremiumProduct(this.product.productNumber).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'premium-product-payment-confirmation' });
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	}
</script>
