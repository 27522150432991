<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="panel" v-if="product !== null">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-7">
						<h4>Product details</h4>	
					</div>
					<div class="col-md-4">
						<button class="btn btn-danger btn-block" @click="makePremiumProduct">Make Premium (fees apply)</button><br><br>
					</div>
					<router-link :to="{ name: 'productsPreview'}">
						<div class="col-md-1">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>
				<br>

				<h4>Product photos</h4>
		        <div class="row">
		          <div class="col-md-2" v-for="photo in product.photos" :key="photo.id">
		            <img :src="GENERIC_SERVICE_URL + 'photos/' + photo.id" class="img img-responsive img-rounded img-preview" width="300px" height="300px">
		          </div>
		        </div>
				<div class="row">
					<div class="col-md-3">
						<h4>{{ product.percentageOff !== null ? product.percentageOff: 0 }}% off</h4>
						<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded img-preview" width="280" height="200">
					</div>
					<div class="col-md-6">
						<h4>{{ product.name }}</h4>
						<p>{{ product.description }}</p>
						<p>4.3, 104 Reviews</p>
						<hr>
						<h4>In stock <span class="badge">{{ product.inStock ? "Yes": "No" }}</span>, JHBCPT, When do I get it?</h4>
						<p>Sold by <span><a href="#">
							<router-link :to="{ name: 'sellers/products', params: { sellerId: product.seller.id }}">
								{{ product.seller.name }}
							</router-link>
						</a></span>. Fulfilled by <span><a href="#">{{ product.deliveryFulfilledBy }}</a></span></p>
						<hr>
						<ul>
							<li>Eligible for next-day delivery or collection. <span class="badge">{{ product.eligibleForNextDayDeliveryOrCollection ? "Yes": "No" }}</span></li>
							<li>Eligible for Cash on Delivery. <span class="badge">{{ product.eligibleForCashOnDelivery ? "Yes": "No" }}</span></li>
							<li>Free Delivery Available. <span class="badge">{{ product.freeDeliveryAvailable ? "Yes": "No" }}</span></li>
							<li>Hassle-Free Exchanges & Returns for 30 Days. <span class="badge">{{ product.hassleFreeExchangesAndReturnsFor30Days ? "Yes": "No" }}</span></li>
							<li>12-Month Limited Warranty. <span class="badge">{{ product.twelveMonthLimitedWarranty ? "Yes": "No" }}</span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="panel" v-if="product !== null">
			<div class="panel-body">
				<h4>Description</h4>
				<p>{{ product.description }}</p>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import loading from "vue-full-loading";
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

	export default{
		name: "MyProductListDetails",
		components: {
			loading
		},

		data: function(){
			return {
				products: [],
				product: null,
				show: false,
				label: "Processing request",
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
				cartProducts: [],
			}
		},

		mounted(){
			this.findById();
		},

		computed: {
			getProductId: function(){
				return this.$route.params.productId;
			}
		},

		methods: {
			findById: function(){
				this.show = true;
				ProductService.findById(this.getProductId).then(response => {
					this.product = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			makePremiumProduct(){
				this.show = true;
				this.$router.push({ name: 'pay-premium-product', params: { productId: this.getProductId }});
				this.show = false;
			}
		}
	};
</script>

<style type="text/css" scoped>
	.img-preview {
    width: 100%;
    height: 200px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    object-fit: cover;
  }
</style>
