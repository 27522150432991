<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<div class="row">
	    			<div class="col-md-10">
	    				<h4>Update Delivery Status of order {{ order !== null ? order.orderNumber: '' }}</h4><br>
	    			</div>
	    			<router-link :to="{ name: 'buyer-shipments' }">
	    				<div class="col-md-2">
	    					<button class="btn btn-primary btn-block">Back</button>
	    				</div>
	    			</router-link>
	    		</div>
				<div v-if="order !== null">
					<form class="form" @submit.prevent="confirmedDelivery">
						<div class="form-group">
							<label>Delivery Status</label>
							<select class="form-control" v-model="order.status" required>
								<option value="" disabled>Select option</option>
								<option value="DELIVERY_CONFIRMED">Delivery Confirmed</option>
								<option value="DELIVERED_WITH_DEFECTS">Delivered with defects</option>
							</select>
						</div>
						<br>
						
						<div v-show="order.status === 'DELIVERY_CONFIRMED'">
							<h4><i>You can leave a comment below (optional)</i></h4>
						</div>

						<div v-show="order.status === 'DELIVERED_WITH_DEFECTS'">
							<h4><i>Further investigation will be required. Our service agent will contact you to get more details of the order defected delivery</i></h4>
							<p class="text-danger">N.B. Payment will not be released to the carrier until issue has ben resolved.</p>
						</div>

						<div class="form-group">
							<label>Comments (optional)</label>
							<textarea class="form-control" rows="5" placeholder="Enter comments" v-model="order.deliveryComments"></textarea>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<button class="btn btn-primary btn-block">Submit</button>
							</div>
						</div>
					</form>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import OrderService from "@/services/OrderService.js";

	export default{
		name: 'UpdateLoadDeliveryStatus',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    order: null,
			    deliveryStatus: null
			}
		},

		mounted(){
			this.findByOrderNumber();
		},

		computed: {
			getOrderNumber: function(){
				return this.$route.params.orderNumber;
			},
		},

		methods: {
			findByOrderNumber: function(){
				this.show = true;
				OrderService.findByOrderNumber(this.getOrderNumber).then(response => {
					this.order = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			confirmedDelivery: function(){
				this.show = true;
				OrderService.confirmedDelivery(this.order).then(response => {
					if (response.data.statusCode === 201){
						this.$router.push({ name: 'buyer-shipments' });
						window.location.reload();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>