<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
    	<div class="panel-body">
    		<div class="row" v-if="company !== null">
    			<div class="col-md-10">Follow {{ company.name }}</div>
    			<div class="col-md-2">
    				<router-link :to="{ name: 'company-search' }">
    					<button class="btn btn-default btn-block">Back</button>
		        	</router-link>
		      	</div>
		    </div>
		   	<hr>

		   	<p class="error-message" v-if="message !== null">{{ message }}</p>
		   	<form class="form" @submit.prevent="follow">
		   		<div class="row">
		   			<div class="col-md-10"></div>
		   			<div class="col-md-2">
		   				<div class="form-group">
		   					<label>&nbsp;</label>
		            		<button class="btn btn-success btn-block" type="sumbit">Submit Following</button>
		          		</div>
		        	</div>
		      	</div>
		      </form>
		  </div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import CompanyService from "@/services/CompanyService.js";
	import loading from "vue-full-loading";

	export default {
		name: "FollowCompany",
		components: {
			loading
		},
  
	    data: function() {
	      return {
	        company: null,
	        show: false,
	        label: "Processing request",
	        message: null
	      };
	    },

	    mounted() {
	      this.findById();
	    },

	    methods: {
	      findById: function() {
	        CompanyService.findById(this.getCompanyId).then(response => {
	          this.company = response.data.body;
	        }).catch(error => {
	          console.log(error);
	        });
	      },

	      follow: function() {
	        this.show = true;
	        CompanyService.follow(this.getCompanyId, this.getUser.username).then(response => {
	        	this.show = false;
	          if (response.data.statusCode == 201) {
	            this.$router.push({ name: "company-search" });
	          }
	        }).catch(error => {
	          console.log(error.response);
	          this.show = false;
	          this.message = error.response.data.message;
	        });
	      }
	    },

	    computed: {
	    	getCompanyId: function() {
	    		return this.$route.params.companyId;
	    	},

	    	getUser: function() {
	    		return this.$store.state.auth.user.user;
	    	}
	    }
	};
</script>
