<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<router-link :to="{ name: 'compliances'}">
			<button class="btn btn-default">Back</button>
		</router-link>
		<br><br>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Name</label>
				<input type="text" name="name" id="name" placeholder="Enter compliance name" class="form-control" v-model="compliance.name">
			</div>
			<div class="form-group">
				<label>Country</label>
				<select class="form-control" name="countryId" id="countryId" v-model="compliance.countryId">
					<option value="">Select option</option>
					<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
				</select>
			</div>
			<div class="form-group">
				<label>Compliance Category</label>
				<select class="form-control" v-model="compliance.category">
					<option value="">Select option</option>
					<option value="ONE_TIME">One time - eg, licences, approvals, company incorporation, bond issue, share re-allotment</option>
					<option value="ONGOING">On Going - eg. registers, procedures, provisions</option>
					<option value="EVENT_BASED">Event Based - eg, Launches, modifications, etc</option>
					<option value="REOCCURRING">Recurring - eg, monthly, quarterly, half yearly and annually</option>
				</select>
			</div>
			<div class="form-group" v-if="compliance.category === 'REOCCURRING'">
				<label>Occurrence</label>
				<select class="form-control" name="occurrence" id="occurrence" v-model="compliance.occurrence">
					<option value="">Select option</option>
					<option value="WEEKLY">WEEKLY</option>
					<option value="MONTHLY">MONTHLY</option>
					<option value="QUARTERLY">QUARTERLY</option>
					<option value="ANNUALLY">ANNUALLY</option>
				</select>
			</div>
			<div class="form-group">
				<label>Compliance Type</label>
				<select class="form-control" name="countryId" id="countryId" v-model="compliance.complianceTypeId">
					<option value="">Select option</option>
					<option v-for="complianceType in complianceTypes" :key="complianceType.id" :value="complianceType.id">{{ complianceType.name }}</option>
				</select>
			</div>
			<div class="form-group">
				<label>Status</label>
				<select class="form-control" name="status" id="status" v-model="compliance.status">
					<option value="">Select option</option>
					<option value="ACTIVE">ACTIVE</option>
					<option value="INACTIVE">INACTIVE</option>
				</select>
			</div>
			<div class="form-group">
				<label>Description</label>
				<textarea class="form-control" rows="5" placeholder="Enter compliance description" name="description" id="description" v-model="compliance.description"></textarea>
			</div>
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success btn-block" type="submit">Submit</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import ComplianceService from "@/services/ComplianceService";
	import Compliance from "@/models/Compliance";
	import loading from "vue-full-loading";
	import CountryService from "@/services/CountryService";
	import ComplianceTypeService from "@/services/ComplianceTypeService";

	export default{
		name: "NewCompliance",
		components: {
			loading
		},

		data: function(){
			return {
				compliance: new Compliance,
				label: 'Processing request',
				show: false,
				countries: [],
				complianceTypes: []
			}
		},

		created(){
			this.findAll();
			this.findAllComplianceTypes();

		},

		methods: {
			findAll: function(){
				this.show = true;
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			findAllComplianceTypes: function(){
				this.show = true;
				ComplianceTypeService.findAll().then(response => {
					this.complianceTypes = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			save: function(){
				this.show = true;
				ComplianceService.save(this.compliance).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({ name : 'compliance-list' });
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>