import { SKILLS_SERVICE_URL } from "@/config/SkillsServiceConfig";
import axios from "axios";

class ProfessionalService{
	findAll(){
		return axios.get(SKILLS_SERVICE_URL + "professionals");
	}

	save(professional) {
        const authToken = JSON.parse(localStorage.getItem("user"));
        return axios.post(SKILLS_SERVICE_URL + "professionals",
        {
            userId: authToken === null ? professional.userId: authToken.userId,
        	professionalId: professional.professionalId,
        	cityId: professional.cityId,
        	countryId: professional.countryId,
            overview: professional.overview,
            title: professional.title,
            videoIntroduction: professional.videoIntroduction,
            availability: professional.availability,
            languages: professional.languages,
            educations: professional.educations,
            serviceCharge: professional.serviceCharge,
            workHistories: professional.workHistories,
            portfolioProjects: professional.portfolioProjects,
            skills: professional.skills,
            testimonials: professional.testimonials,
            certifications: professional.certifications,
            otherExperiences: professional.otherExperiences,
            jobPreferences: professional.jobPreferences,
            recommendations: professional.recommendations,
        });
    }

    findById(id){
        return axios.get(SKILLS_SERVICE_URL + "professionals/" + id);
    }

    findByUserId(userId){
        return axios.get(SKILLS_SERVICE_URL + "professionals/findByUserId/" + userId);
    }

    saveLocationRestriction(professional) {
        return axios.post(SKILLS_SERVICE_URL + "professionals/saveLocationRestriction",{
            id: professional.id,
            locationRestriction: professional.locationRestriction
        });
    }

    saveVideoIntroduction(formData){
        return axios.post(SKILLS_SERVICE_URL + "professionals/saveVideoIntroduction", formData);
    }

    publish(professionalId){
        return axios.post(SKILLS_SERVICE_URL + "professionals/publish/" + professionalId);
    }
}

export default new ProfessionalService;