<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<h4>Edit Branch</h4><br>
		<form class="form" @submit.prevent="updateBranch">
			<div class="form-group">
				<label>Branch Name</label>
				<input type="text" v-model="branch.branchName" placeholder="Enter Branch name" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'branchName' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Branch Description</label>
				<input type="text" v-model="branch.branchDescription" placeholder="Enter Branch Description" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'branchDescription' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Status</label>
				<select v-model="branch.branchStatus" class="form-control">
					<option value="">Select status</option>
					<option value="1">Active</option>
					<option value="0">Inactive</option>
				</select>
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'branchStatus' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>&nbsp;</label>
				<router-link :to="{ name: 'banks/bankBranches/', params: {bankId: getBankId }}" replace><button class="btn btn-default" data-dismiss="modal">Cancel</button></router-link> &nbsp; <button class="btn btn-success">Save & Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import Branch from "@/models/Branch";
	import BankBranchService from "@/services/BankBranchService";
	import loading from "vue-full-loading";

	export default {
		name: 'EditBankBranch',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing...',
				branch: new Branch(),
				errors: [],
			}
		},
		computed: {
			getBankId(){
				return this.$route.params.bankId;
			},
			getBankBranchId(){
				return this.$route.params.branchId;
			}
		},
		created(){
			this.getBankBranchById(this.getBankId);
		},
		methods: {
			findByIdAndBankId: function(id, bankId){
				BankBranchService.findByIdAndBankId(id, bankId).then(response => {
					this.branch = response.data.data;
				}).catch(error => {
					console.log('error: ', error);
					this.$router.push({ name: 'banks/bankBranches', params: { bankId: this.getBankId }});
				});
			},
			updateBank: function(){
				this.show = true;
				this.bank.id = this.getBankId;
				BankBranchService.updateBranch(this.bank, this.getBankId).then(response => {
					console.log(response.data.errors);
					if(response.data.statusCode == 422){
						this.show = false;
						console.log('validation errors: ');
						this.errors = response.data.errors;
					}
					if(response.data.statusCode == 200){
						this.show = false;
						this.$router.push({ name: 'banks'});
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
					this.errors = error.response.data.errors;
				});
			}	
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>