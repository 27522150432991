<template>
	<div>
		<router-link :to="{ name: 'jobConfigurationList' }" replace>
			<button class="btn btn-primary">Back</button>
		</router-link><br/><br/>

		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<form class="form" @submit.prevent="saveRequestForQuotationAttachment">
			<div class="form-group">
				<label>Attachment File (e.g. Job description document)</label>
				<input type="file" name="attachments" class="form-control" v-on:change="selectAttachments">
			</div><br>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import RequestForQuotationService from "@/services/RequestForQuotationService";
	import loading from "vue-full-loading";

	export default{
		name: "NewRequestForQuotationAttachment",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				label: "Processing...",
				show: false,
				formData: new FormData
			}
		},

		created: function(){

		},

		computed: {
			getRequestForQuotationId: function(){
				return this.$route.params.requestForQuotationId;
			}
		},

		methods: {
			selectAttachments: function(event){
				this.formData.append("attachments", event.target.files[0]);
				console.log(event.target.files);
			},

			saveRequestForQuotationAttachment: function(){
				this.show = true;
				this.formData.append("requestForQuotationId", this.getRequestForQuotationId);
				RequestForQuotationService.saveRequestForQuotationAttachment(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "requestForQuotationConfigurationList" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>