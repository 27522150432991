<template>
	<div>
		<h4>Job Configuration List</h4>

		<router-link :to="{ name: 'jobs/new' }" replace><button class="btn btn-success pull-right">New job <span class="glyphicon glyphicon-plus"></span></button></router-link><br/><br/>

		<table class="table table-responsive table-bordered">
			<tr class="bg-primary">
				<th>ID</th>
				<th>Name</th>
				<th>Rate</th>
				<th>Type</th>
				<th>Status</th>
				<th>Actions</th>
			</tr>
			<tbody>
				<tr v-for="job in jobs" :key="job.id">
					<td>{{ job.id }}</td>
					<td>{{ job.jobTitle !== null ? job.jobTitle.name: '' }}</td>
					<td>{{ job.currency }} {{ job.hourlyRate }} per hour</td>
					<td>{{ job.type }}</td>
					<td>
						<span class="label label-success" v-if="job.status === 'ACTIVE'">{{ job.status }}</span>
						<span class="label label-primary" v-if="job.status === 'INACTIVE'">{{ job.status }}</span>
					</td>
					<td>
						<router-link :to="{ name: 'jobConfigurationView', params: { jobId: job.id }}">
							<button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script type="text/javascript">
	import JobService from "@/services/JobService";

	export default{
		name: "JobConfigurationList",
		component: {

		},

		data: function(){
			return {
				jobs: []
			}
		},

		created: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				JobService.findAll().then(response => {
					this.jobs = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>