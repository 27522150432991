<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<router-link :to="{ name: 'tenderConfigurationList', params: { tenderId: tender.id }}">
			<button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button>
		</router-link>

      	<h4>Create new tender</h4><br>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Country/Region</label>
				<select class="form-control" v-model="tender.countryId">
					<option value="">Select option</option>
					<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
				</select>
			</div>

			<div class="form-group">
				<label>Title</label>
				<input type="text" name="title" v-model="tender.title" class="form-control" placeholder="Enter tender title">
			</div>

			<div class="form-group">
				<label>Category</label>
				<select class="form-control" v-model="tender.tenderCategoryId">
					<option value="">Select option</option>
					<option v-for="tenderCategory in tenderCategories" :key="tenderCategory.id" :value="tenderCategory.id">{{ tenderCategory.name }}</option>
				</select>
			</div>

			<div class="form-group">
				<label>Company</label>
				<select class="form-control" v-model="tender.companyId">
					<option value="">Select option</option>
					<option v-for="company in companies" v-bind:key="company.id" :value="company.id">{{ company.name }}</option>
				</select>
			</div>

			<div class="form-group">
				<label>Reach</label>
				<select class="form-control" v-model="tender.reach">
					<option value="">Select option</option>
					<option value="LOCAL">Local</option>
					<option value="INTERNATIONAL">International</option>
				</select>
			</div>

			<div class="form-group">
				<label>Type</label>
				<select class="form-control" v-model="tender.type">
					<option value="">Select option</option>
					<option value="RFQ">RFQ</option>
					<option value="COMPETETIVE_BIDDING">COMPETETIVE_BIDDING</option>
				</select>
			</div>

			<div class="form-group">
				<label>Section</label>
				<select class="form-control" v-model="tender.section">
					<option value="">Select option</option>
					<option value="GOODS">GOODS</option>
				</select>
			</div>

			<div class="form-group">
				<label>Closing Date</label>
				<input type="date" name="closingDate" v-model="tender.closingDate" class="form-control">
			</div>

			<!-- <div class="form-group">
				<label>Tender Status</label>
				<select class="form-control" v-model="tender.status">
					<option value="">Select option</option>
					<option value="PENDING">PENDING</option>
				</select>
			</div> -->

			<!-- <div class="form-group">
				<label>Estimated Cost</label>
				<input type="text" name="estimatedCost" v-model="tender.estimatedCost" class="form-control" placeholder="Enter tender estimated cost">
			</div>

			<div class="form-group">
				<label>Estimated Cost Currency</label>
				<select class="form-control" v-model="tender.estimatedCostCurrency">
					<option value="">Select option</option>
					<option value="USD">USD</option>
					<option value="ZWL">ZWL</option>
					<option value="RAND">RAND</option>
					<option value="PULA">PULA</option>
					<option value="KWACHA">KWACHA</option>
					<option value="SHILLING">SHILLING</option>
				</select>
			</div>

			<div class="form-group">
				<label>Tender Fee</label>
				<select class="form-control" v-model="tenderFeesArray" multiple>
					<option value="" disabled>Select option</option>
					<option v-for="tenderFee in tenderFees" :key="tenderFee.id" :value="tenderFee.id">{{ tenderFee.feeType }}</option>
				</select>
			</div> -->
			<br><br>

			<div class="row">
				<div class="col-md-3">
					<div class="form-group">
						<label>&nbsp;</label>
						<router-link :to="{ name: 'tenderConfiguration'}">
							<button class="btn btn-default" type="submit">Cancel</button>
						</router-link>
					</div>
				</div>
				<div class="col-md-3"></div>
				<div class="col-md-3"></div>
				<div class="col-md-3">
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success pull-right" type="submit">Submit and Save</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import TenderService from "@/services/TenderService";
	import Tender from "@/models/Tender";
	import CompanyService from "@/services/CompanyService";
	import TenderFeeService from "@/services/TenderFeeService";
	import CountryService from "@/services/CountryService";
	import TenderCategoryService from "@/services/TenderCategoryService";

	export default{
		name: "NewTender",
		components: {
			loading
		},

		data: function(){
			return {
				tender: new Tender,
				label: "Processing request...",
				show: false,
				companies: [],
				tenderFeesArray: [],
				tenderFees: [],
				countries: [],
				tenderCategories: []
			}
		},

		created: function(){
			this.findAllCountries();
			this.findAllCompanies();
			this.findAllTenderFees();
			this.findAllTenderCategories();
		},

		methods: {
			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.body;;
				}).catch(error => {
					console.log(error);
				})
			},

			findAllTenderCategories: function(){
				TenderCategoryService.findAll().then(response => {
					this.tenderCategories = response.data.body;;
				}).catch(error => {
					console.log(error);
				})
			},

			findAllCompanies: function(){
				CompanyService.findAll().then(response => {
					this.companies = response.data.body;;
				}).catch(error => {
					console.log(error);
				})
			},

			findAllTenderFees: function(){
				TenderFeeService.findAll().then(response => {
					this.tenderFees = response.data.body;
				}).catch(error => {
					console.log(error);
				})
			},

			save: function(){
				let tenderFees = [];
				for(var i = 0; i < this.tenderFeesArray.length; i++){
					tenderFees.push({
						tenderFeeId: this.tenderFeesArray[i]
					});
				}
				this.tender.tenderFees = tenderFees;
				this.show = true;
				TenderService.save(this.tender).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "tenders/newTenderDocument", params: { tenderId: response.data.body.id }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>