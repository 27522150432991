export default class Compliance{
	constructor(id, name, countryId, category, description, occurrence, startDate, endDate, status, complianceTypeId){
		this.id = id;
		this.name = name;
		this.countryId = countryId;
		this.category = category;
		this.description = description;
		this.occurrence = occurrence;
		this.startDate = startDate;
		this.endDate = endDate;
		this.status = status;
		this.complianceTypeId = complianceTypeId;
	}
}