<template>
	<div>
		<h4>Tender Category List</h4>

		<h4>
			<router-link :to="{ name: 'tenderCategories/new' }">
				<button class="btn btn-success pull-right">New <span class="glyphicon glyphicon-plus"></span></button>
			</router-link>
		</h4><br><br>

		<table class="table table-responsive table-bordered">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Name</th>
				<th>Description</th>
				<th>Status</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="tenderCategory in tenderCategories" :key="tenderCategory.id">
					<td>{{ tenderCategory.id }}</td>
					<td>{{ tenderCategory.name }}</td>
					<td>{{ tenderCategory.description }}</td>
					<td>
						<span class="label label-success" v-if="tenderCategory.status === 'ACTIVE'">{{ tenderCategory.status }}</span>
						<span class="label label-primary" v-else>{{ tenderCategory.status }}</span>
					</td>
					<td>
						<router-link :to="{ name: 'tenderCategories/view', params: { tenderCategoryId : tenderCategory.id }}">
							<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	import TenderCategoryService from "@/services/TenderCategoryService";

	export default{
		name: 'ComplianceList',
		data: function(){
			return {
				tenderCategories: []
			}
		},

		created(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				TenderCategoryService.findAll().then(response => {
					this.tenderCategories = response.data.body
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {}
	}
</script>