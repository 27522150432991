<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <router-link :to="{ name: 'countries/new' }">
          <button class="btn btn-success pull-right">
            New <span class="fa fa-plus"></span>
          </button> </router-link
        ><br /><br />

        <h4>Country List</h4>

        <table class="table table-bordered table-responsive-md">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Status</th>
            <td>Actions</td>
          </thead>
          <tbody>
            <tr v-for="country in countries" :key="country.id">
              <td>{{ country.id }}</td>
              <td>{{ country.name }}</td>
              <td>
                <span v-if="country.status === 'ACTIVE'">
                  <span class="badge badge-success">Active</span>
                </span>
                <span v-if="country.status === 'INACTIVE'">
                  <span class="badge badge-secondary">Inactive</span>
                </span>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'countries/view',
                    params: { countryId: country.id }
                  }"
                >
                  <button class="btn btn-primary">
                    View <span class="fa fa-info"></span>
                  </button> </router-link
                >&nbsp;
                <router-link
                  :to="{
                    name: 'countries/edit',
                    params: { countryId: country.id }
                  }"
                >
                  <button class="btn btn-warning">
                    Edit <span class="fa fa-pencil"></span>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import CountryService from "@/services/CountryService.js";

export default {
  name: "CountryList",
  data: function() {
    return {
      countries: []
    };
  },

  created: function() {
    this.findAll();
  },

  methods: {
    findAll: function() {
      CountryService.findAll()
        .then(response => {
          this.countries = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
