<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <div class="col-md-10">
        <h4 class="text-center">New product departments</h4>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: 'product-department-list' }">
          <button class="btn btn-warning pull-right"> Cancel</button>
        </router-link>
      </div>
    </div>
    <br>

    <p v-if="message !== null" class="error-message text-center">{{ message }}</p>
    <form class="form" @submit.prevent="save">
      <div class="form-group">
        <label> Name</label>
        <input type="text" v-model="productDepartment.name" placeholder="Enter product department name" class="form-control"/>
      </div>
      
      <div class="form-group">
        <label>Description</label>
        <input type="text" v-model="productDepartment.description" placeholder="Enter product department description" class="form-control"/>
      </div>

      <div class="form-group">
        <label>Status</label>
        <select v-model="productDepartment.status" class="form-control">
          <option value="">Select status</option>
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
          <option value="DELETED">Deleted</option>
        </select>
      </div>

      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <label>&nbsp;</label>
          <button class="btn btn-success btn-block">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import ProductDepartment from "@/models/ProductDepartment";
  import ProductDepartmentService from "@/services/ProductDepartmentService";
  import loading from "vue-full-loading";

  export default {
    name: "NewProductDepartment",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        productDepartment: new ProductDepartment,
        errors: [],
        message: null,
        editing: false
      };
    },

    mounted(){

    },

    methods: {
      save: function() {
        this.show = true;
        ProductDepartmentService.save(this.productDepartment).then(response => {
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "product-department-list" });
          }
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>