<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>User</label>
				<select class="form-control" v-model="job.jobTitleId">
					<option value="">Select option</option>
					<option v-for="jobTitle in jobTitles" :key="jobTitle.id" :value="jobTitle.id">{{ jobTitle.name }}</option>
				</select>
			</div>
			<div class="form-group">
				<label>Countries</label>
				<select class="form-control" v-model="job.jobTitleId">
					<option value="">Select option</option>
					<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
				</select>
			</div>
			<div class="form-group">
				<label>Type</label>
				<select class="form-control" v-model="job.type">
					<option value="">Select option</option>
					<option value="FULL_TIME">FULL_TIME</option>
					<option value="PART_TIME">PART_TIME</option>
					<option value="VOLUNTEERING">VOLUNTEERING</option>
				</select>
			</div>
			<div class="form-group">
				<label>Hourly Rate</label>
				<input type="text" name="hourlyRate" v-model="job.hourlyRate" placeholder="Enter Hourly Rate" class="form-control">
			</div>
			<div class="form-group">
				<label>Currency</label>
				<select class="form-control" v-model="job.currencyId">
					<option value="">Select option</option>
					<option value="USD">USD</option>
					<option value="ZWL">ZWL</option>
					<option value="RAND">RAND</option>
					<option value="PULA">PULA</option>
					<option value="KWACHA">KWACHA</option>
					<option value="SHILLING">SHILLING</option>
				</select>
			</div>
			<div class="form-group">
				<label>Expiry Date</label>
				<input type="date" name="expiryDate" v-model="job.expiryDate" class="form-control">
			</div>
			<div class="form-group">
				<label>Attachment</label>
				<input type="file" name="attachment" class="form-control">
			</div>
			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobService from "@/services/JobService";
	import CountryService from "@/services/CountryService";
	import Job from "@/models/Job";

	export default{
		name: "CreateJob",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				job: new Job,
				label: "Processing...",
				show: false,
				countries: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
		},

		methods: {
			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.data;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.data;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "skills" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>