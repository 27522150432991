<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <div class="col-md-3">
        <h4>Filter/Search</h4>
        <div class="panel">
          <div class="panel-body">
            <form class="form">
              <div class="form-group">
                <label>Country</label>
                <select class="form-control">
                  <option value="">All countries</option>
                  <option v-for="country in countries" :key="country.id">{{ country.name }}</option>
                </select>
              </div>
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-success btn-block">Filter</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="panel" v-for="company in companies" :key="company.id">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-3">
                <img src="@/assets/images/grants.png" height="100" width="150">
              </div>
              <div class="col-md-4">
                <p>{{ company.name }}</p>
                <p><b>Member since: {{ company.createdAt }}</b></p>
                <p>
                  <span class="badge">{{ company.followers.length }}</span> Followers
                  <router-link :to="{ name: 'view-company-search-detail', params: { companyId: company.id }}">
                    <button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
                  </router-link>&nbsp;
                  <router-link :to="{ name: 'follow-company', params: { companyId: company.id }}">
                    <button class="btn btn-danger">Follow</button>
                  </router-link>&nbsp;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import CountryService from "@/services/CountryService";

  export default{
    name: "CompanySearch",
    components: {
      loading
    },

    data: function(){
      return {
        companies: [],
        countries: [],
        label: "Processing request",
        show: false
      }
    },

    mounted: function(){
      this.findAll();
      this.findAllCountries();
    },

    methods: {
      findAll: function(){
        this.show = true;
        CompanyService.findAll().then(response => {
          this.companies = response.data.body;
          this.show = false;
        }).catch(error => {
          this.show = false;
          return Promise.reject(error);
        });
      },

      findAllCountries: function(){
        this.show = true;
        CountryService.findAll().then(response => {
          this.countries = response.data.body;
          this.show = false;
        }).catch(error => {
          return Promise.reject(error);
        });
      }
    },
  };
</script>