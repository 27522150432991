<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-11">
            <h4>Edit fuel price</h4>
          </div>
          <router-link :to="{ name: 'fuel-price-list' }">
            <div class="col-md-1">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <form class="form" @submit.prevent="save" v-if="qualificationType !== null">
          <div class="form-group">
            <label>Name</label>
            <input type="text" name="name" class="form-control" v-model="qualificationType.name" placeholder="Enter qualification type">
          </div>

          <div class="form-group">
            <label>Description</label>
            <textarea rows="5" placeholder="Enter description" class="form-control" v-model="qualificationType.description"></textarea>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select v-model="qualificationType.status" class="form-control">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group"><label>&nbsp;</label>
                <button class="btn btn-success btn-block">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import QualificationTypeService from "@/services/QualificationTypeService";
  import loading from "vue-full-loading";

  export default {
    name: "EditQualificationType",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        qualificationType: null,
        errors: [],
        message: null,
        countries: []
      };
    },

    mounted(){
      this.findById();
    },

    methods: {
      findById: function() {
        QualificationTypeService.findById(this.getId).then(response => {
          this.show = false;
          this.qualificationType = response.data.body;
        }).catch(error => {
          console.log(error);
          this.show = false;
          });
      },

      save: function() {
        this.show = true;
        QualificationTypeService.save(this.qualificationType).then(response => {        
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "qualification-type-list" });
          }
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    },

    computed: {
      getId() {
        return this.$route.params.id;
      }
    }
  };
</script>
