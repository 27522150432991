import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class TenantService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "tenants");
  }

  findById(tenantId) {
    return axios.get(
      GENERIC_SERVICE_URL + "tenants/findById/" + tenantId
    );
  }

  save(data) {
    return axios.post(GENERIC_SERVICE_URL + "tenants", {
      tenantId: data.tenantId,
      companyId: data.companyId,
      name: data.name,
      bankCode: data.bankCode,
      description: data.description,
      status: data.status
    });
  }
}

export default new TenantService();
