import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class BasicDeliveryChargeService {
  save(basicDeliveryCharge) {
    return axios.post(GENERIC_SERVICE_URL + "basicDeliveryCharges", {
      id: basicDeliveryCharge.id,
      amount: basicDeliveryCharge.amount,
      currency: basicDeliveryCharge.currency,
      active: basicDeliveryCharge.active,
      current: basicDeliveryCharge.current,
      enabled: basicDeliveryCharge.enabled,
      expired: basicDeliveryCharge.expired,
      description: basicDeliveryCharge.description,
      status: basicDeliveryCharge.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "basicDeliveryCharges");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "basicDeliveryCharges/" + id);
  }

  findActiveBasicDeliveryCharge() {
    return axios.get(GENERIC_SERVICE_URL + "basicDeliveryCharges/findActiveBasicDeliveryCharge");
  }
}

export default new BasicDeliveryChargeService;
