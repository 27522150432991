<template>
	<div>
		<p>Our Compliance Management Tool helps your organization become more organized and compliant to local laws and regulations.</p>
		<hr>

		<div v-if="company !== null">
			<router-link :to="{ name: 'compliance-management-impact-report' }">
				<button class="btn btn-primary pull-right">View Compliance Impact Report <span class="glyphicon glyphicon-info-sign"></span>
				</button>
			</router-link>
			<br><br><br>

			<div v-if="company.complianceLines !== null">
				<div v-if="company.complianceLines.length > 0">
					<div class="list-group">
						<li class="list-group-item" v-for="(complianceLine, index) in company.complianceLines" :key="complianceLine.uuid">
								<div class="row">
									<router-link :to="{ name: 'view-compliance-management-details', params: { name: complianceLine.compliance.name }}">
										<div class="col-md-4">
											<h4>{{ index + 1 }}. {{ complianceLine.compliance !== null ? complianceLine.compliance.name: 'Not available' }}
											</h4>
										</div>
										<div class="col-md-2">
											Incepted On: {{ complianceLine.inceptionDate }}
										</div>
										<div class="col-md-2">
											<span v-if="remainingDays(complianceLine.inceptionDate, complianceLine.dueDate) !== null">
												<span v-if="remainingDays(complianceLine.inceptionDate, complianceLine.dueDate) === 0">
													<span class="label label-success">Compliant</span>
												</span>
												<span class="label label-warning" v-if="remainingDays(complianceLine.inceptionDate, complianceLine.dueDate) > 0">Due in {{ remainingDays(complianceLine.inceptionDate, complianceLine.dueDate) }} days</span>
											</span>
											<!-- how many days are we from due date, thats the over due days-->
											<h4 v-if="complianceLine.status === 'OVERDUE'">
												<span class="label label-danger">{{ complianceLine.remainingDays }} days overdue</span>
											</h4>
										</div>
										<div class="col-md-4">
											<span class="label label-primary" v-if="complianceLine.compliance.category !== null"> 
												<span v-if="complianceLine.compliance.category === 'ONE_TIME'">
													Occurs One time
												</span>
												<span v-if="complianceLine.compliance.category === 'REOCCURRING'">
													Recurring
												</span>
												<span v-if="complianceLine.compliance.category === 'EVENT_BASED'">
													Event Based
												</span>
												<span v-if="complianceLine.compliance.category === 'ONGOING'">
													On Going
												</span>
											</span>
											<button class="btn btn-success btn-sm pull-right">View <span class="glyphicon glyphicon-info-sign"></span></button>
										</div>
									</router-link>
								</div>
							</li>
						</div>
					</div>

				<div v-else>
					<h4>You have not complied to any regulatory law. Proceed to start to become compliant.</h4>
					<div class="form-group">
						<label>&nbsp;</label>
						<router-link :to="{ name: 'new-company-compliance'}">
							<button class="btn btn-success pull-right" type="submit"><span class="glyphicon glyphicon-edit"></span> Comply Now</button>
						</router-link>
					</div>
				</div>	

				<div v-show="company.complianceLines.length > 0">
					<form class="form" @submit.prevent="save">
						<div class="form-group">
							<label>&nbsp;</label>
							<router-link :to="{ name: 'new-company-compliance'}">
								<button class="btn btn-warning pull-right" type="submit">Update <span class="glyphicon glyphicon-edit"></span></button>
							</router-link>
						</div>
					</form>
				</div>
			</div>
			<div v-else>
				<h4>Company unavailable.</h4>
				<h4>
					<span class="label label-warning">Company profile not available. Please create a company profile first 
						<router-link :to="{ name: 'companyProfile'}">here</router-link>
					</span>
				</h4>
			</div>
		</div>
		<div v-else>
			<p>You have not created any company that associated with your account.</p>
			<p>To start to become compliant, to your country and business laws and regulations. <router-link :to="{ name: 'companies/new' }">Create new company here </router-link></p>
		</div>
	</div>
</template>

<script type="text/javascript">
	import CompanyService from "@/services/CompanyService";
	import Company from "@/models/Company";

	export default{
		name: "ComplianceManagement",
		data: function(){
			return{
				company: new Company,
			}
		},

		created(){
			this.findByAssociatedUserId();
		},

		methods: {
			findByAssociatedUserId: function(){
				CompanyService.findByAssociatedUserId(this.getUser.userId).then(response => {
					this.company = response.data.body;
					console.log(this.company);
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			},

			remainingDays: function(){
				// var vm = this;
				return function(startDate, endDate){
					if (startDate && endDate !== null) {
						var diffInMs = new Date(endDate) - new Date(startDate);
						var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
						return diffInDays;
					}else{
						return null;
					}
				}
			}
		}
	};
</script>