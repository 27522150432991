<template>
	<div>
		<router-link :to="{ name: 'jobConfigurationList' }" replace>
			<button class="btn btn-primary">Back</button>
		</router-link><br/><br/>

		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Attach RFP</label>
				<input type="file" name="attachment" class="form-control" v-on:change="selectAttachment" accept="application/pdf">
			</div><br>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";

	export default{
		name: "NewJobAttachment",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				job: new Job,
				label: "Processing...",
				show: false,
				formData: new FormData
			}
		},

		created: function(){
			console.log(JSON.parse(localStorage.getItem("type")));

			this.findAllJobTitles();
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		methods: {
			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.show = true;
				this.formData.append("jobId", this.getJobId);
				JobService.saveJobQuotationAttachment(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "my-contract-jobs-configuration" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>