<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<router-link :to="{ name: 'promotion-list' }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>

				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>Promo Section Type</label>
						<select class="form-control" v-model="promotion.sectionType">
							<option value="">Select option</option>
							<option value="FULL_TIME_JOBS">Full time jobs</option>
							<option value="TENDER_JOBS">Contract jobs</option>
							<option value="VOLUNTEERING_JOBS">Volunteering jobs</option>
							<option value="SKILLED_EXPERTS">Skilled Experts</option>
							<option value="GRANT_AND_FUNDING">Grant & Funding</option>
							<option value="PRODUCTS">Product</option>
						</select>
					</div>

					<div class="form-group">
						<label>Promo Headline</label>
						<input type="text" v-model="promotion.headline" class="form-control" placeholder="Enter headline">
					</div>

					<div class="form-group">
						<label>Promo Description <span class="text-danger">(max 50 characters)</span></label>
						<input type="text" v-model="promotion.description" class="form-control" placeholder="Enter description">
					</div>

					<div class="form-group">
						<label>Promo Image</label>
						<input type="file" name="image" class="form-control" v-on:change="selectImage" accept="image/png, image/jpeg, image/jpg, image/gif" required>
					</div>
					<div class="row">
						<div class="col-md-6">
							<label>Promo Runs From</label>
							<input type="date" v-model="promotion.runsFrom" class="form-control">
						</div>
						<div class="col-md-6">
							<label>Promo Runs To</label>
							<input type="date" v-model="promotion.runsTo" class="form-control">
						</div>
					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
			</div>	
		</div>
	</div>
</template>

<script type="text/javascript">
	import PromotionService from "@/services/PromotionService.js";
	import Promotion from "@/models/Promotion.js";
	import loading from "vue-full-loading";

	export default{
		components: {
			loading
		},

		data: function () {
			return {
				promotion: new Promotion,
		        show: false,
		        label: "Processing request",
		        errorMessage: null,
		        errors: [],
		        formData: new FormData
			}
		},

		methods: {
			selectImage: function(event){
				console.log(event.target.files[0]);
				this.formData.append("image", event.target.files[0]);
			},

			save: function (){
				this.show = true;
				this.formData.append('promotionId', null);
				this.formData.append('sectionType', this.promotion.sectionType);
				this.formData.append('headline', this.promotion.headline);
				this.formData.append('description', this.promotion.description);
				this.formData.append('runsFrom', this.promotion.runsFrom);
				this.formData.append('runsTo', this.promotion.runsTo);
				PromotionService.save(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "promotion-list" });
					}
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>
