<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Request a client testimonial</h4><br>
		      	<p>Add your client’s contact details. Don’t worry—we’ll only display their first name and last initial.</p>

		      	<div class="row">
		      		<div class="col-md-6">
		      			<form class="form" @submit.prevent="save">
		      				<button class="btn btn-success btn-sm pull-right" @click="addTestimonialField" type="button">Add <span class="glyphicon glyphicon-plus"></span></button>

							<div v-for="(testimonialField, index) in testimonialFields" :key="testimonialField.id">
								<h4>Testimonial Number {{ index + 1 }}</h4>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>First Name</label><br><br>
											<input type="text" name="school" id="school" placeholder="Enter a brief, but descriptive title" class="form-control" v-model="testimonialField.firstName">
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Last Name</label><br><br>
											<input type="text" name="school" id="school" placeholder="Enter a brief, but descriptive title" class="form-control" v-model="testimonialField.lastName">
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Business email address</label><br><br>
											<input type="text" name="school" id="school" placeholder="Enter a brief, but descriptive title" class="form-control" v-model="testimonialField.businessEmailAddress">
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Client's LinkedIn Profile </label><br><br>
											<input type="text" name="school" id="school" placeholder="Enter a brief, but descriptive title" class="form-control" v-model="testimonialField.clientLinkedInProfile">
										</div>
									</div>
								</div>

								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Client's title Optional</label><br><br>
											<input type="text" name="school" id="school" placeholder="Enter a brief, but descriptive title" class="form-control" v-model="testimonialField.clientTitle">
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Project Type Optional </label><br><br>
											<input type="text" name="school" id="school" placeholder="Enter a brief, but descriptive title" class="form-control" v-model="testimonialField.projectType">
										</div>
									</div>
								</div>

								<div class="row">
									<div class="form-group">
										<label>Message to Client 0/800</label>
										<textarea class="form-control" rows="5" placeholder="Hi, it was great working together on your recent project! Would you mind sharing a brief testimonial about my work with you? It will help build my freelance business on Skilled Bios and show my value to other clients. Thanks in advance for your help!" v-model="testimonialField.messageToClient"></textarea>
									</div>
								</div>

								<div class="form-group" v-if="testimonialFields.length > 0">
									<label>&nbsp;</label>
									<button class="btn btn-danger btn-sm pull-left" type="button" @click="testimonialFields.splice(index, 1)">Remove</button>
								</div>
							</div><br><br>
							<hr>

							<div class="form-group" v-if="testimonialFields.length > 0">
								<label>&nbsp;</label>
								<button class="btn btn-success pull-right" type="submit">Request Testimonial</button>
							</div>

							<div class="form-group" v-else>
								<label>&nbsp;</label>
								<router-link :to="{ name: 'newCertifications', params: { professionalId: this.getProfessionalId }}">
									<button class="btn btn-success pull-right" type="submit">Next</button>
								</router-link>
							</div>
						</form>
		      		</div>
		      		<div class="col-md-6">
		      			<h4>Strengthen your profile with client testimonials</h4><br><br>
		      			<p>Showcase your skills and successes from clients outside of SkilledBioz</p><br>
		      			<p>Your clients will get an email with instructions for submitting your success story</p><br>
		      			<p>The testimonial will display on your profile once it’s verified by SkilledBioz.</p><br>
		      		</div>
		      	</div>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewTestimonial",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing request",
				show: false,
				testimonialFields: []
			}
		},

		created: function(){

		},

		computed: {
			getProfessionalId: function(){ return this.$route.params.professionalId; }
		},

		methods: {
			addTestimonialField: function(){
				this.testimonialFields.push({
					firstName: null,
					lastName: null,
					clientLinkedInProfile: null,
					clientTitle: null,
					projectType: null,
					messageToClient: null,
				});
			},

			save: function(){
				this.show = true;
				this.professional.testimonials = this.testimonialFields;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newCertifications" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>