<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <h4>{{ editing ? "Edit": "New" }} Product Category</h4>
    <br/>
    <p v-if="message !== null" class="error-message text-center">
      {{ message }}
    </p>
    <form class="form" @submit.prevent="save">
      <div class="form-group">
        <label> Name</label>
        <input type="text" v-model="productCategory.name" placeholder="Enter Product Category Name" class="form-control"/>
      </div>
      
      <div class="form-group">
        <label>Description</label>
        <input type="text" v-model="productCategory.description" placeholder="Enter Product Category description" class="form-control"/>
      </div>

      <div class="form-group">
        <label>Status</label>
        <select v-model="productCategory.status" class="form-control">
          <option value="">Select status</option>
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
          <option value="DELETED">Deleted</option>
        </select>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>&nbsp;</label>
            <router-link :to="{ name: 'complianceCategories' }" replace>
              <button class="btn btn-default btn-block" data-dismiss="modal">Cancel</button>
            </router-link>
          </div>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <label>&nbsp;</label>
          <button class="btn btn-success btn-block">Submit</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import ProductCategory from "@/models/ProductCategory";
  import ProductCategoryService from "@/services/ProductCategoryService";
  import loading from "vue-full-loading";

  export default {
    name: "NewRole",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing...",
        productCategory: new ProductCategory(),
        errors: [],
        message: null,
        editing: false
      };
    },

    mounted(){
      if("roleId" in this.$route.params){
        this.editing = true;
        this.findById()
      }
    },

    methods: {
      save: function() {
        this.productCategory.id = this.$route.params.productCategoryId;
        this.show = true;
        ProductCategoryService.save(this.productCategory).then(response => {
            if (response.data.statusCode == 422) {
              this.show = false;
              this.errors = response.data.errors;
            }
            if (response.data.statusCode == 400) {
              this.show = false;
              this.errors = response.data.errors;
              this.message = response.data.message;
            }
            if (response.data.statusCode == 201) {
              this.show = false;
              this.$router.push({ name: "productCategories" });
            }
          })
          .catch(error => {
            this.show = false;
            console.log(error);
            this.errors = error.response.data.errors;
            this.message = error.response.data.message;
          });
      },

      findById: function(){
        ProductCategoryService.findById(this.$route.params.productCategoryId).then(response => {
          this.role = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
