<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-8">
                <h4>Sunday, August 28th</h4>
                <h4>Good Morning, Joseph M.</h4>
              </div>
              <div class="col-md-4">
                <img src="@/assets/images/people.svg" height="100" width="150" class="img img-rounded">
              </div>
            </div>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <form class="form">
              <div class="form-group">
                <div class="input-group">
                  <input type="text" name="search" class="form-control" placeholder="Search for job...">
                  <div class="input-group-addon"><span class="glyphicon glyphicon-search"></span></div>
                </div>
              </div>
            </form>
            <p>Recent Searches: </p>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-9">
                <h4>Jobs you might like</h4>
                <p><a href="#">Best matches | Most recent | Saved jobs</a></p>
                <hr>
                <p>Browse jobs that match your experience to a client's hiring preferences. Ordered by most relevant.</p>
                <hr>
                <p>Need java with springboot, AWS</p>
                <p>Hourly: $6.00-$7.00 - Intermediate - Est. Time: More than 6 months, 30+ hrs/week - Posted 19 hours ago</p>
                <p>Having good knowledge on core java, springboot,Spring Api,springbatch,aws deployment,jenkins,angular8</p>
                <p><span class="badge">Java</span> <span class="badge">Spring Boot</span> <span class="badge">Amazon Web Services</span> <span class="badge">API</span></p>
                <p>Proposals: 5 to 10</p>
                <p><img src="@/assets/images/verified.png" height="30" width="30" class="img img-circle"> Payment verified. Rating: <span class="glyphicon glyphicon-star"></span> <span class="glyphicon glyphicon-star"></span> $9 spent. Harare</p>
              </div>
              <div class="col-md-3">
                <p>
                  <img src="@/assets/images/dislike.png" height="30" width="30" class="img img-circle">&nbsp;
                  <img src="@/assets/images/like.png" height="30" width="30" class="img img-circle">
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-9">
                <h4>Jobs you might like</h4>
                <p><a href="#">Best matches | Most recent | Saved jobs</a></p>
                <hr>
                <p>Browse jobs that match your experience to a client's hiring preferences. Ordered by most relevant.</p>
                <hr>
                <p>Need java with springboot, AWS</p>
                <p>Hourly: $6.00-$7.00 - Intermediate - Est. Time: More than 6 months, 30+ hrs/week - Posted 19 hours ago</p>
                <p>Having good knowledge on core java, springboot,Spring Api,springbatch,aws deployment,jenkins,angular8</p>
                <p><span class="badge">Java</span> <span class="badge">Spring Boot</span> <span class="badge">Amazon Web Services</span> <span class="badge">API</span></p>
                <p>Proposals: 5 to 10</p>
                <p><img src="@/assets/images/verified.png" height="30" width="30" class="img img-circle"> Payment verified. Rating: <span class="glyphicon glyphicon-star"></span> <span class="glyphicon glyphicon-star"></span> $9 spent. Harare</p>
              </div>
              <div class="col-md-3">
                <p>
                  <img src="@/assets/images/dislike.png" height="30" width="30" class="img img-circle">&nbsp;
                  <img src="@/assets/images/like.png" height="30" width="30" class="img img-circle">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body">
            <p class="text-center"><img src="@/assets/images/professional-1024x768.jpeg" height="100" width="150" class="img img-circle"></p>
            <h4 class="text-center"><router-link :to="{ name: 'freelancers', params: { freelancerId : 1 }}">Joseph M.</router-link></h4>
            <h4 class="text-center">Java and Spring Certified Professional.</h4>
            <hr>
            <p>Profile Completeness:</p>
            <div class="progress">
              <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 70%">
                <span class="sr-only">70% complete</span>
              </div>
            </div>
            <hr>
            <p>Ways to stand out to clients right now...</p>
            <p>Add your past work so clients know you’re a pro (+30%). <button class="btn btn-success btn-sm">Add work <span class="glyphicon glyphicon-plus"></span></button></p>
            <p><img src="@/assets/images/skillsBadge.jpeg" height="30" width="30"> Keep your skills up to date! <button class="btn btn-success btn-sm">Add new skills <span class="glyphicon glyphicon-plus"></span></button></p>
            <hr>
            <p><img src="@/assets/images/certficate.png" height="30" width="30"> Continue your Upwork Skill Certification <button class="btn btn-success btn-sm">Edit <span class="glyphicon glyphicon-edit"></span></button></p>
            <hr>
            <p><a href="#">132 Available Connects</a></p>
            <hr>
            <p>Availability Badge <span class="glyphicon glyphicon-edit"></span><br><i>Available now</i></p>
            <p>Hours per week <span class="glyphicon glyphicon-edit"></span><br><i>As Needed - Open to Offers</i></p>
            <p>Profile Visibility <span class="glyphicon glyphicon-edit"></span><br><i>Private</i></p>
            <hr>
            <p>My Categories <span class="glyphicon glyphicon-edit"></span></p>
            <div class="list-group">
              <li class="list-group-item">Web Development</li>
              <li class="list-group-item">Product Management</li>
              <li class="list-group-item">QA & Testing</li>
              <li class="list-group-item">Other - Software Development</li>
              <li class="list-group-item">Desktop Software Development</li>
              <li class="list-group-item">Ecommerce Development</li>
              <li class="list-group-item">Web & Mobile Design</li>
            </div>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <h4>Proposals</h4>
            <p><a href="#">My Proposals</a></p><br>
            <p>Looking for work? Browse jobs and get started on a proposal.</p>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <h4>Project Catalog</h4>
            <p><a href="#">My Project Dashboard</a></p><br>
            <p><a href="#">Create a Catalog project</a> for clients to purchase instantly.</p>
          </div>
        </div>

        <div class="panel">
          <div class="panel-body">
            <h4><a href="#">Direct Contracts</a></h4>
            <hr>
            <h4><a href="#">Get Paid</a></h4>
            <hr>
            <h4><a href="#">Community and Forums</a></h4>
            <hr>
            <h4><a href="#">Help Center</a></h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data: function() {
    return {};
  },

  mounted() {},

  computed: {
    currentUser: function() {
      return this.$store.state.auth.user.user;
    }
  }
};
</script>
