<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-6">
						<h4>Our Compliance Management Tool helps your business stay compliant and afloat with local laws and regulations.</h4>
						<img src="@/assets/images/compliance.webp" height="550" width="550" class="img img-responsive img-rounded">
					</div>
					<div class="col-md-6">
						<h4 class="text-danger">The process is simple</h4><br>
						<p><router-link :to="{ name: 'login' }">1. Log in to the dashboard</router-link></p>
						<p>2. Choose Compliances from the menu</p>
						<p>3. Choose the laws or regulations that your organization is mandated to comply with</p>
						<p>4. Get notified when due complying dates are approaching so as to stay afloat and avoid penalties and fines</p>
						<p>5. Get notified of any changes with local laws that affect your business or organization</p>
						<p>Enjoy the convinience: <img src="@/assets/images/approved.png" height="50" width="50"></p>
					</div>
				</div>
			</div>
		</div>

		<FooterComponent/>
	</div>
</template>

<script type="text/javascript">
	import FooterComponent from "@/components/FooterComponent";

	export default{
		components: {
			FooterComponent
		}
	};
</script>