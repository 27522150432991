<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Recommend Professional</h4>
		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Connection</label>
				<input type="text" name="connection" id="connection" placeholder="Enter connection" class="form-control" v-model="connection">
			</div>
			<div class="form-group">
				<label>Why They Are Qualified</label>
				<textarea name="whyTheyAreQualified" id="whyTheyAreQualified" placeholder="Enter whyTheyAreQualified" rows="5" class="form-control" v-model="whyTheyAreQualified"></textarea>
			</div>
			<div class="form-group">
				<label>Their Specific Skills</label>
				<input type="text" name="theirSpecificSkills" id="theirSpecificSkills" placeholder="Enter theirSpecificSkills" class="form-control" v-model="theirSpecificSkills">
			</div>
			<div class="form-group">
				<label>Scale Rating</label>
				<select class="form-control" v-model="scaleRating">
					<option value="">Select option</option>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
				</select>
			</div>
			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Recommendation from "@/models/Recommendation";
	import Professional from "@/models/Professional";

	export default{
		name: "CreateJob",
		components: {
			loading
		},

		data: function(){
			return {
				recommendation: new Recommendation,
				professional: new Professional,
				label: "Processing...",
				show: false,
				recommendations: [],
				connection: null,
				whyTheyAreQualified: null,
				theirSpecificSkills: null,
				scaleRating: null
			}
		},

		created: function(){ },

		computed: {
			getUser: function(){ return this.$store.state.auth.user }
		},

		methods: {
			save: function(){
				this.recommendations.push({
					recommenderId: this.getUser.userId,
					connection: this.connection,
					whyTheyAreQualified: this.whyTheyAreQualified,
					theirSpecificSkills: this.theirSpecificSkills,
					scaleRating: this.scaleRating,
				});
				console.log(this.recommendations);
				this.professional.recommendations = this.recommendations;
				this.show = true;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "professionals" });
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>