<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Promotion List</h4>
					</div>
					<router-link :to="{ name: 'new-promotion' }">
						<div class="col-md-2">
							<button class="btn btn-primary btn-block">New Promotion <span class="glyphicon glyphicon-plus"></span></button>
						</div>
					</router-link>
				</div>
				<!-- <hr> -->
				<!-- <form class="form">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group">
								<label>Section Type</label>
								<select class="form-control">
									<option value="">Select option</option>
									<option value="FULL_TIME_JOBS">Full time jobs</option>
									<option value="TENDER_JOBS">Contract jobs</option>
									<option value="VOLUNTEERING_JOBS">Volunteering jobs</option>
									<option value="SKILLED_EXPERTS">Skilled Experts</option>
									<option value="GRANT_AND_FUNDING">Grant & Funding</option>
									<option value="PRODUCTS">Product</option>
								</select>
							</div>
						</div>

						<div class="col-md-2">
							<label>Promo Runs From</label>
							<input type="date" class="form-control">
						</div>
						<div class="col-md-2">
							<label>Promo Runs To</label>
							<input type="date" class="form-control">
						</div>

						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-primary btn-block">Filter</button>
							</div>
						</div>
					</div>
				</form> -->
				<br>

				<table class="table table-bordered table-striped table-responsive">
					<thead class="bg-primary">
						<th>ID</th>
						<th>Section Type</th>
						<th>Headline</th>
						<th>Image</th>
						<th>Runs From</th>
						<th>Runs To</th>
						<th>Status</th>
						<th>Action</th>
					</thead>
					<tbody>
						<tr v-for="promotion in promotions" :key="promotion.id">
							<td>{{ promotion.id }}</td>
							<td>{{ promotion.sectionType }}</td>
							<td>{{ promotion.headline }}</td>
							<td>
								<img :src="PDF_API_URL + 'pdf/getPromotionPhoto/' + promotion.id" class="img img-responsive img-rounded" height="100" width="150">
							</td>
							<td>{{ promotion.runsFrom }}</td>
							<td>{{ promotion.runsTo }}</td>
							<td>{{ promotion.status }}</td>
							<td>
								<span>
									<router-link :to="{ name: 'view-promotion', params: { referenceNumber: promotion.referenceNumber }}">
										<button class="btn btn-primary btn-sm">View</button>
									</router-link>
								</span>&nbsp;
								<span v-if="promotion.status === 'ACTIVE'">
									<button class="btn btn-warning btn-sm" @click="approve(promotion.id)">Approve</button>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import PromotionService from "@/services/PromotionService.js";
	import loading from "vue-full-loading";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

	export default{
		name: "PromotionList",
		components: {
			loading
		},

		data: function () {
			return {
				promotions: [],
				label: 'Processing request',
				show: false,
				PDF_API_URL: PDF_API_URL,
			}
		},

		mounted(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				PromotionService.findAll().then(response => {
					this.show = false;
					this.promotions = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			approve: function(id){
				PromotionService.approve(id).then(() => {
					this.findAll();
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>