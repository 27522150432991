import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class SellerService{
	findAll(){
		return axios.get(GENERIC_SERVICE_URL + "sellers");
	}

	findById(id){
		return axios.get(GENERIC_SERVICE_URL + "sellers/" + id);
	}

	findByAssociatedAccountUsername(username){
		return axios.get(GENERIC_SERVICE_URL + "sellers/findByAssociatedAccountUsername/" + username);
	}

	save(seller){
		return axios.post(GENERIC_SERVICE_URL + "sellers", {
			id: seller.id,
			sellerType: seller.sellerType,
			description: seller.description,
			status: seller.status,
			name: seller.name,
			associatedAccountUsername: seller.associatedAccountUsername,
		});
	}
}

export default new SellerService;