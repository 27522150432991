<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
		        <div class="row" v-if="paymentResponse !== null">
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<fieldset>
							<legend><h4 class="text-center text-primary">Delivery service subscription payment details</h4></legend>
							<div class="list-group">
								<li class="list-group-item">
									<p class="text-center" v-show="paymentResponse.status === 'PAID'">
										<img src="@/assets/images/paymentSuccess.png" width="185" height="180">
									</p>
									<p class="text-center" v-show="paymentResponse.status !== 'PAID'">
										<img src="@/assets/images/paymentFail.png" height="190" width="230">
									</p>
								</li>
								<li class="list-group-item">Amount: <span class="pull-right">{{ paymentResponse.amount }}{{ deliveryCharge }}</span></li>
								<li class="list-group-item">Status: <span class="pull-right">{{ paymentResponse.status }}</span></li>
								<li class="list-group-item">Reference Number: <span class="pull-right">{{ paymentResponse.debitReference }}</span></li>
								<li class="list-group-item">Description: <span class="pull-right">{{ paymentResponse.description }}</span></li>
								<li class="list-group-item">Date: <span class="pull-right">{{ paymentResponse.createdAt }}</span></li>
							</div>
							<router-link :to="{ name: 'dashboard'}">
								<button class="btn pull-right black-btn" @click="proceedLogin">Proceed to dashboard</button>
							</router-link>
						</fieldset>
					</div>
					<div class="col-md-4"></div>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	// import PaymentService from "@/services/PaymentService";
	// import InvoiceService from "@/services/InvoiceService";
	import loading from "vue-full-loading";
	// import UserService from "@/services/UserService";
	// import SubscriptionService from "@/services/SubscriptionService.js";
	// import Subscription from "@/models/Subscription.js";

	export default {
		components: {
			loading
		},

		name: 'SubscriptionPaymentResponse',
		data: function(){
			return {
				show: false,
				label: "Processing Payment",
				gateway: null,
				merchantReference: null,
				paymentResponse: {
					status: 'PAID',
					currency: 'USD',
					amount: 30,
					debitReference: 'EFT123456',
					createdAt: new Date().getTime()
				},
				hash: null,
				username: JSON.parse(localStorage.getItem("username")),
				subscriptionPlanNumber: JSON.parse(localStorage.getItem('selectedSubscriptionPlanNumber')),
	    		price: JSON.parse(localStorage.getItem('selectedPrice')),
	    		currency: JSON.parse(localStorage.getItem('selectedCurrency')),
				// subscription: new Subscription,
				activeSubscription: null,
				deliveryCharge: JSON.parse(localStorage.getItem('deliveryCharge')),
				deliveryChargeCurrency: JSON.parse(localStorage.getItem('deliveryChargeCurrency')),
			}
		},

		mounted(){
			// this.findByActiveSubscription();
			// this.gateway = this.$route.query.gateway;
			// this.merchantReference = this.$route.query.merchantReference;
			// this.hash = this.$route.query.hash;
			// if (this.getUser !== null) {
			// 	this.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash);
			// }else{
			// 	if (this.username !== null) {
			// 		this.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash);
			// 	}else{
			// 		alert('Payment already processed.'); // stop replay payment
			// 		localStorage.clear();
			// 		this.$router.push({ name: 'login'});
			// 	}
			// }
		},

		computed: {
			// loggedIn() {
			// 	return this.$store.state.auth.status.loggedIn;
			// },

			// getUser: function() {
			// 	if (this.loggedIn) {
			// 		return this.$store.state.auth.user.user;
			// 	}else{
			// 		return null;
			// 	}
			// },
		},

		methods: {
			// receivePaymentUpdate: function(){
			// 	this.show = true;
			// 	PaymentService.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash).then(response => {
			// 		if (response.status === 200) {
			// 			this.paymentResponse = response.data.body;
			// 			if (this.paymentResponse.status === 'PAID') {
			// 				let paymentPostDto = {
			// 					referenceNumber: response.data.body.referenceNumber
			// 				};
			// 				if (this.activeSubscription === null) {
			// 					this.subscribe(paymentPostDto);
			// 				}
			// 			}else{
			// 				alert('Snap! Subscription payment failed.');
			// 			}
			// 		}
			// 		this.show = false;
			// 	}).catch(error => {
			// 		console.log(error);
			// 		this.show = true;
			// 	});
			// },

			// subscribe: function(paymentPostDto) {
		 //        this.show = true;
		 //        this.subscription.associatedUserUsername = this.username;
		 //        this.subscription.subscriptionPlanNumber = this.subscriptionPlanNumber;
		 //        SubscriptionService.save(this.subscription).then(response => {
		 //          if (response.data.statusCode == 201) {
			// 		this.invoice = response.data.body.invoice;
			// 		if (this.invoice !== null) {
			// 			this.updateInvoice(paymentPostDto, this.invoice.invoiceNumber);
			// 		}
		 //          }
		 //          this.show = false;
		 //        }).catch(error => {
		 //        	console.log(error);
		 //        	this.show = false;
		 //        });
		 //    },

			// updateInvoice: function(paymentPostDto, invoiceNumber) {
			// 	console.log('getUser', this.getUser);
		 //        this.show = true;
		 //        let invoice = null;
		 //        if (this.getUser !== null && this.getUser !== undefined) {
		 //        	console.log('if statement');
		 //        	invoice = {
		 //        		invoiceNumber: invoiceNumber,
		 //        		payment: paymentPostDto,
		 //        		payerUsername: this.getUser.username
		 //        	};
		 //        }else{
		 //        	console.log('else statement');
		 //        	invoice = {
		 //        		invoiceNumber: invoiceNumber,
		 //        		payment: paymentPostDto,
		 //        		payerUsername: this.username
		 //        	};
		 //        }
		 //        InvoiceService.save(invoice).then(response => {
		 //          if (response.data.statusCode == 201) {
		 //          	if (this.getUser === null && this.username !== null) {
		 //          		this.verifyUserAccount(this.username);
		 //          	}
		 //          }
		 //          this.show = false;
		 //        }).catch(error => {
		 //        	console.log(error);
		 //        	this.show = false;
		 //        });
		 //    },

		    proceedLogin(){
		    	localStorage.clear();
		    	this.$router.push({ name: 'login'});
		    },

		 //    verifyUserAccount(username){
		 //    	UserService.verifyUserAccount(username).then(response => {
		 //    		if (response.data.statusCode === 201) {
		 //    			console.log('Yay user verified!');
		 //    		}
		 //    	}).catch(error => {
		 //    		console.log(error);
		 //    	});
		 //    },

		 //    findByActiveSubscription: function() {
		 //        this.show = true;
		 //        SubscriptionService.findByActiveSubscription(this.username).then(response => {
		 //          if (response.data.statusCode == 200) {
			// 		this.activeSubscription = response.data.body;
		 //          }
		 //          this.show = false;
		 //        }).catch(error => {
		 //        	console.log(error);
		 //        	this.show = false;
		 //        });
		 //    },
		}
	};
</script>