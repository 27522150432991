import { API_URL } from "@/config/api.js";
import axios from "axios";
import authHeader from "@/services/authHeader";

class CompanyTypeService {
  getAllCompanyTypes() {
    return axios.get(API_URL + "companyTypes");
  }

  createCompanyType(companyType) {
    return axios.post(API_URL + "companyTypes", {
      companyType: companyType.companyType,
      companyDescription: companyType.companyDescription,
      companyTypeStatus: companyType.companyTypeStatus
    });
  }

  getCompanyTypeById(id) {
    return axios.get(API_URL + "companyTypes/" + id);
  }

  updateCompanyType(companyType, id) {
    return axios.put(
      API_URL + "companyTypes/" + id,
      {
        id: companyType.id,
        companyType: companyType.companyType,
        companyDescription: companyType.companyDescription,
        companyTypeStatus: companyType.companyTypeStatus
      },
      { headers: authHeader() }
    );
  }
}

export default new CompanyTypeService();
