<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <h4>Role Details</h4>
    <br />
    <div class="list-group">
      <div class="list-group-item">
        <p>{{ role.name }}</p>
        <p>{{ role.description }}</p>
        <p>
          <span class="label label-success" v-if="role.status == 'ACTIVE'"
            >Active</span
          >
          <span class="label label-default" v-if="role.status == 'INACTIVE'"
            >Inactive</span
          >
          <span class="label label-danger" v-if="role.status == 'DELETED'"
            >Deleted</span
          >
        </p>
        <p>
          <router-link :to="{ name: 'roles' }" replace
            ><button class="btn btn-default" data-dismiss="modal">
              Back
            </button></router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import Role from "@/models/Role";
import RoleService from "@/services/RoleService";
import loading from "vue-full-loading";

export default {
  name: "ViewBank",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Processing...",
      role: new Role()
    };
  },
  computed: {
    getRoleId() {
      return this.$route.params.roleId;
    }
  },
  created() {
    this.findById(this.getRoleId);
  },
  methods: {
    findById: function(id) {
      this.show = true;
      RoleService.findById(id)
        .then(response => {
          this.show = false;
          this.role = response.data.body;
        })
        .catch(error => {
          this.show = false;
          console.log("error: ", error);
          this.$router.push({ name: "roles" });
        });
    }
  }
};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
