<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-9">
						<h4>Product details</h4>	
					</div>
					<router-link :to="{ name: 'product-cart'}">
						<div class="col-md-2">
							<button class="btn btn-danger btn-block">View Cart</button><br><br>
						</div>
					</router-link>
					<router-link :to="{ name: 'product-search'}">
						<div class="col-md-1">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-9">
				<div class="panel">
					<div class="panel-body">
						<h4>Product photos</h4>
				        <div class="row">
				          <div class="col-md-2" v-for="photo in product.photos" :key="photo.id">
				            <img :src="GENERIC_SERVICE_URL + 'photos/' + photo.id" class="img img-responsive img-rounded img-preview" width="300px" height="300px">
				          </div>
				        </div>
						<div class="row">
							<div class="col-md-3">
								<h4>{{ product.percentageOff !== null ? product.percentageOff: 0 }}% off</h4>
								<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded img-preview" width="280" height="200">
							</div>
							<div class="col-md-6">
								<h4>{{ product.name }}</h4>
								<p>{{ product.description }}</p>
								<p>4.3, 104 Reviews</p>
								<hr>
								<h4>In stock <span class="badge">{{ product.inStock ? "Yes": "No" }}</span>, JHBCPT, When do I get it?</h4>
								product.seller: {{ product.seller === null }}
								<p v-if="product.seller !== null">Sold by 
									<router-link :to="{ name: 'sellers/products', params: { sellerId: product.seller.id }}">
										{{ product.seller.name }}
									</router-link>
									<!-- </a></span>. Fulfilled by <span><a href="#">{{ product.deliveryFulfilledBy }}</a></span> -->
								<!-- </p> -->
								<hr>
								<ul>
									<li>Eligible for next-day delivery or collection. <span class="badge">{{ product.eligibleForNextDayDeliveryOrCollection ? "Yes": "No" }}</span></li>
									<li>Eligible for Cash on Delivery. <span class="badge">{{ product.eligibleForCashOnDelivery ? "Yes": "No" }}</span></li>
									<li>Free Delivery Available. <span class="badge">{{ product.freeDeliveryAvailable ? "Yes": "No" }}</span></li>
									<li>Hassle-Free Exchanges & Returns for 30 Days. <span class="badge">{{ product.hassleFreeExchangesAndReturnsFor30Days ? "Yes": "No" }}</span></li>
									<li>12-Month Limited Warranty. <span class="badge">{{ product.twelveMonthLimitedWarranty ? "Yes": "No" }}</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="panel">
					<div class="panel-body">
						<h4>Description</h4>
						<p>{{ product.description }}</p>
					</div>
				</div>

				<!-- <h4>You Might Also Like</h4>
				<div class="row">
					<div class="col-md-3" v-for="product in products" :key="product.id">
						<div class="panel">
							<div class="panel-body">
								<router-link :to="{ name: 'like-product', params: { productNumber: product.productNumber }}">
									<p>{{ product.percentageOff }}% off <span class="pull-right">Like/Dislike</span></p>
								</router-link>
								<img src="@/assets/images/computers.jpeg" height="100" width="150" class="img img-rounded"><br><br>
								<img src="@/assets/images/computers.jpeg" height="20" width="20" class="img img-rounded">
								<p>{{ product.name }}</p>
								<h4>{{ product.description }}</h4>
								<p>Current price: {{ product.currency }}{{ product.price }}</p> -->
									<!-- Was <del>{{ product.currency }}{{ product.price }}</del></p> -->
							<!-- 	<p><span class="badge">JHB</span> <span class="badge">CPT</span></p>
								<p>
									<button class="btn btn-primary">Add to cart <span class="glyphicon glyphicon-plus"></span></button>&nbsp;
									<router-link :to="{ name: 'myProductListDetails/view', params: { productId: product.id }}">
										<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div> -->
			</div>

			<div class="col-md-3">
				<div class="panel">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-6">
								<h4>{{ product.currency }}{{ product.price }}</h4>
							</div>
							<div class="col-md-3">
								<p><span class="glyphicon glyphicon-thumbs-up"> {{ product.likes.length }}</span></p>
							</div>
							<div class="col-md-3">
								<p><span class="glyphicon glyphicon-thumbs-down"> {{ product.dislikes.length }}</span></p>
							</div>
						</div>
						<!-- <router-link :to="{ name: 'like-product', params: { productNumber: product.productNumber }}"> -->
						<div class="row">
							<div class="col-md-8"></div>
							<div class="col-md-2">
								<a href="#"><span class="btn btn-success btn-xs" @click="likeProduct(product.productNumber)">Like</span></a>
							</div>
							<div class="col-md-2">
								<span class="btn btn-danger btn-xs" @click="dislikeProduct(product.productNumber)">Dislike</span>	
							</div>
						</div>
						<!-- </router-link> -->
						<p><span class="badge">{{ product.freeDeliveryAvailable ? "FREE DELIVERY": "FREE DELIVERY NOT AVAILABLE" }}</span></p>
						<!-- <p><del>{{ product.currency }}{{ product.price }}</del></p> -->
						<p>{{ product.description }}</p>
						<hr>
						<p>{{ product.buyNowPayLater ? "Buy Now, Pay Later": "Buy Now, Pay Later Not Available" }}</p>
						<!-- <p>Pay 4 interest-free instalments of R 481 using Payflex. Learn More</p> -->
						<button :class="product.addedToCart ? 'btn btn-success': 'btn btn-default' " @click="addProductToCart(product)" type="button">{{ product.addedToCart ? "Added to cart": "Add to cart" }} <span class="glyphicon glyphicon-plus"></span>
						</button>&nbsp;
						<!-- <p><button class="btn btn-primary">Add to wish list</button></p> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import Product from "@/models/Product";
	import loading from "vue-full-loading";
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

	export default{
		name: "ViewProduct",
		components: {
			loading
		},

		data: function(){
			return {
				products: [],
				product: new Product,
				show: false,
				label: "Processing request",
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
				cartProducts: [],
			}
		},

		mounted(){
			this.findById();
			this.findAll();
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts") || '[]');
			console.log('cartProducts test: ', this.cartProducts);
		},

		computed: {
			getProductId: function(){
				return this.$route.params.productId;
			},

			addedToCartTest(){
				return function(){
					var vm = this;
					return vm.containsObject(vm.product, vm.cartProducts);
				}
			},

			getUser: function() {
	    		return this.$store.state.auth.user.user;
	    	}
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProductService.findAll().then(response => {
					this.products = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			findById: function(){
				this.show = true;
				ProductService.findById(this.getProductId).then(response => {
					this.product = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			containsObject(obj, list) {
				console.log('list: ', list);
			    if (list !== null && list !== undefined) {
			    	for (let i = 0; i < list.length; i++) {
				        if (list[i] === obj) {
				            return true;
				        }
				    }
			    }
			    return false;
			},

			addProductToCart(product){
				if (this.containsObject(product, JSON.parse(localStorage.getItem("cartProducts") || '[]'))) {
					console.log('product already added to cart');
				}else{
					console.log('addedToCart')
					product.addedToCart = true;
					this.cartProducts.push(product);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
				}
			},

			likeProduct: function(productNumber) {
				this.show = true;
		        ProductService.likeProduct(productNumber, this.getUser.username).then(() => {
		        	this.findById();
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error.response);
		        	this.show = false;
		          	this.message = error.response.data.message;
		        });
		    },

		    dislikeProduct: function(productNumber) {
				this.show = true;
		        ProductService.dislikeProduct(productNumber, this.getUser.username).then(() => {
		        	this.findById();
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error.response);
		        	this.show = false;
		          	this.message = error.response.data.message;
		        });
		    }
		}
	};
</script>

<style type="text/css" scoped>
	.img-preview {
    width: 100%;
    height: 200px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    object-fit: cover;
  }
</style>
