<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<div class="row">
		      		<div class="col-md-10"></div>
		      		<div class="col-md-2">
		      			<router-link :to="{ name: 'tender-jobs-configuration' }">
				      		<button class="btn btn-primary btn-block">Back</button>
				      	</router-link>
		      		</div>
		      	</div>
		      	<br>

				<form class="form" @submit.prevent="save">
					<fieldset>
						<legend>Tender Job Details</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Tender reference number</label>
									<input type="text" name="" class="form-control" placeholder="Enter reference number" v-model="job.referenceNumber">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Job category</label>
									<select class="form-control" v-model="job.categoryId">
										<option value="" disabled>Select option</option>
										<option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">{{ jobCategory.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Job title</label>
									<input type="text" name="" class="form-control" placeholder="Enter job title" v-model="job.title">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expiry date</label>
									<input type="date" name="expiryDate" v-model="job.expiryDate" class="form-control">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Internal or external</label>
									<select class="form-control" v-model="job.internalOrExternal">
										<option value="" disabled>Select option</option>
										<option value="INTERNAL">INTERNAL</option>
										<option value="EXTERNAL">EXTERNAL</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-if="job.internalOrExternal === 'INTERNAL'">
								<div class="form-group">
									<label>Fixed rate</label>
									<input type="text" name="fixedRate" v-model="job.fixedRate" placeholder="Enter Fixed Rate" class="form-control">
								</div>
							</div>
							<div class="col-md-3" v-if="job.internalOrExternal === 'INTERNAL'">
								<div class="form-group">
									<label>Currency</label>
									<select class="form-control" v-model="job.currency">
										<option value="" disabled>Select option</option>
										<option value="USD">USD</option>
										<option value="ZWL">ZWL</option>
										<option value="RAND">RAND</option>
										<option value="PULA">PULA</option>
										<option value="KWACHA">KWACHA</option>
										<option value="SHILLING">SHILLING</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group" v-if="job.internalOrExternal === 'EXTERNAL'">
									<label>External link</label>
									<input type="text" name="externalLink" v-model="job.externalLink" placeholder="Enter external link" class="form-control">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Availability</label>
									<select class="form-control" v-model="job.availability">
										<option value="" disabled>Select option</option>
										<option value="MORE_THAN_30_HOURS_PER_WEEK">MORE_THAN_30_HOURS_PER_WEEK</option>
										<option value="LESS_THAN_30_HOURS_PER_WEEK">LESS_THAN_30_HOURS_PER_WEEK</option>
										<option value="AS_NEEDED">AS_NEEDED</option>
										<option value="NONE">NONE</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Experience years</label>
									<input type="text" v-model="job.experienceYears" class="form-control" placeholder="Number of years of experience">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Required skills</label>
									<input type="text" v-model="job.requiredSkills" class="form-control" placeholder="Enter required skills">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Payment terms</label>
									<select class="form-control" v-model="job.paymentTerms">
										<option value="" disabled>Select option</option>
										<option value="PAY_ON_DELIVERY">PAY_ON_DELIVERY</option>
										<option value="PAID_PER_HOUR">PAID_PER_HOUR</option>
										<option value="ONCE_OFF">ONCE_OFF</option>
										<option value="ONGOING_PER_DELIVERABLE">ONGOING_PER_DELIVERABLE</option>
										<option value="MONTHLY">MONTHLY</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group" v-show="job.type !== 'FULL_TIME'">
									<label>Project type</label>
									<select class="form-control" v-model="job.projectType">
										<option value="" disabled>Select option</option>
										<option value="SHORT_TERM">SHORT_TERM</option>
										<option value="LONG_TERM">LONG_TERM</option>
										<option value="AS_NEEDED">AS_NEEDED</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Duration of the work (engagement)</label>
									<input type="text" name="engagementDuration" v-model="job.engagementDuration" placeholder="Enter engagement duration" class="form-control">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Engagement Duration Type</label>
									<select class="form-control" v-model="job.engagementDurationMeasurement">
										<option value="" disabled>Select option</option>
										<option value="DAYS">Days</option>
										<option value="MONTHS">Months</option>
										<option value="YEARS">Years</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Objective of the engagement</label>
									<textarea class="form-control" rows="5" placeholder="Enter objective of engagement" v-model="job.engagementObjective"></textarea>
								</div>
							</div>
						</div>
					</fieldset>
					
					<fieldset>
						<legend>Scope of work</legend>
						<button class="btn btn-primary btn-md pull-right" type="button" @click="addScopeOfWorkFields">Add <span class="glyphicon glyphicon-plus"></span></button>
						<br><br>
						<div class="row" v-for="(scopeOfWorkField, index) in scopeOfWorkFields" :key="scopeOfWorkField.id">
							<div class="col-md-4">
								<div class="form-group">
									<label>Scope of work name</label>
									<input type="text" name="name" v-model="scopeOfWorkField.name" placeholder="Enter scope of work name" class="form-control">
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Scope of work description</label>
									<input type="text" name="description" v-model="scopeOfWorkField.description" placeholder="Enter scope of work description" class="form-control">
								</div>
							</div>
							<div class="col-md-2">
								<br>
								<button class="btn btn-danger btn-md" type="submit" @click="scopeOfWorkFields.splice(index, 1)">Remove</button>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Reporting requirments</legend>
						<button class="btn btn-primary btn-md pull-right" type="button" @click="addReportingRequirmentsFields">Add <span class="glyphicon glyphicon-plus"></span></button>
						<br><br>
						<div class="row" v-for="(reportingRequirmentsField, index) in reportingRequirmentFields" :key="reportingRequirmentsField.id">
							<div class="col-md-4">
								<div class="form-group">
									<label>Reporting requirement name</label>
									<input type="text" name="name" v-model="reportingRequirmentsField.name" placeholder="Enter reporting requirement name" class="form-control">
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Reporting requirement description</label>
									<input type="text" name="description" v-model="reportingRequirmentsField.description" placeholder="Enter reporting requirement description" class="form-control">
								</div>
							</div>
							<div class="col-md-2">
								<br>
								<button class="btn btn-danger btn-md" type="submit" @click="reportingRequirmentFields.splice(index, 1)">Remove</button>
							</div>
						</div>
					</fieldset>

					<fieldset>
						<legend>Contractual obligations</legend>
						<button class="btn btn-primary btn-md pull-right" type="button" @click="addContractualObligationsFields">Add <span class="glyphicon glyphicon-plus"></span></button>
						<br><br>
						<div class="row" v-for="(contractualObligationsField, index) in contractualObligationFields" :key="contractualObligationsField.id">
							<div class="col-md-4">
								<div class="form-group">
									<label>Contractual obligation name</label>
									<input type="text" name="name" v-model="contractualObligationsField.name" placeholder="Enter contractual obligation name" class="form-control">
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Contractual obligation description</label>
									<input type="text" name="description" v-model="contractualObligationsField.description" placeholder="Enter contractual obligation description" class="form-control">
								</div>
							</div>
							<div class="col-md-2">
								<br>
								<button class="btn btn-danger btn-md" type="submit" @click="contractualObligationFields.splice(index, 1)">Remove</button>
							</div>
						</div>
					</fieldset>
					
					<fieldset>
						<legend>Fees and expenses</legend>
						<button class="btn btn-primary btn-md pull-right" type="button" @click="addFeesAndExpenseFields">Add <span class="glyphicon glyphicon-plus"></span></button>
						<br><br>
						<div class="row" v-for="(feesAndExpenseField, index) in feesAndExpenseFields" :key="feesAndExpenseField.id">
							<div class="col-md-4">
								<div class="form-group">
									<label>Fees and expense name</label>
									<input type="text" name="name" v-model="feesAndExpenseField.name" placeholder="Enter fees and expense name" class="form-control">
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Fees and expense description</label>
									<input type="text" name="description" v-model="feesAndExpenseField.description" placeholder="Enter fees and expense description" class="form-control">
								</div>
							</div>
							<div class="col-md-2">
								<br>
								<button class="btn btn-danger btn-md" type="submit" @click="feesAndExpenseFields.splice(index, 1)">Remove</button>
							</div>
						</div>
					</fieldset> 

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobCategoryService from "@/services/JobCategoryService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";

	export default{
		name: "NewTenderJob",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				jobCategories: [],
				job: new Job,
				label: "Processing request",
				show: false,
				scopeOfWorkFields: [],
				reportingRequirmentFields: [],
				contractualObligationFields: [],
				feesAndExpenseFields: []
			}
		},

		mounted(){
			this.findAllJobCategories();
		},

		methods: {
			addScopeOfWorkFields(){
				this.scopeOfWorkFields.push({
					id: null,
					name: null,
					description: null
				});
			},

			addReportingRequirmentsFields(){
				this.reportingRequirmentFields.push({
					id: null,
					name: null,
					description: null
				});
			},

			addContractualObligationsFields(){
				this.contractualObligationFields.push({
					id: null,
					name: null,
					description: null
				});
			},

			addFeesAndExpenseFields(){
				this.feesAndExpenseFields.push({
					id: null,
					name: null,
					description: null
				});
			},

			findAllJobCategories: function(){
				JobCategoryService.findAll(this.job).then(response => {
					this.jobCategories = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.show = true;
				this.job.posterUserId = this.getUser.userId;
				this.job.type = 'TENDER';
				localStorage.setItem("type", JSON.stringify(this.job.type));
				this.job.scopeOfWorks = this.scopeOfWorkFields;
				this.job.reportingRequirements = this.reportingRequirmentFields;
				this.job.contractualObligations = this.contractualObligationFields;
				this.job.feesAndExpenses = this.feesAndExpenseFields;
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "tender-job-location", params: { jobId: response.data.body.id, type: response.data.body.type }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
				this.show = false;
			}
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			}
		}
	};
</script>