<template>
	<div>
		<router-link :to="{ name: 'compliance-list' }">
			<button class="btn btn-default pull-right">Close</button>
		</router-link>
		<br><br>

		<div class="list-group" v-if="compliance !== null">
			<li class="list-group-item">ID: {{ compliance.id }}</li>
			<li class="list-group-item">Name: {{ compliance.name }}</li>
			<li class="list-group-item">Description: {{ compliance.description }}</li>
			<li class="list-group-item">Occurence: {{ compliance.occurence }}</li>
			<li class="list-group-item">Start Date {{ compliance.startDate }}</li>
			<li class="list-group-item">End Date: {{ compliance.endDate }}</li>
			<li class="list-group-item">Country: {{ compliance.country !== null ? compliance.country.name: '' }}</li>
			<li class="list-group-item">Category: {{ compliance.category }}</li>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ComplianceService from "@/services/ComplianceService";

	export default{
		name: 'ViewCompliance',
		data: function(){
			return {
				compliance: null
			}
		},

		created(){
			this.findById();
		},

		methods: {
			findById: function(){
				ComplianceService.findById(this.getComplianceId).then(response => {
					this.compliance = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {
			getComplianceId: function(){
				return this.$route.params.complianceId;
			}
		}
	}
</script>