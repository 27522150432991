<template>
	<div>

		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Our Compliance Management Tool helps your organization become more organized and compliant to local laws and regulations, helping you become more organized and compliant!</h4>
      	<hr>

      	<h4>Premium Compliance Management</h4>

      	<div class="row">
      		<div class="col-md-6">
      			<h4>$30 Per Annum</h4>
      			<hr>
		      	<ul>
		      		<li>Dashboard, SMS, Email and WhatsApp notification</li>
		      		<li>Alerts on compliance due dates approaching</li>
		      		<li>Access to all uploaded evidence of compliance</li>
		      		<li>Impact report showing penalties to be incurred for non-compliance at each stage of overdue dates</li>
		      		<li>24/7 Customer Support</li>
		      	</ul>
      		</div>
      		<div class="col-md-6">
      			<h4>Your monthly payment will be ${{ premiumAmount }}</h4>
      			<img src="@/assets/images/Visa-Mastercard-Logo.png" height="200" width="300">
      		</div>
      	</div>

      	<h4>Choose your plan.</h4>
      	<div class="btn btn-group">
      		<button class="btn btn-success">Monthly Premium</button>
      		<button class="btn btn-primary" @click="calculatePremium(12)">Annual Premium</button>
      	</div><br><br>

      	<form class="form" @submit.prevent="subscribe">
			<div class="row">
	      		<div class="col-md-9"></div>
	      		<div class="col-md-3">
	      			<button class="btn btn-success btn-block pull-right" type="submit">Subscribe</button>
	      		</div>
	      	</div>
		</form>
	
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	// import JobCategoryService from "@/services/JobCategoryService";
	// import JobService from "@/services/JobService";
	// import UserService from "@/services/UserService";
	import User from "@/models/User";

	export default{
		name: "JobsPreferences",
		components: {
			loading
		},

		data: function(){
			return {
				selectedPreferences: [],
				user: new User,
				label: "Processing request...",
				show: false,
				jobCategories: [],
				person: null,
				premium: 2.50,
			}
		},

		created: function(){
		},

		computed: {
			getPersonId: function(){
				return this.$store.state.auth.user.userId;
			},

			premiumAmount: function(){
				return this.premium;
			}
		},

		methods: {
			calculatePremium: function(numberOfMonths){
				let premium = this.premium * numberOfMonths;
				this.premium = premium;
			},

			subscribe: function(){
				this.$router.push({ name: 'compliancePaymentPlanCheckout' });
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>