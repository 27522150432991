import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class OrderService {
  save(order) {
    return axios.post(GENERIC_SERVICE_URL + "orders", {
      id: order.id,
      orderNumber: order.orderNumber,
      awarded: order.awarded,
      awardedCarrierId: order.awardedCarrierId,
      assignedVehicleId: order.assignedVehicleId,
      fulfilledById: order.fulfilledById,
      deliveryAddressId: order.deliveryAddressId,
      collectionAddresses: order.collectionAddresses,
      cartProducts: order.cartProducts,
      customerUsername: order.customerUsername,
      orderDate: order.orderDate,
      orderLines: order.orderLines,
      invoice: order.invoice,
      released: order.released,
      releasedDate: order.releasedDate,
      releasedTime: order.releasedTime,
      collected: order.collected,
      collectionAt: order.collectionAt,
      deliveredAt: order.deliveredAt,
      confirmedDelivery: order.confirmedDelivery,
      confirmedDeliveryAt: order.confirmedDeliveryAt,
      deliveryComments: order.deliveryComments,
      deliveryCharge: order.deliveryCharge,
      currency: order.currency,
      status: order.status,
    });
  }

  saveCompliance(company) {
    return axios.post(GENERIC_SERVICE_URL + "orders" + "/saveCompliance", {
        companyId: company.companyId,
        compliances: company.compliances
      }
    );
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "orders");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "orders/" + id);
  }

  findByAssociatedUserId(userId) {
    return axios.get(GENERIC_SERVICE_URL + "orders/findByAssociatedUserId/" + userId);
  }
  
  updateCompanyById(company, id) {
    return axios.put(GENERIC_SERVICE_URL + "orders/" + id,{
        companyId: company.companyId,
        companyTypeId: company.companyTypeId,
        companyName: company.companyName,
        companyDescription: company.companyDescription,
        possibleWorkDays: company.possibleWorkDays,
        companyStatus: company.companyStatus
      });
  }

  saveAttachment(formData){
    return axios.post(GENERIC_SERVICE_URL + "orders/saveAttachment", formData);
  }

  findByUuid(uuid) {
    return axios.get(GENERIC_SERVICE_URL + "orders/findByUuid/" + uuid);
  }

  findByAssociatedUserUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "orders/findByAssociatedUserUsername/" + username);
  }

  findByOrderNumber(orderNumber) {
    return axios.get(GENERIC_SERVICE_URL + "orders/findByOrderNumber/" + orderNumber);
  }

  acceptOrder(orderNumber, carrierId) {
    return axios.post(GENERIC_SERVICE_URL + "orders/acceptOrder/" + orderNumber + "/" + carrierId);
  }

  declineOrder(orderNumber) {
    return axios.post(GENERIC_SERVICE_URL + "orders/declineOrder/" + orderNumber);
  }

  release(order) {
    return axios.post(GENERIC_SERVICE_URL + "orders/release", {
      orderNumber: order.orderNumber,
      releasedDate: order.releasedDate,
      releasedTime: order.releasedTime,
    });
  }

  collected(orderNumber) {
    return axios.post(GENERIC_SERVICE_URL + "orders/collected/" + orderNumber);
  }

  delivered(order) {
    return axios.post(GENERIC_SERVICE_URL + "orders/delivered", {
      orderNumber: order.orderNumber
    });
  }

  confirmedDelivery(order) {
    return axios.post(GENERIC_SERVICE_URL + "orders/confirmedDelivery", {
      orderNumber: order.orderNumber,
      status: order.status,
      deliveryComments: order.deliveryComments
    });
  }

  findByCustomerUsername(customerUsername) {
    return axios.get(GENERIC_SERVICE_URL + "orders/findByCustomerUsername/" + customerUsername);
  }

  findByAwardedCarrierAssociatedUserUsername(customerUsername) {
    return axios.get(GENERIC_SERVICE_URL + "orders/findByAwardedCarrierAssociatedUserUsername/" + customerUsername);
  }
}
export default new OrderService;
