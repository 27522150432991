<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <div class="col-md-2">
        <router-link :to="{ name: 'configurations' }">
          <button class="btn btn-primary">Back</button>
        </router-link>
      </div>

      <div class="col-md-8">
        <h4 class="text-center">All product departments</h4>
      </div>

      <div class="col-md-2">
        <router-link :to="{ name: 'new-product-department' }">
          <button class="btn btn-success pull-right"> New product department <span class="glyphicon glyphicon-plus"></span></button>
        </router-link>
      </div>
    </div>
    <br>

    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="productDepartment in productDepartments" :key="productDepartment.id">
          <td>{{ productDepartment.id }}</td>
          <td>{{ productDepartment.name }}</td>
          <td>{{ productDepartment.description }}</td>
          <td><span class="label label-primary">{{ productDepartment.status }}</span></td>
          <td>
            <router-link :to="{ name: 'view-product-department', params: { id: productDepartment.id } }">
              <button class="btn btn-primary">View</button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import ProductDepartmentService from "@/services/ProductDepartmentService";
  import loading from "vue-full-loading";

  export default {
    name: "ProductDepartmentList",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        productDepartments: []
      };
    },

    created() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        ProductDepartmentService.findAll().then(response => {
            this.productDepartments = response.data.body;
            this.show = false;
          }).catch(error => {
            this.show = false;
            console.log(error);
          });
      }
    }
  };
</script>