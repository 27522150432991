<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<router-link :to="{ name: 'tender-job-search'}">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>

				<h4>Tender Job Details</h4>
				<ul class="list-group" v-if="job !== null">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">Job Number: {{ job.jobNumber }}</li>
					<li class="list-group-item">Job Title: {{ job.title }}</li>
					<li class="list-group-item">Type: {{ job.type }}</li>
					<li class="list-group-item">Category: {{ job.category !== null ? job.category.name: "Not available" }}</li>
					<li class="list-group-item">Reference Number: {{ job.referenceNumber }}</li>
					<span v-if="job.type === 'FULL_TIME'">
						<li class="list-group-item">Fixed Rate: {{ job.currency }} {{ job.fixedRate }} per hour</li>
					</span>
					<span v-if="job.type === 'CONTRACT'">
						<li class="list-group-item">Hourly Rate: {{ job.currency }} {{ job.hourlyRate }} per hour</li>
					</span>
					<li class="list-group-item">Expiry Date: {{ job.expiryDate }}</li>
					<li class="list-group-item">Skills Level: {{ job.skillsLevel }}</li>
					<li class="list-group-item">Availability: {{ job.availability }}</li>
					<li class="list-group-item">Estimated Budget: {{ job.estimatedBudget }}</li>
					<li class="list-group-item">Expertise Level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Payment Terms: {{ job.paymentTerms }}</li>
					<li class="list-group-item">Reach: {{ job.reach }}</li>
					<li class="list-group-item">Project Type: {{ job.projectType }}</li>
					<li class="list-group-item">Commitment hours per week: {{ job.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Project length: {{ job.projectLengthInMonths }}</li>
					<li class="list-group-item">Description: {{ job.description }}</li>
					<li class="list-group-item">Experience required: {{ job.experienceYears }} years</li>
					<li class="list-group-item">Required Skills: {{ job.requiredSkills }}</li>
					<li class="list-group-item">Engagement Duration: {{ job.engagementDuration }} {{ job.engagementDurationMeasurement }}</li>
					<li class="list-group-item">Engagement Objective: {{ job.engagementObjective }}</li>
					<li class="list-group-item">Scope Of Works <br><br>
						<div class="list-group" v-for="scopeOfWork in job.scopeOfWorks" :key="scopeOfWork.id">
							<li class="list-group-item">{{ scopeOfWork.name }}</li>
							<li class="list-group-item">{{ scopeOfWork.description }}</li>
						</div>
					</li>
					<li class="list-group-item">Reporting Requirements <br><br>
						<div class="list-group" v-for="reportingRequirement in job.reportingRequirements" :key="reportingRequirement.id">
							<li class="list-group-item">{{ reportingRequirement.name }}</li>
							<li class="list-group-item">{{ reportingRequirement.description }}</li>
						</div>
					</li>
					<li class="list-group-item">Contractual Obligations <br><br>
						<div class="list-group" v-for="contractualObligation in job.contractualObligations" :key="contractualObligation.id">
							<li class="list-group-item">{{ contractualObligation.name }}</li>
							<li class="list-group-item">{{ contractualObligation.description }}</li>
						</div>
					</li>
					<li class="list-group-item">Fees And Expenses <br><br>
						<div class="list-group" v-for="feesAndExpense in job.feesAndExpenses" :key="feesAndExpense.id">
							<li class="list-group-item">{{ feesAndExpense.name }}</li>
							<li class="list-group-item">{{ feesAndExpense.description }}</li>
						</div>
					</li>
					<li class="list-group-item">
						<h4><a :href="PDF_API_URL + 'pdf/getJobAttachment/' + job.id" target="_blank" class="text-danger">View Tender Attachment</a></h4>
					</li>
					<li class="list-group-item" v-if="job.location !== null">
						Job location
						<ul class="list-group">
							<li class="list-group-item">Country: {{ job.location.country !== null ? job.location.country.name: 'NA' }}</li>
							<li class="list-group-item">Province: {{ job.location.province !== null ? job.location.province.name: 'NA' }}</li>
							<li class="list-group-item">City: {{ job.location.city !== null ? job.location.city.name: 'NA' }}</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";
	import loading from "vue-full-loading";

	export default{
		name: "ViewTenderJobsConfiguration",
		components: {
			loading
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		data: function(){
			return {
				job: null,
				PDF_API_URL: PDF_API_URL,
				show: false,
				label: "Processing request"
			}
		},

		mounted(){
			this.findById();
		},

		methods: {
			findById: function(){
				this.show = true;
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	};
</script>