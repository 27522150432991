<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>Payment Checkout</h4>
					</div>
					<router-link :to="{ name: 'product-cart' }">
						<div class="col-md-1">
							<button class="btn btn-danger btn-block">Cancel Order</button>
						</div>
					</router-link>
				</div>
				<hr><br>

				<p>Products Cost {{ cartProducts[0].currency }}{{ totalProductsCost }}</p>
				<p>Delivery Charge {{ cartProducts[0].currency }}{{ deliveryCharge }}</p>
				<hr>
				<p class="text-danger">Total payment {{ cartProducts[0].currency }}{{ totalPayment }}</p>
				<br><br>

				<form class="form" @submit.prevent="pay">
					<div class="form-group">
						<label>Choose payment gateway</label>
						<select class="form-control" v-model="integrationGatewayType" required>
							<option value="">Select option</option>
							<option value="DPO">VISA (USD Payment)</option>
						</select>
					</div><br><br>
					<div class="row">
						<div class="col-md-10">
							<br>
							<p>If you choose to pay, you will be redirected to payment gateway to complete the payment process.</p>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Pay now</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		name: "Checkout",
		components: {

		},
		data: function(){
			return {
				cartProducts: JSON.parse(localStorage.getItem("cartProducts" || 0)),
				integrationGatewayType: null,
				trackId: "BUYING_ICT_EQUIPMENT",
				terminalId: "SBZ01253",
				currency: "USD",
				amount: 1.00,
				deliveryCharge: JSON.parse(localStorage.getItem('deliveryCharge')),
				deliveryChargeCurrency: JSON.parse(localStorage.getItem('deliveryChargeCurrency')),
			}
		},

		mounted: function(){
			console.log('cartProducts: ', this.cartProducts);
		},

		computed: {
			totalProductsCost: function(){
				let totalProductsCost = 0;
				for(var i = 0; i < this.cartProducts.length; i++){
					totalProductsCost += this.cartProducts[i].price;
				}
				return totalProductsCost;
			},

			// calculateTotalProductsCost: function(){
			// 	let totalProductsCost = 0;
			// 	for(var i = 0; i < this.cartProducts.length; i++){
			// 		totalProductsCost += this.cartProducts[i].totalProductCost;
			// 	}
			// 	return parseFloat(totalProductsCost);
			// }
			totalPayment(){
				return parseFloat(this.totalProductsCost) + parseFloat(this.deliveryCharge);
			}
		},

		methods: {
			pay: function(){
				// const stewardPayPaymentUrl = "https://paynow.co.zw";
				// // const upayWisePaymentUrl = "https://upaywise.com/paymentgateway-external/pghosted.xhtml?trackId=" + this.trackId + "&terminalId=" + this.terminalId + "&amount=" + this.calculateTotalProductsCost + "&currency=" + this.currency;
				// const dpoPaymentUrl = "https://dpogroup.com/login/";
				
				// if(this.integrationGatewayType === "STEWARD_PAY"){
				// 	this.redirectToCheckout(stewardPayPaymentUrl)
				// }
				// if(this.integrationGatewayType === "DPO"){
				// 	this.redirectToCheckout(dpoPaymentUrl);
				// }
				this.$router.push({ name: 'payment-response' });
				localStorage.removeItem('cartProducts');
			},

			redirectToCheckout: function(paymentUrl){
				window.location.replace(paymentUrl);
			}

			// wait for the asyncrhonous check of response url and get success parameters
		}
	}
</script>
