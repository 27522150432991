import axios from "axios";
import authHeader from "@/services/AuthHeaderService.js";
import { AUTH_API_URL } from "@/config/AuthServiceConfig.js";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

class UserService {
  findAll() {
    return axios.get(AUTH_API_URL + "users", { headers: authHeader() });
  }

  findByEmail(email) {
    return axios.get(AUTH_API_URL + "users/findByEmail/" + email, {
      headers: authHeader()
    });
  }

  findByUsername(username) {
    return axios.get(GENERIC_SERVICE_URL + "users/findByUsername/" + username);
  }

  findById(id) {
    return axios.get(AUTH_API_URL + "users/findById/" + id, {
      headers: authHeader()
    });
  }

  findByUserId(id) {
    return axios.get(GENERIC_SERVICE_URL + "users/" + id);
  }

  updateUser(user) {
    return axios.put(
      AUTH_API_URL + "users/updateUser", {
        personType: user.personType,
        tenantId: user.tenantId,
        username: user.username,
        email: user.email,
        password: user.password,
        confirmPassword: user.confirmPassword,
        accountNonExpired: user.accountNonExpired,
        accountNonLocked: user.accountNonLocked,
        credentialsNonExpired: user.credentialsNonExpired,
        enabled: user.enabled,
        roles: user.roles,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        nickname: user.nickname,
        dateOfBirth: user.dateOfBirth,
        phoneNumbers: user.phoneNumbers,
        faxNumber: user.faxNumber,
        skypeAddress: user.skypeAddress,
        gender: user.gender,
        salutation: user.salutation,
        maritalStatus: user.maritalStatus,
        nationalIdNumber: user.nationalIdNumber,
        passportNumber: user.passportNumber,
        driversLicenceNumber: user.driversLicenceNumber,
        nationality: user.nationality,
        placeOfBirth: user.placeOfBirth,
        countryOfBirth: user.countryOfBirth,
        homeAddress: user.homeAddress,
        ethnicGroup: user.ethnicGroup,
        religion: user.religion,
        digitalSignature: user.digitalSignature,
        photo: user.photo,
        occupation: user.occupation
      },
      { headers: authHeader() }
    );
  }

  register(user) {
    const authToken = JSON.parse(localStorage.getItem("user"));
    return axios.post(GENERIC_SERVICE_URL + "users/register",{
      personId: authToken === null ? user.personId: authToken.userId,
      personType: "USER",
      username: user.username,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
      accountNonExpired: user.accountNonExpired,
      accountNonLocked: user.accountNonLocked,
      credentialsNonExpired: user.credentialsNonExpired,
      enabled: user.enabled,
      roles: user.roles,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      nickname: user.nickname,
      dateOfBirth: user.dateOfBirth,
      phoneNumbers: user.phoneNumbers,
      faxNumber: user.faxNumber,
      skypeAddress: user.skypeAddress,
      gender: user.gender,
      salutation: user.salutation,
      maritalStatus: user.maritalStatus,
      nationalIdNumber: user.nationalIdNumber,
      passportNumber: user.passportNumber,
      driversLicenceNumber: user.driversLicenceNumber,
      nationality: user.nationality,
      placeOfBirth: user.placeOfBirth,
      countryOfBirth: user.countryOfBirth,
      homeAddress: user.homeAddress,
      postalAddress: user.postalAddress,
      ethnicGroup: user.ethnicGroup,
      religion: user.religion,
      digitalSignature: user.digitalSignature,
      photo: user.photo,

      overview: user.overview,
      title: user.title,
      videoIntroduction: user.videoIntroduction,
      availability: user.availability,
      languages: user.languages,
      educations: user.educations,
      serviceCharge: user.serviceCharge,
      workHistories: user.workHistories,
      portfolioProjects: user.portfolioProjects,
      skills: user.skills,
      testimonials: user.testimonials,
      certifications: user.certifications,
      otherExperiences: user.otherExperiences,
      jobPreferences: user.jobPreferences,
      preferences: user.preferences
    });
  }

  saveDigitalSignature(formData){
    return axios.post(AUTH_API_URL + "users/saveDigitalSignature", formData, { headers: authHeader() });
  }

  savePhoto(formData){
    return axios.post(AUTH_API_URL + "users/savePhoto", formData, { headers: authHeader() });
  }

  savePreference(userId, data){
    return axios.post(GENERIC_SERVICE_URL + "users/savePreference/" + userId, data);
  }

  registerDeliveryServiceUser(user) {
    const authToken = JSON.parse(localStorage.getItem("user"));
    return axios.post(GENERIC_SERVICE_URL + "users/registerDeliveryServiceUser",{
      personId: authToken === null ? user.personId: authToken.userId,
      personType: "USER",
      username: user.username,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
      accountNonExpired: user.accountNonExpired,
      accountNonLocked: user.accountNonLocked,
      credentialsNonExpired: user.credentialsNonExpired,
      enabled: user.enabled,
      roles: user.roles,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      nickname: user.nickname,
      dateOfBirth: user.dateOfBirth,
      phoneNumbers: user.phoneNumbers,
      faxNumber: user.faxNumber,
      skypeAddress: user.skypeAddress,
      gender: user.gender,
      salutation: user.salutation,
      maritalStatus: user.maritalStatus,
      nationalIdNumber: user.nationalIdNumber,
      passportNumber: user.passportNumber,
      driversLicenceNumber: user.driversLicenceNumber,
      nationality: user.nationality,
      placeOfBirth: user.placeOfBirth,
      countryOfBirth: user.countryOfBirth,
      homeAddress: user.homeAddress,
      postalAddress: user.postalAddress,
      ethnicGroup: user.ethnicGroup,
      religion: user.religion,
      digitalSignature: user.digitalSignature,
      photo: user.photo,

      overview: user.overview,
      title: user.title,
      videoIntroduction: user.videoIntroduction,
      availability: user.availability,
      languages: user.languages,
      educations: user.educations,
      serviceCharge: user.serviceCharge,
      workHistories: user.workHistories,
      portfolioProjects: user.portfolioProjects,
      skills: user.skills,
      testimonials: user.testimonials,
      certifications: user.certifications,
      otherExperiences: user.otherExperiences,
      jobPreferences: user.jobPreferences,
      preferences: user.preferences
    });
  }

  saveHomeAddress(user) {
    return axios.post(GENERIC_SERVICE_URL + "users/saveHomeAddress",{
      username: user.username,
      homeAddress: user.homeAddress
    });
  }

  savePreferences(user) {
    return axios.post(GENERIC_SERVICE_URL + "users/savePreferences",{
      username: user.username,
      preferences: user.preferences
    });
  }

  resendVerifyUserOTP(username){
    return axios.post(GENERIC_SERVICE_URL + "users/resendVerifyUserOTP/" + username);
  }
}

export default new UserService();
