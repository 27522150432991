<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>{{ editing ? "Edit": "New" }} User Preferences</h4>
            <p>You will be notified of any of the following if you tick any one of the preferences below.</p>
          </div>

          <router-link :to="{ name: 'profiles' }">
            <div class="col-md-2">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
        <br>

        <p class="error-message text-center" v-if="message !== null">{{ message }}</p>
        <form class="form" @submit.prevent="save" v-if="user !== null">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="status" class="text-danger">Select preferences(s)</label><br>
                <span v-for='preference in preferences' :key="preference.id">
                  <input type="checkbox" id="jack" :value="preference.id" v-model="checkedPreferences"  ref="precheck"> 
                  {{ preference.name.replace('_', ' ') }} <br>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="submit">&nbsp;</label>
                <button class="btn btn-success btn-block" type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import PreferenceService from "@/services/PreferenceService";
  import loading from "vue-full-loading";
  import UserService from "@/services/UserService";

  export default {
    name: "NewPreference",
    components: {
      loading
    },

    data: function() {
      return {
        checkedPreferences: [],
        user: null,
        errors: [],
        message: null,
        show: false,
        label: "Processing request",
        roles: [],
        editing: false,
        selectedRoleIds: [],
        preferences: []
      }
    },

    computed: {
      getUser: function(){
        return this.$store.state.auth.user.user;
      }
    },

    mounted: function() {
      this.findByUsername();
      this.findAllPreferences();
    },

    methods: {
      findByUsername: function() {
        UserService.findByUsername(this.getUser.username).then(response => {
          this.user = response.data.body;
          for (var i = 0; i < this.user.preferences.length; i++) {
            this.selectedRoleIds.push(this.user.preferences[i].id);
          }
          for(let i = 0; i < this.selectedRoleIds.length; i++){
            this.checkedPreferences.push(this.selectedRoleIds[i]);
          }
          this.checkedPreferences.push(this.$refs.precheck.value);
        }).catch(error => {
          console.log(error);
        });
      },

      findAllPreferences: function() {
        this.show = true;
        PreferenceService.findAll().then(response => {
          this.preferences = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      // https://stackoverflow.com/questions/56720152/how-to-check-if-an-array-contains-an-item-from-another-array-from-within-vue-js
      save: function() {
        this.show = true;
        let preferences = [];
        for (let i = 0; i < this.checkedPreferences.length; i++) {
          preferences.push({ id: this.checkedPreferences[i] });
        }
        this.user.preferences = preferences;
        let postDto = {
          username: this.getUser.username,
          preferences: preferences
        };
        UserService.savePreferences(postDto).then(response => {
          this.show = false;
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "profiles" });
          }
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },
    }
  };
</script>
