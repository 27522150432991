import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/store/modules/AuthModule";
import { companies } from "@/store/modules/company";

Vue.use(Vuex);

export default new Vuex.Store({
  // break down into modules!!!
  state: {
  	count: 0,
  	pickupAddressLatitude: 0,
  	pickupAddressLongitude: 0,
  	destinationAddressLatitude: 0,
  	destinationAddressLongitude: 0,
    physicalAddress: null,
    destinationAddress: null,
    estimatedDrivingTime: null,
    estimatedDistance: null,
    selectedSubscriptionPlanNumber: null,
    selectedPrice: null,
    selectedCurrency: null,
    complianceFields: null
  },

  mutations: {
  	increment(state){
  		state.count++;
  	},

  	setPickupAddressLatitude(state, lat){
  		state.pickupAddressLatitude = lat;
  	},

  	setPickupAddressLongitude(state, lng){
  		state.pickupAddressLongitude = lng;
  	},

  	setDestinationAddressLatitude(state, lat){
  		state.destinationAddressLatitude = lat;
  	},

  	setDestinationAddressLongitude(state, lng){
  		state.destinationAddressLongitude = lng;
  	},

    setPickupAddress(state, physicalAddress){
      state.physicalAddress = physicalAddress;
    },

    setDestinationAddress(state, destinationAddress){
      state.destinationAddress = destinationAddress;
    },

    setEstimatedDrivingTime(state, estimatedDrivingTime){
      state.estimatedDrivingTime = estimatedDrivingTime;
    },

    setEstimatedDistance(state, estimatedDistance){
      state.estimatedDistance = estimatedDistance;
    },

    setSelectedSubscriptionPlanNumber(state, subscriptionPlanNumber){
      console.log(subscriptionPlanNumber);
      state.selectedSubscriptionPlanNumber = subscriptionPlanNumber;
    },

    setSelectedPrice(state, price){
      state.selectedPrice = price;
    },
    
    setSelectedCurrency(state, currency){
      state.selectedCurrency = currency;
    },

    setComplianceFields(state, complianceFields){
      state.complianceFields = complianceFields;
    }
  },
  actions: {},
  modules: {
    auth,
    companies
  }
});