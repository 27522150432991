<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<h4>New Company Type</h4><br>
		<p v-if="message !== null" class="error-message">{{ message }}</p>

		<form class="form" @submit.prevent="createCompanyType">
			<div class="form-group">
				<label>CompanyType Name</label>
				<input type="text" v-model="companyType.companyTypeName" placeholder="Enter Company Type Name" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'companyTypeName' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>CompanyType Description</label>
				<input type="text" v-model="companyType.companyDescription" placeholder="Enter Company Type Description" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'companyDescription' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Status</label>
				<select v-model="companyType.companyTypeStatus" class="form-control">
					<option value="">Select status</option>
					<option value="1">Active</option>
					<option value="0">Inactive</option>
				</select>
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'companyTypeStatus' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>&nbsp;</label>
				<router-link :to="{ name: 'companyTypes' }" replace><button class="btn btn-default" data-dismiss="modal">Cancel</button></router-link> &nbsp; <button class="btn btn-success">Save & Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import CompanyType from "@/models/CompanyType";
	import CompanyTypeService from "@/services/CompanyTypeService";
	import loading from "vue-full-loading";

	export default {
		name: 'CreateCompanyType',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing...',
				companyType: new CompanyType(),
				errors: [],
				message: null
			}
		},
		methods: {
			createCompanyType: function(){
				this.show = true;
				CompanyTypeService.createCompanyType(this.companyType).then(response => {
					if(response.data.statusCode == 200){
						this.show = false;
						this.$router.push({ name: 'companyTypes'});
						console.log(response);
					}
					if(response.data.statusCode == 400){
						this.show = false;
						this.errors = response.data.errors;
						this.message = response.data.message;
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
					if(error.response.data.statusCode == 422){
						console.log('validation errors: ');
						this.errors = error.response.data.errors;
						this.message = error.response.data.message;
					}
				});
			}	
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>