import axios from "axios";
import { PRODUCT_SERVICE_URL } from "@/config/ProductServiceConfig";

class ProductService{
	findAll(){
		return axios.get(PRODUCT_SERVICE_URL + "products");
	}

	findBySellerId(sellerId){
		return axios.get(PRODUCT_SERVICE_URL + "products/findBySellerId/" + sellerId);
	}

	findById(id){
		return axios.get(PRODUCT_SERVICE_URL + "products/" + id);
	}

	findByProductNumber(productNumber){
		return axios.get(PRODUCT_SERVICE_URL + "products/findByProductNumber/" + productNumber);
	}

	save(product){
		return axios.post(PRODUCT_SERVICE_URL + "products", {
			id: product.id,
			productNumber: product.productNumber,
			categoryId: product.categoryId,
			departmentId: product.departmentId,
			name: product.name,
			inStock: product.inStock,
			quantity: product.quantity,
			price: product.price,
			percentageDiscount: product.percentageDiscount,
			soldBy: product.soldBy,
			deliveryFulfilledBy: product.deliveryFulfilledBy,
			availableQuantity: product.availableQuantity,
			currency: product.currency,
			eligibleForNextDayDeliveryOrCollection: product.eligibleForNextDayDeliveryOrCollection,
			eligibleForCashOnDelivery: product.eligibleForCashOnDelivery,
			freeDeliveryAvailable: product.freeDeliveryAvailable,
			hassleFreeExchangesAndReturnsFor30Days: product.hassleFreeExchangesAndReturnsFor30Days,
			warrantyMonths: product.warrantyMonths,
			buyNowPayLater: product.buyNowPayLater,
			reviews: product.reviews,
			companyId: product.companyId,
			photos: product.photos,
			availableLocationsOfProduct: product.availableLocationsOfProduct,
			sellerId: product.sellerId,
			status: product.status,
			description: product.description,
			productPhotos: product.productPhotos
		});
	}

	savePreference(userId, data){
		return axios.post(PRODUCT_SERVICE_URL + "products/savePreference/" + userId, data);
	}

	makePremiumProduct(productNumber){
		return axios.post(PRODUCT_SERVICE_URL + "products/makePremiumProduct/" + productNumber);
	}

	updateProductPhotos(product){
		return axios.post(PRODUCT_SERVICE_URL + "products/updateProductPhotos", {
			productNumber: product.productNumber,
			productPhotos: product.productPhotos
		});
	}

	likeProduct(productNumber, username) {
		return axios.post(PRODUCT_SERVICE_URL + "products/likeProduct/" + productNumber + "/" + username);
  	}

  	checkSellerProductUploadLimit(sellerId) {
		return axios.get(PRODUCT_SERVICE_URL + "products/checkSellerProductUploadLimit/" + sellerId);
  	}

  	dislikeProduct(productNumber, username) {
		return axios.post(PRODUCT_SERVICE_URL + "products/dislikeProduct/" + productNumber + "/" + username);
  	}
}

export default new ProductService;