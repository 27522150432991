<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="row" v-if="product !== null">
			<div class="col-md-10">
				<h4>Product preview detail</h4>		
			</div>
			<!-- <router-link :to="{ name: 'edit-product-photos', params: { productId: product.id }}">
				<div class="col-md-2">
					<button class="btn btn-warning btn-block">Edit</button>
				</div>
			</router-link> -->
			<router-link :to="{ name: 'productsPreview'}">
				<div class="col-md-2">
					<button class="btn btn-primary btn-block">Back</button>
				</div>
			</router-link>
		</div>
		<br>

		<div class="row" v-if="product !== null">
			<div class="col-md-9">
				<div class="panel">
					<div class="panel-body">
						<h4>Product photos</h4>
				        <div class="row">
				          <div class="col-md-2" v-for="(photo, index) in product.photos" :key="photo.id">
				            <img :src="GENERIC_SERVICE_URL + 'photos/' + photo.id" class="img img-responsive img-rounded img-preview" width="300px" height="300px">
				            <button class="btn btn-danger btn-block" @click="product.photos.splice(index, 1)">Delete</button>
				          </div>
				        </div>
				        <br>
				        <button class="btn btn-success" @click="updateProductPhotos">Update Photos</button>
				        <br>
						<div class="row">
							<div class="col-md-3">
								<h4>{{ product.percentageDiscount }}% off</h4>
								<img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded img-preview" width="280" height="200">
							</div>
							<div class="col-md-6">
								<h4>{{ product.name }}</h4>
								<h4>{{ product.name }}</h4>
								<p>{{ product.description }}</p>
								<p>4.3, 104 Reviews</p>
								<hr>
								<h4>In stock <span class="badge">{{ product.inStock ? "Yes": "No" }}</span>, JHBCPT, When do I get it?</h4>
								<p>Sold by <span><a href="#">{{ product.soldBy }}</a></span>. Fulfilled by <span><a href="#">{{ product.deliveryFulfilledBy }}</a></span></p>
								<hr>
								<ul>
									<li>Eligible for next-day delivery or collection. <span class="badge">{{ product.eligibleForNextDayDeliveryOrCollection ? "Yes": "No" }}</span></li>
									<li>Eligible for Cash on Delivery. <span class="badge">{{ product.eligibleForCashOnDelivery ? "Yes": "No" }}</span></li>
									<li>Free Delivery Available. <span class="badge">{{ product.freeDeliveryAvailable ? "Yes": "No" }}</span></li>
									<li>Hassle-Free Exchanges & Returns for 30 Days. <span class="badge">{{ product.hassleFreeExchangesAndReturnsFor30Days ? "Yes": "No" }}</span></li>
									<li>12-Month Limited Warranty. <span class="badge">{{ product.warranty ? "Yes": "No" }}</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="panel">
					<div class="panel-body">
						<h4>Description</h4>
						<p>{{ product.description }}</p>
					</div>
				</div>
			</div>

			<div class="col-md-3">
				<div class="panel">
					<div class="panel-body">
						<h4>{{ product.currency }}{{ product.unitPrice }}</h4>
						<p><span class="badge">{{ product.freeDeliveryAvailable ? "FREE DELIVERY": "FREE DELIVERY NOT AVAILABLE" }}</span></p>
						<p><del>{{ product.currency }}{{ product.unitPrice }}</del></p>
						<p>Ebucks logo eB19,250 Discovery Miles 19,250</p>
						<hr>
						<p>{{ product.buyNowPayLater ? "Buy Now, Pay Later": "Buy Now, Pay Later Not Available" }}</p>
						<p>Pay 4 interest-free instalments of R 481 using Payflex. Learn More</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import loading from "vue-full-loading";
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";

	export default{
		name: "MyProductListDetails",
		components: {
			loading
		},

		data: function(){
			return {
				products: [],
				product: null,
				show: false,
				label: "Processing request",
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL
			}
		},

		mounted(){
			this.findById();
			this.findAll();
		},

		computed: {
			getProductId: function(){
				return this.$route.params.productId;
			}
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProductService.findAll().then(response => {
					this.show = false;
					this.products = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			findById: function(){
				this.show = true;
				ProductService.findById(this.getProductId).then(response => {
					this.show = false;
					this.product = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			updateProductPhotos(){
				this.show = true;
				let productPhotos = [];
				for(let i = 0; i < this.product.photos.length; i++){
					productPhotos.push({ id: this.product.photos[i].id });
				}
        		this.product.productPhotos = productPhotos;
				ProductService.updateProductPhotos(this.product).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'productsPreview' });
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
	.img-preview {
    width: 100%;
    height: 200px;
    margin: auto;
    display: block;
    margin-bottom: 10px;
    object-fit: cover;
  }
</style>
