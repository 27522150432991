<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>Our assigned orders</h4>
					</div>
				</div>
				<br><br>
				<table class="table table-responsive table-bordered table-hover">
					<thead class="bg-primary">
						<th>ID</th>
						<th>Order Number</th>
						<th>Order Date</th>
						<th>Price</th>
						<th>Pickup</th>
						<th>Drop Off</th>
				        <th>Order Status</th>
				        <th>Actions</th>

					</thead>
					<tbody>
						<tr v-for="order in orders" :key="order.id">
							<td>{{ order.id }}</td>
							<td>{{ order.orderNumber }}</td>
							<td>{{ order.orderDate }}</td>
							<td>{{ order.currency }}{{ 40 }}</td>
							<td>{{ order.collectionAddresses.length > 0 ? order.collectionAddresses[0].city.name + ' ' + order.collectionAddresses[0].physicalAddress: 'N/A' }}</td>
							<td>{{ order.deliveryAddress !== null ? order.deliveryAddress.location.city.name + ' ' + order.deliveryAddress.location.physicalAddress: 'N/A' }}</td>
							<td><span class="label label-primary">{{ order.status }}</span></td>
							<td>
								<router-link :to="{ name: 'view-order', params: { orderNumber: order.orderNumber }, query: { source: 'carrier-order-list'}}">
									<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
								</router-link>&nbsp;
								<span v-if="!order.accepted">
				          			<router-link :to="{ name: 'accept-or-decline-order-offer', params: { orderNumber: order.orderNumber }}">
				          				<button class="btn btn-warning btn-md"><span class="glyphicon glyphicon-thumbs-up"></span> Accept or decline offer <span class="glyphicon glyphicon-thumbs-down"></span></button>
				          			</router-link>
				          		</span>&nbsp;
				          		<!-- <span v-show="loadBooking.load.released && !loadBooking.load.collected">
				          			<button class="btn btn-success" @click="collected(loadBooking.load)">Confirm Collection</button>
				          		</span>
				          		<span v-show="loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.carrierConfirmedDelivery">
				          			<button class="btn btn-success" @click="carrierConfirmedDelivery(loadBooking.load)">Confirm delivery</button>
				          		</span> -->
				          		<span>
				          			<button class="btn btn-danger btn-md">Assign Vehicle</button>
				          		</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>			
	</div>
</template>

<script type="text/javascript">
	import OrderService from "@/services/OrderService";

	export default{
		name: "CustomerOrderList",
		data: function(){
			return {
				orders: [],
			}
		},

		mounted: function(){
			this.findByAwardedCarrierAssociatedUserUsername();
		},

		methods: {
			findByAwardedCarrierAssociatedUserUsername: function(){
				this.show = true;
				OrderService.findByAwardedCarrierAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
					this.show = false;
					this.orders = response.data.body;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>