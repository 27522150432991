<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <h4>Driver assigned</h4>
	    <div class="panel">
	    	<div class="panel-body">
	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary" style="background: #000000; color: #ffffff;">
				        <th>Load Number</th>
				        <th>Booking Number</th>
				        <th>Load Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Assigned Truck</th>
				        <th>Load Status</th>
				        <th>Actions</th>
				      </thead>
				      <tbody>
				        <tr v-for="order in getFilteredLoadBookings" :key="order.id">
				        	<td>
				        		<router-link :to="{ name: 'view-load', params: { orderNumber: order.orderNumber }}">
				        			{{ order.orderNumber }}
				        		</router-link>
				        	</td>
				        	<td>{{ order.orderNumber }}</td>
				        	<td>{{ order.currency }}{{ order.price }}</td>
				          	<!-- <td>
				          		{{ loadBooking.load.pickupAddress !== null ? loadBooking.load.pickupAddress.city.name + ' ' + loadBooking.load.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ loadBooking.load.dropOffAddress !== null ? loadBooking.load.dropOffAddress.city.name + ' ' + loadBooking.load.dropOffAddress.province.name: '' }}
				          	</td> -->
				          	<td>
				          		<!-- <router-link :to="{ name: 'trucks/view', params: { truckNumber: loadBooking.booking.assignedTruck.truckNumber }}">
				          			{{ loadBooking.booking.assignedTruck !== null ? loadBooking.booking.assignedTruck.registrationNumber: '' }}
				          		</router-link> -->
				          	</td>
				          	<td>
				          		<span v-show="order.released && order.collected && !order.delivered">
				          			<button class="btn btn-success btn-xs" @click="delivered(order)">Confirm delivery</button>
				          		</span>&nbsp;
				          		<!-- <span>
				          			<router-link :to="{ name: 'contact-information-for-carrier', params: { loadNumber: loadBooking.load.loadNumber }}">
				          				<button class="btn btn-primary btn-xs">Delivery contact Info</button>
				          			</router-link>
				          		</span> -->
				          	</td>
				          	<td>{{ order.status }}</td>
				          	<td></td>
				          </tr>
				    </tbody>
				</table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import OrderService from "@/services/OrderService.js";

	export default{
		name: 'InTransitLoads',
		components: {
			loading,
		},

		props: {
			orders: {
				type: Array,
				default: function(){
					return []
				}
			}
		},

		data: function(){
			return {
				label: "Processing request",
				show: false
			}
		},

		computed: {
			getFilteredLoadBookings(){
				return this.orders.filter(order => {
					return order.status === 'IN_TRANSIT' && order.released && order.collected;
				});
		    }
		},

		methods: {
			delivered: function(load){
				this.show = true;
				OrderService.delivered(load).then(response => {
					if (response.data.statusCode === 201){
						window.location.reload();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>