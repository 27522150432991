import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class FuelPriceService {
  save(fuelPrice) {
    return axios.post(GENERIC_SERVICE_URL + "fuelPrices", {
      id: fuelPrice.id,
      countryId: fuelPrice.countryId,
      dieselPrice: fuelPrice.dieselPrice,
      petrolPrice: fuelPrice.petrolPrice,
      currency: fuelPrice.currency,
      active: fuelPrice.active,
      current: fuelPrice.current,
      enabled: fuelPrice.enabled,
      expired: fuelPrice.expired,
      description: fuelPrice.description,
      status: fuelPrice.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "fuelPrices");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "fuelPrices" + "/" + id);
  }

  findActiveFuelPrice(countryId) {
    return axios.get(GENERIC_SERVICE_URL + "fuelPrices/findActiveFuelPrice/" + countryId);
  }
}

export default new FuelPriceService;
