import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class OneTimePasswordService {

  OTPExpired(otpCode) {
    return axios.get(GENERIC_SERVICE_URL + "otp/OTPExpired/" + otpCode);
  }
}

export default new OneTimePasswordService;
