<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<router-link :to="{ name: 'jobs/contractJobList'}">
					<button class="btn btn-primary">Back</button>
				</router-link><br>

				<h4>View Job Details</h4>
				<ul class="list-group">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">ID: {{ job.id }}</li>
					<li class="list-group-item">Job Number: {{ job.jobNumber }}</li>
					<li class="list-group-item">Job Title: {{ job.jobTitle !== null ? job.jobTitle.name: "Not available"}}</li>
					<li class="list-group-item">Type: {{ job.type }}</li>
					<li class="list-group-item">Hourly Rate: {{ job.currency }} {{ job.hourlyRate }} per hour</li>
					<li class="list-group-item">Expiry Date: {{ job.expiryDate }}</li>
					<li class="list-group-item">
						<a :href="PDF_API_URL + 'pdf/getJobAttachment/' + job.id" target="_blank">View Job Attachment</a>
					</li>
					<li class="list-group-item">Skills Level: {{ job.skillsLevel }}</li>
					<li class="list-group-item">Availability: {{ job.availability }}</li>
					<li class="list-group-item">Estimated Budget: {{ job.estimatedBudget }}</li>
					<li class="list-group-item">Expertise Level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Payment Terms: {{ job.paymentTerms }}</li>
					<li class="list-group-item">Reach: {{ job.reach }}</li>
					<li class="list-group-item">Project Type: {{ job.projectType }}</li>
					<li class="list-group-item">Commitment hours per week: {{ job.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Project length: {{ job.projectLengthInMonths }}</li>
					<li class="list-group-item">Description: {{ job.description }}</li>
					<li class="list-group-item">
						<ul class="list-group" v-if="job.applications.length > 0"><h4><b>Quotations</b></h4>
							<span v-for="application in job.applications" :key="application.id">
								<span v-if="application.quotation !== null">
									<li class="list-group-item">ID: {{ application.quotation.id }}</li>
									<li class="list-group-item">Validity: {{ application.quotation.validityInDays }} days</li>
									<li class="list-group-item">Offer: {{ application.quotation.offer }}</li>
									<li class="list-group-item">Currency: {{ application.quotation.currency }}</li>
									<li class="list-group-item">Hours Needed To Complete: {{ application.quotation.hoursNeededToComplete }}</li>
									<li class="list-group-item">Additional Information: {{ application.quotation.additionalInformation }}</li>
									<li class="list-group-item"><h4><b><router-link :to="{ name: 'professionals/view', params: { professionalId: application.applicant === null ? 57: application.applicant.id }}">View their professional profile</router-link></b></h4></li>
								</span><br>
							</span>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import PDF_API_URL from "@/config/PdfServiceConfig.js";

	export default{
		name: "ViewJob",
		component: {

		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		data: function(){
			return {
				job: null,
				PDF_API_URL: PDF_API_URL
			}
		},

		created: function(){
			this.findById();
		},

		methods: {
			findById: function(){
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>