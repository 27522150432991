<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>Attach file (e.g. Job description document) - optional</label>
						<input type="file" name="attachment" class="form-control" v-on:change="selectAttachment" accept="application/pdf" required>
					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
					<br><hr>
					<div class="row">
						<div class="col-md-10"></div>
						<span v-if="getJobType === 'FULL_TIME'">
							<router-link :to="{ name: 'full-time-jobs' }">
								<div class="col-md-2">
									<button class="btn btn-primary btn-block">Next</button>
								</div>
							</router-link>
						</span>
						<span v-if="getJobType === 'VOLUNTEERING'">
							<router-link :to="{ name: 'volunteering-jobs-configuration-list' }">
								<div class="col-md-2">
									<button class="btn btn-primary btn-block">Next</button>
								</div>
							</router-link>
						</span>
						<span v-if="getJobType === 'CONTRACT'">
							<router-link :to="{ name: 'my-contract-jobs-configuration' }">
								<div class="col-md-2">
									<button class="btn btn-primary btn-block">Next</button>
								</div>
							</router-link>
						</span>
						<span v-if="getJobType === 'TENDER'">
							<router-link :to="{ name: 'tender-jobs-configuration' }">
								<div class="col-md-2">
									<button class="btn btn-primary btn-block">Next</button>
								</div>
							</router-link>
						</span>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";

	export default{
		name: "NewJobAttachment",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				job: new Job,
				label: "Processing request",
				show: false,
				formData: new FormData
			}
		},

		created: function(){
			this.findAllJobTitles();
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			},

			getJobType: function(){
				return this.$route.params.type;
			}
		},

		methods: {
			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.show = true;
				this.formData.append("jobId", this.getJobId);
				JobService.saveJobAttachment(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						if (this.getJobType === 'FULL_TIME') {
							this.$router.push({ name: "full-time-jobs" });
						}
						else if (this.getJobType === 'VOLUNTEERING') {
							this.$router.push({ name: "volunteering-jobs-configuration-list" });	
						}
						else if (this.getJobType === 'CONTRACT') {
							this.$router.push({ name: "my-contract-jobs-configuration"})
						}
						else if (this.getJobType === 'TENDER') {
							this.$router.push({ name: "tender-jobs-configuration"})
						}
						else{
							this.$router.push({ name: "jobs/contractJobList" });
						}
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>