<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add language</h4>
      			<hr>
				<form class="form" @submit.prevent="save">
					<button class="btn btn-success btn-sm pull-right" @click="addLanguageField" type="button">Add <span class="glyphicon glyphicon-plus"></span></button>

					<div v-for="(languageField, index) in languageFields" :key="languageField.id">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Language</label>
									<select class="form-control" v-model="languageField.name">
										<option value="">Select option</option>
										<option value="ENGLISH">English</option>
										<option value="SHONA">Shona</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Proficiency level</label>
									<select class="form-control" v-model="languageField.proficiencyLevel">
										<option value="">Select option</option>
										<option value="BASIC">Basic (I am only able to communicate in this language through written communication)</option>
										<option value="CONVERSATIONAL">Conversational (I know this language well enough to verbally discuss project details with a client)</option>
										<option value="FLUENT">Fluent (I have complete command of this language with perfect grammar)</option>
										<option value="NATIVE">Native or Bilingual (I have complete command of this language, including breadth of vocabulary, idioms, and colloquialisms)</option>
									</select>
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group" v-if="languageFields.length > 0">
									<label>&nbsp;</label>
									<button class="btn btn-danger btn-sm btn-block" type="button" @click="languageFields.splice(index, 1)">Remove</button>
								</div>
							</div>
						</div>
					</div><br><br>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group" v-if="languageFields.length > 0">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
					<br>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group" v-if="languageFields.length === 0">
								<label>&nbsp;</label>
								<router-link :to="{ name: 'newEducation'}">
									<button class="btn btn-primary btn-block" type="submit">Next</button>
								</router-link>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewLanguage",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				professional: new Professional,
				label: "Processing request",
				show: false,
				languageFields: []
			}
		},

		created: function(){ },

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			addLanguageField: function(){
				this.languageFields.push({
					name: null,
					proficiencyLevel: null
				});
			},

			save: function(){
				this.show = true;
				this.professional.languages = this.languageFields;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newEducation", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>