<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<div class="row">
	    			<div class="col-md-2">
	    				<h4>New truck</h4>
	    			</div>
	    			<div class="col-md-8"></div>
	    			<div class="col-md-2">
	    				<router-link to="truck-list">
			    			<button class="btn btn-warning btn-block">Cancel</button>
		              	</router-link>
	    			</div>
	    		</div>
	    		<br>
				<form class="form" @submit.prevent="save">
					<fieldset>
						<legend>Truck information</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Registration number</label>
									<input type="text" class="form-control" v-model="truck.registrationNumber" placeholder="Enter vehicle plate number" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Truck number</label>
									<input type="text" class="form-control" v-model="truck.truckNumber" placeholder="Enter truck number" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Truck type</label>
									<select class="form-control" v-model="truck.type" required>
										<option value="" disabled>Select option</option>
										<option value="STANDARD">Standard</option>
										<option value="BOX_TRUCK">Box Truck (Rigid)</option>
										<option value="POWER_ONLY">Power Only</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Make</label>
									<input type="text" class="form-control" v-model="truck.make" placeholder="Enter make e.g Freightliner" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Model</label>
									<input type="text" class="form-control" v-model="truck.model" placeholder="Enter model e.g Argosy"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Year</label>
									<select class="form-control" v-model="truck.year">
										<option v-for="year in years" :value="year" :key="year">{{ year }}</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>VIN</label>
									<input type="text" class="form-control" v-model="truck.vehicleIdentificationNumber" placeholder="Enter vehicle identification number"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Truck ownership</label>
									<select class="form-control" v-model="truck.ownershipType" required>
										<option value="" disabled>Select option</option>
										<option value="OWN_OPERATOR">Own operator</option>
										<option value="RENTED">Rent</option>
										<option value="COMPANY_OWNED">Company owned</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>In service date</label>
									<input type="date" class="form-control" v-model="truck.inServiceDate"/>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3" v-if="truck.type !== 'STANDARD'">
								<div class="form-group">
									<label>Trailer type</label>
									<select class="form-control" v-model="truck.trailerType" required>
										<option value="" disabled>Select option</option>
										<option value="FLATBED">Flatbed</option>
										<option value="VAN">Van</option>
										<option value="REEFER">Reefer</option>
										<option value="STEP_DECK">Step Deck</option>
										<option value="POWER_ONLY">Power Only</option>
										<option value="AUTO_CARRIER">Auto Carrier</option>
										<option value="B_TRAIN">B Train</option>
										<option value="BOX_TRUCK">Box Truck</option>
										<option value="CONESTOGA">Consetoga</option>
										<option value="CONTAINER">Container</option>
										<option value="DOUBLE_DROP">Double Drop</option>
										<option value="DRY_BULK">Dry Bulk</option>
										<option value="DUMP_TRAILER">Dump Trailer</option>
										<option value="HOPPER_BOTTOM">Hooper Bottom</option>
										<option value="HOTSHOT">Hotshot</option>
										<option value="LOWBOY">Lowboy</option>
										<option value="TANKER">Tanker</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Load mode</label>
									<select class="form-control" name="loadMode" v-model="truck.loadMode" required>
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload</option>
										<option value="LTL">Less than truckload</option>
										<option value="FCL">Full Container Load</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Max load size</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="truck.maxLoadSize" placeholder="Enter max load size" required/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Max load size measurement</label>
									<select class="form-control" v-model="truck.maxLoadSizeMeasurement" required>
										<option value="" disabled>Select option</option>
										<option value="KG">Kilogram</option>
										<option value="TONNE">Tonne</option>
										<option value="GIGA_TONNE">Gigatonne</option>
										<option value="CUBIT">Cubic</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Gross vehicle mass</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="truck.grossVehicleMass" placeholder="Enter gross vehicle mass"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Gross vehicle mass measurement</label>
									<select class="form-control" v-model="truck.grossVehicleMassMeasurement">
										<option value="" disabled>Select option</option>
										<option value="KG">Kilogram</option>
										<option value="TONNE">Tonne</option>
										<option value="GIGA_TONNE">Gigatonne</option>
										<option value="CUBIT">Cubic</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Net vehicle mass</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="truck.netVehicleMass" placeholder="Enter vehicle net mass"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Net vehicle mass measurement</label>
									<select class="form-control" v-model="truck.netVehicleMassMeasurement">
										<option value="" disabled>Select option</option>
										<option value="KG">Kilogram</option>
										<option value="TONNE">Tonne</option>
										<option value="GIGA_TONNE">Gigatonne</option>
										<option value="CUBIT">Cubic</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Desired rate</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="truck.desiredRate" placeholder="Enter desired rate"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Desired rate currency</label>
									<select class="form-control" v-model="truck.currency">
										<option value="" disabled>Select option</option>
										<option value="ZWL">ZWL</option>
										<option value="USD">USD</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Desired rate measurement</label>
									<select class="form-control" v-model="truck.desiredRateMeasurement">
										<option value="" disabled>Select option</option>
										<option value="KM">Per Kilometers</option>
										<option value="MILE">Per Miles</option>
										<option value="METERS">Per Meters</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Truck length</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="truck.length" placeholder="Enter truck length"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Truck length measurement</label>
									<select class="form-control" v-model="truck.lengthMeasurement">
										<option value="" disabled>Select option</option>
										<option value="CM">Centimeters</option>
										<option value="METER">Meters</option>
										<option value="SQM">Square Meters</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Max height clearance</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="truck.maxHeightClearance" placeholder="Enter max height clearance"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Max height clearance measurement</label>
									<select class="form-control" v-model="truck.maxHeightClearanceMeasurement">
										<option value="" disabled>Select option</option>
										<option value="CM">CM</option>
										<option value="METER">Meter</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Notes (optional)</label>
									<textarea class="form-control" rows="5" placeholder="Enter notes" minlength="5" maxlength="250" v-model="truck.description"></textarea>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-primary btn-block">Submit</button>
								</div>
							</div>
						</div>
					</fieldset>
				</form>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import TruckService from "@/services/TruckService";
	import CityService from "@/services/CityService";
	import ProvinceService from "@/services/ProvinceService";
	import Truck from "@/models/Truck";

	export default{
		name: 'NewTruck',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinces: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				truck: new Truck,
				regularPickUpAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			    regularDropOffAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			}
		},

		mounted(){
			this.getProvinces();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
			},

			getRoles: function() {
				let roles = [];
				for (var i = 0; i < this.getUser.authorities.length; i++) {
					roles.push(this.getUser.authorities[i].authority);
				}
				return roles;
			},

			years: function() {
		      const year = new Date().getFullYear();
		      const years = Array.from(
		        { length: year - 1900 },
		        (value, index) => 1901 + index
		      );
		      return years;
		    },
		},

		methods: {
			getProvinces: function(){
				this.show = true;
				ProvinceService.findAll().then(response => {
					this.provinces = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			getPickupCities: function() {
		      CityService.findByProvinceId(this.regularPickUpAddress.provinceId).then(response => {
		      	this.pickupCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

		    getDropOffCities: function() {
		      CityService.findByProvinceId(this.regularDropOffAddress.provinceId).then(response => {
		      	this.deliveryCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

			save: function(){
				this.show = true;
				this.truck.carrierUsername = this.getUser.username;
				this.truck.regularPickUpAddress = this.regularPickUpAddress;
				this.truck.regularDropOffAddress = this.regularDropOffAddress;
				TruckService.save(this.truck).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'regular-route', params: { truckNumber: response.data.body.truckNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>