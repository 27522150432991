<template>
	<div>
		<h4>Compliance Management Tool</h4><br>
		<hr>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>&nbsp;</label>
				<div class="input-group">
					<input type="text" name="search" id="search" class="form-control" placeholder="Search...">
					<div class="input-group-addon">
						<span class="glyphicon glyphicon-search"></span>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label><h4>Select the compliances that your organization is required by law or reulation.</h4></label><br><br>
				<div v-for="compliance in compliances" :key="compliance.id">
					<input type="checkbox" name="compliances" v-model="checkedCompliances" :value="compliance"> {{ compliance.name }} ~ <i>{{ compliance.description }}</i> <br><br>
				</div>
			</div>
			<hr>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Comply now</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import ComplianceService from "@/services/ComplianceService";

	export default{
		name: "NewComplianceManagement",
		data: function(){
			return{
				compliances: [],
			    checkedCompliances: []
			}
		},

		created(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				ComplianceService.findAll().then(res => {
					this.compliances = res.data.body;
				}).catch(err => {
					console.log(err);
				});
			},

			save: function(){
				localStorage.setItem("checkedCompliances", JSON.stringify(this.checkedCompliances));
				if (this.checkedCompliances !== null) {
					this.$router.push({ name: 'complianceManagement/preview' });
				}
			}
		},

		computed: {
			getCheckedCompliances: function(){
				return this.checkedCompliances;
			}
		},

		watch: {
			getCheckedCompliance: function(){
				return this.checkedCompliances;
			}
		}
	};
</script>