<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add video introduction</h4>
      			<hr>
				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>What type of video do you want to upload? </label>
						<select class="form-control" v-model="videoIntroduction.videoType">
							<option value="">Select option</option>
							<option value="TALENT_SHOWCASE">Me talking about my skills and experiences</option>
							<option value="SKILLS_VISUALS_SHOWCASE">Visual samples of my work</option>
							<option value="OTHER_SHOWCASE">Something else</option>
						</select>
					</div>

					<div class="form-group">
						<label>Select Video</label>
						<input type="file" name="video" class="form-control" v-on:change="selectVideo" accept="video/mp4,video/x-m4v,video/*" required>
					</div>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import VideoIntroduction from "@/models/VideoIntroduction";
	import Professional from "@/models/Professional";

	export default{
		name: "NewVideoIntroduction",
		components: {
			loading
		},

		data: function(){
			return {
				videoIntroduction: new VideoIntroduction,
				professional: new Professional,
				label: "Processing request",
				show: false,
				countries: [],
				formData: new FormData
			}
		},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {	
			selectVideo: function(event){
				this.formData.append("video", event.target.files[0]);
			},

			save: function(){
				this.show = true;
				this.formData.append('professionalId', this.getProfessionalId);
				this.formData.append('videoType', this.videoIntroduction.videoType);
				ProfessionalService.saveVideoIntroduction(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newHoursPerWeek", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>