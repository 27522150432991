import axios from "axios";
import { SKILLS_SERVICE_URL } from "@/config/SkillsServiceConfig";

class JobService{
	findAll(){
		return axios.get(SKILLS_SERVICE_URL + "jobs");
	}

	findById(id){
		return axios.get(SKILLS_SERVICE_URL + "jobs/" + id);
	}

	save(job){
		return axios.post(SKILLS_SERVICE_URL + "jobs", {
			id: job.id,
			jobNumber: job.jobNumber,
			categoryId: job.categoryId,
			title: job.title,
			type: job.type,
			hourlyRate: job.hourlyRate,
			fixedRate: job.fixedRate,
			currency: job.currency,
			expiryDate: job.expiryDate,
			attachment: job.attachment,
			availability: job.availability,
			expertiseLevel: job.expertiseLevel,
			paymentTerms: job.paymentTerms,
			reach: job.reach,
			projectType: job.projectType,
			projectLengthInMonths: job.projectLengthInMonths,
			commitmentHoursPerWeek: job.commitmentHoursPerWeek,
			applications: job.applications,
			internalOrExternal: job.internalOrExternal,
			externalLink: job.externalLink,
			locationRestriction: job.locationRestriction,
			postedByUsername: job.postedByUsername,
			remuneration: job.remuneration,
			location: job.location,
			workType: job.workType,
			totalHoursOfService: job.totalHoursOfService,
			referenceNumber: job.referenceNumber,
			experienceYears: job.experienceYears,
			requiredSkills: job.requiredSkills,
			scopeOfWorks: job.scopeOfWorks,
			reportingRequirements: job.reportingRequirements,
			contractualObligations: job.contractualObligations,
			feesAndExpenses: job.feesAndExpenses,
			engagementObjective: job.engagementObjective,
			description: job.description,
			status: job.status,
		});
	}

	saveJobAttachment(formData){
		return axios.post(SKILLS_SERVICE_URL + "jobs/saveJobAttachment", formData);
	}

	saveJobQuotationAttachment(formData){
		return axios.post(SKILLS_SERVICE_URL + "jobs/saveJobQuotationAttachment", formData);
	}
	
	savePreference(userId, data){
		return axios.post(SKILLS_SERVICE_URL + "jobs/savePreference/" + userId, data);
	}

	quickApply(applicationPostDto){
		return axios.post(SKILLS_SERVICE_URL + "jobs/quickApply", {
			jobId: applicationPostDto.jobId,
			userId: applicationPostDto.userId,
			validityInDays: applicationPostDto.validityInDays,
			offer: applicationPostDto.offer,
			currency: applicationPostDto.currency,
			hoursNeededToComplete: applicationPostDto.hoursNeededToComplete,
			additionalInformation: applicationPostDto.additionalInformation,
		});
	}

	approve(id){
		return axios.post(SKILLS_SERVICE_URL + "jobs/approve/" + id);
	}

	findByPosterUsername(username){
		return axios.get(SKILLS_SERVICE_URL + "jobs/findByPosterUsername/" + username);
	}

	submitApplication(jobId, username){
		return axios.post(SKILLS_SERVICE_URL + "jobs/submitApplication/" + jobId + "/" + username);
	}

	checkUserAlreadyAppliedJob(jobId, username){
		return axios.get(SKILLS_SERVICE_URL + "jobApplications/checkUserAlreadyAppliedJob/" + jobId + "/" + username);
	}

	checkApplicationLimit(jobId){
		return axios.get(SKILLS_SERVICE_URL + "jobs/checkApplicationLimit/" + jobId);
	}

	submitProposalBid(formData){
		return axios.post(SKILLS_SERVICE_URL + "jobs/submitProposalBid", formData);
	}

	submitTechnicalProposal(formData){
		return axios.post(SKILLS_SERVICE_URL + "jobs/submitTechnicalProposal", formData);
	}

	submitCompanyRegistrationDocuments(formData){
		return axios.post(SKILLS_SERVICE_URL + "jobs/submitCompanyRegistrationDocuments", formData);
	}

	submitReferenceCertificates(formData){
		return axios.post(SKILLS_SERVICE_URL + "jobs/submitReferenceCertificates", formData);
	}
}

export default new JobService;