<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add or configure your hourly rate</h4><br>
		      	<p>Please note that your new hourly rate will only apply to new contracts.</p>
		      	<br>
				<form class="form" @submit.prevent="save">
					<div class="row">
						<div class="col-md-5">
							<div class="form-group">
								<label>Hourly Rate</label>
							</div>
						</div>
						<div class="col-md-2">
							<label>&nbsp;</label>
							<select class="form-control" v-model="currency">
								<option value="">Select option</option>
								<option value="USD">USD</option>
								<option value="RAND">RAND</option>
								<option value="ZWL">ZWL</option>
							</select>
						</div>
						<div class="col-md-4">
							<label>&nbsp;</label>
							<input type="text" name="school" id="school" placeholder="Ex: $25" class="form-control" v-model="hourlyRate">
						</div>
						<div class="col-md-1">
							<label>&nbsp;</label>
							<p>/hr</p>
						</div>
					</div>

					<!-- <div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label><h4>20% SkilledBioz Service Fee</h4></label>
							</div>
						</div>
						<div class="col-md-5">
							<label>&nbsp;</label>
							<input type="text" name="school" id="school" placeholder="Ex: $25" class="form-control" v-model="currency">
						</div>
						<div class="col-md-1">
							<label>&nbsp;</label>
							<p>/hr</p>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label><h4>You'll Receive</h4></label>
								<p>The estimated amount you'll receive after service fees</p>						
							</div>
						</div>
						<div class="col-md-5">
							<label>&nbsp;</label>
							<input type="text" name="school" id="school" placeholder="Ex: $25" class="form-control" disabled>
						</div>
						<div class="col-md-1">
							<label>&nbsp;</label>
							<p>/hr</p>
						</div>
					</div> -->

					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success pull-right" type="submit">Submit</button>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewHourlyRate",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing...",
				show: false,
				hourlyRate: null,
				currency: null 
			}
		},

		created: function(){},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			save: function(){
				this.show = true;
				let serviceCharge = {
					currency: this.currency,
					hourlyRate: this.hourlyRate
				};
				this.professional.serviceCharge = serviceCharge;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newWorkHistory", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>