var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"show":_vm.show,"label":_vm.label}},[_c('div',{staticClass:"processing-row",attrs:{"slot":"loading-container"},slot:"loading-container"},[_c('div',{staticClass:"text-center"},[_c('div',{attrs:{"slot":"loading-spinner"},slot:"loading-spinner"},[_c('img',{attrs:{"src":require("@/assets/images/station-loading.gif"),"height":"100","width":"100"}})]),_c('p',{staticClass:"text-center",attrs:{"slot":"loading-text"},slot:"loading-text"},[_vm._v(_vm._s(_vm.label))])])])]),_c('h4',[_vm._v("Add employment")]),_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Company")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"Company","id":"Company","placeholder":"Ex: SkilledBioz"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h4',[_vm._v("Location")]),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("City")]),_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("Select option")])])]),_c('div',{staticClass:"col-md-6"},[_c('label',[_vm._v("Country")]),_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("Select option")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Title")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"Company","id":"Company","placeholder":"Ex: Senior Software Engineer"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('h4',[_vm._v("Period")]),_c('div',{staticClass:"col-md-6"},[_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("From, month")])])]),_c('div',{staticClass:"col-md-6"},[_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("From, year")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description (Optional)")]),_c('textarea',{staticClass:"form-control",attrs:{"rows":"5","placeholder":"Description (Optional)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" ")]),_c('button',{staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v("Save")])])
}]

export { render, staticRenderFns }