<template>
  <div>
    <template>
      <loading :show="show" :label="label">
        <div class="processing-row" slot="loading-container">
          <div class="text-center">
            <div slot="loading-spinner">
              <img src="@/assets/images/LucecoPLC-Loading.gif" height="100" width="100"/>
            </div>
            <p class="text-center" slot="loading-text">{{ label }}</p>
          </div>
        </div>
      </loading>
    </template>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h3 class="text-center">Sign in to Skilled Bios</h3>
        <router-link to="register">
          <p class="text-center"><a href="#">Or, Create an Account</a></p>
          </router-link>

        <div v-if="message">
          <p class="error-message text-center">{{ message }}</p>
        </div>
        <div class="panel">
          <div class="panel-header"><div class="header-title"></div></div>
          <div class="panel-body">
            <form class="form" @submit.prevent="login">
              <p class="error-message text-center">{{ errorMessage }}</p>
              <div class="form-group">
                <label>Your Username</label>
                <input
                  type="text"
                  v-model="user.username"
                  placeholder="Enter username"
                  class="form-control"
                  autocomplete="on"
                  required/>
              </div>
              <p v-if="errorz" class="error-message">{{ errorz.username }}</p>

              <div class="form-group">
                <label
                  >Your Password
                  <router-link to="forgotPassword"
                    >Forgot password?</router-link
                  >
                </label>
                <input
                  type="password"
                  v-model="user.password"
                  placeholder="Enter password"
                  class="form-control"
                  autocomplete="on"
                  required/>
                <p v-if="errorz" class="error-message">{{ errorz.password }}</p>
              </div>
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-success btn-block"><strong>Sign in</strong></button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";

  export default {
    components: {
      loading
    },
    
    data() {
      return {
        user: new User(),
        show: false,
        label: "Signing in. Please wait...",
        errorz: [], // change error bag of vue validate if present
        message: "",
        path: "/dashboard",
        errorMessage: null,
      };
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },
    
    created() {
      if (this.loggedIn) {
        if (this.$route.path != this.path) {
          this.$router.push({ name: 'dashboard' });
        }
      }
    },
    
    methods: {
      login() {
        this.show = true;
        this.$store.dispatch("auth/login", this.user).then(response => {
            console.log("Login successful: ", response.data);
            this.show = false;
            this.$router.replace(this.$route.query.redirect || '/dashboard');
          }).catch(error => {
            console.log("Login failed: ", error.response);
            if (error.response.status === 401) {
              this.message = error.response.data.error_description;
            }

            if (error.response.status === 400) {
              this.message = error.response.data.error_description;
            }
            if (error.response.status === 500) {
              this.message = "Login failed. Invalid username or password.";
            }
            this.show = false;
          });
      },

      logOut() {
        this.$store.dispatch("auth/logout");
      }
    }
  };
</script>
