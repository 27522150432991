<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="row">
      <router-link :to="{ name: 'configurations' }">
        <div class="col-md-10">
          <button class="btn btn-primary">Back</button>
        </div>
      </router-link>
      <router-link :to="{ name: 'new-subscription-plan' }">
        <div class="col-md-2">
          <button class="btn btn-success pull-right">New subscription plan <span class="glyphicon glyphicon-plus"></span></button>
        </div>
      </router-link>
    </div>
    <br>

    <h4>Subscription plans</h4>
    <div class="panel">
      <div class="panel-body">
        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Price</th>
            <th>Validity Period (Days)</th>
            <th>Subscription Type</th>
            <th>Description</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="subscriptionPlan in subscriptionPlans" :key="subscriptionPlan.id">
              <td>{{ subscriptionPlan.id }}</td>
              <td>{{ subscriptionPlan.name.replace('_', ' ') }}</td>
              <td>{{ subscriptionPlan.price }}</td>
              <td>{{ subscriptionPlan.validityPeriodInDays }}</td>
              <td>{{ subscriptionPlan.subscriptionType }}</td>
              <td>{{ subscriptionPlan.description }}</td>
              <td>
                <span class="label label-success" v-if="subscriptionPlan.status == 'ACTIVE'">Active</span>
                <span class="label label-default" v-if="subscriptionPlan.status == 'INACTIVE'">Inactive</span>
              </td>
              <td>
                <router-link :to="{ name: 'view-subscription-plan', params: { subscriptionPlanId: subscriptionPlan.id } }">
                  <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>&nbsp;
                <router-link :to="{ name: 'edit-subscription-plan', params: { subscriptionPlanId: subscriptionPlan.id } }">
                  <button class="btn btn-warning">Edit <span class="glyphicon glyphicon-edit"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import SubscriptionPlanService from "@/services/SubscriptionPlanService";
  import loading from "vue-full-loading";

  export default {
    name: "SubscriptionPlanList",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing request",
        subscriptionPlans: []
      };
    },

    mounted() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        SubscriptionPlanService.findAll().then(response => {
          this.show = false;
          this.subscriptionPlans = response.data.body;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>
