<template>
	<div>
		<router-link :to="{ name: 'tenders/new' }" replace>
			<button class="btn btn-primary">Back</button>
		</router-link><br/><br/>

		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>New Request For Proposal (RFP)</label>
				<input type="file" name="attachment" class="form-control" v-on:change="selectAttachment" accept="application/pdf">
			</div><br>

			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import TenderService from "@/services/TenderService";

	export default{
		name: "NewTenderDocument",
		components: {
			loading
		},

		data: function(){
			return {
				label: "Processing...",
				show: false,
				formData: new FormData
			}
		},

		created: function(){},

		computed: {
			getTenderId: function(){
				return this.$route.params.tenderId;
			}
		},

		methods: {
			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.show = true;
				this.formData.append("tenderId", this.getTenderId);
				TenderService.saveRFP(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "tenders", params: { tenderId: this.getTenderId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>