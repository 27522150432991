<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add New Skill</h4><br>
				<form class="form" @submit.prevent="save">
					<label>Select from the variety of skills below</label><br><br>

					<div class="form-group" v-for="skill in skills" :key="skill.id">
						<input type="checkbox" name="selectedSkills" id="selectedSkills" v-model="selectedSkills" :value="skill.id"> {{ skill.name }}<br>
					</div><br>
					<p><b><i>Maximum 15 skills</i></b></p>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import SkillService from "@/services/SkillService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewSkills",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				professional: new Professional,
				label: "Processing request",
				show: false,
				selectedSkills: [],
				skills: [],
			}
		},

		created: function(){
			this.findAllSkills();
		},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			findAllSkills: function(){
				SkillService.findAll().then(response => {
					this.skills = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			save: function(){
				this.show = true;
				let mySkills = [];
				for(var i = 0; i < this.selectedSkills.length; i++){
					mySkills.push({ skillId: this.selectedSkills[i] });
				}
				this.professional.skills = mySkills;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newTestimonial", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>