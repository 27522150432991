<template>
	<div>
		<h4>Contract Job List</h4>

		<router-link :to="{ name: 'jobs/newContractJob' }" replace><button class="btn btn-success pull-right">New Contract Job <span class="glyphicon glyphicon-plus"></span></button></router-link><br/><br/>

		<table class="table table-responsive table-bordered">
			<tr class="bg-primary">
				<th>ID</th>
				<th>Name</th>
				<th>Rate</th>
				<th>Type</th>
				<th>Applications</th>
				<th>Status</th>
				<th>Actions</th>
			</tr>
			<tbody>
				<tr v-for="job in jobs" :key="job.id">
					<td>{{ job.id }}</td>
					<td>{{ job.jobTitle.name }}</td>
					<td>{{ job.currency }} {{ job.hourlyRate }} per hour</td>
					<td>{{ job.type }}</td>
					<td><span class="badge badge-primary">{{ job.applications.length }}</span></td>
					<td>
						<span class="label label-success" v-if="job.status === 'ACTIVE'">{{ job.status }}</span>
						<span class="label label-primary" v-if="job.status === 'INACTIVE'">{{ job.status }}</span>
						<span class="label label-primary" v-if="job.status === 'PENDING'">{{ job.status }}</span>
					</td>
					<td>
						<router-link :to="{ name: 'contractJobConfigurationView', params: { jobId: job.id }}">
							<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
						</router-link>
						<span v-if="job.status === 'PENDING' & getRoles.includes('SUPER_ADMIN')">
							<router-link :to="{ name: 'jobs/approve', params: { jobId: job.id }}">
								<button class="btn btn-success">Review <span class="glyphicon glyphicon-edit"></span></button>
							</router-link>
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script type="text/javascript">
	import JobService from "@/services/JobService";

	export default{
		name: "JobConfigurationList",
		component: {

		},

		data: function(){
			return {
				jobs: [],
				roles: []
			}
		},

		created: function(){
			this.findAll();
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			},

			getRoles: function(){
				let roles = [];
				for(var i = 0; i < this.getUser.roles.length; i++){
					roles.push(this.getUser.roles[i].authority);
				}
				return roles;
			}
		},

		methods: {
			findAll: function(){
				JobService.findAll().then(response => {
					this.jobs = response.data.body;
					this.jobs = this.jobs.filter(job => job.type === 'CONTRACT');
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	}
</script>