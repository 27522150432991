<template>
	<div>
		<h4>Job Preferences Profile</h4>

		<router-link :to="{ name: 'jobPreference/new' }">
			<button class="btn btn-success pull-right">New job preference <span class="glyphicon glyphicon-plus"></span></button>
		</router-link><br><br>

		<ul class="list-group">
			<span v-if="professional.jobPreferences.length > 0">
				<h4>You are subscribed to receive the following job preferences.</h4><br>
				<li class="list-group-item" v-for="jobPreference in professional.jobPreferences" :key="jobPreference.id">{{ jobPreference.jobType }}</li>
			</span>
			<span v-else>
				<h4><span class="label label-primary">You have no job preferences available.</span></h4>
			</span>
		</ul>
	</div>
</template>

<script type="text/javascript">
	import ProfessionalService from "@/services/ProfessionalService.js";

	export default{
		name: "JobsPreference",
		data: function(){
			return {
				professional: null
			}
		},

		created(){
			this.findByUserId();
		},

		computed: {
			getUserId: function(){
				return this.$store.state.auth.user.userId;
			}
		},

		methods: {
			findByUserId: function(){
				ProfessionalService.findByUserId(this.getUserId).then(response => {
					this.professional = response.data.body;
					console.log(this.professional);
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>