<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="row">
			<div class="col-md-2">
				<router-link :to="{ name: 'contract-jobs-configuration'}">
					<button class="btn btn-primary">Back</button>
				</router-link>		
			</div>
			<div class="col-md-6">
				<h4 class="text-center">New RFQ</h4>
			</div>
			<div class="col-md-4"></div>
		</div>
		<br>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Proposal Notes</label>
				<textarea class="form-control" placeholder="Enter notes" rows="5"></textarea>
			</div>
			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success btn-block" type="submit">Submit</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobCategoryService from "@/services/JobCategoryService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";
	import CountryService from "@/services/CountryService";
	import ProvinceService from "@/services/ProvinceService";
	import CityService from "@/services/CityService";
	import AreaService from "@/services/AreaService";
	import Location from "@/models/Location";

	export default{
		name: "CreateJob",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				jobCategories: [],
				job: new Job,
				label: "Processing...",
				show: false,
				formData: new FormData,
				location: new Location,
				selectedAreas: [],
				areas: [],
				cities: [],
				countries: [],
				provinces: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
			this.findAllJobCategories();
		},

		methods: {
			findAllJobCategories: function(){
				JobCategoryService.findAll(this.job).then(response => {
					this.jobCategories = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCountryId: function(){
				ProvinceService.findByCountryId(this.location.countryId).then(response => {
					this.provinces = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByProvinceId: function(){
				CityService.findByProvinceId(this.location.provinceId).then(response => {
					this.cities = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCityId: function(){
				AreaService.findByCityId(this.location.cityId).then(response => {
					this.areas = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			save: function(){
				this.formData.append("jobTitleId", this.job.jobTitleId);
				this.formData.append("type", this.job.type);
				this.formData.append("hourlyRate", this.job.hourlyRate);
				this.formData.append("currency", this.job.currency);
				this.formData.append("expiryDate", this.job.expiryDate);
				this.formData.append("availability", this.job.availability);
				this.formData.append("expertiseLevel", this.job.expertiseLevel);
				this.formData.append("paymentTerms", this.job.paymentTerms);
				this.formData.append("reach", this.job.reach);
				this.formData.append("projectType", this.job.projectType);
				this.formData.append("projectLengthInMonths", this.job.projectLengthInMonths);
				this.formData.append("commitmentHoursPerWeek", this.job.commitmentHoursPerWeek);
				localStorage.setItem("type", JSON.stringify(this.job.type));
				this.show = true;
				let selectedAreas = [];
				for(var i = 0; i < this.selectedAreas.length; i++){
					selectedAreas.push({
						areaId: this.selectedAreas[i]
					});
				}
				this.job.areaRestriction = selectedAreas;

				this.job.posterUserId = this.getUser.userId;
				this.job.type = 'CONTRACT';
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "submit-contract-jobs-quotation-attachment", params: { jobId: response.data.body.id, type: response.data.body.type }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			}
		}
	};
</script>