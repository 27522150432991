import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class JobCategoryService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "jobCategories");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "jobCategories/" + id);
  }

  save(jobCategory) {
    return axios.post(GENERIC_SERVICE_URL + "jobCategories", {
      jobTitleId: jobCategory.jobTitleId,
      name: jobCategory.name,
      description: jobCategory.description,
      status: jobCategory.status
    });
  }
}

export default new JobCategoryService;
