import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class PaymentService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "payments");
  }

  findByReferenceNumber(referenceNumber) {
    return axios.get(GENERIC_SERVICE_URL + "payments" + "/findByReferenceNumber/" + referenceNumber);
  }

  save(payment) {
    return axios.post(GENERIC_SERVICE_URL + "payments", {
      payerUsername: payment.payerUsername,
      amount: payment.amount,
      currency: payment.currency
    });
  }
}

export default new PaymentService;
