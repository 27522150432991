<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<h4>New Bank</h4><br>
		<p v-if="message !== null" class="error-message text-center">{{ message }}</p>
		<form class="form" @submit.prevent="createBank">
			<div class="form-group">
				<label>Bank Name</label>
				<input type="text" v-model="bank.bankName" placeholder="Enter Bank name" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-message">
						<span v-if="error.bankName">{{ error.bankName }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Bank Description</label>
				<input type="text" v-model="bank.bankDescription" placeholder="Enter Bank description" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-message">
						<span v-if="error.bankDescription">{{ error.bankDescription }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Status</label>
				<select v-model="bank.bankStatus" class="form-control">
					<option value="">Select status</option>
					<option value="1">Active</option>
					<option value="0">Inactive</option>
				</select>
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-message">
						<span v-if="error.bankStatus">{{ error.bankStatus }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>&nbsp;</label>
				<router-link :to="{ name: 'banks' }" replace><button class="btn btn-default" data-dismiss="modal">Cancel</button></router-link> &nbsp; <button class="btn btn-success">Save & Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import Bank from "@/models/Bank";
	import BankService from "@/services/BankService";
	import loading from "vue-full-loading";

	export default {
		name: 'NewBank',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing request',
				bank: new Bank(),
				errors: [],
				message: null
			}
		},
		methods: {
			createBank: function(){
				this.show = true;
				BankService.createBank(this.bank).then(response => {
					console.log(response);
					if(response.data.statusCode == 422){
						this.show = false;
						console.log('validation errors: ');
						this.errors = response.data.errors;
					}
					if(response.data.statusCode == 400){
						this.show = false;
						this.errors = response.data.errors;
						this.message = response.data.message;
					}
					if(response.data.statusCode == 201){
						this.show = false;
						this.$router.push({ name: 'banks'});
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
					this.errors = error.response.data.errors;
					this.message = error.response.data.message;
				});
			}	
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message{
  color: red;
}
</style>