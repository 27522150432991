<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>Post your truck regular route</h4>
	    		<br>
				<form class="form" @submit.prevent="save">	
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>Recurring Type</label>
								<select class="form-control" v-model="truck.recurringType">
									<option value="" disabled>Select option</option>
									<option value="ONE_TIME">One time</option>
									<option value="DAILY">Daily</option>
									<option value="WEEKLY">Weekly</option>
									<option value="MONTHLY">Monthly</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<fieldset>
								<legend>Regular pickup location</legend>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Province</label>
											<select class="form-control" v-model="regularPickUpAddress.provinceId" @change="getPickupCities">
												<option value="" disabled>Select option</option>
												<option v-for="province in provinces" v-bind:key="province.id" :value="province.id">{{ province.name }}</option>
											</select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>City</label>
											<select class="form-control" v-model="regularPickUpAddress.cityId">
												<option value="" disabled>Select option</option>
												<option v-for="city in pickupCities" v-bind:key="city.id" :value="city.id">{{ city.name }}</option>
											</select>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<div class="col-md-6">
							<fieldset>
								<legend>Regular drop off location</legend>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Province</label>
											<select class="form-control" v-model="regularDropOffAddress.provinceId" @change="getDropOffCities">
												<option value="" disabled>Select option</option>
												<option v-for="province in provinces" v-bind:key="province.id" :value="province.id">{{ province.name }}</option>
											</select>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>City</label>
											<select class="form-control" v-model="regularDropOffAddress.cityId">
												<option value="" disabled>Select option</option>
												<option v-for="city in deliveryCities" v-bind:key="city.id" :value="city.id">{{ city.name }}</option>
											</select>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-primary btn-block">Submit</button>
							</div>
						</div>
					</div>
				</form>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import TruckService from "@/services/TruckService";
	import CityService from "@/services/CityService";
	import ProvinceService from "@/services/ProvinceService";
	import Truck from "@/models/Truck";

	export default{
		name: 'TruckRegularRoute',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinces: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				truck: new Truck,
				regularPickUpAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			    regularDropOffAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			}
		},

		created(){
			this.findByTruckNumber();
			this.getProvinces();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
			},

			getRoles: function() {
				let roles = [];
				for (var i = 0; i < this.getUser.authorities.length; i++) {
					roles.push(this.getUser.authorities[i].authority);
				}
				return roles;
			},

			getTruckNumber: function() {
				return this.$route.params.truckNumber;
			}
		},

		methods: {
			getProvinces: function(){
				ProvinceService.findAll().then(response => {
					this.provinces = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			getPickupCities: function() {
		      CityService.findByProvinceId(this.regularPickUpAddress.provinceId).then(response => {
		      	this.pickupCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

		    getDropOffCities: function() {
		      CityService.findByProvinceId(this.regularDropOffAddress.provinceId).then(response => {
		      	this.deliveryCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

		    findByTruckNumber: function(){
				this.show = true;
				TruckService.findByTruckNumber(this.getTruckNumber).then(response => {
					this.truck = response.data.body;
					this.show = false;
					console.log(this.truck);
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			save: function(){
				this.show = true;
				this.truck.carrierUsername = this.getUser.username;
				this.truck.regularPickUpAddress = this.regularPickUpAddress;
				this.truck.regularDropOffAddress = this.regularDropOffAddress;
				TruckService.save(this.truck).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'truck-pictures', params: { truckNumber: response.data.body.truckNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>