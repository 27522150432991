<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<h4>Edit Bank</h4><br>
		<form class="form" @submit.prevent="updateBank">
			<div class="form-group">
				<label>Bank Name</label>
				<input type="text" v-model="bank.bank_name" placeholder="Enter role name" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'bank_name' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Bank Description</label>
				<input type="text" v-model="bank.bank_description" placeholder="Enter role description" class="form-control">
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'bank_description' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>Status</label>
				<select v-model="bank.bank_status" class="form-control">
					<option value="">Select status</option>
					<option value="1">Active</option>
					<option value="0">Inactive</option>
				</select>
				<div v-if="errors">
					<p v-for="error in errors" :key="error.id" class="error-messages">
						<span v-if="error.field === 'bank_status' ">{{ error.message }}</span>
					</p>
				</div>
			</div>
			<div class="form-group">
				<label>&nbsp;</label>
				<router-link :to="{ name: 'banks' }" replace><button class="btn btn-default" data-dismiss="modal">Cancel</button></router-link> &nbsp; <button class="btn btn-success">Save & Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import Bank from "@/models/Bank";
	import BankService from "@/services/BankService";
	import loading from "vue-full-loading";

	export default {
		name: 'CreateProductCategory',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing...',
				bank: new Bank("", "", ""),
				errors: [],
			}
		},
		computed: {
			getBankId(){
				return this.$route.params.bankId;
			}
		},
		created(){
			this.getBankById(this.getBankId);
		},
		methods: {
			getBankById: function(id){
				BankService.getBankById(id).then(response => {
					this.bank = response.data.data;
					console.log('response: ', response.data.data);
				}).catch(error => {
					console.log('error: ', error);
					this.$router.push({ name: 'banks'});
				});
			},
			updateBank: function(){
				this.show = true;
				this.bank.id = this.getBankId;
				console.log('getBankId', this.getBankId);
				console.log('bank', this.bank);
				BankService.updateBank(this.bank, this.getBankId).then(response => {
					console.log(response.data.errors);
					if(response.data.statusCode == 422){
						this.show = false;
						console.log('validation errors: ');
						this.errors = response.data.errors;
					}
					if(response.data.statusCode == 200){
						this.show = false;
						this.$router.push({ name: 'banks'});
					}
				}).catch(error => {
					this.show = false;
					console.log(error);
					this.errors = error.response.data.errors;
				});
			}	
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>