import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class PreferenceService {
  save(preference) {
    return axios.post(GENERIC_SERVICE_URL + "preferences", {
      id: preference.id,
      name: preference.name,
      description: preference.description,
      status: preference.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "preferences");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "preferences/" + id);
  }
}

export default new PreferenceService;
