<!-- <template>
	<div>
	<loading :show="show" :label="label">
	    <div class="processing-row" slot="loading-container">
	      <div class="text-center">
	        <div slot="loading-spinner">
	          <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	        </div>
	        <p class="text-center" slot="loading-text">{{ label }}</p>
	      </div>
	    </div>
	</loading>

	<div class="row">
		<div class="col-md-3">
			<h4>Filter/Search</h4>
			<div class="panel">
				<div class="panel-body">
					<form class="form">
						<div class="form-group">
							<label>Category</label>
							<select class="form-control">
								<option value="">Choose option</option>
								<option v-for="tenderCategory in tenderCategories" :key="tenderCategory.id">{{ tenderCategory.name }}</option>
							</select>
						</div>
						<div class="form-group">
							<label>Location</label>
							<select class="form-control">
								<option value="">Choose option</option>
								<option v-for="country in countries" :key="country.id">{{ country.name }}</option>
							</select>
						</div>
						<div class="form-group">
							<label>&nbsp;</label>
							<button class="btn btn-success">Search...</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		
		<div class="col-md-9" v-if="tenders.length > 0">
		<h4>Browse tenders and get bucks!</h4>
			<div class="panel" v-for="tender in tenders" :key="tender.id">
				<div class="panel-body">
					<div class="row">
						<div class="col-md-3">
							<img src="@/assets/images/contractJobs.png" height="150" width="250">
						</div>
						<div class="col-md-3">
							<p>Tender Number: {{ tender.tenderNumber }}</p>
							<p>{{ tender.title }}</p>
							<p>{{ tender.closingDate }}</p>
							<p>
								<router-link :to="{ name: 'tenders/view', params: { tenderId: tender.id }}">
									<button class="btn btn-primary"><span class="glyphicon glyphicon-info-sign"></span> View</button>
								</router-link>
							</p>	
						</div>
					</div>
				</div>
			</div>
			<ul class="pagination">
				<li><a href="#">Prev</a></li>
				<li class="active"><a href="#">1</a></li>
				<li><a href="#">2</a></li>
				<li><a href="#">Next</a></li>
			</ul>
		</div>

		<div v-else>
			<div class="panel">
				<div class="panel-body">
					<h4>No jobs available at the moment. Check back again soon.</h4>
				</div>
			</div>
		</div>

	</div>
	</div>
</template>
<script type="text/javascript">
	import TenderService from "@/services/TenderService";
	import TenderCategoryService from "@/services/TenderCategoryService";
	import CountryService from "@/services/CountryService";
	import loading from "vue-full-loading";

	export default{
		name: "TenderList",
		components: {
			loading
		},

		data: function(){
			return {
				tenders: [],
				show: false,
				label: "Processing request...",
				tenderCategories: [],
				countries: []
			}
		},

		mounted: function(){
			this.findAll();
			this.findAllTenderCategories();
			this.findAllCountries();
		},

		computed: {

		},

		methods: {
			findAll: function(){
				this.show = true;
				TenderService.findAll().then(response => {
					this.tenders = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllTenderCategories: function(){
				this.show = true;
				TenderCategoryService.findAll().then(response => {
					this.tenderCategories = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllCountries: function(){
				this.show = true;
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},
		}
	}
</script>

<style type="text/css" scoped>
	.error-message {
	  color: red;
	}
	.processing-row {
	  display: flex;
	  justify-content: center;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translateX(-50%) translateY(-50%);
	}
</style> -->

<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="row">
			<div class="col-md-3">
				<h4>Filter/Search</h4>
				<div class="panel">
					<div class="panel-body">
						<form class="form">
							<div class="form-group">
								<label>Type</label>
								<select class="form-control">
									<option value="">Choose option</option>
								</select>
							</div>
							<div class="form-group">
								<label>Title</label>
								<select class="form-control">
									<option value="" disabled>Select option</option>
								</select>
							</div>
							<div class="form-group">
								<label>Rate</label>
								<input type="rate" name="rate" id="rate" placeholder="Enter limit" class="form-control">
							</div>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success">Search...</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<h4>Browse tenders and get bucks!</h4>
				<div v-if="tenders.length > 0">
					<div class="panel" v-for="tender in tenders" :key="tender.id">
						<div class="panel-body">
							<div class="row">
								<div class="col-md-3">
									<img src="@/assets/images/contractJobs.png" height="150" width="250">
								</div>
								<div class="col-md-3">
									<p>Job Title: {{ tender.title !== null ? tender.title: "Not available" }}</p>
									<p>Job Number: {{ tender.tenderNumber }}</p>						
									<p><span class="badge badge-primary">{{ tender.type }}</span></p>
									<p>Applications <span class="badge badge-primary">{{ tender.closingDate }}</span></p>
									<p>
										<router-link :to="{ name: 'jobs/view', params: { jobId: tender.id }}">
											<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
										</router-link>&nbsp;
										<span v-if="tender.internalOrExternal !== 'EXTERNAL'">
											<router-link :to="{ name: 'jobs/quickApply', params: { jobId: tender.id }}">
												<button class="btn btn-success">Quick Apply <span class="glyphicon glyphicon-edit"></span></button>
											</router-link>
										</span>
										<span v-else>
											<router-link :to="{ name: 'jobs/quickApply', params: { jobId: tender.id }}">
												<button class="btn btn-primary">Apply Externally <span class="glyphicon glyphicon-edit"></span></button>
											</router-link>
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<ul class="pagination">
						<li><a href="#">Prev</a></li>
						<li class="active"><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">Next</a></li>
					</ul>
				</div>
				<div v-else>
					<div class="panel">
						<div class="panel-body">
							<h4>No tenders available at the moment. Check back again soon.</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TenderService from "@/services/TenderService";
	import loading from "vue-full-loading";

	export default{
		name: "TenderList",
		components: {
			loading
		},

		data: function(){
			return {
				tenders: [],
				show: false,
				label: "Processing request..."
			}
		},

		mounted: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				TenderService.findAll().then(response => {
					this.tenders = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			}
		}
	}
</script>

<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>