<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Hours per week</h4>
      			<hr>
		      	<p>Knowing how much you can work helps Skilled Bios find the right jobs for you.</p>
				<form class="form" @submit.prevent="save">
					<div class="form-group">
						<label>I can currently work</label><br><br>
						<input type="radio" name="availability" id="availability" placeholder="Ex: https://localhost:2022" v-model="professional.availability" value="MORE_THAN_30_HOURS_PER_WEEK"> More than 30 hrs/week <br><br>
						<input type="radio" name="availability" id="availability" placeholder="Ex: https://localhost:2022" v-model="professional.availability" value="LESS_THAN_30_HOURS_PER_WEEK"> Less than 30 hrs/week <br><br>
						<input type="radio" name="availability" id="availability" placeholder="Ex: https://localhost:2022" v-model="professional.availability" value="AS_NEEDED"> As needed - open to offers <br><br>
						<input type="radio" name="availability" id="availability" placeholder="Ex: https://localhost:2022" v-model="professional.availability" value="NONE"> None

					</div>
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Save</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewHoursPerWeek",
		components: {
			loading
		},

		data: function(){
			return {
				professional: new Professional,
				label: "Processing...",
				show: false
			}
		},

		created: function(){ },

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			save: function(){
				this.show = true;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newLanguage", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>