import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class ProductDepartmentService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "productDepartments");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "productDepartments/" + id);
  }

  save(productDepartment) {
    return axios.post(GENERIC_SERVICE_URL + "productDepartments", {
      id: productDepartment.id,
      name: productDepartment.name,
      description: productDepartment.description,
      status: productDepartment.status
    });
  }
}

export default new ProductDepartmentService;
