<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<loading :show="show" :label="label">
			        <div class="processing-row" slot="loading-container">
			          <div class="text-center">
			            <div slot="loading-spinner">
			              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			            </div>
			            <p class="text-center" slot="loading-text">{{ label }}</p>
			          </div>
			        </div>
		      	</loading>

				<router-link :to="{ name: 'jobs/fullTimeJobList'}">
					<button class="btn btn-primary">Back</button>
				</router-link><br>

				<form class="form" @submit.prevent="save">
					<div v-show="job.status === 'PENDING'">
						<h4><button class="btn btn-success pull-right" type="submit">Approve</button></h4>
					</div>
				</form>
				
				<h4>Job Details</h4><br><br>

				<ul class="list-group">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">ID: {{ job.id }}</li>
					<li class="list-group-item">Job Number: {{ job.jobNumber }}</li>
					<li class="list-group-item">Job Title: {{ job.jobTitle.name }}</li>
					<li class="list-group-item">Type: {{ job.type }}</li>
					<li class="list-group-item">Hourly Rate: {{ job.currency }} {{ job.hourlyRate }} per hour</li>
					<li class="list-group-item">Expiry Date: {{ job.expiryDate }}</li>
					<li class="list-group-item">Attachment: {{ job.attachment }}</li>
					<li class="list-group-item">Skills Level: {{ job.skillsLevel }}</li>
					<li class="list-group-item">Availability: {{ job.availability }}</li>
					<li class="list-group-item">Estimated Budget: {{ job.estimatedBudget }}</li>
					<li class="list-group-item">Expertise Level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Payment Terms: {{ job.paymentTerms }}</li>
					<li class="list-group-item">Reach: {{ job.reach }}</li>
					<li class="list-group-item">Project Type: {{ job.projectType }}</li>
					<li class="list-group-item">Commitment hours per week: {{ job.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Project length: {{ job.projectLengthInMonths }}</li>
					<li class="list-group-item">Description: {{ job.description }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";
	import loading from "vue-full-loading";
	
	export default{
		name: "ViewJob",
		component: {
			loading
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		data: function(){
			return {
				job: new Job,
				show: false,
				label: "Processing request..."
			}
		},

		created: function(){
			this.findById();
		},

		methods: {
			findById: function(){
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				this.job.status = "ACTIVE";
				this.job.jobId = this.getJobId;
				console.log(this.job);
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "jobs/fullTimeJobList", params: { jobId: response.data.body.id }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>