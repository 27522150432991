<template>
	<div>
		<loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

		<div class="panel">
			<div class="panel-body">
				<p>Your products will be delivered to your delivery address that you provided as outlined below.</p>
				<hr>
				<div v-if="productDeliveryAddress !== null">
					<p class="text-danger">Product Delivery Address</p>
					<ul class="list-group">
						<li class="list-group-item">City: {{ productDeliveryAddress.location.city.name }}</li>
						<li class="list-group-item">Physical Address: {{ productDeliveryAddress.location.physicalAddress }}</li>
					</ul>
				</div>

				<router-link :to="{ name: 'calculate-charge', params: { deliveryAddressId: this.getDeliveryAddressId }}">
					<button class="btn btn-primary">Confirm delivery address</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import CompanyService from "@/services/CompanyService";
	import OrderService from "@/services/OrderService";
	import loading from "vue-full-loading";
	import ProductDeliveryAddressService from "@/services/ProductDeliveryAddressService";

	export default{
		name: "SearchDeliveryService",
		components: {
			loading
		},
		data: function(){
			return {
				companies: [],
				show: false,
				label: 'Processing request',
				productDeliveryAddress: null
			}
		},

		created: function(){
			this.findById();
		},

		computed: {
			getDeliveryAddressId(){
				return this.$route.params.deliveryAddressId;
			}
		},

		methods: {
			findAll: function(){
				this.show = true;
				CompanyService.findAll().then(response => {
					this.companies = response.data.body.filter(company => {
						return company.companyType === 'CARRIER';
					});
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			findById: function(){
				this.show = true;
				ProductDeliveryAddressService.findById(this.getDeliveryAddressId).then(response => {
					this.productDeliveryAddress = response.data.body;
					if (this.productDeliveryAddress !== null) {
						this.findAll();
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			bookOrder(){
				this.show = true;
				console.log('Booking offered to delivery company!');
				let post = {
				    orderNumber: null,
				    awarded: false,
				    assignedCarrierId: 30,
				    deliveryAddressId: 1
				};
				OrderService.save(post).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'checkout' });
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>
