<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <router-link :to="{ name: 'areas' }">
          <button class="btn btn-primary">
            <span class="fa fa-step-backward"></span> Back
          </button> </router-link
        ><br /><br />

        <ul class="list-group">
          <li class="list-group-item">ID : {{ area.id }}</li>
          <li class="list-group-item">Name : {{ area.name }}</li>
          <li class="list-group-item">Description : {{ area.description }}</li>
          <li class="list-group-item">
            Status
            <span v-if="area.status === 'ACTIVE'">
              <span class="badge badge-success">Active</span>
            </span>
            <span v-if="area.status === 'INACTIVE'">
              <span class="badge badge-secondary">Inactive</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import AreaService from "@/services/AreaService";
import Area from "@/models/Area";

export default {
  name: "ViewProvince",
  data: function() {
    return {
      area: new Area()
    };
  },

  computed: {
    getAreaId() {
      return this.$route.params.areaId;
    }
  },

  created: function() {
    this.findById();
  },

  methods: {
    findById: function() {
      AreaService.findById(this.getAreaId)
        .then(response => {
          this.area = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
