<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>My Contract Job List</h4>	
					</div>
					<router-link :to="{ name: 'new-contract-job' }">
						<div class="col-md-2">
							<button class="btn btn-success pull-right">New Job <span class="glyphicon glyphicon-plus"></span></button>
						</div>
					</router-link>
				</div>
				<br>

				<table class="table table-responsive table-bordered">
					<tr class="bg-primary">
						<th>ID</th>
						<th>Title</th>
						<th>Fixed Rate</th>
						<th>Type</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
					<tbody>
						<tr v-for="job in jobs" :key="job.id">
							<td>{{ job.id }}</td>
							<td>{{ job.title }}</td>
							<td>{{ job.currency }} {{ job.fixedRate }} per hour</td>
							<td>{{ job.type }}</td>
							<td>
								<span class="label label-success" v-if="job.status === 'ACTIVE'">{{ job.status }}</span>
								<span class="label label-primary" v-if="job.status === 'INACTIVE'">{{ job.status }}</span>
							</td>
							<td>
								<span>
									<router-link :to="{ name: 'view-contract-jobs-configuration', params: { jobId: job.id }}">
										<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
									</router-link>
								</span>&nbsp;
								<span v-if="job.status === 'PENDING'">
									<button class="btn btn-warning" type="button" @click="approve(job.id)">Approve</button>
								</span>&nbsp;
								<router-link :to="{ name: 'view-contract-jobs-quotations', params: { jobId: job.id }}">
									<button class="btn btn-primary"><span class="badge">{{ job.quotations.length }}</span> Quotations</button>
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";

	export default{
		name: "JobConfigurationList",
		component: {

		},

		data: function(){
			return {
				jobs: []
			}
		},

		mounted(){
			this.findByPosterUsername();
		},

		computed: {
			getUser(){
				return this.$store.state.auth.user.user;
			}
		},

		methods: {
			findByPosterUsername: function(){
				JobService.findByPosterUsername(this.getUser.username).then(response => {
					this.jobs = response.data.body.filter(job => {
						return job.type === 'CONTRACT'
					});
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			approve: function(id){
				this.show = true;
				JobService.approve(id).then(response => {
					if (response.data.statusCode === 201) {
						this.findByPosterUsername();
					}
					this.show = false;
				}).catch(error => {
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	}
</script>