<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>New Grant and Funding opportunity</h4>
      	<hr>

		<form class="form" @submit.prevent="save">
			
			<div class="form-group">
				<label>Title</label>
				<input type="text" name="title" v-model="grantAndFundingOpportunity.title" placeholder="Enter title" class="form-control" required>
			</div>
			
			<div class="form-group">
				<label>Grant and Funding Opportunity Category</label>
				<select class="form-control" v-model="grantAndFundingOpportunity.grantAndFundingOpportunityCategoryId">
					<option value="">Select option</option>
					<option v-for="grantAndFundingOpportunityCategory in grantAndFundingOpportunityCategories" :key="grantAndFundingOpportunityCategory.id" :value="grantAndFundingOpportunityCategory.id">{{ grantAndFundingOpportunityCategory.name }}</option>
				</select>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Estimated Budget</label>
						<input type="text" name="title" v-model="grantAndFundingOpportunity.estimatedBudget" placeholder="Enter estimated budget" class="form-control" required>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>Currency</label>
						<select class="form-control" v-model="grantAndFundingOpportunity.currency" required>
							<option value="" disabled>Select option</option>
							<option value="ZWL">ZWL</option>
							<option value="USD">USD</option>
							<option value="RAND">RAND</option>
						</select>
				    </div>
				</div>
			</div>

			<div class="form-group">
				<label>Expiry Date</label>
				<input type="date" name="expiryDate" v-model="grantAndFundingOpportunity.expiryDate" class="form-control" required>
			</div>

			<div class="form-group">
				<label>Description</label>
				<textarea class="form-control" placeholder="Enter Description" v-model="grantAndFundingOpportunity.description" rows="5" required></textarea>
			</div>

			<div class="form-group">
				<label>Status</label>
				<select class="form-control" v-model="grantAndFundingOpportunity.status" required>
					<option value="" disabled>Select option</option>
					<option value="ACTIVE">ACTIVE</option>
					<option value="INACTIVE">INACTIVE</option>
				</select>
			</div>
			
			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import GrantAndFundingOpportunityService from "@/services/GrantAndFundingOpportunityService";
	import GrantAndFundingOpportunity from "@/models/GrantAndFundingOpportunity";
	import GrantAndFundingOpportunityCategoryService from "@/services/GrantAndFundingOpportunityCategoryService";

	export default{
		name: "NewGrantAndFundingOpportunity",
		components: {
			loading
		},

		data: function(){
			return {
				grantAndFundingOpportunity: new GrantAndFundingOpportunity,
				label: "Processing...",
				show: false,
				grantAndFundingOpportunityCategories: []
			}
		},

		created: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				GrantAndFundingOpportunityCategoryService.findAll(this.grantAndFundingOpportunity).then(response => {
					this.grantAndFundingOpportunityCategories = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				GrantAndFundingOpportunityService.save(this.grantAndFundingOpportunity).then(response => {
					this.show = false;
					if (response.data.statusCode === 201) {
						this.$router.push({ name: "grantAndFundingOpportunityAttachment", params: { grantAndFundingOpportunityId: response.data.body.id }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>