<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<h4>
			<router-link :to="{ name: 'compliances' }">
				<button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button>
			</router-link>
		</h4>

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>Name</label>
				<input type="text" name="name" id="name" placeholder="Enter Tender Category name" class="form-control" v-model="tenderCategory.name">
			</div>

			<div class="form-group">
				<label>Description</label>
				<textarea class="form-control" rows="5" placeholder="Enter Tender Category description" name="description" id="description" v-model="tenderCategory.description"></textarea>
			</div>

			<div class="form-group">
				<label>Status</label>
				<select class="form-control" name="status" id="status" v-model="tenderCategory.status">
					<option value="">Select option</option>
					<option value="ACTIVE">ACTIVE</option>
					<option value="INACTIVE">INACTIVE</option>
				</select>
			</div>

			<div>
				<label>&nbsp;</label>
				<button class="btn btn-success pull-right" type="submit">Submit</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import TenderCategoryService from "@/services/TenderCategoryService";
	import TenderCategory from "@/models/TenderCategory";
	import loading from "vue-full-loading";

	export default{
		name: "NewTenderCategory",
		components: {
			loading
		},

		data: function(){
			return {
				tenderCategory: new TenderCategory,
				label: 'Processing request...',
				show: false
			}
		},

		created(){

		},

		methods: {
			save: function(){
				this.show = true;
				TenderCategoryService.save(this.tenderCategory).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name : 'tenderCategories' });
					}
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>