import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";
import authHeader from "@/services/authHeader";

class BankService {
  getAllBanks() {
    return axios.get(GENERIC_SERVICE_URL + "banks");
  }
  createBank(bank) {
    return axios.post(GENERIC_SERVICE_URL + "banks", {
      bankName: bank.bankName,
      bankDescription: bank.bankDescription,
      bankStatus: bank.bankStatus
    });
  }

  getBankById(id) {
    return axios.get(GENERIC_SERVICE_URL + "banks/" + id);
  }

  updateBank(bank, id) {
    return axios.put(
      GENERIC_SERVICE_URL + "banks/" + id,
      {
        id: bank.id,
        bankName: bank.bankName,
        bankDescription: bank.bankDescription,
        bankStatus: bank.bankStatus
      },
      { headers: authHeader() }
    );
  }
}

export default new BankService();
