<template>
	<div>
		<h4>
			<router-link :to="{ name: 'tenderCategories' }">
				<button class="btn btn-default"><span class="glyphicon glyphicon-step-backward"></span> Back</button>
			</router-link>
		</h4>

		<div class="list-group">
			<li class="list-group-item">{{ tenderCategory.id }}</li>
			<li class="list-group-item">{{ tenderCategory.name }}</li>
			<li class="list-group-item">{{ tenderCategory.description }}</li>
			<li class="list-group-item">{{ tenderCategory.status }}</li>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TenderCategoryService from "@/services/TenderCategoryService";

	export default{
		name: 'ViewCompliance',
		data: function(){
			return {
				tenderCategory: null
			}
		},

		created(){
			this.findById();
		},

		methods: {
			findById: function(){
				TenderCategoryService.findById(this.getComplianceId).then(response => {
					this.tenderCategory = response.data.body;
					console.log(this.compliance);
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {
			getComplianceId: function(){
				return this.$route.params.complianceId;
			}
		}
	}
</script>