<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
		      	<div class="row">
					<div class="col-md-10"></div>
					<router-link :to="{ name: 'full-time-jobs'}">
						<div class="col-md-2">
							<button class="btn btn-danger btn-block">Cancel</button>
						</div>
					</router-link>
				</div>
				<br>

				<form class="form" @submit.prevent="save">
					<fieldset>
						<legend>Job Details</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Job category</label>
									<select class="form-control" v-model="job.categoryId" required>
										<option value="" disabled>Select option</option>
										<option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">{{ jobCategory.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Job title</label>
									<input type="text" name="title" class="form-control" placeholder="Enter job title" v-model="job.title" required maxlength="50" minlength="2">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expiry date</label>
									<input type="date" name="expiryDate" v-model="job.expiryDate" class="form-control" required>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Internal or external</label>
									<select class="form-control" v-model="job.internalOrExternal" required>
										<option value="" disabled>Select option</option>
										<option value="INTERNAL">INTERNAL</option>
										<option value="EXTERNAL">EXTERNAL</option>
									</select>
								</div>
							</div>
							<div class="col-md-3" v-show="job.internalOrExternal === 'INTERNAL'">
								<div class="form-group">
									<label>Remuneration/Salary</label>
									<!-- <input type="text" name="remuneration" v-model="job.remuneration" placeholder="Enter remuneration" class="form-control"> -->
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="job.remuneration" placeholder="Enter amount"/>
								</div>
							</div>
							<div class="col-md-3" v-show="job.internalOrExternal === 'INTERNAL'">
								<div class="form-group">
									<label>Remuneration currency</label>
									<select class="form-control" v-model="job.currency">
										<option value="" disabled>Select option</option>
										<option value="USD">USD</option>
										<option value="ZWL">ZWL</option>
										<option value="RAND">RAND</option>
										<option value="PULA">PULA</option>
										<option value="KWACHA">KWACHA</option>
										<option value="SHILLING">SHILLING</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group" v-show="job.internalOrExternal === 'EXTERNAL'">
									<label>External Link</label>
									<input type="text" name="externalLink" v-model="job.externalLink" placeholder="Enter job external link" class="form-control">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Work type</label>
									<select class="form-control" v-model="job.workType" required>
										<option value="" disabled>Select option</option>
										<option value="ONSITE">On site</option>
										<option value="REMOTE">Remote</option>
										<option value="HYBRID">Hybrid</option>
									</select>
								</div>
							</div>
							<!-- <div class="col-md-3">
								<div class="form-group">
									<label>Hours of commitment per week</label>
									<input type="text" name="commitmentHoursPerWeek" v-model="job.commitmentHoursPerWeek" placeholder="Enter commitment hours per week" class="form-control" required>
								</div>
							</div> -->
							<div class="col-md-3">
								<div class="form-group">
									<label>Expertise level</label>
									<select class="form-control" v-model="job.expertiseLevel" required>
										<option value="" disabled>Select option</option>
										<option value="JUNIOR">Junior</option>
										<option value="INTERMEDIATE">Intermediate</option>
										<option value="MIXTURE_OF_SKILLS">Mixture of skills</option>
										<option value="HIGHLY_SKILLED">Highly Skilled</option>
										<option value="EXPERT">Expert</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<label>Job description</label>
								<textarea class="form-control" v-model="job.description" placeholder="Enter job description (optional) - max 3000 characters" rows="30" maxlength="5000"></textarea>
							</div>
						</div>
					</fieldset>
				<!-- 	<fieldset>
						<legend>Job Location</legend>
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Country</label>
									<select class="form-control" v-model="location.countryId" v-on:change="findByCountryId" required>
										<option value="" disabled>Select option</option>
										<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Province</label>
									<select class="form-control" v-model="location.provinceId" v-on:change="findByProvinceId" required>
										<option value="" disabled>Select option</option>
										<option v-for="province in provinces" :key="province.id" :value="province.id">{{ province.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>City</label>
									<select class="form-control" v-model="location.cityId" @change="findByCityId" required>
										<option value="" disabled>Select option</option>
										<option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
									</select>
								</div>
							</div>
						</div>
					</fieldset> -->
					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	import JobCategoryService from "@/services/JobCategoryService";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";
	import CountryService from "@/services/CountryService";
	import ProvinceService from "@/services/ProvinceService";
	import CityService from "@/services/CityService";
	import AreaService from "@/services/AreaService";
	import Location from "@/models/Location";

	export default{
		name: "NewFullTimeJob",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				jobCategories: [],
				job: new Job,
				label: "Processing request",
				show: false,
				formData: new FormData,
				location: new Location,
				selectedAreas: [],
				areas: [],
				cities: [],
				countries: [],
				provinces: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
			this.findAllJobCategories();
		},

		methods: {
			findAllJobCategories: function(){
				JobCategoryService.findAll(this.job).then(response => {
					this.jobCategories = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCountryId: function(){
				ProvinceService.findByCountryId(this.location.countryId).then(response => {
					this.provinces = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByProvinceId: function(){
				CityService.findByProvinceId(this.location.provinceId).then(response => {
					this.cities = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findByCityId: function(){
				AreaService.findByCityId(this.location.cityId).then(response => {
					this.areas = response.data.body;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				this.job.type = 'FULL_TIME';
				this.job.postedByUsername = this.getUser.user.username;
				this.job.location = this.location;
				this.job.status = 'PENDING';
				JobService.save(this.job).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "full-time-job-location", params: { jobId: response.data.body.id }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getUser: function(){
				return this.$store.state.auth.user;
			}
		}
	};
</script>