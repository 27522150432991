<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<h4>Projects are a new way to earn on SkilledBioz that helps you do more of the work you love to do. Create project offerings that highlight your strengths and attract more clients.</h4><br>

<!-- 		<router-link :to="{ name: 'newProjectCatalogDetails'}">
			<button class="btn btn-success" type="submit">Manage Projects</button>
		</router-link><br><br> -->

		<form class="form" @submit.prevent="save">
			<div class="form-group">
				<label>&nbsp;</label>
				<button class="btn btn-success" type="submit">Save</button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobTitleService from "@/services/JobTitleService";
	// import JobService from "@/services/JobService";
	import CountryService from "@/services/CountryService";
	import Job from "@/models/Job";

	export default{
		name: "NewProjectCatalog",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				job: new Job,
				label: "Processing request...",
				show: false,
				countries: []
			}
		},

		created: function(){
			this.findAllJobTitles();
			this.findAllCountries();
		},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			findAllCountries: function(){
				CountryService.findAll().then(response => {
					this.countries = response.data.data;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllJobTitles: function(){
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.data;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			save: function(){
				this.show = true;
				// this.professional.professionalId = this.getProfessionalId;
				this.$router.push({ name: "newTestimonial", params: { professionalId: this.getProfessionalId }});
				// JobService.save(this.job).then(response => {
				// 	this.show = false;
				// 	if(response.data.statusCode === 201){
				// 		this.$router.push({ name: "newTestimonial" });
				// 	}
				// }).catch(error => {
				// 	console.log(error.response);
				// 	this.show = false;
				// 	return Promise.reject(error);
				// });
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>