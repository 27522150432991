<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10">
            <h4>Fuel price configuration list</h4>
          </div>
          <router-link :to="{ name: 'new-fuel-price'}">
            <div class="col-md-2">
              <button class="btn btn-success btn-block">New fuel price</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <table class="table table-responsive table-bordered">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Country</th>
            <th>Diesel Amount</th>
            <th>Petrol Amount</th>
            <th>Active</th>
            <th>Current</th>
            <th>Enabled</th>
            <th>Expired</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="fuelPrice in fuelPriceList" :key="fuelPrice.id">
              <td>{{ fuelPrice.id }}</td>
              <td>{{ fuelPrice.country !== null ? fuelPrice.country.name: 'Not available' }}</td> 
              <td>{{ fuelPrice.currency }}{{ fuelPrice.dieselAmount }}</td>
              <td>{{ fuelPrice.currency }}{{ fuelPrice.petrolAmount }}</td>
              <td>{{ fuelPrice.active }}</td>
              <td>{{ fuelPrice.current }}</td>
              <td>{{ fuelPrice.enabled }}</td>
              <td>{{ fuelPrice.expired }}</td>
              <td>
                <span class="label label-primary" v-if="fuelPrice.status === 'ACTIVE'">Active</span>
                <span class="label label-default" v-if="fuelPrice.status === 'INACTIVE'">Inactive</span>
              </td>
              <td>
                <router-link :to="{ name: 'edit-fuel-price', params: { id: fuelPrice.id }}">
                  <button class="btn btn-warning">Edit</button>
                </router-link>&nbsp;
                <router-link :to="{ name: 'view-fuel-price', params: { id: fuelPrice.id }}">
                  <button class="btn btn-primary">View</button>
                </router-link>&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
	import FuelPriceService from "@/services/FuelPriceService";
	import loading from "vue-full-loading";

	export default {
	  name: "FuelPriceList",
	  components: {
	    loading
	  },

	  data: function() {
	    return {
	      show: false,
	      label: "Processing request",
	      fuelPriceList: []
	    };
	  },

	  mounted() {
	    this.findAll();
	  },

	  methods: {
	    findAll() {
	      this.show = true;
	      FuelPriceService.findAll().then(response => {
	      	this.fuelPriceList = response.data.body;
	      	this.show = false;
	      }).catch(error => {
	      	this.show = false;
	      	console.log(error);
	      });
	    }
	  }
	};
</script>
