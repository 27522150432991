<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <loading :show="show" :label="label">
          <div class="processing-row" slot="loading-container">
            <div class="text-center">
              <div slot="loading-spinner">
                <img
                  src="@/assets/images/station-loading.gif"
                  height="100"
                  width="100"
                />
              </div>
              <p class="text-center" slot="loading-text">{{ label }}</p>
            </div>
          </div>
        </loading>

        <router-link :to="{ name: 'areas' }">
          <button class="btn btn-primary">
            <span class="fa fa-step-backward"></span> Back
          </button> </router-link
        ><br /><br />

        <p class="error-message text-center" v-if="errorMessage !== null">
          {{ errorMessage }}
        </p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label>City</label>
            <select class="form-control" v-model="area.cityId" required>
              <option value="">Select city</option>
              <option v-for="city in cities" :key="city.id" :value="city.id">{{
                city.name
              }}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="code">Name</label>
            <input
              type="text"
              v-model="area.name"
              class="form-control"
              placeholder="Enter Area Name"
            />
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.name }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="description">Description</label>
            <input
              type="text"
              v-model="area.description"
              class="form-control"
              placeholder="Enter Description"
            />
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.description }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" v-model="area.status" required>
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
            <div v-if="errors !== null">
              <span v-for="error in errors" :key="error" class="error-message">
                {{ error.status }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="submit">&nbsp;</label>
            <button class="btn btn-success" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import AreaService from "@/services/AreaService";
import CityService from "@/services/CityService";
import Area from "@/models/Area";
import loading from "vue-full-loading";

export default {
  name: "NewZipCode",
  components: {
    loading
  },

  data: function() {
    return {
      area: new Area(),
      errors: [],
      errorMessage: null,
      show: false,
      label: "Processing...",
      cities: []
    };
  },

  computed: {},

  created: function() {
    this.findAllCities();
  },

  methods: {
    findAllCities: function() {
      CityService.findAll()
        .then(response => {
          this.cities = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    save: function() {
      this.showLoader();
      AreaService.save(this.area)
        .then(response => {
          this.cancelLoader();
          if (response.data.statusCode == 422) {
            this.setNotificationData(response);
          }
          if (response.data.statusCode == 201) {
            this.clearNotificationData();
            this.$router.push({ name: "areas" });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // make these functions global :: reuse, via the app instance
    clearNotificationData: function() {
      this.errors = null;
      this.errorMessage = null;
    },

    setNotificationData: function(response) {
      this.errors = response.data.errors;
      this.errorMessage = response.data.message;
    },

    cancelLoader: function() {
      this.show = false;
    },

    showLoader: function() {
      this.show = true;
    }
  }
};
</script>
<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
