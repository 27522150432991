<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>Company Profile</h4>
				<br>
				<div v-if="company !== null">
					<router-link :to="{ name: 'companies/tenderPreference', params: { companyId: company.id }}">
						<button class="btn btn-success pull-right">Tender Preference <span class="glyphicon glyphicon-plus"></span></button>
					</router-link>
				</div>

				<div v-if="company !== null">
					<router-link :to="{ name: 'companies/new' }">
						<button class="btn btn-success pull-right">New Company <span class="glyphicon glyphicon-plus"></span></button>
					</router-link>
				</div>

				<div v-if="company === null">
					<h4><span class="text-danger">Company profile not available</span></h4>
					<router-link :to="{ name: 'companies/new' }">
						<button class="btn btn-primary pull-right">New company profile <span class="glyphicon glyphicon-plus"></span></button>
					</router-link>
				</div>

				<div v-if="company !== null">
					<ul class="list-group">
						<li class="list-group-item">ID: {{ company.id }}</li>
						<li class="list-group-item">Contact Person First Name: {{ company.contactPerson != null ? company.contactPerson.firstName: "Not available" }}</li>
						<li class="list-group-item">Contact Person Last Name: {{ company.contactPerson != null ? company.contactPerson.firstName: "Not available" }}</li>
						<li class="list-group-item">Company Name: {{ company.name }}</li>
						<li class="list-group-item">Trading Name: {{ company.tradingName }}</li>
						<li class="list-group-item">Business Partner Number: {{ company.businessPartnerNumber }}</li>
						<li class="list-group-item">Phone Numbers: 
							<span v-if="company.phoneNumbers !== null">
								<span v-for="phoneNumber in company.phoneNumbers" :key="phoneNumber.id">
									<li class="list-group-item">+{{ phoneNumber.zipCode.code }}{{ phoneNumber.phoneNumber }}</li><br>
								</span>
							</span>
							<span v-else>
								Not available 
							</span>
						</li>
						<li class="list-group-item">Email: {{ company.email }}</li>
						<li class="list-group-item">Fax Number: {{ company.faxNumber }}</li>
						<li class="list-group-item">Website URL: {{ company.websiteUrl }}</li>
						<li class="list-group-item">Number Of Employees: {{ company.numberOfEmployees }}</li>
						<li class="list-group-item">Established Year: {{ company.establishedYear }}</li>
						<li class="list-group-item">Organization Type: {{ company.organizationType }}</li>
						<li class="list-group-item">Business Sector: {{ company.businessSector !== null ? company.businessSector.name: "Not available" }}</li>
						<li class="list-group-item">Business Categories: 
							<span v-if="company.businessCategories !== null">
								<span v-for="businessCategory in company.businessCategories" :key="businessCategory.id">
									{{ businessCategory.name }}
								</span>
							</span>
							<span v-else>
								Not available 
							</span>
						</li>
						<li class="list-group-item">Compliances: {{ company.compliances }}</li>
					</ul>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import CompanyService from "@/services/CompanyService.js";
	import loading from "vue-full-loading";
	import Company from "@/models/Company.js";

	export default{
		name: "CompanyProfile",
		components: {
			loading
		},

		data: function(){
			return {
				company: new Company,
				show: false,
				label: "Processing request",
			}
		},

		created(){
			this.findByAssociatedUserUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
      		},
		},

		methods: {
			findByAssociatedUserUsername: function(){
				console.log(this.getUser.username);
				this.show = true;
				CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
					this.company = response.data.body;
					console.log('company: ', this.company);
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			}
		}
	};
</script>