<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add Work History</h4><br>
				<form class="form" @submit.prevent="save">
					<button class="btn btn-success btn-sm pull-right" @click="addEducationField" type="button">Add <span class="glyphicon glyphicon-plus"></span></button>

					<div v-for="(workHistoryField, index) in workHistoryFields" :key="workHistoryField.id">
						<h4>Work History Number {{ index + 1 }}</h4>

						<div class="form-group">
							<label>Company Name</label><br><br>
							<input type="text" name="companyName" id="companyName" placeholder="Ex: Company Name" class="form-control" v-model="workHistoryField.companyName">
						</div>

						<h4>Dates Attended (Optional)</h4>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>From</label>
									<select class="form-control" v-model="workHistoryField.startYear">
										<option value="">Select option</option>
										<option value="2022">2022</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>To or (expected graduation year)</label>
									<select class="form-control" v-model="workHistoryField.endYear">
										<option value="">Select option</option>
										<option value="2022">2022</option>
									</select>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>Position</label><br><br>
							<input type="text" name="position" id="position" placeholder="Ex: Software Engineer" class="form-control" v-model="workHistoryField.position">
						</div>

						<div class="form-group">
							<label>Contact Person First Name</label><br><br>
							<input type="text" name="contactPersonFirstName" id="contactPersonFirstName" placeholder="Ex: James" class="form-control" v-model="workHistoryField.contactPersonFirstName">
						</div>

						<div class="form-group">
							<label>Contact Person Last Name</label><br><br>
							<input type="text" name="contactPersonLastName" id="contactPersonLastName" placeholder="Ex: Paraffin" class="form-control" v-model="workHistoryField.contactPersonLastName">
						</div>

						<label>Contact Person Phone Number</label>
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Zip Code</label>
									<select class="form-control" v-model="workHistoryField.contactPersonPhoneNumber.zipCodeId">
										<option value="">Select option</option>
										<option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Phone Number</label>
									<select class="form-control" v-model="workHistoryField.contactPersonPhoneNumber.phoneNumber">
										<option value="">Select option</option>
										<option>2022</option>
									</select>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label>Reason For Leaving</label><br><br>
							<textarea class="form-control" placeholder="Enter reasonForLeaving" rows="5" v-model="workHistoryField.reasonForLeaving"></textarea>
						</div>

						<div class="form-group" v-if="workHistoryFields.length > 0">
							<label>&nbsp;</label>
							<button class="btn btn-danger btn-sm pull-right" type="button" @click="workHistoryFields.splice(index, 1)">Remove</button>
						</div>
					</div><br><br>
					<br>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group" v-if="workHistoryFields.length > 0">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>

					<div class="row" v-if="workHistoryFields.length === 0">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<router-link :to="{ name: 'newPortfolio', params: { professionalId: this.getProfessionalId }}">
									<button class="btn btn-primary btn-block" type="submit">Next</button>
								</router-link>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProfessionalService from "@/services/ProfessionalService";
	import ZipCodeService from "@/services/ZipCodeService";
	import Professional from "@/models/Professional";

	export default{
		name: "NewWorkHistory",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				professional: new Professional,
				label: "Processing...",
				show: false,
				workHistoryFields: [],
			}
		},

		created: function(){ 
			this.findAllZipCodes();
		},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			findAllZipCodes: function(){
				ZipCodeService.findAll().then(response => {
					this.zipCodes = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			addEducationField: function(){
				this.workHistoryFields.push({
					companyName: null,
					startYear: null,
					endYear: null,
					position: null,
					contactPersonFirstName: null,
					contactPersonLastName: null,
					contactPersonPhoneNumber: {
						zipCodeId: null,
						phoneNumber: null,
					},
					reasonForLeaving: null
				});
			},

			save: function(){
				this.show = true;
				this.professional.workHistories = this.workHistoryFields;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newPortfolio", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>