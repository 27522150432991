<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-3">
            <h4>My Profile</h4>
            <router-link :to="{ name: 'userProfile' }">
              <img src="@/assets/images/tenders.png" width="80" height="80" class="img img-rounded img-responsive">
            </router-link>
          </div>

          <div class="col-md-3">
            <h4>Company Profile</h4>
            <router-link :to="{ name: 'companyProfile' }">
              <img src="@/assets/images/compliant.png" width="80" height="80" class="img img-rounded img-responsive">
            </router-link>
          </div>

          <div class="col-md-2">
            <h4>Professional Profile</h4>
            <router-link :to="{ name: 'newProfessional' }">
              <img src="@/assets/images/contract.png" width="80" height="80" class="img img-rounded img-responsive">
            </router-link>
          </div>

          <div class="col-md-2">
            <h4>Preferences</h4>
            <router-link :to="{ name: 'new-preferences', params: { username: this.getUser.username }}">
              <img src="@/assets/images/contract.png" width="80" height="80" class="img img-rounded img-responsive">
            </router-link>
          </div>

          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default{
    data: function(){
      return {

      }
    },

    computed: {
      getUser(){
        return this.$store.state.auth.user.user;
      }
    }
  };
</script>