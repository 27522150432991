import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import RegisterDeliveryService from "@/views/auth/RegisterDeliveryService.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import UserLocation from "@/views/auth/UserLocation.vue";
import OTP from "@/views/auth/OTP";

import DashboardNavigation from "@/components/DashboardNavigation.vue";
import Configuration from "@/views/configurations/Configuration";

import CompanyList from "@/views/companies/CompanyList";
import NewCompany from "@/views/companies/NewCompany";
import ViewCompany from "@/views/companies/ViewCompany";
import CompanySearch from "@/views/companies/CompanySearch";
import ViewCompanySearchDetail from "@/views/companies/ViewCompanySearchDetail";

import HelpCenter from "@/views/help-center/HelpCenter.vue";
import NotFound from "@/components/NotFound.vue";

import UserList from "@/views/users/UserList.vue";
import ViewUser from "@/views/users/ViewUser.vue";
import EditUser from "@/views/users/EditUser.vue";
import EditUserProfile from "@/views/users/EditUserProfile.vue";
import NewUser from "@/views/users/NewUser.vue";

import Security from "@/views/security-and-privacy/Security";
import Privacy from "@/views/security-and-privacy/Privacy";

import CompanyTypeList from "@/views/companytypes/CompanyTypeList";
import NewCompanyType from "@/views/companytypes/NewCompanyType";
import EditCompanyType from "@/views/companytypes/EditCompanyType";
import ViewCompanyType from "@/views/companytypes/ViewCompanyType";

import BankList from "@/views/banks/BankList";
import NewBank from "@/views/banks/NewBank";
import EditBank from "@/views/banks/EditBank";
import ViewBank from "@/views/banks/ViewBank";

import BankBranchList from "@/views/bankBranches/BankBranchList";
import NewBankBranch from "@/views/bankBranches/NewBankBranch";
import EditBankBranch from "@/views/bankBranches/EditBankBranch";
import ViewBankBranch from "@/views/bankBranches/ViewBankBranch";

import IndustryTypeList from "@/views/industryTypes/IndustryTypeList";
import NewIndustryType from "@/views/industryTypes/NewIndustryType";
import EditIndustryType from "@/views/industryTypes/EditIndustryType";
import ViewIndustryType from "@/views/industryTypes/ViewIndustryType";

import RoleList from "@/views/roles/RoleList";
import NewRole from "@/views/roles/NewRole";
import ViewRole from "@/views/roles/ViewRole";
import EditRole from "@/views/roles/EditRole";

import NewPerson from "@/views/persons/NewPerson.vue";
import NewBusinessDocuments from "@/views/deliveryServices/NewBusinessDocuments.vue";

import PaymentList from "@/views/payments/PaymentList.vue";
import NewPayment from "@/views/payments/NewPayment.vue";
import ViewPayment from "@/views/payments/ViewPayment.vue";

import JobList from "@/views/jobs/JobList";
import ViewJob from "@/views/jobs/ViewJob";

import FullTimeJobSearch from "@/views/fullTimeJobs/FullTimeJobSearch";
import FullTimeJobList from "@/views/fullTimeJobs/FullTimeJobList";
import ViewFullTimeJob from "@/views/fullTimeJobs/ViewFullTimeJob";
import FullTimeJobApplications from "@/views/fullTimeJobs/FullTimeJobApplications";

import TenderList from "@/views/tenders/TenderList";


import LandingPage from "@/views/landing/LandingPage";

import Tenders from "@/views/tenders/Tenders";

import ProfessionalSearch from "@/views/professionals/ProfessionalSearch";
import ViewProfessional from "@/views/professionals/ViewProfessional";
import CreateProfessional from "@/views/professionals/CreateProfessional";
import RecommendProfessional from "@/views/professionals/RecommendProfessional";

import NewZipCode from "@/views/zipCodes/NewZipCode.vue";
import ZipCodeList from "@/views/zipCodes/ZipCodeList.vue";
import ViewZipCode from "@/views/zipCodes/ViewZipCode.vue";
import EditZipCode from "@/views/zipCodes/EditZipCode.vue";

import NewCountry from "@/views/countries/NewCountry.vue";
import CountryList from "@/views/countries/CountryList.vue";
import ViewCountry from "@/views/countries/ViewCountry.vue";
import EditCountry from "@/views/countries/EditCountry.vue";

import NewProvince from "@/views/provinces/NewProvince.vue";
import ProvinceList from "@/views/provinces/ProvinceList.vue";
import ViewProvince from "@/views/provinces/ViewProvince.vue";
import EditProvince from "@/views/provinces/EditProvince.vue";

import NewCity from "@/views/cities/NewCity.vue";
import CityList from "@/views/cities/CityList.vue";
import ViewCity from "@/views/cities/ViewCity.vue";
import EditCity from "@/views/cities/EditCity.vue";

import NewArea from "@/views/areas/NewArea.vue";
import AreaList from "@/views/areas/AreaList.vue";
import ViewArea from "@/views/areas/ViewArea.vue";
import EditArea from "@/views/areas/EditArea.vue";

import ComplianceManagement from "@/views/complianceManagement/ComplianceManagement.vue";
import NewComplianceManagement from "@/views/complianceManagement/NewComplianceManagement.vue";
import ComplianceManagementPreview from "@/views/complianceManagement/ComplianceManagementPreview.vue";
import AttachComplianceEvidence from "@/views/complianceManagement/AttachComplianceEvidence.vue";
import ViewComplianceManagementDetail from "@/views/complianceManagement/ViewComplianceManagementDetail.vue";
import ComplianceManagementPreviewTest from "@/views/complianceManagement/ComplianceManagementPreviewTest.vue";

import NewCompanyCompliance from "@/views/complianceManagement/NewCompanyCompliance.vue";

import NewProduct from "@/views/products/NewProduct.vue";
import NewProductPhotos from "@/views/products/NewProductPhotos.vue";
import ProductSearch from "@/views/products/ProductSearch.vue";
import ProductListPreview from "@/views/products/ProductListPreview.vue";
import ProductListConfiguration from "@/views/products/ProductListConfiguration.vue";
import ProductCart from "@/views/products/ProductCart.vue";
import ProductDeliveryAddress from "@/views/products/ProductDeliveryAddress.vue";
import ViewProduct from "@/views/products/ViewProduct.vue";
import ViewProductPreview from "@/views/products/ViewProductPreview.vue";
import EditProductPhotos from "@/views/products/EditProductPhotos.vue";

import Checkout from "@/views/checkout/Checkout.vue";
import CheckoutOption from "@/views/checkout/CheckoutOption.vue";

import FundingOpportunitySearch from "@/views/grandAndfundingOpportunities/FundingOpportunitySearch.vue";
import ViewFundingOpportunity from "@/views/grandAndfundingOpportunities/ViewFundingOpportunity.vue";

import JobConfigurationList from "@/views/jobs/JobConfigurationList.vue";
import NewJob from "@/views/jobs/NewJob.vue";

import TenderConfigurationList from "@/views/tenders/TenderConfigurationList.vue";
import NewTender from "@/views/tenders/NewTender.vue";
import ViewTender from "@/views/tenders/ViewTender.vue";
import NewTenderDocument from "@/views/tenders/NewTenderDocument.vue";

import Freelancer from "@/views/freelancers/Freelancer";

import ComplianceList from "@/views/compliances/ComplianceList";
import NewCompliance from "@/views/compliances/NewCompliance";
import ViewCompliance from "@/views/compliances/ViewCompliance";

import NewProfessional from "@/views/professionals/NewProfessional";
import NewProfessionalLocationRestriction from "@/views/professionals/NewProfessionalLocationRestriction";
import NewVideoIntroduction from "@/views/professionals/NewVideoIntroduction";
import NewHoursPerWeek from "@/views/professionals/NewHoursPerWeek";
import NewLanguage from "@/views/professionals/NewLanguage";
import NewEducation from "@/views/professionals/NewEducation";
import NewHourlyRate from "@/views/professionals/NewHourlyRate";
import NewWorkHistory from "@/views/professionals/NewWorkHistory";
import NewPortfolio from "@/views/professionals/NewPortfolio";
import NewSkills from "@/views/professionals/NewSkills";
import NewProjectCatalog from "@/views/professionals/NewProjectCatalog";
import NewTestimonial from "@/views/professionals/NewTestimonial";
import NewCertifications from "@/views/professionals/NewCertifications";
import NewEmploymentHistory from "@/views/professionals/NewEmploymentHistory";
import NewOtherExperiences from "@/views/professionals/NewOtherExperiences";
import ProfessionalProfilePreview from "@/views/professionals/ProfessionalProfilePreview";

import SellerList from "@/views/sellers/SellerList";
import SellerProductList from "@/views/sellers/SellerProductList";
import SellerViewProduct from "@/views/sellers/SellerViewProduct";

import CompanyProfile from "@/views/companies/CompanyProfile";
import SellerProfile from "@/views/sellers/SellerProfile";
import NewSeller from "@/views/sellers/NewSeller";

import JobsPreference from "@/views/jobs/JobsPreference";
import NewJobPreference from "@/views/jobs/NewJobPreference";

import PreviewTender from "@/views/tenders/PreviewTender";

import NewJobAttachment from "@/views/jobs/NewJobAttachment";

import RequestForQuotationConfigurationList from "@/views/requestForQuotation/RequestForQuotationConfigurationList";
import NewRequestForQuotation from "@/views/requestForQuotation/NewRequestForQuotation";
import NewRequestForQuotationAttachment from "@/views/requestForQuotation/NewRequestForQuotationAttachment";
import RequestForQuotationConfigurationView from "@/views/requestForQuotation/RequestForQuotationConfigurationView";

import UserPreference from "@/views/userPreferences/UserPreference";

import NewFundingOpportunity from "@/views/grandAndfundingOpportunities/NewFundingOpportunity";
import GrantAndFundingOpportunitiesConfigurationList from "@/views/grandAndfundingOpportunities/GrantAndFundingOpportunitiesConfigurationList";

import QuickApply from "@/views/jobs/QuickApply";
import ApproveJob from "@/views/jobs/ApproveJob";
import ContractJobList from "@/views/jobs/ContractJobList";

import ContractJobConfigurationView from "@/views/jobs/ContractJobConfigurationView.vue";
import FullTimeJobConfigurationView from "@/views/jobs/FullTimeJobConfigurationView.vue";
 
import NewGrandAndFundingOpportunity from "@/views/grandAndfundingOpportunities/NewGrandAndFundingOpportunity.vue";
import GrantAndFundingOpportunityAttachment from "@/views/grandAndfundingOpportunities/GrantAndFundingOpportunityAttachment";


import GrantAndFundingOpportunityCategoryList from "@/views/grantAndFundingOpportunityCategories/GrantAndFundingOpportunityCategoryList";
import ViewGrantAndFundingOpportunityCategory from "@/views/grantAndFundingOpportunityCategories/ViewGrantAndFundingOpportunityCategory";
import NewGrantAndFundingOpportunityCategory from "@/views/grantAndFundingOpportunityCategories/NewGrantAndFundingOpportunityCategory";

import TenderCategoryList from "@/views/tenderCategories/TenderCategoryList";
import NewTenderCategory from "@/views/tenderCategories/NewTenderCategory";
import ViewTenderCategory from "@/views/tenderCategories/ViewTenderCategory";

import JobTitleList from "@/views/jobTitles/JobTitleList";
import NewJobTitle from "@/views/jobTitles/NewJobTitle";
import ViewJobTitle from "@/views/jobTitles/ViewJobTitle";

import JobCategoryList from "@/views/jobCategories/JobCategoryList";
import NewJobCategory from "@/views/jobCategories/NewJobCategory";
import ViewJobCategory from "@/views/jobCategories/ViewJobCategory";

import VolunteeringJobSearch from "@/views/volunteeringJobs/VolunteeringJobSearch";
import FriendInvite from "@/views/friendInvite/FriendInvite";

import CompliancePaymentPlan from "@/views/complianceManagement/CompliancePaymentPlan";
import CompliancePaymentPlanConfirm from "@/views/complianceManagement/CompliancePaymentPlanConfirm";
import CompliancePaymentPlanCheckout from "@/views/complianceManagement/CompliancePaymentPlanCheckout";

import PrivacyPolicy from "@/views/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "@/views/termsAndConditions/TermsAndConditions";

import ComplianceCategoryList from "@/views/complianceCategories/ComplianceCategoryList";
import NewComplianceCategory from "@/views/complianceCategories/NewComplianceCategory";
import ViewComplianceCategory from "@/views/complianceCategories/ViewComplianceCategory";

import ProductCategoryList from "@/views/product-categories/ProductCategoryList.vue";
import NewProductCategory from "@/views/product-categories/NewProductCategory.vue";
import ViewProductCategory from "@/views/product-categories/ViewProductCategory.vue";

import Subscriptions from "@/views/subscriptions/Subscriptions.vue";
import ComplianceManagementSubscription from "@/views/subscriptions/ComplianceManagementSubscription.vue";
import TenderSubscription from "@/views/subscriptions/TenderSubscription.vue";
import NGOFundingAndGrantsLeadsSubscription from "@/views/subscriptions/NGOFundingAndGrantsLeadsSubscription.vue";
import SalesLeadsSubscription from "@/views/subscriptions/SalesLeadsSubscription.vue";
import JobLeads from "@/views/subscriptions/JobLeads.vue";


import Profiles from "@/views/profiles/Profiles.vue";
import UserProfile from "@/views/profiles/UserProfile.vue";

import Preferences from "@/views/preference/Preferences.vue";
import JobsPreferences from "@/views/preference/JobsPreferences";
import ProductsPreferences from "@/views/preference/ProductsPreferences";
import TenderPreferences from "@/views/preference/TenderPreferences";
import GrantAndFundingOpportunityPreference from "@/views/preference/GrantAndFundingOpportunityPreference.vue";

import Pricing from "@/views/pricing/Pricing.vue";

import MessageFeed from "@/views/messageFeeds/MessageFeed.vue";

import JobQuotationList from "@/views/jobQuotations/JobQuotationList.vue";

import ComplianceManagementFeatures from "@/views/complianceManagement/ComplianceManagementFeatures.vue";

import HireAPro from "@/views/jobs/HireAPro.vue";
import ComplianceImpactReport from "@/views/complianceManagement/ComplianceImpactReport.vue";

import AddressComponent from "@/views/locations/AddressComponent.vue";
import PostalAddressComponent from "@/views/locations/PostalAddressComponent.vue";
import NewBusinessProfile from "@/views/deliveryServices/NewBusinessProfile.vue";
import NewBankAccount from "@/views/bankAccounts/NewBankAccount.vue";

import RegisterUser from "@/views/deliveryServices/RegisterUser.vue";
import DeliveryServiceAddress from "@/views/deliveryServices/DeliveryServiceAddress.vue";
import ViewDeliveryService from "@/views/deliveryServices/ViewDeliveryService.vue";

import NewCompanyAddress from "@/views/companies/NewCompanyAddress.vue";

import ContractJobs from "@/views/contractJobs/ContractJobs.vue";
import ViewContractJobs from "@/views/contractJobs/ViewContractJobs.vue";
import ViewContractJobsQuotations from "@/views/contractJobs/ViewContractJobsQuotations.vue";
import SubmitContractJobsQuotation from "@/views/contractJobs/SubmitContractJobsQuotation.vue";
import SubmitContractJobsQuotationAttachment from "@/views/contractJobs/SubmitContractJobsQuotationAttachment.vue";

import NewFullTimeJob from "@/views/fullTimeJobs/NewFullTimeJob.vue";
import ViewFullTimeJobsConfiguration from "@/views/fullTimeJobs/ViewFullTimeJobsConfiguration.vue";

import NewContractJob from "@/views/contractJobs/NewContractJob.vue";
import ContractJobsConfiguration from "@/views/contractJobs/ContractJobsConfiguration.vue";
import MyContractJobsConfiguration from "@/views/contractJobs/MyContractJobsConfiguration.vue";
import ViewContractJobConfiguration from "@/views/contractJobs/ViewContractJobConfiguration.vue";

import NewVolunteeringJob from "@/views/volunteeringJobs/NewVolunteeringJob.vue";
import VolunteeringJobConfigurationList from "@/views/volunteeringJobs/VolunteeringJobConfigurationList.vue";
import ViewVolunteeringJobsConfiguration from "@/views/volunteeringJobs/ViewVolunteeringJobsConfiguration.vue";

import TenderJobSearch from "@/views/tenderJobs/TenderJobSearch.vue";
import NewTenderJob from "@/views/tenderJobs/NewTenderJob.vue";
import ViewTenderJob from "@/views/tenderJobs/ViewTenderJob.vue";
import TenderJobsConfiguration from "@/views/tenderJobs/TenderJobsConfiguration.vue";
import ViewTenderJobsConfiguration from "@/views/tenderJobs/ViewTenderJobsConfiguration.vue";
import SendQuotation from "@/views/tenderJobs/SendQuotation.vue";

import ContractJobLocationRestriction from "@/views/contractJobs/ContractJobLocationRestriction.vue";

import ViewVolunteeringJob from "@/views/volunteeringJobs/ViewVolunteeringJob.vue";

import DeliveryServices from "@/views/deliveryServices/DeliveryServices.vue";

import NewSellerCompany from "@/views/sellers/NewSellerCompany.vue";
import NewSellerCompanyAddress from "@/views/sellers/NewSellerCompanyAddress.vue";
import NewSellerCompanyDocument from "@/views/sellers/NewSellerCompanyDocument.vue";

import SearchDeliveryService from "@/views/checkout/SearchDeliveryService.vue";
import CalculateDeliveryCharge from "@/views/checkout/CalculateDeliveryCharge.vue";

import ProductOrderList from "@/views/productOrders/ProductOrderList.vue";
import ViewProductOrder from "@/views/productOrders/ViewProductOrder.vue";

import NewFullTimeJobApplication from "@/views/fullTimeJobs/NewFullTimeJobApplication.vue";
import ApplyVolunteeringJob from "@/views/volunteeringJobs/ApplyVolunteeringJob.vue";


import ProductDepartmentList from "@/views/product-departments/ProductDepartmentList.vue";
import NewProductDepartment from "@/views/product-departments/NewProductDepartment.vue";
import ViewProductDepartment from "@/views/product-departments/ViewProductDepartment.vue";

import ProductSubCategoryList from "@/views/product-sub-categories/ProductSubCategoryList.vue";
import NewProductSubCategory from "@/views/product-sub-categories/NewProductSubCategory.vue";
import ViewProductSubCategory from "@/views/product-sub-categories/ViewProductSubCategory.vue";

import DeliveryServiceHome from "@/views/deliveryServices/DeliveryServiceHome.vue";

import VerifyCompany from "@/views/companies/VerifyCompany.vue";

import BuyerShipmentDashboard from "@/views/dashboard/BuyerShipmentDashboard";
import SellerShipmentDashboard from "@/views/dashboard/SellerShipmentDashboard";
import CarrierShipmentDashboard from "@/views/dashboard/CarrierShipmentDashboard";

import PaymentResponse from "@/views/payments/PaymentResponse";

import TruckList from "@/views/trucks/TruckList";
import NewTruck from "@/views/trucks/NewTruck";
import ViewTruck from "@/views/trucks/ViewTruck";
import NewTruckPictures from "@/views/trucks/NewTruckPictures";

import BasicDeliveryChargeList from "@/views/basic-delivery-charges/BasicDeliveryChargeList";
import EditBasicDeliveryCharge from "@/views/basic-delivery-charges/EditBasicDeliveryCharge";
import NewBasicDeliveryCharge from "@/views/basic-delivery-charges/NewBasicDeliveryCharge";
import ViewBasicDeliveryCharge from "@/views/basic-delivery-charges/ViewBasicDeliveryCharge";

import FuelPriceList from "@/views/fuel-prices/FuelPriceList";
import EditFuelPrice from "@/views/fuel-prices/EditFuelPrice";
import NewFuelPrice from "@/views/fuel-prices/NewFuelPrice";
import ViewFuelPrice from "@/views/fuel-prices/ViewFuelPrice";

import NewDeliveryServiceSubscription from "@/views/subscriptions/NewDeliveryServiceSubscription";
import SubscriptionPaymentResponse from "@/views/payments/SubscriptionPaymentResponse";

import CustomerOrderList from "@/views/orders/CustomerOrderList";
import ViewOrder from "@/views/orders/ViewOrder";
import CarrierOrderList from "@/views/orders/CarrierOrderList";
import SellerOrderList from "@/views/orders/SellerOrderList";
import AcceptOrDeclineOrderOffer from "@/views/orders/AcceptOrDeclineOrderOffer";

import UpdateOrderDeliveryStatus from "@/views/buyer-shipments/UpdateOrderDeliveryStatus";
import TruckRegularRoute from "@/views/trucks/TruckRegularRoute";

import NewPremiumProduct from "@/views/products/NewPremiumProduct";
import PayPremiumProduct from "@/views/payments/PayPremiumProduct";
import PremiumProductPaymentConfirmation from "@/views/payments/PremiumProductPaymentConfirmation";

import PromotionList from "@/views/promotions/PromotionList";
import NewPromotion from "@/views/promotions/NewPromotion";

import CarouselPromoList from "@/views/carousel/CarouselPromoList";
import NewCarouselPromo from "@/views/carousel/NewCarouselPromo";

import FullTimeJobLocation from "@/views/fullTimeJobs/FullTimeJobLocation";

import QualificationTypeList from "@/views/qualification-types/QualificationTypeList";
import NewQualificationType from "@/views/qualification-types/NewQualificationType";
import EditQualificationType from "@/views/qualification-types/EditQualificationType";
import ViewQualificationType from "@/views/qualification-types/ViewQualificationType";

import VolunteeringJobLocation from "@/views/volunteeringJobs/VolunteeringJobLocation";
import TenderJobLocation from "@/views/tenderJobs/TenderJobLocation";
import FollowCompany from "@/views/companies/FollowCompany";

import NewPreference from "@/views/preferences/NewPreference";

import PreferenceCategoryList from "@/views/preference-categories/PreferenceCategoryList";
import NewPreferenceCategory from "@/views/preference-categories/NewPreferenceCategory";
import ViewPreferenceCategory from "@/views/preference-categories/ViewPreferenceCategory";

import SubscriptionPlanList from "@/views/subscription-plans/SubscriptionPlanList";
import NewSubscriptionPlan from "@/views/subscription-plans/NewSubscriptionPlan";

import LikeProduct from "@/views/products/LikeProduct";

import SubmitProposalBid from "@/views/tenderJobs/SubmitProposalBid";
import SubmitTechnicalProposal from "@/views/tenderJobs/SubmitTechnicalProposal";
import SubmitCompanyRegistrationDocuments from "@/views/tenderJobs/SubmitCompanyRegistrationDocuments";
import SubmitReferenceCertificates from "@/views/tenderJobs/SubmitReferenceCertificates";
import ThankYouForSubmittingTenderProposal from "@/views/tenderJobs/ThankYouForSubmittingTenderProposal";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFound
  },

  {
    path: "/",
    name: "landing",
    component: LandingPage
  },

  {
    path: "/about",
    name: "About",
    component: () => import("../views/about/About.vue")
  },

  {
    path: "/login",
    name: "login",
    component: Login
  },

  {
    path: "/register",
    name: "register",
    component: Register
  },

  {
    path: "/otp",
    name: "otp",
    component: OTP
  },

  {
    path: "/register-delivery-service",
    name: "register-delivery-service",
    component: RegisterDeliveryService
  },

	{
		path: "/company-search",
	    name: "company-search",
	    component: CompanySearch
	},

	{
		path: "/view-company-search-detail/:companyId",
	    name: "view-company-search-detail",
	    component: ViewCompanySearchDetail
	},

  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword
  },

  {
    path: "/password-reset",
    name: "password-reset",
    component: ChangePassword
  },

  {
    path: "/user-location",
    name: "user-location",
    component: UserLocation
  },

  {
    path: "/termsAndConditions",
    name: "termsAndConditions",
    component: TermsAndConditions
  },

  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword
  },

  {
    path: "/landing",
    name: "landing",
    component: LandingPage
  },

  {
    path: "/jobs",
    name: "jobs",
    component: JobList
  },

  {
    path: "/view-full-time-job/:jobId",
    name: "view-full-time-job",
    component: ViewFullTimeJob
  },

  {
    path: "/jobs/view/:jobId",
    name: "jobs/view",
    component: ViewJob
  },

  {
    path: "/product-search",
    name: "product-search",
    component: ProductSearch
  },

  {
    path: "/view-product/:productId",
    name: "view-product",
    component: ViewProduct
  },

  {
    path: "/viewTenderConfigurationList/view/:tenderId",
    name: "viewTenderConfigurationList/view",
    component: PreviewTender
  },

  {
    path: "/tenders",
    name: "tenders",
    component: TenderList
  },

  {
    path: "/tenders/view/:tenderId",
    name: "tenders/view",
    component: ViewTender
  },

  {
    path: "/tenders/view/:tenderId",
    name: "tenders/view",
    component: ViewTender
  },

  {
    path: "/professionals-search",
    name: "professionals-search",
    component: ProfessionalSearch
  },

  {
    path: "/view-professional/:professionalId",
    name: "view-professional",
    component: ViewProfessional
  },

  {
    path: "/complianceManagementFeatures",
    name: "complianceManagementFeatures",
    component: ComplianceManagementFeatures
  },

  {
    path: "/products",
    name: "products",
    component: ProductSearch
  },

  {
    path: "/view-product/:productId",
    name: "view-product",
    component: ViewProduct
  },

  {
    path: "/product-cart",
    name: "product-cart",
    component: ProductCart
  },

  {
    path: "/products/delivery-address",
    name: "products/delivery-address",
    component: ProductDeliveryAddress
  },

  {
    path: "/search-delivery-service/:deliveryAddressId",
    name: "search-delivery-service",
    component: SearchDeliveryService
  },

  {
    path: "/calculate-charge/:deliveryAddressId",
    name: "calculate-charge",
    component: CalculateDeliveryCharge
  },

  {
    path: "/checkout-option",
    name: "checkout-option",
    component: CheckoutOption
  },

  {
    path: "/checkout",
    name: "checkout",
    component: Checkout
  },

  {
    path: "/funding-opportunity-search",
    name: "funding-opportunity-search",
    component: FundingOpportunitySearch
  },

  {
    path: "/view-funding-opportunity/:grantAndFundingOpportunityId",
    name: "view-funding-opportunity",
    component: ViewFundingOpportunity
  },

  {
    path: "/userPreferences/:userId",
    name: "userPreferences",
    component: UserPreference
  },

  {
    path: "/userProfile",
    name: "userProfile",
    component: UserProfile
  },

  {
    path: "/freelancers",
    name: "freelancers",
    component: Freelancer
  },

  {
    path: "/sellers",
    name: "sellers",
    component: SellerList
  },

  {
    path: "/sellers/products/:sellerId",
    name: "sellers/products",
    component: SellerProductList
  },

  {
    path: "/sellers/products/view/:productId/:sellerId",
    name: "sellers/products/view",
    component: SellerViewProduct
  },

  {
    path: "/companies",
    name: "companies",
    component: CompanyList
  },

  {
    path: "/companies/view/:companyId", 
    name: "companies/view",
    component: ViewCompany
  },

  {
    path: "/pricing", 
    name: "pricing",
    component: Pricing
  },

  {
    path: "/volunteering-job-search",
    name: "volunteering-job-search",
    component: VolunteeringJobSearch
  },

  {
    path: "/view-volunteering-job/:jobId",
    name: "view-volunteering-job",
    component: ViewVolunteeringJob
  },

  {
    path: "/tender-job-search",
    name: "tender-job-search",
    component: TenderJobSearch
  },

  {
    path: "/send-quotation/:jobId",
    name: "send-quotation",
    component: SendQuotation
  },

  {
    path: "/view-tender-job/:jobId",
    name: "view-tender-job",
    component: ViewTenderJob
  },

  {
  	path: "/full-time-job-search",
  	name: "full-time-job-search",
  	component: FullTimeJobSearch
  },

  {
  	path: "/delivery-service-home",
  	name: "delivery-service-home",
  	component: DeliveryServiceHome
  },

  {
  	path: "delivery-service/register-user",
    name: "delivery-service/register-user",
    component: RegisterUser
  },

  {
	  path: "/new-business-profile",
	  name: "new-business-profile",
	  component: NewBusinessProfile
	},

	{
	  path: "/delivery-service-address/:uuid",
	  name: "delivery-service-address",
	  component: DeliveryServiceAddress
	},

	{
	  path: "/new-business-profile/address/:uuid",
	  name: "new-business-profile/address",
	  component: AddressComponent
	},

	{
	  path: "/new-business-profile/postalAddress/:uuid",
	  name: "new-business-profile/postalAddress",
	  component: PostalAddressComponent
	},

	{
	  path: "/new-business-profile/business-documents/:uuid",
	  name: "new-business-profile/business-documents",
	  component: NewBusinessDocuments
	},

	{
	  path: "/new-business-profile/bank-accounts/:uuid",
	  name: "new-business-profile/bank-accounts",
	  component: NewBankAccount
	},

	{
	  path: "/new-delivery-service-subscription/:uuid",
	  name: "new-delivery-service-subscription",
	  component: NewDeliveryServiceSubscription
	},

	{
		path: "/subscription-payment-response",
        name: "subscription-payment-response",
        component: SubscriptionPaymentResponse
    },

  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardNavigation,
    children: [   
        {
          path: "/product-department-list",
          name: "product-department-list",
          component: ProductDepartmentList
        },

        {
          path: "/new-product-department",
          name: "new-product-department",
          component: NewProductDepartment
        },

        {
          path: "/view-product-department/:id",
          name: "view-product-department",
          component: ViewProductDepartment
        },

        {
          path: "/full-time-jobs",
          name: "full-time-jobs",
          component: FullTimeJobList
        },

        {
          path: "/full-time-job-location/:jobId",
          name: "full-time-job-location",
          component: FullTimeJobLocation
        },

        {
          path: "/full-time-job-applications/:jobId",
          name: "full-time-job-applications",
          component: FullTimeJobApplications
        },

        {
          path: "/new-full-time-job",
          name: "new-full-time-job",
          component: NewFullTimeJob
        },

        {
          path: "/new-full-time-job-application/:jobId",
          name: "new-full-time-job-application",
          component: NewFullTimeJobApplication
        },

        {
          path: "/new-volunteering-job-application/:jobId",
          name: "new-volunteering-job-application",
          component: ApplyVolunteeringJob
        },

        {
          path: "/view-full-time-jobs-configuration/:jobId",
          name: "view-full-time-jobs-configuration",
          component: ViewFullTimeJobsConfiguration
        },

        {
          path: "/contract-jobs-configuration",
          name: "contract-jobs-configuration",
          component: ContractJobsConfiguration
        },

        {
          path: "/my-contract-jobs-configuration",
          name: "my-contract-jobs-configuration",
          component: MyContractJobsConfiguration
        },

        {
          path: "/new-contract-job",
          name: "new-contract-job",
          component: NewContractJob
        },

        {
          path: "/view-contract-jobs-configuration/:jobId",
          name: "view-contract-jobs-configuration",
          component: ViewContractJobConfiguration
        },

        {
          path: "/contract-jobs",
          name: "contract-jobs",
          component: ContractJobs
        },

        {
          path: "/contract-job-location-restriction/:jobId",
          name: "contract-job-location-restriction",
          component: ContractJobLocationRestriction
        },

        {
          path: "/view-contract-jobs/:id",
          name: "view-contract-jobs",
          component: ViewContractJobs
        },

        {
          path: "/view-contract-jobs-quotations/:jobId",
          name: "view-contract-jobs-quotations",
          component: ViewContractJobsQuotations
        },

        {
          path: "/submit-contract-jobs-quotation-attachment/:jobId",
          name: "submit-contract-jobs-quotation-attachment",
          component: SubmitContractJobsQuotationAttachment
        },

        {
          path: "/submit-contract-jobs-quotation/:jobId",
          name: "submit-contract-jobs-quotation",
          component: SubmitContractJobsQuotation
        },
        
        {
          path: "/volunteering-jobs-configuration-list",
          name: "volunteering-jobs-configuration-list",
          component: VolunteeringJobConfigurationList
        },

        {
          path: "/new-volunteering-job",
          name: "new-volunteering-job",
          component: NewVolunteeringJob
        },

        {
          path: "/volunteering-job-location/:jobId",
          name: "volunteering-job-location",
          component: VolunteeringJobLocation
        },

        {
          path: "/view-volunteering-jobs-configuration/:jobId",
          name: "view-volunteering-jobs-configuration",
          component: ViewVolunteeringJobsConfiguration
        },

        {
          path: "/tender-jobs-configuration",
          name: "tender-jobs-configuration",
          component: TenderJobsConfiguration
        },

        {
          path: "/view-tender-jobs-configuration/:jobId",
          name: "view-tender-jobs-configuration",
          component: ViewTenderJobsConfiguration
        },

        {
          path: "/new-tender-job",
          name: "new-tender-job",
          component: NewTenderJob
        },
        
        {
          path: "companyList",
          name: "companyList",
          component: CompanyList
        },

        {
          path: "delivery-services",
          name: "delivery-services",
          component: DeliveryServices
        },

        {
          path: "view-delivery-service/:uuid",
          name: "view-delivery-service",
          component: ViewDeliveryService
        },

        {
          path: "/new-company-address/:uuid",
          name: "new-company-address",
          component: NewCompanyAddress
        },

        {
          path: "messageFeeds",
          name: "messageFeeds",
          component: MessageFeed
        },

        {
          path: "hire-a-pro",
          name: "hire-a-pro",
          component: HireAPro
        },

        {
          path: "jobQuotations",
          name: "jobQuotations",
          component: JobQuotationList
        },

        {
          path: "/compliancePaymentPlan",
          name: "compliancePaymentPlan",
          component: CompliancePaymentPlan
        },

        {
          path: "/compliancePaymentPlanCheckout",
          name: "compliancePaymentPlanCheckout",
          component: CompliancePaymentPlanCheckout
        },

        {
          path: "/compliancePaymentPlanConfirm/:plan",
          name: "compliancePaymentPlanConfirm",
          component: CompliancePaymentPlanConfirm
        },

        {
          path: "/friendInvite",
          name: "friendInvite",
          component: FriendInvite
        },

        {
          path: "/preferences",
          name: "preferences",
          component: Preferences
        },

        {
          path: "/fundingOpportunities/new",
          name: "fundingOpportunities/new",
          component: NewFundingOpportunity
        },

        {
          path: "/grandAndfundingOpportunityConfigurationList",
          name: "grandAndfundingOpportunityConfigurationList",
          component: GrantAndFundingOpportunitiesConfigurationList
        },

        {
          path: "/grantAndFundingOpportunityAttachment/:grantAndFundingOpportunityId",
          name: "grantAndFundingOpportunityAttachment",
          component: GrantAndFundingOpportunityAttachment
        },

        {
          path: "/newGrandAndFundingOpportunity",
          name: "newGrandAndFundingOpportunity",
          component: NewGrandAndFundingOpportunity
        },

        {
          path: "/grandAndfundingOpportunityConfiguration",
          name: "grandAndfundingOpportunityConfiguration",
          component: NewGrandAndFundingOpportunity
        },

        {
          path: "/grantAndFundingOpportunityPreference",
          name: "grantAndFundingOpportunityPreference",
          component: GrantAndFundingOpportunityPreference
        },

        {
          path: "/jobs/newFullTimeJob",
          name: "jobs/newFullTimeJob",
          component: NewJob
        },

        {
          path: "/jobs/fullTimeJobList",
          name: "jobs/fullTimeJobList",
          component: FullTimeJobList
        },

        {
          path: "/jobs/approve/:jobId",
          name: "jobs/approve",
          component: ApproveJob
        },

        {
          path: "/jobs/newContractJob",
          name: "jobs/newContractJob",
          component: NewJob
        },

        {
          path: "/jobs/newVolunteeringJob",
          name: "jobs/newVolunteeringJob",
          component: NewJob
        },

        {
          path: "/jobs/contractJobList",
          name: "jobs/contractJobList",
          component: ContractJobList
        },

        {
          path: "/tenders/newTenderDocument/:tenderId",
          name: "tenders/newTenderDocument",
          component: NewTenderDocument
        },

      {
        path: "/fundingOpportunity/new",
        name: "fundingOpportunity/new",
        component: NewFundingOpportunity
      },
      
      {
        path: "/requestForQuotationConfigurationList",
        name: "requestForQuotationConfigurationList",
        component: RequestForQuotationConfigurationList
      },

      {
        path: "/requestForQuotation/new",
        name: "requestForQuotation/new",
        component: NewRequestForQuotation
      },

      {
        path: "/newRequestForQuotationAttachment/:requestForQuotationId",
        name: "newRequestForQuotationAttachment",
        component: NewRequestForQuotationAttachment
      },

      {
        path: "/requestForQuotationConfigurationView/:requestForQuotationId",
        name: "requestForQuotationConfigurationView",
        component: RequestForQuotationConfigurationView
      },

      {
        path: "/new-job-attachment/:jobId/:type",
        name: "new-job-attachment",
        component: NewJobAttachment
      },

      {
        path: "/companyProfile",
        name: "companyProfile",
        component: CompanyProfile
      },

      {
        path: "/productPreferences",
        name: "productPreferences",
        component: ProductsPreferences
      },

      {
        path: "/tenderPreferences",
        name: "tenderPreferences",
        component: TenderPreferences
      },

      {
        path: "/sellerProfile",
        name: "sellerProfile",
        component: SellerProfile
      },

      {
        path: "/sellers/new",
        name: "sellers/new",
        component: NewSeller
      },

      {
        path: "/new-seller-company",
        name: "new-seller-company",
        component: NewSellerCompany
      },

      {
        path: "/new-seller-company-documents/:uuid",
        name: "new-seller-company-documents",
        component: NewSellerCompanyDocument
      },

      {
        path: "/new-seller-company-address/:uuid",
        name: "new-seller-company-address",
        component: NewSellerCompanyAddress
      },

      {
        path: "/jobPreference",
        name: "jobPreference",
        component: JobsPreference
      },

      {
        path: "/jobPreferences",
        name: "jobPreferences",
        component: JobsPreferences
      },

      {
        path: "/jobPreference/new",
        name: "jobPreference/new",
        component: NewJobPreference
      },

      {
        path: "/productsPreview",
        name: "productsPreview",
        component: ProductListPreview
      },

      {
        path: "/productsPreview/view/:productId",
        name: "productsPreview/view",
        component: ViewProductPreview
      },
      	{
      		path: "/edit-product-photos/:productId",
        	name: "edit-product-photos",
        	component: EditProductPhotos
      	},

      {
        path: "/new-premium-product/:productId",
        name: "new-premium-product",
        component: NewPremiumProduct
      },

      {
        path: "/pay-premium-product/:productId",
        name: "pay-premium-product",
        component: PayPremiumProduct
      },

      {
        path: "/premium-product-payment-confirmation/:productId",
        name: "premium-product-payment-confirmation",
        component: PremiumProductPaymentConfirmation
      },

      {
        path: "/newProfessional",
        name: "newProfessional",
        component: NewProfessional
      },

      {
        path: "/new-professional-location-restriction/:professionalId",
        name: "new-professional-location-restriction",
        component: NewProfessionalLocationRestriction
      },

      {
        path: "/newVideoIntroduction/:professionalId",
        name: "newVideoIntroduction",
        component: NewVideoIntroduction
      },

      {
        path: "/newHoursPerWeek/:professionalId",
        name: "newHoursPerWeek",
        component: NewHoursPerWeek
      },

      {
        path: "/newLanguage/:professionalId",
        name: "newLanguage",
        component: NewLanguage
      },

      {
        path: "/newEducation/:professionalId",
        name: "newEducation",
        component: NewEducation
      },

      {
        path: "/newHourlyRate/:professionalId",
        name: "newHourlyRate",
        component: NewHourlyRate
      },

      {
        path: "/newWorkHistory/:professionalId",
        name: "newWorkHistory",
        component: NewWorkHistory
      },

      {
        path: "/newPortfolio/:professionalId",
        name: "newPortfolio",
        component: NewPortfolio
      },

      {
        path: "/newSkills/:professionalId",
        name: "newSkills",
        component: NewSkills
      },

      {
        path: "/newProjectCatalog/:professionalId",
        name: "newProjectCatalog",
        component: NewProjectCatalog
      },

      {
        path: "/newTestimonial/:professionalId",
        name: "newTestimonial",
        component: NewTestimonial
      },

      {
        path: "/newCertifications/:professionalId",
        name: "newCertifications",
        component: NewCertifications
      },

      {
        path: "/newEmploymentHistory/:professionalId",
        name: "newEmploymentHistory",
        component: NewEmploymentHistory
      },

      {
        path: "/newOtherExperiences/:professionalId",
        name: "newOtherExperiences",
        component: NewOtherExperiences
      },

      {
        path: "/professionalProfilePreview/:professionalId",
        name: "professionalProfilePreview",
        component: ProfessionalProfilePreview
      },

      {
        path: "/tenderConfigurationList",
        name: "tenderConfigurationList",
        component: TenderConfigurationList
      },

      {
        path: "/compliance-list",
        name: "compliance-list",
        component: ComplianceList
      },

      {
        path: "/new-compliance",
        name: "new-compliance",
        component: NewCompliance
      },

      {
        path: "/view-compliance-management-details/:name",
        name: "view-compliance-management-details",
        component: ViewComplianceManagementDetail
      },

      {
        path: "/compliance-management-impact-report",
        name: "compliance-management-impact-report",
        component: ComplianceImpactReport
      },

      {
        path: "/view-compliance/:complianceId",
        name: "view-compliance",
        component: ViewCompliance
      },

      {
        path: "/professionals/create",
        name: "professionals/create",
        component: CreateProfessional
      },

      {
        path: "/professionals/recommend/:professionalId",
        name: "professionals/recommend",
        component: RecommendProfessional
      },

      {
        path: "/apply-full-time-job/:jobId",
        name: "apply-full-time-job",
        component: QuickApply
      },

      {
        path: "/compliance-management",
        name: "compliance-management",
        component: ComplianceManagement
      },

      {
        path: "/subscriptions",
        name: "subscriptions",
        component: Subscriptions
      },

      {
        path: "/subscriptions/complianceManagement",
        name: "subscriptions/complianceManagement",
        component: ComplianceManagementSubscription
      },

      {
        path: "/subscriptions/tenders",
        name: "subscriptions/tenders",
        component: TenderSubscription
      },

      {
        path: "/subscriptions/jobLeads",
        name: "subscriptions/jobLeads",
        component: JobLeads
      },

      {
        path: "/subscriptions/NGOFundingAndGrantsLeadsSubscription",
        name: "subscriptions/NGOFundingAndGrantsLeadsSubscription",
        component: NGOFundingAndGrantsLeadsSubscription
      },

      {
        path: "/subscriptions/salesLeads",
        name: "subscriptions/salesLeads",
        component: SalesLeadsSubscription
      },

      {
        path: "/complianceManagement/new",
        name: "complianceManagement/new",
        component: NewComplianceManagement
      },

      {
        path: "/new-company-compliance",
        name: "new-company-compliance",
        component: NewCompanyCompliance
      },

      {
        path: "/complianceManagement/preview",
        name: "complianceManagement/preview",
        component: ComplianceManagementPreview
      },

      {
        path: "/compliance-management-preview",
        name: "compliance-management-preview",
        component: ComplianceManagementPreviewTest
      },

      {
        path: "/complianceManagement/attachComplianceEvidence/:complianceId",
        name: "complianceManagement/attachComplianceEvidence",
        component: AttachComplianceEvidence
      },

      {
        path: "/tenderConfiguration",
        name: "tenderConfiguration",
        component: Tenders
      },

      {
        path: "/tenderConfiguration/create",
        name: "tenderConfiguration/create",
        component: NewTender
      },

      {
        path: "/new-product",
        name: "new-product",
        component: NewProduct
      },

      {
        path: "/new-product-photos/:productNumber",
        name: "new-product-photos",
        component: NewProductPhotos
      },

      {
        path: "/products/configuration",
        name: "products/configuration",
        component: ProductListConfiguration
      },

      {
        path: "/jobs/new",
        name: "jobs/new",
        component: NewJob
      },

      {
        path: "/jobConfigurationList",
        name: "jobConfigurationList",
        component: JobConfigurationList
      },

      {
        path: "/fullTimeJobConfigurationView/:jobId",
        name: "fullTimeJobConfigurationView",
        component: FullTimeJobConfigurationView
      },

      {
        path: "/contractJobConfigurationView/:jobId",
        name: "contractJobConfigurationView",
        component: ContractJobConfigurationView
      },

      {
        path: "/tenders/new",
        name: "tenders/new",
        component: NewTender
      },

      {
        path: "/students/new/attachments/:studentNumber",
        name: "students/new/attachments",
        component: NewBusinessDocuments
      },

      // payments
      {
        path: "/payments",
        name: "payments",
        component: PaymentList
      },

      {
        path: "/payments/new",
        name: "payments/new",
        component: NewPayment
      },
      {
        path: "/payments/view/:referenceNumber",
        name: "payments/view",
        component: ViewPayment
      },
      
      {
        path: "/roles",
        name: "roles",
        component: RoleList
      },
      {
        path: "/roles/new",
        name: "roles/new",
        component: NewRole
      },
      {
        path: '/roles/view/:roleId',
        name: "roles/view",
        component: ViewRole
      },

      {
        path: '/roles/edit/:roleId',
        name: "roles/edit",
        component: EditRole
      },

      {
        path: "/complianceCategories",
        name: "complianceCategories",
        component: ComplianceCategoryList
      },
      {
        path: "/complianceCategories/new",
        name: "complianceCategories/new",
        component: NewComplianceCategory
      },
      {
        path: '/complianceCategories/view/:complianceCategoryId',
        name: "complianceCategories/view",
        component: ViewComplianceCategory
      },


      {
        path: "/product-category-list",
        name: "product-category-list",
        component: ProductCategoryList
      },
      {
        path: "/new-product-category",
        name: "new-product-category",
        component: NewProductCategory
      },
      {
        path: '/view-product-category/:id',
        name: "view-product-category",
        component: ViewProductCategory
      },


      {
        path: "/grantAndFundingOpportunityCategories",
        name: "grantAndFundingOpportunityCategories",
        component: GrantAndFundingOpportunityCategoryList
      },

      {
        path: "/grantAndFundingOpportunityCategories/new",
        name: "grantAndFundingOpportunityCategories/new",
        component: NewGrantAndFundingOpportunityCategory
      },

      {
        path: '/grantAndFundingOpportunityCategories/view/:grantAndFundingOpportunityCategoryId',
        name: "grantAndFundingOpportunityCategories/view",
        component: ViewGrantAndFundingOpportunityCategory
      },

      {
        path: "/profiles",
        name: "profiles",
        component: Profiles
      },

      {
        path: "/userProfile",
        name: "userProfile",
        component: UserProfile
      },

      {
        path: '/persons/new',
        name: 'persons/new',
        component: NewPerson
      },

      {
        path: "/companies/new",
        name: "companies/new",
        component: NewCompany
      },

      {
        path: "/configurations",
        name: "configurations",
        component: Configuration
      },

      {
        path: "/help-center",
        name: "help-center",
        component: HelpCenter
      },

      {
        path: "/security",
        name: "security",
        component: Security
      },

      {
        path: "/privacy",
        name: "privacy",
        component: Privacy
      },

      {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: TermsAndConditions
      },

      // users
      {
        path: "/users",
        name: "users",
        component: UserList
      },

      {
        path: "/users/new",
        name: "users/new",
        component: NewUser
      },

      {
        path: "/users/view/:userId",
        name: "users/view",
        component: ViewUser
      },

      {
        path: "/users/edit/:userId",
        name: "users/edit",
        component: EditUser
      },

      {
        path: "/users/profile/editProfile/:userId",
        name: "users/profile/editProfile",
        component: EditUserProfile
      },

      // company types
      {
        path: "/companyTypes",
        name: "companyTypes",
        component: CompanyTypeList
      },

      {
        path: "/companyTypes/new",
        name: "companyTypes/new",
        component: NewCompanyType
      },

      {
        path: "/companyTypes/edit/:companyTypeId",
        name: "companyTypes/edit",
        component: EditCompanyType
      },

      {
        path: "/companyTypes/view/:companyTypeId",
        name: "companyTypes/view",
        component: ViewCompanyType
      },

      // banks
      {
        path: "/banks",
        name: "banks",
        component: BankList
      },
      {
        path: "/banks/new",
        name: "banks/new",
        component: NewBank
      },
      {
        path: "/banks/edit/:bankId",
        name: "banks/edit",
        component: EditBank
      },
      {
        path: "/banks/view/:bankId",
        name: "banks/view",
        component: ViewBank
      },

      {
        path: "/industryTypes",
        name: "industryTypes",
        component: IndustryTypeList
      },
      {
        path: "/industryTypes/new",
        name: "industryTypes/new",
        component: NewIndustryType
      },

      {
        path: "/industryTypes/edit/:industryTypeId",
        name: "industryTypes/edit",
        component: EditIndustryType
      },

      {
        path: "/industryTypes/view/:industryTypeId",
        name: "industryTypes/view",
        component: ViewIndustryType
      }, 

      {
        path: "/banks/bankBranches/:bankId",
        name: "banks/bankBranches",
        component: BankBranchList
      },

      {
        path: "/banks/bankBranches/new/:bankId",
        name: "banks/bankBranches/new",
        component: NewBankBranch
      },

      {
        path: "/banks/bankBranches/edit/:branchId",
        name: "banks/bankBranches/edit",
        component: EditBankBranch
      },

      {
        path: "/banks/bankBranches/view/:bankId/:branchId",
        name: "banks/bankBranches/view",
        component: ViewBankBranch
      },

      {
        path: "/zipCodes",
        name: "zipCodes",
        component: ZipCodeList
      },
      {
        path: "/zipCodes/new",
        name: "zipCodes/new",
        component: NewZipCode
      },
      {
        path: "/zipCodes/view/:ledgerId",
        name: "zipCodes/view",
        component: ViewZipCode
      },
      {
        path: "/zipCodes/edit/:ledgerId",
        name: "zipCodes/edit",
        component: EditZipCode
      },

      {
        path: "/countries",
        name: "countries",
        component: CountryList
      },
      {
        path: "/countries/new",
        name: "countries/new",
        component: NewCountry
      },
      {
        path: "/countries/view/:ledgerId",
        name: "countries/view",
        component: ViewCountry
      },
      {
        path: "/countries/edit/:ledgerId",
        name: "countries/edit",
        component: EditCountry
      },

      {
        path: "/provinces",
        name: "provinces",
        component: ProvinceList
      },
      {
        path: "/provinces/new",
        name: "provinces/new",
        component: NewProvince
      },
      {
        path: "/provinces/view/:ledgerId",
        name: "provinces/view",
        component: ViewProvince
      },
      {
        path: "/provinces/edit/:ledgerId",
        name: "provinces/edit",
        component: EditProvince
      },

      {
        path: "/cities",
        name: "cities",
        component: CityList
      },
      {
        path: "/cities/new",
        name: "cities/new",
        component: NewCity
      },
      {
        path: "/cities/view/:ledgerId",
        name: "cities/view",
        component: ViewCity
      },
      {
        path: "/cities/edit/:ledgerId",
        name: "cities/edit",
        component: EditCity
      },

      {
        path: "/areas",
        name: "areas",
        component: AreaList
      },
      {
        path: "/areas/new",
        name: "areas/new",
        component: NewArea
      },
      {
        path: "/areas/view/:ledgerId",
        name: "areas/view",
        component: ViewArea
      },
      {
        path: "/areas/edit/:ledgerId",
        name: "areas/edit",
        component: EditArea
      },

      {
        path: "/tenderCategories",
        name: "tenderCategories",
        component: TenderCategoryList
      },
      {
        path: "/tenderCategories/new",
        name: "tenderCategories/new",
        component: NewTenderCategory
      },
      {
        path: "/tenderCategories/view/:tenderCategoryId",
        name: "tenderCategories/view",
        component: ViewTenderCategory
      },

      {
        path: "/jobTitles",
        name: "jobTitles",
        component: JobTitleList
      },
      {
        path: "/jobTitles/new",
        name: "jobTitles/new",
        component: NewJobTitle
      },
      {
        path: "/jobTitles/view/:jobTitleId",
        name: "jobTitles/view",
        component: ViewJobTitle
      },

      {
        path: "/job-category-list",
        name: "job-category-list",
        component: JobCategoryList
      },

      {
        path: "/new-job-category",
        name: "new-job-category",
        component: NewJobCategory
      },
      
      {
        path: "/view-job-category/:jobCategoryId",
        name: "view-job-category",
        component: ViewJobCategory
      },

      // {
      //   path: "/apply-full-time-job/:jobId",
      //   name: "apply-full-time-job",
      //   component: ApplyFullTimeJob
      // },

      {
        path: "/product-orders",
        name: "product-orders",
        component: ProductOrderList
      },

      {
        path: "/view-product-order/:orderNumber",
        name: "view-product-order",
        component: ViewProductOrder
      },

      {
        path: "/accept-or-decline-order-offer/:orderNumber",
        name: "accept-or-decline-order-offer",
        component: AcceptOrDeclineOrderOffer
      },

      {
        path: "/product-sub-category-list",
        name: "product-sub-category-list",
        component: ProductSubCategoryList
      },
      
      {
        path: "/product-sub-category-list",
        name: "product-sub-category-list",
        component: NewProductSubCategory
      },

      {
        path: "/product-sub-category-list",
        name: "product-sub-category-list",
        component: ViewProductSubCategory
      },

      {
        path: "/companies/verify/:id",
        name: "companies/verify",
        component: VerifyCompany
      },

      {
        path: "/buyer-shipments",
        name: "buyer-shipments",
        component: BuyerShipmentDashboard
      },

      {
        path: "/seller-shipments",
        name: "seller-shipments",
        component: SellerShipmentDashboard
      },

      {
        path: "/carrier-shipments",
        name: "carrier-shipments",
        component: CarrierShipmentDashboard
      },

      {
        path: "/payment-response",
        name: "payment-response",
        component: PaymentResponse
      },

      {
        path: "/truck-list",
        name: "truck-list",
        component: TruckList
      },

        {
        path: "/new-truck",
        name: "new-truck",
        component: NewTruck
      },

      {
        path: "/truck-pictures/:truckNumber",
        name: "truck-pictures",
        component: NewTruckPictures
      }, 

      {
        path: "/view-truck/:truckNumber",
        name: "view-truck",
        component: ViewTruck
      },

      // basic charge delivery
      {
        path: "/basic-delivery-charge-list",
        name: "basic-delivery-charge-list",
        component: BasicDeliveryChargeList
      },

      {
        path: "/new-basic-delivery-charge",
        name: "new-basic-delivery-charge",
        component: NewBasicDeliveryCharge
      },

      {
        path: "/edit-basic-delivery-charge/:id",
        name: "edit-basic-delivery-charge",
        component: EditBasicDeliveryCharge
      },

      {
        path: "/view-basic-delivery-charge/:id",
        name: "view-basic-delivery-charge",
        component: ViewBasicDeliveryCharge
      },

      // fuel price
      {
        path: "/fuel-price-list",
        name: "fuel-price-list",
        component: FuelPriceList
      },

      {
        path: "/new-fuel-price",
        name: "new-fuel-price",
        component: NewFuelPrice
      },

      {
        path: "/edit-fuel-price/:id",
        name: "edit-fuel-price",
        component: EditFuelPrice
      },

      {
        path: "/view-fuel-price/:id",
        name: "view-fuel-price",
        component: ViewFuelPrice
      },

      {
        path: "/new-delivery-service-subscription/:id",
        name: "new-delivery-service-subscription",
        component: NewDeliveryServiceSubscription
      },

      {
        path: "/customer-order-list",
        name: "customer-order-list",
        component: CustomerOrderList
      },

      {
        path: "/view-order/:orderNumber",
        name: "view-order",
        component: ViewOrder
      },

      {
        path: "/carrier-order-list",
        name: "carrier-order-list",
        component: CarrierOrderList
      },

      {
        path: "/seller-order-list",
        name: "seller-order-list",
        component: SellerOrderList
      },

      {
        path: "/update-delivery-status/:orderNumber",
        name: "update-delivery-status",
        component: UpdateOrderDeliveryStatus
      },

      {
        path: "/regular-route/:truckNumber",
        name: "regular-route",
        component: TruckRegularRoute
      },

      {
        path: "/payment-list",
        name: "payment-list",
        component: PaymentList
      },

      {
        path: "/view-payment/:referenceNumber",
        name: "view-payment",
        component: ViewPayment
      },

      {
        path: "/promotion-list",
        name: "promotion-list",
        component: PromotionList
      },

      {
        path: "/new-promotion",
        name: "new-promotion",
        component: NewPromotion
      },

      {
        path: "/carousel-promo-list",
        name: "carousel-promo-list",
        component: CarouselPromoList
      },

      {
        path: "/new-carousel-promo",
        name: "new-carousel-promo",
        component: NewCarouselPromo
      },

      {
        path: "/qualification-type-list",
        name: "qualification-type-list",
        component: QualificationTypeList
      },

      {
        path: "/new-qualification-type",
        name: "new-qualification-type",
        component: NewQualificationType
      },

      {
        path: "/edit-qualification-type/:id",
        name: "edit-qualification-type",
        component: EditQualificationType
      },

      {
        path: "/view-qualification-type/:id",
        name: "view-qualification-type",
        component: ViewQualificationType
      },

      {
        path: "/tender-job-location/:jobId",
        name: "tender-job-location",
        component: TenderJobLocation
      },

      {
        path: "/follow-company/:companyId",
        name: "follow-company",
        component: FollowCompany
      },

      {
        path: "/like-product/:productNumber",
        name: "like-product",
        component: LikeProduct
      },

      {
        path: "/new-preferences/:username",
        name: "new-preferences",
        component: NewPreference
      },

      {
        path: "/preference-category-list",
        name: "preference-category-list",
        component: PreferenceCategoryList
      },

      {
        path: "/new-preference-category",
        name: "new-preference-category",
        component: NewPreferenceCategory
      },

      {
        path: "/view-preference-category/:preferenceCategoryId",
        name: "view-preference-category",
        component: ViewPreferenceCategory
      },

        {
        	path: "/subscription-plans",
        	name: "subscription-plans",
        	component: SubscriptionPlanList
     	},

     	{
     		path: "/new-subscription-plan",
     		name: "new-subscription-plan",
        	component: NewSubscriptionPlan
      	},

      	{
     		path: "/submit-proposal-bid/:jobId",
     		name: "submit-proposal-bid",
        	component: SubmitProposalBid
      	},

      	{
     		path: "/submit-technical-proposal/:jobId",
     		name: "submit-technical-proposal",
        	component: SubmitTechnicalProposal
      	},

      	{
     		path: "/submit-company-registration-documents/:jobId",
     		name: "submit-company-registration-documents",
        	component: SubmitCompanyRegistrationDocuments
      	},

      	{
     		path: "/submit-reference-certificates/:jobId",
     		name: "submit-reference-certificates",
        	component: SubmitReferenceCertificates
      	},

      	{
     		path: "/thank-you-for-submitting-tender-proposal",
     		name: "thank-you-for-submitting-tender-proposal",
        	component: ThankYouForSubmittingTenderProposal
      	},
    ]
  }
];

const router = new VueRouter({
  // mode: "history", // # on routes :: may leave it there
  base: process.env.BASE_URL, // change the base url to app later on
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [ 
  'login', 'forgotPassword', 'register', 'register-delivery-service', 'landing', 'full-time-job-search', 'view-full-time-job', 
  'detotalHoursOfService', 'password-reset', 'tender-job-search', 'view-tender-job', 'funding-opportunity-search', 
  'view-funding-opportunity', 'professionals-search', 'product-search', 'view-product', 'volunteering-job-search',
  'view-volunteering-job', 'delivery-service-home', 'complianceManagementFeatures', 'pricing', 'new-business-profile',
  'delivery-service-address', 'new-business-profile/business-documents', 'new-delivery-service-subscription',
  'subscription-payment-response', 'sellers/products', 'sellers/products/view', 'user-location', 'otp', 'company-search',
  'view-company-search-detail'];
  const authorizedPages = !publicPages.includes(to.name);
  const loggedIn = JSON.parse(localStorage.getItem("user"));
  if(authorizedPages && !loggedIn){
    next({
    	path: '/login',
    	query: {
    		redirect: to.fullPath
    	}
    });
  }else{
    next();
  }
});
export default router;
