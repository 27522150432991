<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <router-link :to="{ name: 'configurations' }" replace>
      <button class="btn btn-primary">Back</button>
      </router-link>

    <router-link :to="{ name: 'grantAndFundingOpportunityCategories/new' }" replace>
      <button class="btn btn-success pull-right">New Grant And Funding Opportunity Category <span class="glyphicon glyphicon-plus"></span>
      </button>
    </router-link><br/><br/>

    <h4 class="text-center">All Grant And Funding Opportunity Categories</h4>
    <table class="table table-responsive table-bordered">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="grantAndFundingOpportunityCategory in grantAndFundingOpportunityCategories" :key="grantAndFundingOpportunityCategory.id">
          <td>{{ grantAndFundingOpportunityCategory.id }}</td>
          <td>{{ grantAndFundingOpportunityCategory.name }}</td>
          <td>{{ grantAndFundingOpportunityCategory.description }}</td>
          <td>
            <span class="label label-success" v-if="grantAndFundingOpportunityCategory.status == 'ACTIVE'"
              >Active</span
            >
            <span class="label label-default" v-if="grantAndFundingOpportunityCategory.status == 'INACTIVE'"
              >Inactive</span
            >
            <span class="label label-danger" v-if="grantAndFundingOpportunityCategory.status == 'DELETED'"
              >Deleted</span
            >
          </td>
          <td>
            <router-link :to="{ name: 'grantAndFundingOpportunityCategoryId/edit', params: { grantAndFundingOpportunityCategoryId: grantAndFundingOpportunityCategory.id } }"
              ><button class="btn btn-primary">Edit</button>
            </router-link>&nbsp;
            <router-link :to="{ name: 'grantAndFundingOpportunityCategoryId/view', params: { grantAndFundingOpportunityCategoryId: grantAndFundingOpportunityCategory.id } }">
              <button class="btn btn-primary">View</button>
            </router-link
            >&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
  import GrantAndFundingOpportunityCategoryService from "@/services/GrantAndFundingOpportunityCategoryService";
  import loading from "vue-full-loading";

  export default {
    name: "GrantAndFundingOpportunityCategory",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing request...",
        grantAndFundingOpportunityCategories: []
      };
    },

    created() {
      this.findAll();
    },

    methods: {
      findAll() {
        this.show = true;
        GrantAndFundingOpportunityCategoryService.findAll().then(response => {
          this.show = false;
          this.grantAndFundingOpportunityCategories = response.data.body;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
