<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
			  <div class="text-center">
			    <div slot="loading-spinner">
			      <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
			    </div>
			    <p class="text-center" slot="loading-text">{{ label }}</p>
			  </div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-11">
						<h4>New Product</h4>		
					</div>
					<div class="col-md-1">
						<router-link :to="{ name: 'productsPreview'}">
							<button class="btn btn-danger btn-block pull-right">Cancel</button>
						</router-link>
					</div>
				</div>
				<br><br>

				<p v-if="message">{{ message }}</p>
				<div v-if="seller !== null">
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Name</label>
									<input type="text" name="name" v-model="product.name" class="form-control" placeholder="Enter product name" required>
								</div>	
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Product Category</label>
									<select class="form-control" name="categoryId" v-model="product.categoryId">
										<option value="">Select option</option>
										<option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
									</select>
								</div>
							</div>
							<!-- <div class="col-md-4"> -->
								<!-- <div class="form-group"> -->
									<!-- is this sub category? -->
									<!-- <label>Product Department</label>
									<select class="form-control" name="departmentId" v-model="product.departmentId">
										<option value="">Select option</option>
										<option v-for="department in departments" :key="department.id" :value="department.id">{{ department.name }}</option>
									</select> -->
								<!-- </div> -->
							<!-- </div> -->
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Available quantity</label>
									<input type="text" name="quantity" v-model="product.quantity" class="form-control" placeholder="Enter product quantity">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Price</label>
									<!-- <input type="text" name="price" v-model="product.price" class="form-control" placeholder="Enter product unit price"> -->
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="product.price" placeholder="Enter product unit price"/>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Currency</label>
									<select class="form-control" name="currency" v-model="product.currency">
										<option value="">Select currency</option>
										<option value="ZWL">ZWL</option>
										<option value="USD">USD</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Percentage discount</label>
									<input type="text" name="percentageDiscount" v-model="product.percentageDiscount" class="form-control" placeholder="Enter percentage discount">
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>In Stock</label><br>
									<input type="radio" name="inStock" v-model="product.inStock" value="true"> Yes &nbsp;&nbsp;&nbsp;
									<input type="radio" name="inStock" v-model="product.inStock" value="false"> No
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Eligible for next day delivery or collection</label><br>
									<input type="radio" name="eligibleForNextDayDeliveryOrCollection" v-model="product.eligibleForNextDayDeliveryOrCollection" value="true"> Yes
									<input type="radio" name="eligibleForNextDayDeliveryOrCollection" v-model="product.eligibleForNextDayDeliveryOrCollection" value="false"> No
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Eligible for cash on delivery</label><br>
									<input type="radio" name="eligibleForCashOnDelivery" v-model="product.eligibleForCashOnDelivery" value="true"> Yes
									<input type="radio" name="eligibleForCashOnDelivery" v-model="product.eligibleForCashOnDelivery" value="false"> No
								</div>
							</div>
						</div>
						<br>

						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Free delivery available</label><br>
									<input type="radio" name="freeDeliveryAvailable" v-model="product.freeDeliveryAvailable" value="true"> Yes
									<input type="radio" name="freeDeliveryAvailable" v-model="product.freeDeliveryAvailable" value="false"> No
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Hassle Free Exchanges And Returns For 30 Days</label><br>
									<input type="radio" name="hassleFreeExchangesAndReturnsFor30Days" v-model="product.hassleFreeExchangesAndReturnsFor30Days" value="true"> Yes
									<input type="radio" name="hassleFreeExchangesAndReturnsFor30Days" v-model="product.hassleFreeExchangesAndReturnsFor30Days" value="false"> No
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Warranty months</label><br>
									<input type="number" name="warrantyMonths" v-model="product.warrantyMonths" class="form-control" placeholder="Enter warranty months">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Buy Now Pay Later</label><br>
									<input type="radio" name="buyNowPayLater" v-model="product.buyNowPayLater" value="true"> Yes
									<input type="radio" name="buyNowPayLater" v-model="product.buyNowPayLater" value="false"> No
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Description</label>
									<textarea name="description" id="description" v-model="product.description" class="form-control" placeholder="Enter product description" rows="5"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Save now</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div v-else>
					<h3>
						<span class="label label-warning">
						Seller profile not available. Please create a seller profile first <router-link :to="{ name: 'sellerProfile'}">here</router-link></span>
					</h3>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ProductDepartmentService from "@/services/ProductDepartmentService";
	import ProductCategoryService from "@/services/ProductCategoryService";
	import ProductService from "@/services/ProductService";
	import SellerService from "@/services/SellerService";
	import Product from "@/models/Product";

	export default{
		name: "NewProduct",
		components: {
			loading
		},

		data: function () {
			return {
				categories: [],
				suppliers: [],
				productFields: [],
				label: "Processing request",
				show: false,
				product: new Product,
				departments: [],
				seller: null,
				message: null
			}
		},

		created: function(){
			this.findByAssociatedAccountUsername();
			this.findAllProductCategories();
			this.findAllProductDepartments();
		},

		methods: {
			findAllProductCategories: function(){
				ProductCategoryService.findAll().then(response => {
					this.categories = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllProductDepartments: function(){
				ProductDepartmentService.findAll().then(response => {
					this.departments = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			findByAssociatedAccountUsername: function(){
				SellerService.findByAssociatedAccountUsername(this.getUser.username).then(response => {
					console.log(response.data.body)
					this.seller = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			save: function(){
				this.show = true;
				this.product.sellerId = this.seller.id;
				ProductService.save(this.product).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "new-product-photos", params: {productNumber: response.data.body.productNumber }});
					}
				}).catch(error => {
					this.show = false;
					console.log(error); 
				});
			}

		},

		computed: {
			getUser(){
				return this.$store.state.auth.user.user;
			}
		}
	};
</script>