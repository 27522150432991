<template>
  <div>
    <h4>All Users</h4>

    <router-link :to="{ name: 'users/new' }">
      <button class="btn btn-success pull-right">New User
        <span class="glyphicon glyphicon-plus"></span></button>
    </router-link><br/><br/>

    <form class="form">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Search</label>
            <input
              type="text"
              v-model="searchValue"
              placeholder="Enter First Name, Last Name, Email or Username"
              class="form-control"
              v-on:input="getFilteredUsers"
            />
          </div>
        </div>
      </div>
    </form>

    <table class="table table-bordered table-responsive">
      <thead class="bg-primary">
        <th>ID</th>
        <th>Names</th>
        <th>Username</th>
        <th>Roles</th>
        <th>Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="user in filteredUsers" v-bind:key="user.id">
          <td>{{ user.id }}</td>
          <td>
            {{ user.firstName === null ? "Not available" : user.firstName }}
            {{ user.lastName === null ? "Not available" : user.lastName }}
          </td>
          <td>{{ user.username }}</td>
          <td>
            <ul class="list-group" v-for="role in user.roles" :key="role.id">
              <li class="list-group-item">{{ role.name }}</li>
            </ul>
          </td>
          <td>
            <span class="label label-success" v-if="user.status === 'ACTIVE'">{{ user.status }}</span>
            <span class="label label-default" v-if="user.status === 'INACTIVE'">{{ user.status }}</span>
          </td>
          <td>
            <router-link :to="{ name: 'users/view', params: { userId: user.id } }">
              <button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button> </router-link
            >&nbsp;
            <router-link :to="{ name: 'users/edit', params: { userId: user.id } }">
              <button class="btn btn-warning">Edit <span class="glyphicon glyphicon-pencil"></span></button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
import UserService from "@/services/UserService.js";

export default {
  name: "UserList",
  components: {},

  data: function() {
    return {
      users: [],
      searchValue: null,
      filteredUsers: []
    };
  },

  created: function() {
    this.findAll();
  },

  methods: {
    findAll: function() {
      UserService.findAll()
        .then(response => {
          if (response.data.statusCode === 200) {
            this.users = response.data.body;
            this.filteredUsers = this.users;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    getFilteredUsers: function() {
      const users = this.users;
      const filteredData = users.filter(user => {
        console.log(this.searchValue);
        return (
          user.username
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          user.firstName
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          user.lastName
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          user.email.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      });
      this.filteredUsers = filteredData;
    }
  }
};
</script>
