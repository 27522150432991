import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class ProductCategoryService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "productCategories");
  }

  save(productCategory) {
    return axios.post(GENERIC_SERVICE_URL + "productCategories", {
    	productCategoryId: productCategory.productCategoryId,
      	name: productCategory.name,
      	description: productCategory.description,
      	status: productCategory.status
      });
	}
}

export default new ProductCategoryService();
