<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<h4>Checkout Option</h4>
				<hr>
				<div class="row">
					<div class="col-md-4">
						<p>You can choose to opt for collection in person or add delivery to your doorstep for your convinience !</p>
					</div>
					<router-link :to="{ name: 'checkout' }">
						<div class="col-md-4">
							<button class="btn btn-warning">Collect products in person</button>
							<br><br>
							<p>More hassle</p>
							<p>High chances of breakages for hired transport</p>
							<p>More expensive</p>
						</div>
					</router-link>
					<router-link :to="{ name: 'products/delivery-address' }">
						<div class="col-md-4">
							<button class="btn btn-primary">Add delivery service <span class="glyphicon glyphicon-plus"></span></button>
							<br><br>
							<p>Less hassle</p>
							<p>Delivery to your doorstep</p>
							<p>Less chances of breakages, our delivery service offering handles your products more careful than your own hired transport</p>
							<p class="text-danger">More affordable and reduces your transportation costs</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default{
		name: "MyProductList",
		components: {
			
		},

		data: function(){
			return {
				show: false,
				label: "Processing request",
				cartProducts: [],
			}
		},

		mounted(){
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts") || '[]');
			console.log(this.cartProducts);
		},

		methods: {

		}
	};
</script>