<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<router-link :to="{ name: 'banks' }" replace><button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button></router-link>

		<router-link :to="{ name: 'banks/bankBranches/new', params: { bankId: getBankId }}" replace><button class="btn btn-success pull-right">New Branch <span class="glyphicon glyphicon-plus"></span></button></router-link><br/><br/>

		<h4 class="text-center">All Branches</h4>
		<table class="table table-responsive table-bordered">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Branch Name</th>
				<th>Description</th>
				<th>Status</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="bankBranch in bankBranches" :key="bankBranch.id">
					<td>{{ bankBranch.id }}</td>
					<td>{{ bankBranch.branchName }}</td>
					<td>{{ bankBranch.branchDescription }}</td>
					<td>
						<span class="label label-success" v-if="bankBranch.branchStatus == 1">Active</span>
						<span class="label label-default" v-if="bankBranch.branchStatus == 0">Inactive</span>
					</td>
					<td>
						<router-link :to="{ name: 'banks/bankBranches/edit', params: { branchId: bankBranch.id }}" replace><button class="btn btn-primary">Edit</button></router-link>&nbsp;
						<router-link :to="{ name: 'banks/bankBranches/view', params: { branchId: bankBranch.id }}" replace><button class="btn btn-primary">View</button></router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	import BankBranchService from "@/services/BankBranchService";
	import loading from "vue-full-loading";

	export default{
		name: "BankBranchList",
		components: {
			loading
		},
		data: function(){
			return {
				show: false,
				label: 'Processing...',
				bankBranches: []
			}
		},
		computed: {
			getBankId(){
				return this.$route.params.bankId;
			}
		},
		created(){
			this.getAllBankBranches(this.getBankId);
			console.log("getBankId: ", this.getBankId);
		},
		methods: {
			getAllBankBranches(bankId){
				this.show = true;
				BankBranchService.getAllBankBranches(bankId).then(response => {
					this.bankBranches = response.data.data;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>