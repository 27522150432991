<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<fieldset>
      				<legend>Submit Company Registration Documents</legend>
      				<form class="form" @submit.prevent="submitCompanyRegistrationDocuments">
						<div class="form-group">
							<label>Attach File</label>
							<input type="file" name="attachment" class="form-control" v-on:change="selectAttachment" accept="application/pdf" required>
						</div>
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
      			</fieldset>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import JobService from "@/services/JobService";
	import Job from "@/models/Job";

	export default{
		name: "SubmitCompanyRegistrationDocuments",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				job: new Job,
				label: "Processing request",
				show: false,
				formData: new FormData
			}
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},

		methods: {
			selectAttachment: function(event){
				this.formData.append("attachment", event.target.files[0]);
			},

			submitCompanyRegistrationDocuments(){
				this.show = true;
				this.formData.append("jobId", this.getJobId);
				JobService.submitCompanyRegistrationDocuments(this.formData).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "submit-reference-certificates", params: { jobId: this.getJobId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>