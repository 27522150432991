<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <form class="form" v-on:submit.prevent="register">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="salutation">Salutation</label>
            <select v-model="user.salutation" class="form-control" required>
              <option value="" disabled>Select option</option>
              <option value="MR">Mr</option>
              <option value="MRS">Mrs</option>
              <option value="MISS">Miss</option>
              <option value="MS">Ms</option>
              <option value="DOCTOR">Doc</option>
              <option value="PROFESSOR">Prof</option>
              <option value="SIR">Sir</option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <label>First Name</label>
          <input type="text" v-model="user.firstName" placeholder="Enter first name" class="form-control" required/>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <label>Last Name</label>
          <input type="text" v-model="user.lastName" placeholder="Enter last name" class="form-control" required/>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Email</label>
            <input type="email" v-model="user.email" placeholder="Enter email address" class="form-control" autocomplete="on" required/>
          </div>
        </div>
      </div>

      <div v-if="phoneNumberFields.length > 0">
        <div v-for="(phoneNumberField, phoneNumberIndex) in phoneNumberFields" :key="phoneNumberField.id">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="zipCodeId">Country Code</label>
                <select class="form-control" v-model="phoneNumberField.zipCodeId" required>
                  <option value="" disabled>Select option</option>
                  <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="number">Phone Number</label>
                <input type="number" name="number" class="form-control" v-model="phoneNumberField.number" minlength="9" maxlength="10" placeholder="Enter phone number" required>
              </div>              
            </div>
            <div class="col-md-3">
              <div class="form-group" v-show="phoneNumberFields.length > 1">
                <label>&nbsp;</label><br>
                <button class="btn btn-danger btn-sm pull-left" type="button" @click="phoneNumberFields.splice(phoneNumberIndex, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
              </div>
            </div>
          </div>          
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Password</label>
            <input :type="fieldType" v-model="user.password" placeholder="Enter password" class="form-control" autocomplete="on" required/>
            <p class="text-danger"><i>Use mixture of character, special symbols and digits</i></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Confirm password</label>
            <input :type="fieldType" v-model="user.confirmPassword" placeholder="Enter password confirmation" class="form-control" autocomplete="on" required/>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label>&nbsp;</label><br>
            <button class="btn btn-primary" @click="showPasswords" type="button">{{ btnText }}</button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <div class="form-group">
            <label>&nbsp;</label>
            <button class="btn btn-success btn-block">Create account</button>
          </div>
        </div>
        <div class="col-md-4"></div>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";
  import ZipCodeService from "@/services/ZipCodeService.js";
  import UserService from "@/services/UserService.js";

  export default {
    components: {
      loading
    },
    data() {
      return {
        message: null,
        show: false,
        label: "Registering profile",
        user: new User,
        selectedRoles: [],
        phoneNumberFields: [{
          zipCodeId: null,
          number: null
        }],
        zipCodes: [],
        btnText: "Show passwords",
        fieldType: 'password',
      };
    },

    methods: {
      getZipCodes: function() {
        this.show = true;
        return ZipCodeService.findAll().then(response => {
          this.zipCodes = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      register() {
        this.show = true;
        this.user.personType = "USER";
        let roles = [{ name: 'CARRIER' }];
        this.user.roles = roles;
        this.user.phoneNumbers = this.phoneNumberFields;
        UserService.registerDeliveryServiceUser(this.user).then(response => {
          localStorage.setItem('username', JSON.stringify(response.data.body.username));
          this.show = false;
          this.$router.push({ name: 'new-business-profile'});
        }, error => {
          this.message = error.response.data.message;
          this.show = false;
        });
      },

      showPasswords: function(){
        if (this.fieldType === 'password') {
          this.btnText = "Hide passwords";
          this.fieldType = 'text';
        }else{
          this.fieldType = 'password';
          this.btnText = "Show passwords";
        }
      },
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },

    created() {
      this.getZipCodes();      
    }
  };
</script>
