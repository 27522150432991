<template>
  <div>
    <template>
      <loading :show="show" :label="label">
        <div class="processing-row" slot="loading-container">
          <div class="text-center">
            <div slot="loading-spinner">
              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
            </div>
            <p class="text-center" slot="loading-text">{{ label }}</p>
          </div>
        </div>
      </loading>
    </template>

    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <h3 class="text-center">Create your user account</h3>
        <router-link to="login">
          <p class="text-center">Already have a Skilled Bios account? <a href="#">Please sign in here</a></p>
        </router-link>
        <div class="panel">
          <div class="panel-header">
            <div class="header-title"></div>
          </div>
          <div class="panel-body">
            <p class="text-danger text-center" v-if="message !== null">{{ message }}</p>
            <form class="form" v-on:submit.prevent="handleRegister">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="salutation">Salutation</label>
                      <select v-model="user.salutation" class="form-control" required>
                        <option value="" disabled>Select option</option>
                        <option value="MR">Mr</option>
                        <option value="MRS">Mrs</option>
                        <option value="MISS">Miss</option>
                        <option value="MS">Ms</option>
                        <option value="DOCTOR">Doc</option>
                        <option value="PROFESSOR">Prof</option>
                        <option value="SIR">Sir</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label>First name</label>
                    <input type="text" v-model="user.firstName" placeholder="Enter first name" class="form-control" required/>
                    <p class="error-messages" v-if="errors">
                      {{ errors.firstName }}
                    </p>
                  </div>
                  <div class="col-sm-4">
                    <label>Last name</label>
                    <input type="text" v-model="user.lastName" placeholder="Enter last Name" class="form-control" required/>
                    <p class="error-messages" v-if="errors">
                      {{ errors.lastName }}
                    </p>
                  </div>
                </div>
              </div>
              
              <div class="row">
                <!-- <div class="col-md-4">
                  <div class="form-group">
                    <label for="salutation">Register As</label>
                    <select v-model="selectedRoles" class="form-control" required>
                      <option value="" disabled>Select option</option>
                      <option value="USER">Regular user</option>
                      <option value="CARRIER">Delivery Service (registration fees apply)</option>
                    </select>
                  </div>
                </div> -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Username</label>
                    <input type="text" v-model="user.username" placeholder="Enter your desired username" class="form-control" autocomplete="on" required/>
                    <p class="error-messages" v-if="errors">
                      {{ errors.username }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="email" v-model="user.email" placeholder="Enter your email" class="form-control" autocomplete="on" required/>
                    <p class="error-messages" v-if="errors">{{ errors.email }}</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Country code</label>
                    <select class="form-control" v-model="phoneNumber.zipCodeId" required>
                      <option value="" disabled>Select option</option>
                      <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Phone number</label>
                    <input type="number" v-model="phoneNumber.phoneNumber" placeholder="Enter Phone Number e.g 123456789" class="form-control" required/>
                    <p class="error-messages" v-if="errors">
                      {{ errors.phoneNumber }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Password</label>
                    <input :type="fieldType" v-model="user.password" placeholder="Enter password" class="form-control" autocomplete="on" required/>
                    <p class="text-danger"><i>Use mixture of character, special symbols and digits</i></p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Confirm password</label>
                    <input :type="fieldType" v-model="user.confirmPassword" placeholder="Enter password confirmation" class="form-control" autocomplete="on" required/>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>&nbsp;</label><br>
                    <button class="btn btn-primary" @click="showPasswords" type="button">{{ btnText }}</button>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <br>
                  <p> By creating an account you agree to our <router-link to="termsAndConditions">Terms of Use</router-link></p>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>&nbsp;</label>
                    <button class="btn btn-success btn-block" type="submit">
                      <strong>Submit</strong>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";
  import ZipCodeService from "@/services/ZipCodeService.js";

  export default {
    components: {
      loading
    },

    data() {
      return {
        errors: [],
        message: "",
        show: false,
        user: new User,
        label: 'Processing request',
        phoneNumber: {
          zipCodeId: null,
          phoneNumber: null
        },
        phoneNumbers: [],
        zipCodes: [],
        btnText: "Show passwords",
        fieldType: 'password',
        selectedRoles: []
      };
    },

    methods: {
      findAllZipCodes: function(){
        ZipCodeService.findAll().then(response => {
          this.zipCodes = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      handleRegister() {
        this.show = true;
        if (this.user.password !== this.user.confirmPassword) {
          alert("Passwords do not match");
          this.show = false;
          return;
        }
        this.user.personType = "USER";
        //let roles = [{ name: this.selectedRoles }];
        let roles = [{ name: 'USER' }];
        this.user.roles = roles;
        this.phoneNumbers.push(this.phoneNumber);
        this.user.phoneNumbers = this.phoneNumbers;
        localStorage.setItem('username', JSON.stringify(this.user.username));
        this.$store.dispatch("auth/register", this.user).then(response => {
            if(response.data.statusCode === 201){
              this.message = response.data.message;
              this.user = new User;
              this.$router.push({ name: "otp" });
            }
            this.show = false;
          }, error => {
            this.errors = error.response.data.errors;
            this.message = error.response.data.message;
            this.show = false;
          });
      },

      logOut() {
        this.$store.dispatch("auth/logout");
      },

      showPasswords: function(){
        if (this.fieldType === 'password') {
          this.btnText = "Hide passwords";
          this.fieldType = 'text';
        }else{
          this.fieldType = 'password';
          this.btnText = "Show passwords";
        }
      },

    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },

    mounted() {
      this.findAllZipCodes();
    }
  };
</script>

<style type="text/css" scoped>
.error-messages {
  color: red;
}
</style>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
