<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('CARRIER_ASSIGNED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">{{ carrierAssignedOrders }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Carrier Assigned
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-2">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('DRIVER_ASSIGNED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">{{ loadingOrders }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/shipped.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Loading
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-2">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('IN_TRANSIT')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">{{ enrouteOrders }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivery-truck.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								In transit
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('DELIVERY_NEEDS_PAPERWORK')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">{{ deliveredOrders }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checklist.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Delivered
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-2">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('RECENTLY_DELIVERED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-success">{{ confirmedDeliveryOrders }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivered.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Recently delivered
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<CarrierAssigned v-show="selectedComponent === 'CARRIER_ASSIGNED'" :orders="orders"/>
			
		<DriverAssignedLoads v-show="selectedComponent === 'DRIVER_ASSIGNED'" :orders="orders"/>
		<InTransitLoads v-show="selectedComponent === 'IN_TRANSIT'" :orders="orders"/>
		<DeliveryNeedsPaperWorkLoads v-show="selectedComponent === 'DELIVERY_NEEDS_PAPERWORK'" :orders="orders"/>
		<RecentlyDeliveredLoads v-show="selectedComponent === 'RECENTLY_DELIVERED'" :orders="orders"/>
	</div>
</template>

<script type="text/javascript">
	import CarrierAssigned from "@/views/buyer-shipments/CarrierAssigned";
	import DriverAssignedLoads from "@/views/buyer-shipments/DriverAssignedLoads";
	import InTransitLoads from "@/views/buyer-shipments/InTransitLoads";
	import DeliveryNeedsPaperWorkLoads from "@/views/buyer-shipments/DeliveryNeedsPaperWorkLoads";
	import RecentlyDeliveredLoads from "@/views/buyer-shipments/RecentlyDeliveredLoads";
	import OrderService from "@/services/OrderService";
	import loading from "vue-full-loading";

	export default{
		data: function(){
			return {
				label: "Processing request",
				show: false,
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent')),
				orders: []
			}
		},

		components: {
			loading,
			CarrierAssigned,
			DriverAssignedLoads,
			InTransitLoads,
			DeliveryNeedsPaperWorkLoads,
			RecentlyDeliveredLoads
		},

		mounted(){
			this.findByLoadOwnerUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    carrierAssignedOrders(){
		    	return this.orders.filter(order => {
		    		return order.status === 'ACCEPTED' && !order.released
		    	}).length;
		    },

		    loadingOrders(){
		    	return this.orders.filter(order => {
		    		return order.status === 'BOOKED' && order.released;
		    	}).length;
		    },

		    enrouteOrders(){
		    	return this.orders.filter(order => {
		    		return order.status === 'IN_TRANSIT' && order.released && order.collected;
		    	}).length;
		    },

		    deliveredOrders(){
		    	return this.orders.filter(order => {
		    		return order.status === 'DELIVERED' || order.status === 'DELIVERED_WITH_DEFECTS' && order.invoice !== null && order.released && order.collected && order.delivered
		    	}).length;
		    },

		    confirmedDeliveryOrders(){
		    	return this.orders.filter(order => {
		    		return order.status === 'BOOKED' && order.invoice !== null && order.released && order.collected && order.delivered && order.confirmedDelivery && order.status === 'DELIVERY_CONFIRMED';
		    	}).length;
		    },
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByLoadOwnerUsername: function() {
				this.show = true;
		        OrderService.findAll(this.getUser.username).then(response => {
		        	this.orders = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    }
		}
	}
</script>