<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>
      	
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>Volunteering job details</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'volunteering-job-search'}">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br>
				<ul class="list-group" v-if="job !== null">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">Job Number: {{ job.jobNumber }}</li>
					<li class="list-group-item">Job Title: {{ job.title }}</li>
					<li class="list-group-item">Type: {{ job.type }}</li>
					<li class="list-group-item">Category: {{ job.category !== null ? job.category.name: '' }}</li>
					<li class="list-group-item">Remuneration: {{ job.remunerationCurrency }}{{ job.remuneration }} per hour</li>
					<li class="list-group-item">Expiry Date: {{ job.expiryDate }}</li>
					<li class="list-group-item">Skills Level: {{ job.skillsLevel }}</li>
					<li class="list-group-item">Availability: {{ job.availability }}</li>
					<li class="list-group-item">Estimated Budget: {{ job.estimatedBudget }}</li>
					<li class="list-group-item">Expertise Level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Project Type: {{ job.projectType }}</li>
					<li class="list-group-item">Commitment hours per week: {{ job.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Total Hours of service: {{ job.totalHoursOfService }}</li>
					<li class="list-group-item">Project length: {{ job.projectLengthInMonths }}</li>
					<li class="list-group-item">Description: {{ job.description }}</li>
					<li class="list-group-item">Internal Or External: {{ job.internalOrExternal }}</li>
					<li class="list-group-item">External Link: {{ job.externalLink }}</li>
					<li class="list-group-item">
						<a :href="PDF_API_URL + 'pdf/getJobAttachment/' + job.id" target="_blank">View Job Attachment</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";
	import loading from "vue-full-loading";

	export default{
		name: "ViewJob",
		components: {
			loading
		},

		data: function(){
			return {
				job: null,
				PDF_API_URL: PDF_API_URL,
				show: false,
				label: "Processing request"
			}
		},

		mounted(){
			this.findById();
		},

		methods: {
			findById: function(){
				this.show = true;
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},
	};
</script>