import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class ProductDeliveryAddressService {
  save(deliveryAddress) {
    return axios.post(GENERIC_SERVICE_URL + "deliveryAddresses" + "/", {
      id: deliveryAddress.id,
      location: deliveryAddress.location,
      username: deliveryAddress.username
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "deliveryAddresses");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "deliveryAddresses/" + id);
  }
}
export default new ProductDeliveryAddressService;
