export default class GrantAndFundingOpportunity{
	constructor(grantAndFundingOpportunityCategoryId, grantAndFundingOpportunityId, title, 
		estimatedBudget, currency, expiryDate, description, status){
		this.grantAndFundingOpportunityCategoryId = grantAndFundingOpportunityCategoryId;
		this.grantAndFundingOpportunityId = grantAndFundingOpportunityId;
		this.title = title;		
		this.estimatedBudget = estimatedBudget;
		this.currency = currency;
		this.expiryDate = expiryDate;
		this.description = description;
		this.status = status;
	}
}