<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img
              src="@/assets/images/station-loading.gif"
              height="100"
              width="100"
            />
          </div>
          <p class="text-center" slot="loading-text">Processing...</p>
        </div>
      </div>
    </loading>

    <h4>{{ editing ? "Edit": "New" }} Grant And Funding Opportunity Category</h4>
    <br/>
    <p v-if="message !== null" class="error-message text-center">{{ message }}</p>
    <form class="form" @submit.prevent="save">
      <div class="form-group">
        <label> Name</label>
        <input type="text" v-model="grantAndFundingOpportunityCategory.name" placeholder="Enter Grant And Funding Opportunity Category Name" class="form-control"/>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.name">{{ error.name }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>Description</label>
        <input type="text" v-model="grantAndFundingOpportunityCategory.description" placeholder="Enter Grant And Funding Opportunity Category Description" class="form-control"/>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.description">{{ error.description }}</span>
          </p>
        </div>
      </div>

      <div class="form-group">
        <label>Status</label>
        <select v-model="grantAndFundingOpportunityCategory.status" class="form-control">
          <option value="">Select status</option>
          <option value="ACTIVE">Active</option>
          <option value="INACTIVE">Inactive</option>
          <option value="DELETED">Deleted</option>
        </select>
        <div v-if="errors">
          <p v-for="error in errors" :key="error.id" class="error-message">
            <span v-if="error.status">{{ error.status }}</span>
          </p>
        </div>
      </div>
      <div class="form-group">
        <label>&nbsp;</label>
        <router-link :to="{ name: 'grantAndFundingOpportunityCategories' }" replace>
          <button class="btn btn-default" data-dismiss="modal">Cancel</button>
        </router-link>&nbsp;
        <button class="btn btn-success pull-right">Submit</button>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import GrantAndFundingOpportunityCategoryService from "@/services/GrantAndFundingOpportunityCategoryService";
  import GrantAndFundingOpportunityCategory from "@/models/GrantAndFundingOpportunityCategory";
  import loading from "vue-full-loading";

  export default {
    name: "NewGrantAndFundingOpportunityCategory",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request...",
        grantAndFundingOpportunityCategory: new GrantAndFundingOpportunityCategory,
        errors: [],
        message: null,
        editing: false
      };
    },

    mounted(){
      if("grantAndFundingOpportunityCategoryId" in this.$route.params){
        this.editing = true;
        this.findById();
      }
    },

    methods: {
      save: function() {
        this.show = true;
        GrantAndFundingOpportunityCategoryService.save(this.grantAndFundingOpportunityCategory).then(response => {
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "grantAndFundingOpportunityCategories" });
          }
          this.show = false;
        }).catch(error => {
          this.message = error.response.data.message;
          this.show = false;
          });
      },

      findById: function(){
        GrantAndFundingOpportunityCategoryService.findById(this.$route.params.roleId).then(response => {
          this.grantAndFundingOpportunityCategory = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>
