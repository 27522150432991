<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10"></div>
          <router-link :to="{ name: 'carousel-promo-list' }">
            <div class="col-md-2">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
        <br>

        <h5 class="error-message text-center" v-if="message != null">{{ message }}</h5>
        <form class="form" @submit.prevent="save">
          <fieldset>
            <legend>Carousel Promo</legend>
            <div class="form-group">
              <label>Headline</label><br />
              <input type="text" class="form-control" v-model="headline" placeholder="Enter headline" maxlength="20" required/>
            </div>

            <div class="form-group">
              <label>Link</label>
              <input type="text" class="form-control" placeholder="Enter link" v-model="link"/>
            </div>

             <div class="form-group">
              <label for="appraisalNote">Attach image</label><br />
              <input type="file" class="form-control" v-on:change="selectCarouselPromo" accept="image/jpeg,image/jpg,image/png,image/gif" required/>
            </div>

            <div class="form-group">
              <label for="appraisalComments">Promo description</label>
              <textarea class="form-control" v-model="description" placeholder="Enter Promo description max 50 characters" rows="5" maxlength="50"></textarea>
            </div>
          </fieldset>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="submit">&nbsp;</label>
                <button class="btn btn-success btn-block pull-right" type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import CarouselPromoService from "@/services/CarouselPromoService.js";

  export default {
    name: "NewCarouselPromo",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        message: null,
        formData: new FormData,
        headline: null,
        link: null,
        description: null
      };
    },
    
    methods: {
      selectCarouselPromo: function(event) {
        this.formData.append("carouselPromoAttachment", event.target.files[0]);
      },

      save: function() {
        this.show = true;
        this.formData.append("carouselPromoId", null); // check if save or update and set this value appropriately
        this.formData.append("headline", this.headline);
        this.formData.append("link", this.link);
        this.formData.append("description", this.description);
        CarouselPromoService.save(this.formData).then(response => {
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "carousel-promo-list" });
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>
