<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<CarouselPromo/>
      	
		<div class="row">
			<div class="col-md-3">
				<h4>Filter/Search</h4>
				<div class="panel">
					<div class="panel-body">
						<form class="form">
							<div class="form-group">
								<label>Country</label>
								<select class="form-control" v-model="countryId">
									<option value="">All countries</option>
									<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>Category</label>
								<select class="form-control" v-model="categoryId">
									<option value="">All categories</option>
									<option v-for="jobCategory in jobCategories" :key="jobCategory.id" :value="jobCategory.id">{{ jobCategory.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn black-btn btn-block" type="button" @click="clearFilter()">Clear filter</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<h4>Browse jobs and get bucks!</h4>
				<div v-if="paginatedJobs.length > 0">
					<div class="panel" v-for="job in paginatedJobs" :key="job.id">
						<div class="panel-body">
							<div class="row">
								<div class="col-md-3">
									<img src="@/assets/images/contractJobs.png" width="100px" height="100px" class="img img-responsive img-rounded">
								</div>
								<div class="col-md-3">
									<p>Title: {{ job.title }}</p>
									<p>Reference: {{ job.jobNumber }}</p>
								</div>
								<div class="col-md-3">
									<p>USD300{{ job.remunerationCurrency }}{{ job.remuneration }}</p>
									<p>Applications <span class="badge badge-primary">{{ paginatedJobs.length }}</span></p>
								</div>
								<div class="col-md-3">
									<p>
										<router-link :to="{ name: 'view-tender-job', params: { jobId: job.id }}">
											<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
										</router-link>&nbsp;
										<router-link :to="{ name: 'submit-proposal-bid', params: { jobId: job.id }}">
											<button class="btn btn-success">Submit Proposal <span class="glyphicon glyphicon-edit"></span></button>
										</router-link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="paginatedJobs.length === 0">
					<div class="panel">
						<div class="panel-body">
							<h4>No jobs available at the moment. Check back again soon.</h4>
						</div>
					</div>
				</div>
				<div class="row" v-if="totalPages > 1">
					<div class="col-md-7"></div>
					<div class="col-md-3 pull-right">
						<div class="panel">
							<div class="panel-body">
								<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
								&nbsp;<span>Page {{ currentPage }} of {{ totalPages }}</span>&nbsp;
								<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage"></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import JobCategoryService from "@/services/JobCategoryService";
	import loading from "vue-full-loading";
	import CountryService from "@/services/CountryService";
	import CityService from "@/services/CityService";
	import CarouselPromo from "@/views/carousel/CarouselPromo";

	export default{
		name: "TenderJobList",
		components: {
			loading,
			CarouselPromo
		},

		data: function(){
			return {
				jobTitleId: null,
				jobCategories: [],
				jobs: [],
				show: false,
				label: "Processing request",
				countries: [],
				cities: [],
				currentPage: JSON.parse(localStorage.getItem('currentPage')) !== null ? JSON.parse(localStorage.getItem('currentPage')): 1,
				itemsPerPage: 10,
				countryId: null,
				categoryId: null
			}
		},

		mounted: function(){
			this.countryId = JSON.parse(localStorage.getItem('countryId'));
			this.categoryId = JSON.parse(localStorage.getItem('categoryId'));

			this.findAllCities();
			this.findJobCategories();
			this.getAllCountries();
			this.findAll();
		},

		methods: {
			findAllCities: function(){
				CityService.findAll().then(response => {
					this.cities = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			prevPage(){
		    	if (this.currentPage > 1) {
		    		const currentPage = --this.currentPage;
		    		localStorage.setItem('currentPage', JSON.stringify(currentPage));
		    	}
		    },

		    nextPage(){
		    	if (this.currentPage < this.totalPages) {
		    		const currentPage = ++this.currentPage;
		    		localStorage.setItem('currentPage', JSON.stringify(currentPage));
		    	}
		    },

			findAll: function(){
				this.show = true;
				JobService.findAll().then(response => {
					this.jobs = response.data.body.filter(job => {
						return job.type === 'TENDER'
					});
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			findJobCategories: function(){
				this.show = true;
				JobCategoryService.findAll(this.job).then(response => {
					this.jobCategories = response.data.body;
					this.show = false;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			getAllCountries: function(){
				this.show = true;
				CountryService.findAll(this.job).then(response => {
					this.countries = response.data.body;
					this.show = false;
					return Promise.resolve(response);
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			clearFilter(){
				localStorage.removeItem('countryId');
		    	localStorage.removeItem('categoryId');
		    	window.location.reload();
		    }
		},

		computed: {
		    totalPages(){
		    	return Math.ceil(this.filteredJobs.length / this.itemsPerPage);
		    },

		    filteredJobs(){
		    	let filteredJobs;
		    	if ((this.countryId !== null && this.countryId !== undefined) || 
		    		(this.categoryId !== null && this.categoryId !== undefined)) {
		    		localStorage.setItem('countryId', JSON.stringify(this.countryId));
		    		localStorage.setItem('categoryId', JSON.stringify(this.categoryId));
		    		filteredJobs = this.jobs.filter(job => {
		    			return job.location.country.id === this.countryId || job.category.id === this.categoryId
		    		});
		    	}else{
		    		filteredJobs = this.jobs;
		    	}
		    	return filteredJobs;
		    },

		    paginatedJobs(){
				const startIndex = (this.currentPage - 1) * this.itemsPerPage;
				return this.filteredJobs.slice(startIndex, startIndex + this.itemsPerPage);
			}
		}
	};
</script>