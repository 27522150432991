import { render, staticRenderFns } from "./SubmitTechnicalProposal.vue?vue&type=template&id=45a828bc&"
import script from "./SubmitTechnicalProposal.vue?vue&type=script&lang=js&"
export * from "./SubmitTechnicalProposal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports