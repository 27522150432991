<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<CarouselPromo/>

      	<h4>Browse products from our multiple sellers in our e-commerce and buy at knockdown prices!</h4>
		<div class="row">
			<div class="col-md-2">
				<div class="panel">
					<div class="panel-body">
						<select class="form-control">
							<option value="">All countries</option>
							<option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
						</select>
						<hr>
						<p>Refine by Category</p>
						<input type="checkbox" name=""> All Categories<br><br>
						<span v-for="productCategory in productCategories" :key="productCategory.id">
							<input type="checkbox" name=""> {{ productCategory.name }} <br><br>
						</span>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<div class="row">
					<div class="panel">
						<div class="panel-body">
							<div class="col-md-2">
								<p>{{ products.length }} results</p>
							</div>
							<div class="col-md-8">
								<p>Sort by:</p>
								<select class="form-control">
									<option value="">Relevance</option>
									<option value="">Price: High to Low</option>
									<option value="">Price: Low to High</option>
									<option value="">Top rated</option>
									<option value="">Newest arrivals</option>
								</select>
							</div>
							<router-link :to="{ name: 'product-cart'}">
								<div class="col-md-2">
									<button class="btn btn-danger btn-block">View Cart</button><br><br>
								</div>
							</router-link>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3" v-for="(product, index) in products" :key="product.id">
						<!-- <p>Yay: {{ index }}</p> -->
						<div class="panel">
							<div class="panel-body">
								<!-- <h4>Product photos</h4> -->
								<div class="row">
									<div class="col-md-6">
										<p>{{ product.name }}</p>
									</div>
									<div class="col-md-6">
										<p><span class="label label-success" v-if="product.premium">Featured</span></p>
									</div>
								</div>
						        <div class="row">
						          <div class="col-md-6">
						            <img :src="GENERIC_SERVICE_URL + 'photos/' + product.photos[0].id" class="img img-responsive img-rounded product-image">
						          </div>
						        </div>
								<!-- <p>{{ product.percentageDiscount }}% off <span class="pull-right">Like/Dislike</span></p> -->
								<p>{{ product.percentageDiscount }}% off</p>
								<p>Current price: {{ product.currency }}{{ product.price }}</p>
								<!-- <p>Current price: {{ product.currency }}{{ product.price }}. Was <del>{{ product.currency }}{{ product.unitPrice }}</del></p> -->
								<p><span class="badge">JHB</span> <span class="badge">CPT</span></p>
								<p>
									<button :class="product.addedToCart ? 'btn btn-success': 'btn btn-primary' " @click="addProductToCart(index, product)" type="button">{{ product.addedToCart ? "Added to cart": "Add to cart" }} <span class="glyphicon glyphicon-plus"></span>
									</button>&nbsp;
									<router-link :to="{ name: 'view-product', params: { productId: product.id }}">
										<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProductService from "@/services/ProductService";
	import ProductCategoryService from "@/services/ProductCategoryService";
	import loading from "vue-full-loading";
	import CountryService from "@/services/CountryService";
	import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
	import CarouselPromo from "@/views/carousel/CarouselPromo";

	export default{
		name: "MyProductList",
		components: {
			loading,
			CarouselPromo
		},

		data: function(){
			return {
				show: false,
				label: "Processing request",
				products: [],
				cartProducts: [],
				addedToCart: false,
				productCategories: [],
				countries: [],
				GENERIC_SERVICE_URL: GENERIC_SERVICE_URL
			}
		},

		mounted(){
			this.findAll();
			this.cartProducts = JSON.parse(localStorage.getItem("cartProducts") || '[]');
			// localStorage.removeItem("cartProducts");
			console.log("cartProducts: ", this.cartProducts);
			this.findAllProductCategories();
			this.getAllCountries();
		},

		methods: {
			findAll: function(){
				this.show = true;
				ProductService.findAll().then(response => {					
					this.products = response.data.body;
					this.show = false;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			},

			// let searchFields = JSON.parse(localStorage.getItem('LS_searchFields') || '[]');
		 //    	if (this.LS_type !== null && this.LS_type !== undefined) {
			// 	    let searchField = {
			// 	    	'fieldName': 'LS_type',
			// 	    	'value': this.LS_type
			// 	    };
			// 	    const index = searchFields.findIndex((field) => field.fieldName === 'LS_type');
			// 	    if (index === -1) {
			// 	    	searchFields.push(searchField);
			// 	    }else{
			// 	    	searchFields[index] = searchField;
			// 	    }
		 //    		localStorage.setItem('LS_searchFields', JSON.stringify(searchFields));
		 //    	}

			addProductToCart(productIndex, addedProduct){
				// why not create a product transformation that maps to cart products and also add a quantity field so we can have each produyct already have a defined quantity field and other revelant fields
				// get the first product in the product list
				let firstProductInCart = null;
				if (this.cartProducts !== null && this.cartProducts !== undefined && this.cartProducts.length > 0) {
					firstProductInCart = this.cartProducts[0];
				}
				if (firstProductInCart !== null) {
					console.log('firstProductInCart: ', firstProductInCart);
					if (firstProductInCart.currency !== addedProduct.currency) {
						alert("Sorry, you can't add this product to cart because it is not in " + firstProductInCart.currency + ". We do not support multi currency in single basket yet!");
						return;
					}
				}
				console.log('addedProduct: ', addedProduct);
				this.cartProducts = JSON.parse(localStorage.getItem('cartProducts') || '[]');
				const index = this.cartProducts.findIndex((product) => product.id === addedProduct.id);
				console.log('index: ', index);
				if (index === -1) {
					this.cartProducts.push(addedProduct);
					this.products[productIndex].addedToCart = true;
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
				}else{
					this.cartProducts[index] = addedProduct;
				}
				console.log('cartProducts: ', this.cartProducts);
			},

			// do not duplicate the product in list
			addToCart: function(index, product){

				if(this.products[index].addedToCart){
					this.products[index].addedToCart = false;
					console.log("remove from cartProducts");
					this.cartProducts.splice(index, 1);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
					console.log(this.cartProducts);
				}else{
					this.products[index].addedToCart = true;
					console.log("add to cartProducts");
					this.cartProducts.push(product);
					localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
					console.log("cartProducts: ", this.cartProducts);
				}
			},

			findAllProductCategories: function(){
				this.show = true;
				ProductCategoryService.findAll().then(response => {
					this.productCategories = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			getAllCountries: function(){
				this.show = true;
				CountryService.findAll().then(response => {
					this.countries = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},
		}
	};
</script>

<style type="text/css" scoped>
  .product-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>