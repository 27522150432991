<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<loading :show="show" :label="label">
			        <div class="processing-row" slot="loading-container">
			          <div class="text-center">
			            <div slot="loading-spinner">
			              <img
			                src="@/assets/images/station-loading.gif"
			                height="100"
			                width="100"
			              />
			            </div>
			            <p class="text-center" slot="loading-text">{{ label }}</p>
			          </div>
			        </div>
		      	</loading>

		      	<h4>What are you interested in ? We will help you customize your preferences.</h4><br>
		      	<form class="form" @submit.prevent="save">
					<div class="form-group" v-for="userPreference in userPreferences" v-bind:key="userPreference.name">
						<label>&nbsp;</label>
						<input type="checkbox" name="selectedPreferences" id="selectedPreferences" v-model="selectedPreferences" :value="userPreference.name"> {{ userPreference.name }}
					</div>

		      		<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success pull-right" type="submit">Submit preference</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import UserService from "@/services/UserService";
	import User from "@/models/User";

	export default{
		name: "NewCertifications",
		components: {
			loading
		},

		data: function(){
			return {
				selectedPreferences: [],
				user: new User,
				label: "Processing request...",
				show: false,
				userPreferences: [
				{
					name: 'SALES_LEADS',
				},
				{
					name: 'FULL_TIME_JOBS',
				},
				{
					name: 'CONTRACT_JOBS',
				},
								{
					name: 'VOLUNTEERING_JOBS',
				},
				{
					name: 'TENDERS'
				},
				{
					name: 'SKILLED_EXPERTS',
				},
				{
					name: 'GRANTS_AND_FUNDING_OPPORTUNITIES',
				}]
			}
		},

		created: function(){ },

		computed: {
			getPersonId: function(){
				return this.$route.params.userId;
			}
		},

		methods: {
			save: function(){
				this.show = true;
				let preferences = [];
				for(var i = 0; i < this.selectedPreferences.length; i++){
					preferences.push({
						preference: this.selectedPreferences[i]
					});
				}
				UserService.savePreference(this.getPersonId, preferences).then(response => {
					if(response.data.statusCode === 201){
						this.$router.push({ name: "login" });
					}
					this.show = false;
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>