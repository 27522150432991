import axios from 'axios';
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class ComplianceTypeService{
	findAll(){
		return axios.get(GENERIC_SERVICE_URL + "complianceTypes");
	}

	findById(id){
		return axios.get(GENERIC_SERVICE_URL + "complianceTypes/" + id);
	}

	save(complianceType){
		return axios.post(GENERIC_SERVICE_URL + "complianceTypes", {
			id: complianceType.id,
			name: complianceType.name,
			description: complianceType.description,
			status: complianceType.status
		});
	}
}

export default new ComplianceTypeService;