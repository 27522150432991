var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('router-link',{attrs:{"to":{ name: 'zipCodes/new' }}},[_c('button',{staticClass:"btn btn-success pull-right"},[_vm._v(" New "),_c('span',{staticClass:"fa fa-plus"})])]),_c('br'),_c('br'),_c('h4',[_vm._v("Zip Code List")]),_c('table',{staticClass:"table table-bordered table-responsive-md"},[_vm._m(0),_c('tbody',_vm._l((_vm.zipCodes),function(zipCode){return _c('tr',{key:zipCode.id},[_c('td',[_vm._v(_vm._s(zipCode.id))]),_c('td',[_vm._v("+"+_vm._s(zipCode.code))]),_c('td',[(zipCode.status === 'ACTIVE')?_c('span',[_c('span',{staticClass:"label label-success"},[_vm._v("Active")])]):_vm._e(),(zipCode.status === 'INACTIVE')?_c('span',[_c('span',{staticClass:"badge badge-secondary"},[_vm._v("Inactive")])]):_vm._e()]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'zipCodes/view',
                  params: { zipCodeId: zipCode.id }
                }}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v(" View "),_c('span',{staticClass:"fa fa-info"})])]),_vm._v("  "),_c('router-link',{attrs:{"to":{
                  name: 'zipCodes/edit',
                  params: { zipCodeId: zipCode.id }
                }}},[_c('button',{staticClass:"btn btn-warning"},[_vm._v(" Edit "),_c('span',{staticClass:"fa fa-pencil"})])])],1)])}),0)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-primary"},[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Code")]),_c('th',[_vm._v("Status")]),_c('td',[_vm._v("Actions")])])
}]

export { render, staticRenderFns }