<template>
	<div>
		<h4>Job category list</h4>
		<div class="row">
			<div class="col-md-10"></div>
			<div class="col-md-2">
				<router-link :to="{ name: 'new-job-category' }">
					<button class="btn btn-success btn-block">New <span class="glyphicon glyphicon-plus"></span></button>
				</router-link>
			</div>
		</div>
		<br>

		<v-client-table :columns="columns" :data="jobCategories" :options="options">
			<td slot="id" slot-scope="{ row }">{{ row.id }}</td>
			<td slot="name" slot-scope="{ row }">{{ row.name }}</td>
			<td slot="country" slot-scope="{ row }">{{ row.description }}</td>
			<td slot="status" slot-scope="{ row }">
				<span class="label label-success" v-if="row.status === 'ACTIVE'">{{ row.status }}</span>
				<span class="label label-primary" v-else>{{ row.status }}</span>
			</td>
			<td slot="actions" slot-scope="{ row }">
				<router-link :to="{ name: 'view-job-category', params: { jobCategoryId : row.id }}">
					<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
				</router-link>
			</td>
		</v-client-table>
	</div>
</template>

<script type="text/javascript">
	import JobCategoryService from "@/services/JobCategoryService";

	export default{
		name: 'JobCategoryList',
		data: function(){
			return {
				jobCategories: [],
				columns: [ 'id', 'name', 'description', 'status', 'actions' ],
				options: {
		          headings: {
		            id: h => this.a11yCols(h, 'ID'),
		            name: h => this.a11yCols(h, 'Name'),
		            description: h => this.a11yCols(h, 'Description'),
		            status: h => this.a11yCols(h, 'Status'),
		            actions: h => this.a11yCols(h, 'Actions'),
		          },
		          sortable: [ 'id', 'name', 'country', 'description', 'status' ],
		          filterable: [ 'id', 'name', 'country', 'description', 'status' ],
		        }
			}
		},

		created(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				JobCategoryService.findAll().then(response => {
					this.jobCategories = response.data.body
				}).catch(error => {
					console.log(error);
				});
			},
			a11yCols: function(h, header) {
		        return h("a", {
		        	domProps: {
		        		href: "javascript:function a() { return false }",
		            	innerHTML: header
		            }
		        });
		    }
		},

		computed: {}
	}
</script>