<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <p class="error-message" v-if="message !== null">{{ message }}</p>
        <h4>Are you sure you want to set the company <span class="text-danger">{{ company !== null ? company.name: 'Not available' }}</span> to verified?</h4>
        <hr>
        <form class="form" @submit.prevent="verify">
          <div class="row">
            <router-link :to="{ name: 'delivery-services'}">
              <div class="col-md-2">
                <button class="btn btn-danger btn-block" type="sumbit">Cancel</button>
              </div>
            </router-link>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-success btn-block" type="sumbit">Verify</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import Company from "@/models/Company.js";
  import loading from "vue-full-loading";

  export default {
    name: "VerifyCompany",
    components: {
      loading
    },

    data: function() {
      return {
        company: new Company(),
        show: false,
        label: "Processing request...",
        message: null
      };
    },

    mounted() {
      this.findById();
    },

    computed: {
      getId(){ 
        return this.$route.params.id; 
      }
    },

    methods: {
      findById: function() {
        this.show = true;
        CompanyService.findById(this.getId).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
          }
        this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      verify: function() {
        this.show = true;
        CompanyService.verify(this.getId).then(response => {
          if (response.data.statusCode == 422) {
            this.setNotificationData(response);
          }
          if (response.data.statusCode === 400) {
            this.setNotificationData(response);
          }
          if (response.data.statusCode == 201) {
            this.$router.push({ name: "delivery-services" });
          }
        this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    },
  };
</script>
