<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-11">
                    <h4>Basic delivery charge details</h4>
                </div>
                <router-link :to="{ name: 'basic-delivery-charge-list' }">
                    <div class="col-md-1">
                        <button class="btn btn-primary btn-block">Back</button>
                    </div>
                </router-link>
            </div>
        </div>
    </div>

    <div class="panel">
        <div class="panel-body">
            <ul class="list-group" v-if="basicDeliveryCharge !== null">
                <li class="list-group-item">ID: {{ basicDeliveryCharge.id }}</li>
                <li class="list-group-item">Amount: {{ basicDeliveryCharge.currency }}{{ basicDeliveryCharge.amount }}</li>
                <li class="list-group-item">Active: <span class="label label-primary">{{ basicDeliveryCharge.active }}</span></li>
                <li class="list-group-item">Current: <span class="label label-primary">{{ basicDeliveryCharge.current }}</span></li>
                <li class="list-group-item">Enabled: <span class="label label-primary">{{ basicDeliveryCharge.enabled }}</span></li>
                <li class="list-group-item">Expired: <span class="label label-primary">{{ basicDeliveryCharge.expired }}</span></li>
                <li class="list-group-item">Status: {{ basicDeliveryCharge.status }}</li>
                <li class="list-group-item">Description: {{ basicDeliveryCharge.description }}</li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script type="text/javascript">
	import BasicDeliveryChargeService from "@/services/BasicDeliveryChargeService";
	import loading from "vue-full-loading";

	export default {
	  name: "ViewTreatyReinsurance",
	  components: {
	    loading
	  },

	  data: function() {
	    return {
	      show: false,
	      label: "Processing request",
	      basicDeliveryCharge: null
	    };
	  },

	  computed: {
	    getId() {
	      return this.$route.params.id;
	    }
	  },

	  mounted() {
	    this.findById();
	  },

	  methods: {
	    findById: function() {
	    	BasicDeliveryChargeService.findById(this.getId).then(response => {
	    		this.show = false;
	    		this.basicDeliveryCharge = response.data.body;
	    	}).catch(error => {
	    		console.log(error);
	    		this.show = false;
	        });
	    }
	  }
	};
</script>
