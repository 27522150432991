<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img
	                src="@/assets/images/station-loading.gif"
	                height="100"
	                width="100"
	              />
	            </div>
	            <p class="text-center" slot="loading-text">Processing...</p>
	          </div>
	        </div>
      	</loading>

		<router-link :to="{ name: 'configurations' }" replace><button class="btn btn-primary"><span class="glyphicon glyphicon-step-backward"></span> Back</button></router-link>

		<router-link :to="{ name: 'banks/new' }" replace><button class="btn btn-success pull-right">New Bank <span class="glyphicon glyphicon-plus"></span></button></router-link><br/><br/>

		<h4 class="text-center">All Banks</h4>
		<table class="table table-responsive table-bordered">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Bank Name</th>
				<th>Bank Description</th>
				<th>Bank status</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="bank in banks" :key="bank.id">
					<td>{{ bank.id }}</td>
					<td>{{ bank.bankName }}</td>
					<td>{{ bank.bankDescription }}</td>
					<td>
						<span class="label label-success" v-if="bank.bankStatus == 1">Active</span>
						<span class="label label-default" v-if="bank.bankStatus == 0">Inactive</span>
					</td>
					<td>
						<router-link :to="{ name: 'banks/edit', params: { bankId: bank.id }}" replace><button class="btn btn-primary">Edit</button></router-link>&nbsp;
						<router-link :to="{ name: 'banks/view', params: { bankId: bank.id }}" replace><button class="btn btn-primary">View</button></router-link>&nbsp;
						<router-link :to="{ name: 'banks/bankBranches', params: { bankId: bank.id }}" replace><button class="btn btn-primary">Branches</button></router-link>&nbsp;
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	import BankService from "@/services/BankService";
	import loading from "vue-full-loading";

	export default {
		name: 'BankList',
		components: {
			loading
		},
		data: function () {
			return {
				show: false,
				label: 'Processing...',
				banks: []
			}
		},
		created(){
			this.getAllBanks();
		},
		methods: {
			getAllBanks(){
				this.show = true;
				BankService.getAllBanks().then(response => {
					this.show = false;
					this.banks = response.data.data;
				}).catch(error => {
					this.show = false;
					console.log(error);
				});
			}
		}
	}
</script>

<style type="text/css" scoped>
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.error-message {
  color: red;
}
</style>