import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class ZipCodeService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "zipCodes");
  }

  findById(zipCodeId) {
    return axios.get(GENERIC_SERVICE_URL + "zipCodes/" + zipCodeId);
  }

  save(zipCode) {
    return axios.post(GENERIC_SERVICE_URL + "zipCodes", {
      zipCodeId: zipCode.zipCodeId,
      code: zipCode.code,
      description: zipCode.description,
      status: zipCode.status
    });
  }
}

export default new ZipCodeService();
