<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10"></div>
					<div class="col-md-2">
						<router-link :to="{ name: 'professionals-search' }">
							<button class="btn btn-primary btn-block">Back</button>
						</router-link>
					</div>
				</div>
				<br><br>

				<div class="row">
					<div class="col-md-2">
						<img src="@/assets/images/professional-1024x768.jpeg" height="100" width="150" class="img img-rounded">
					</div>
					<div class="col-md-8">
						<h4>Profile SB ID: {{ professional.uuid }}</h4>
						<!-- <p>{{ professional.city === null ? "Not available": professional.city.name }}, {{ professional.country === null ? "Not available": professional.country.name }} - {{ new Date() }}</p> -->
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'professionals/recommend', params: { professionalId: professional.id }}">
							<button class="btn btn-success pull-right">Recommend <span class="glyphicon glyphicon-edit"></span></button>
						</router-link>
					</div>
				</div><hr>
				<div class="row">
					<div class="col-md-3">
						<h4>Hours per week: {{ professional.availability.replace('_', ' ') }}</h4>
						<!-- <h4>Languages 
							<span class="badge" v-for="language in professional.languages" :key="language.id">{{ language.name }}</span>
						</h4>
						<h4>Education</h4>
						<div v-for="education in professional.educations" :key="education.id">
							<p>
							<span class="badge">{{ education.school === null ? "Not available": education.school.name }}</span> -  
							<span class="badge">{{ education.degree === null ? "Not available": education.degree.name }}</span>
							</p>
						</div> -->
					</div>
					<div class="col-md-9">
						<div class="row">
							<div class="col-md-6">
								<h4><b>{{ professional.title }}</b></h4>
							</div>
							<div class="col-md-6">
								<h4>{{ professional.serviceCharge === null ? "NA": professional.serviceCharge.currency }} {{ professional.serviceCharge === null ? "NA": professional.serviceCharge.hourlyRate }}/hr</h4>
							</div>
						</div><hr>
						<p>{{ professional.overview }}</p>
						<hr>
						<h4>Skills</h4>
						<span class="badge" v-for="skill in professional.skills" :key="skill.id">{{ skill.name }}</span>
					</div>
				</div>
				<hr>

				<h4>Certifications</h4>
				<span class="badge" v-for="certification in professional.certifications" :key="certification.id">{{ certification.name }}</span>
				<hr>

				<h4>Testimonials</h4>
				<span class="badge" v-for="testimonial in professional.testimonials" :key="testimonial.id">{{ testimonial.name }}</span>
				<hr>

				<h4>Other Experiences</h4>
				<span class="badge" v-for="otherExperience in professional.otherExperiences" :key="otherExperience.id">{{ otherExperience.name }}</span>
				<hr>

				<h4>Preferences</h4>
				<span class="badge" v-for="preference in professional.preferences" :key="preference.id">{{ preference.name }}</span>
				<hr>

				<h4>Recommendations</h4>
				<span class="badge" v-for="recommendation in professional.recommendations" :key="recommendation.id">{{ recommendation.name }}</span>
				<h4></h4>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ProfessionalService from "@/services/ProfessionalService";
	import loading from "vue-full-loading";

	export default{
		name: "ViewProfessional",
		components: {
			loading
		},

		data: function(){
			return {
				professional: null,
				show: false,
				label: "Processing request..."
			}
		},

		mounted: function(){
			this.findById();
		},

		computed: {
			getProfessionalId: function(){ return this.$route.params.professionalId; }
		},

		methods: {
			findById: function(){
				this.show = true;
				ProfessionalService.findById(this.getProfessionalId).then(response => {
					this.professional = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	};
</script>

<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>