<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>
      	
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10"></div>
					<router-link :to="{ name: 'full-time-jobs'}">
						<div class="col-md-2">
							<button class="btn btn-primary btn-block">Back</button>
						</div>
					</router-link>
				</div>

				<h4>Full time job details</h4>
				<ul class="list-group" v-if="job !== null">
					<li class="list-group-item"><img src="@/assets/images/contractJobs.png" height="150" width="250"></li>
					<li class="list-group-item">Job number: {{ job.jobNumber }}</li>
					<li class="list-group-item">Job title: {{ job.title }}</li>
					<li class="list-group-item">Type: {{ job.type }}</li>
					<li class="list-group-item">Category: {{ job.category !== null ? job.category.name: '' }}</li>
					<span v-if="job.type === 'FULL_TIME'">
						<li class="list-group-item">Remuneration or salary: {{ job.remunerationCurrency }}{{ job.remuneration }} per hour</li>
					</span>
					<span v-if="job.type === 'CONTRACT'">
						<li class="list-group-item">Hourly Rate: {{ job.currency }} {{ job.hourlyRate }} per hour</li>
					</span>
					<li class="list-group-item">Expiry date: {{ job.expiryDate }}</li>
					<li class="list-group-item">Expertise level: {{ job.expertiseLevel }}</li>
					<li class="list-group-item">Commitment hours per week: {{ job.commitmentHoursPerWeek }}</li>
					<li class="list-group-item">Description: {{ job.description }}</li>
					<li class="list-group-item">Internal or external: {{ job.internalOrExternal }}</li>
					<li class="list-group-item">External link: {{ job.externalLink }}</li>
					<li class="list-group-item">
						<a :href="PDF_API_URL + 'pdf/getJobAttachment/' + job.id" target="_blank">View Job Attachment</a>
					</li>
					<li class="list-group-item" v-if="job.location !== null">
						Job location
						<ul class="list-group">
							<li class="list-group-item">Country: {{ job.location.country.name }}</li>
							<li class="list-group-item">Province: {{ job.location.province.name }}</li>
							<li class="list-group-item">City: {{ job.location.city.name }}</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";
	import loading from "vue-full-loading";

	export default{
		name: "ViewJob",
		components: {
			loading
		},

		data: function(){
			return {
				job: null,
				PDF_API_URL: PDF_API_URL,
				show: false,
				label: "Processing request"
			}
		},

		mounted(){
			this.findById();
		},

		methods: {
			findById: function(){
				this.show = true;
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			}
		},
	};
</script>