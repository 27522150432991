<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>View Contract Jobs Quotations</h4>
					</div>
					<router-link :to="{ name: 'my-contract-jobs-configuration'}">
						<div class="col-md-2">
							<button class="btn black-btn btn-block">Close</button>
						</div>
					</router-link>
				</div>
				<br><br>

				<ul class="list-group" v-if="job !== null">
					<span v-if="job.quotations.length > 0">
						<li class="list-group-item">Job Number: {{ job.jobNumber }}</li>
						<li class="list-group-item">Quotations
							<br><br>
							<span v-if="job.quotations !== null">
								<ul class="list-group" v-for="(quotation, index) in job.quotations" :key="quotation.id">
									<li class="list-group-item">Quote: {{ index + 1 }}</li>
									<li class="list-group-item">Description: {{ quotation.description }}</li>
									<li class="list-group-item">Attachment: {{ quotation.attachment }}</li>
									<li class="list-group-item">Skilled professional profile:
									 <router-link :to="{ name: 'professionals/view', params: { professionalId: quotation.professional.id }}">
									 	View professional
									 </router-link>
									</li>
								</ul>
							</span>
						</li>
					</span>
					<span v-else>
						<h4 class="text-danger">No quotes at the moment. Please check back later!</h4>
					</span>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";

	export default{
		name: "ViewContractJobQuotation",
		component: {

		},

		data: function(){
			return {
				job: null
			}
		},

		created: function(){
			this.findById();
		},

		computed: {
			getJobId(){
				return this.$route.params.jobId;
			}
		},

		methods: {
			findById: function(){
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		}
	};
</script>