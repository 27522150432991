import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";
import authHeader from "@/services/authHeader";

class GenderService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "genders");
  }

  save(gender) {
    return axios.post(GENERIC_SERVICE_URL + "genders", {
      genderName: gender.genderName,
      genderDescription: gender.genderDescription,
      genderStatus: gender.genderStatus
    });
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "genders/" + id);
  }

  updateById(gender, id) {
    return axios.put(
      GENERIC_SERVICE_URL + "genders/" + id,
      {
        id: gender.id,
        genderName: gender.genderName,
        genderDescription: gender.genderDescription,
        genderStatus: gender.genderStatus
      },
      { headers: authHeader() }
    );
  }
}

export default new GenderService();
