<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<div class="row">
		      		<div class="col-md-10">
		      			<h4>Job applications</h4>
		      		</div>
		      		<router-link :to="{ name: 'full-time-jobs' }">
		      			<div class="col-md-2">
		      				<button class="btn btn-primary btn-block">Back</button>
		      			</div>
		      		</router-link>
		      	</div>
		      	<br>

		      	<div v-if="job !== null">
		      		<div v-if="job.applications.length > 0">
		      			<table class="table table-responsive table-bordered table-hover">
				      		<thead class="bg-primary">
				      			<th>ID</th>
				      			<th>Applicant</th>
				      			<th>Actions</th>
				      		</thead>
				      		<tbody>
				      			<tr v-for="application in job.applications" :key="application.id">
					      			<td>{{ application.id }}</td>
					      			<td>
					      				<router-link :to="{ name: 'view-professional', params: { professionalId: application.professional.id }}">
					      					{{ application.professional !== null ? application.professional.user.firstName + ' ' + application.professional.user.lastName: '' }}
					      				</router-link>
					      			</td>
					      			<td>
					      				<form class="form" @submit.prevent="sendOfferLetter(application.professional.user.username)">
					      					<div class="col-md-2">
					      						<label>&nbsp;</label>
					      						<button class="btn btn-primary">Send offer letter</button>
					      					</div>
					      				</form>
					      			</td>
					      		</tr>
				      		</tbody>
				      	</table>
		      		</div>
		      		<div v-else>
		      			<p>This job does not have any applications yet!</p>
		      		</div>
		      	</div>
      		</div>
      	</div>	
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import OfferLetterService from "@/services/OfferLetterService";
	import loading from "vue-full-loading";

	export default{
		name: "ViewJob",
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: "Processing request",
				job: null
			}
		},

		mounted(){
			this.findById();
		},

		methods: {
			findById: function(){
				this.show = true;
				JobService.findById(this.getJobId).then(response => {
					this.job = response.data.body;
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			},

			sendOfferLetter: function(username){
				this.show = true;
				OfferLetterService.sendOfferLetter(this.getJobId, username).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'full-time-jobs' });
					}
					this.show = false;
				}).catch(error => {
					return Promise.reject(error);
				});
			}
		},

		computed: {
			getJobId: function(){
				return this.$route.params.jobId;
			},

			getUser(){
				return this.$store.state.auth.user.user;
			}
		},
	};
</script>