<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

		<div class="row">
			<div class="col-md-3">
				<h4>Filter/Search</h4>
				<div class="panel">
					<div class="panel-body">
						<form class="form">
							<div class="form-group">
								<label>Type</label>
								<select class="form-control">
									<option value="">Choose option</option>
									<option value="FULL_TIME">FULL_TIME</option>
									<option value="PART_TIME">PART_TIME</option>
									<option value="VOLUNTEERING">VOLUNTEERING</option>
									<option value="TENDER">TENDER</option>
								</select>
							</div>
							<div class="form-group">
								<label>Title</label>
								<select class="form-control" v-model="jobTitleId">
									<option value="" disabled>Select option</option>
									<option v-for="jobTitle in jobTitles" :key="jobTitle.id" :value="jobTitle.id">{{ jobTitle.name }}</option>
								</select>
							</div>
							<div class="form-group">
								<label>Rate</label>
								<input type="rate" name="rate" id="rate" placeholder="Enter rate" class="form-control">
							</div>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success">Search...</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div class="col-md-9">
				<h4>Browse jobs and get bucks!</h4>
				<div v-if="jobs.length > 0">
					<div class="panel" v-for="job in jobs" :key="job.id">
						<div class="panel-body">
							<div class="row">
								<div class="col-md-3">
									<img src="@/assets/images/contractJobs.png" height="150" width="250">
								</div>
								<div class="col-md-3">
									<p>Job Title: {{ job.jobTitle !== null ? job.jobTitle.name: "Not available" }}</p>
									<p>Job Number: {{ job.jobNumber }}</p>
									<span v-if="job.type === 'FULL_TIME'">
										<p>{{ job.currency }} {{ job.fixedRate }} per month</p>
									</span>
									<span v-else>
										<p>{{ job.currency }} {{ job.hourlyRate }} per hour</p>
									</span>
									<p><span class="badge badge-primary">{{ job.type }}</span></p>
									<p>Applications <span class="badge badge-primary">{{ job.applications.length }}</span></p>
									<p>
										<router-link :to="{ name: 'jobs/view', params: { jobId: job.id }}">
											<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
										</router-link>&nbsp;
										<span v-if="job.internalOrExternal !== 'EXTERNAL'">
											<router-link :to="{ name: 'jobs/quickApply', params: { jobId: job.id }}">
												<button class="btn btn-success">Quick Apply <span class="glyphicon glyphicon-edit"></span></button>
											</router-link>
										</span>
										<span v-else>
											<router-link :to="{ name: 'jobs/quickApply', params: { jobId: job.id }}">
												<button class="btn btn-primary">Apply Externally <span class="glyphicon glyphicon-edit"></span></button>
											</router-link>
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<ul class="pagination">
						<li><a href="#">Prev</a></li>
						<li class="active"><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">Next</a></li>
					</ul>
				</div>
				<div v-else>
					<div class="panel">
						<div class="panel-body">
							<h4>No jobs available at the moment. Check back again soon.</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import JobService from "@/services/JobService";
	import JobTitleService from "@/services/JobTitleService";
	import loading from "vue-full-loading";

	export default{
		name: "JobList",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitleId: null,
				jobTitles: [],
				jobs: [],
				show: false,
				label: "Processing request..."
			}
		},

		mounted: function(){
			this.findAllJobTitles();
			this.findAll();
		},

		methods: {
			findAll: function(){
				this.show = true;
				JobService.findAll().then(response => {
					this.jobs = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllJobTitles: function(){
				this.show = true;
				JobTitleService.findAll(this.job).then(response => {
					this.jobTitles = response.data.body;
					this.show = false;
				}).catch(error => {
					console.log(error);
				});
			},
		}
	}
</script>

<style type="text/css" scoped>
.error-message {
  color: red;
}
.processing-row {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>