<template>
	<div>
		<loading :show="show" :label="label">
	        <div class="processing-row" slot="loading-container">
	          <div class="text-center">
	            <div slot="loading-spinner">
	              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	            </div>
	            <p class="text-center" slot="loading-text">{{ label }}</p>
	          </div>
	        </div>
      	</loading>

      	<div class="panel">
      		<div class="panel-body">
      			<h4>Add education</h4>
      			<hr>

				<form class="form" @submit.prevent="save">
					<button class="btn btn-success btn-sm pull-right" @click="addEducationField" type="button">Add <span class="glyphicon glyphicon-plus"></span></button>

					<div v-for="(educationField, index) in educationFields" :key="educationField.id">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Qualification Name</label>
									<input type="text" name="qualificationName" class="form-control" placeholder="Enter qualification name" v-model="educationField.qualificationName">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Qualification Type</label>
									<select class="form-control" v-model="educationField.qualificationTypeId">
										<option value="">Select option</option>
										<option v-for="qualificationType in qualificationTypes" :key="qualificationType.id" :value="qualificationType.id">{{ qualificationType.name }}</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>School/Institution</label>
									<input type="text" name="institution" class="form-control" v-model="educationField.institution" placeholder="Enter school or institution attended">
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Attended From</label>
									<input type="date" name="startDate" v-model="educationField.startDate" class="form-control">
								</div>
							</div>
							<div class="col-md-2">
								<div class="form-group">
									<label>Attended To</label>
									<input type="date" name="endDate" v-model="educationField.endDate" class="form-control">
								</div>
							</div>
							<div class="col-md-1">
								<div class="form-group" v-if="educationFields.length > 0">
									<label>&nbsp;</label>
									<button class="btn btn-danger btn-sm btn-block" type="button" @click="educationFields.splice(index, 1)">Remove</button>
								</div>
							</div>
						</div>
					</div><br><br>

					<div class="row">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group" v-if="educationFields.length > 0">
								<label>&nbsp;</label>
								<button class="btn btn-success btn-block" type="submit">Submit</button>
							</div>
						</div>
					</div>

					<div class="row" v-if="educationFields.length === 0">
						<div class="col-md-10"></div>
						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label>
								<router-link :to="{ name: 'newHourlyRate', params: { professionalId: this.getProfessionalId }}">
									<button class="btn btn-primary btn-block" type="submit">Next</button>
								</router-link>
							</div>
						</div>
					</div>
				</form>
      		</div>
      	</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import SchoolService from "@/services/SchoolService";
	import ProfessionalService from "@/services/ProfessionalService";
	import Professional from "@/models/Professional";
	import QualificationTypeService from "@/services/QualificationTypeService";

	export default{
		name: "NewEducation",
		components: {
			loading
		},

		data: function(){
			return {
				jobTitles: [],
				professional: new Professional,
				label: "Processing request",
				show: false,
				schools: [],
				educationFields: [],
				qualificationTypes: []
			}
		},

		created: function(){
			this.findAllSchools();
			this.findAllQualificationTypes();
		},

		computed: {
			getProfessionalId: function(){
				return this.$route.params.professionalId;
			}
		},

		methods: {
			findAllSchools: function(){
				SchoolService.findAll().then(response => {
					this.schools = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			findAllQualificationTypes: function(){
				QualificationTypeService.findAll().then(response => {
					this.qualificationTypes = response.data.body;
				}).catch(error => {
					console.log(error);
				});
			},

			addEducationField: function(){
				this.educationFields.push({
					id: null,
					institution: null,
					startDate: null,
					endDate: null,
					qualificationName: null,
					qualificationTypeId: null,
				});
			},

			save: function(){
				this.show = true;
				this.professional.educations = this.educationFields;
				this.professional.professionalId = this.getProfessionalId;
				ProfessionalService.save(this.professional).then(response => {
					this.show = false;
					if(response.data.statusCode === 201){
						this.$router.push({ name: "newHourlyRate", params: { professionalId: this.getProfessionalId }});
					}
				}).catch(error => {
					console.log(error.response);
					this.show = false;
					return Promise.reject(error);
				});
			}
		}
	};
</script>