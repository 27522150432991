import axios from "axios";
import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";

class CertificationService{
	findAll(){
		return axios.get(GENERIC_SERVICE_URL + "certifications");
	}

	findById(id){
		return axios.get(GENERIC_SERVICE_URL + "certifications/" + id);
	}

	save(certification){
		return axios.post(GENERIC_SERVICE_URL + "certifications", {
			certificationId: certification.certificationId,
			description: certification.description,
			status: certification.status,
			name: certification.name
		});
	}
}

export default new CertificationService;