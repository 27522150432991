<template>
	<div>
		<h4>Job Title List</h4>

		<h4>
			<router-link :to="{ name: 'jobTitles/new' }">
				<button class="btn btn-success pull-right">New <span class="glyphicon glyphicon-plus"></span></button>
			</router-link>
		</h4><br><br>

		<table class="table table-responsive table-bordered">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Name</th>
				<th>Description</th>
				<th>Status</th>
				<th>Actions</th>
			</thead>
			<tbody>
				<tr v-for="jobTitle in jobTitles" :key="jobTitle.id">
					<td>{{ jobTitle.id }}</td>
					<td>{{ jobTitle.name }}</td>
					<td>{{ jobTitle.description }}</td>
					<td>
						<span class="label label-success" v-if="jobTitle.status === 'ACTIVE'">{{ jobTitle.status }}</span>
						<span class="label label-primary" v-else>{{ jobTitle.status }}</span>
					</td>
					<td>
						<router-link :to="{ name: 'jobTitles/view', params: { jobTitleId : jobTitle.id }}">
							<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
						</router-link>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	import JobTitleService from "@/services/JobTitleService";

	export default{
		name: 'JobTitleList',
		data: function(){
			return {
				jobTitles: []
			}
		},

		created(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				JobTitleService.findAll().then(response => {
					this.jobTitles = response.data.body
				}).catch(error => {
					console.log(error);
				});
			}
		},

		computed: {}
	}
</script>