<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-11">
            <h4>Edit fuel price</h4>
          </div>
          <router-link :to="{ name: 'fuel-price-list' }">
            <div class="col-md-1">
              <button class="btn btn-primary btn-block">Back</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <form class="form" @submit.prevent="save" v-if="fuelPrice !== null">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Country</label>
                <select class="form-control" v-model="fuelPrice.countryId">
                  <option value="" disabled>Select option</option>
                  <option v-for="country in countries" :value="country.id" :key="country.id">{{ country.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <label>Diesel Amount</label>
              <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="fuelPrice.dieselAmount" placeholder="Enter diesel amount"/>
            </div>
            <div class="col-md-3">
              <label>Petrol Amount</label>
              <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="fuelPrice.petrolAmount" placeholder="Enter petrol amount"/>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Currency</label>
                <select v-model="fuelPrice.currency" class="form-control">
                  <option value="">Select status</option>
                  <option value="USD">USD</option>
                  <option value="ZWL">ZWL</option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>Active</label>&nbsp;
            <input type="radio" v-model="fuelPrice.active" value="true"/> Yes
            <input type="radio" v-model="fuelPrice.active" value="false"/> No
          </div>

          <div class="form-group">
            <label>Current</label>&nbsp;
            <input type="radio" v-model="fuelPrice.current" value="true"/> Yes
            <input type="radio" v-model="fuelPrice.current" value="false"/> No
          </div>

          <div class="form-group">
            <label>Enabled</label>&nbsp;
            <input type="radio" v-model="fuelPrice.enabled" value="true"/> Yes
            <input type="radio" v-model="fuelPrice.enabled" value="false"/> No
          </div>

          <div class="form-group">
            <label>Expired</label>&nbsp;
            <input type="radio" v-model="fuelPrice.expired" value="true"/> Yes
            <input type="radio" v-model="fuelPrice.expired" value="false"/> No
          </div>

          <div class="form-group">
            <label>Description</label>
            <textarea rows="5" placeholder="Enter description" class="form-control" v-model="fuelPrice.description"></textarea>
          </div>

          <div class="form-group">
            <label>Status</label>
            <select v-model="fuelPrice.status" class="form-control">
              <option value="">Select status</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>

          <div class="row">
            <div class="col-md-11"></div>
            <div class="col-md-1">
              <div class="form-group"><label>&nbsp;</label>
                <button class="btn btn-success btn-block">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import FuelPriceService from "@/services/FuelPriceService";
  import loading from "vue-full-loading";
  import CountryService from "@/services/CountryService";

  export default {
    name: "NewTreatyReinsurance",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        fuelPrice: null,
        errors: [],
        message: null,
        countries: []
      };
    },

    mounted(){
      this.findById();
      this.findAllCountries();
    },

    methods: {
      findById: function() {
        FuelPriceService.findById(this.getId).then(response => {
          this.show = false;
          this.fuelPrice = response.data.body;
        }).catch(error => {
          console.log(error);
          this.show = false;
          });
      },

      save: function() {
        this.show = true;
        FuelPriceService.save(this.fuelPrice).then(response => {        
          if (response.data.statusCode == 201) {
            this.show = false;
            this.$router.push({ name: "fuel-price-list" });
          }
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findAllCountries: function() {
        this.show = true;
        CountryService.findAll().then(response => {        
          this.countries = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    },

    computed: {
      getId() {
        return this.$route.params.id;
      }
    }
  };
</script>
