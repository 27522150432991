<template>
	<div>
		<h4>Product List</h4>
		<router-link to="new" replace>
			<button class="btn btn-success pull-right">New <span class="glyphicon glyphicon-plus"></span></button>
		</router-link><br><br>
		<table class="table table-bordered table-responsive">
			<thead class="bg-primary">
				<th>ID</th>
				<th>Requisition Number</th>
				<th>Status</th>
				<th>Action</th>
			</thead>

			<tbody>
				<tr v-for="requisition in requisitions" :key="requisition.id">
					<td>{{ requisition.id }}</td>
					<td>{{ requisition.requisitionNumber }}</td>
					<td>
						<span v-if="requisition.status === 'APPROVED'" class="label label-success">Approved</span>
						<span v-if="requisition.status === 'PENDING'" class="label label-warning">Pending</span>
						<span v-if="requisition.status === 'CLOSED'" class="label label-danger">Closed</span>
					</td>
					<td>
						<router-link :to="{ name: 'requisitions/view', params: { requisitionId : requisition.id }}">
							<button class="btn btn-primary">View <span class="glyphicon glyphicon-info-sign"></span></button>
						</router-link>&nbsp;
						<router-link :to="{ name : 'requisitions/edit', params: { requisitionId: requisition.id }}">
							<button class="btn btn-warning">Edit <span class="glyphicon glyphicon-pencil"></span></button>
						</router-link>&nbsp;
						<span v-if="requisition.status === 'PENDING'">
							<router-link :to="{ name : 'requisitions/approvals', params: { requisitionId: requisition.id }}">
								<button class="btn btn-warning">Approve</button>
							</router-link>
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	import RequisitionService from "@/services/RequisitionService";

	export default{
		data: function(){
			return {
				requisitions: []
			}
		},

		created: function(){
			this.findAll();
		},

		methods: {
			findAll: function(){
				RequisitionService.findAll().then(response => {
					this.requisitions = response.data.data;
				}).catch(error => {
					console.log(error);
				});
			}
		}
	}
</script>